import { Button, Empty, Popconfirm, Tabs, TabsProps, Tag } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { baiTapType } from '../../../Types/baiTapType';

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

type Props = {
    lstBaiTap: baiTapType[],
    //phan task
    lstPhanTask: PhanTaskType[],
    setLstPhanTask: Dispatch<SetStateAction<any>>
}

const ItemType = 'ITEM';

type ItemProps = {
    id: number;
    name: string;
};

type DragItem = {
    id: number;
    name: string;
};

type PhanTaskType = {
    id: number;
    title: string;
    value: TaskNhomType[]
}

type TaskNhomType = {
    viTri: number;
    taskList: number[]
}

const DraggableItem: React.FC<ItemProps> = ({ id, name }) => {
    const [, ref] = useDrag({
        type: ItemType,
        item: { id, name },
    });

    return <Tag ref={ref} color='purple' style={{ cursor: "move" }}><i className="fa-solid fa-up-down-left-right"></i> {name}</Tag>;
};

// id ở đây là vị trí của user nhận task
const DropBox = ({ id, lstTask, phanTask, lstPhanTask, setLstPhanTask, maNhom }: any) => {

    // hàm update item mới vào list đã có
    const updateItem = (list: PhanTaskType[], itemUpdate: PhanTaskType) => {
        const found = list.some((item: PhanTaskType) => item.id === itemUpdate.id);

        let newList: PhanTaskType[] = [];

        if (!found) {
            newList = [...list, itemUpdate];
        } else {
            newList = list.map((item: PhanTaskType) =>
                item.id === itemUpdate.id ? itemUpdate : item
            );
        }
        setLstPhanTask(newList);
    }

    const [, ref] = useDrop({
        accept: ItemType,
        drop: (item: DragItem) => {

            if (id != -1) {

                if (phanTask) {
                    // them task cho nhom moi
                    let itemUpdate: TaskNhomType = phanTask.value.find((n: TaskNhomType) => n.viTri == id);

                    if (itemUpdate.taskList.findIndex(n => n == item.id) == -1)
                        itemUpdate.taskList.push(item.id);

                    itemUpdate.taskList.sort((a, b) => a - b);

                } else {
                    // xủ lý khi chưa có data phan task
                    let valueNew: any = [];
                    for (let index = 1; index <= maNhom + 1; index++) {
                        valueNew.push({
                            viTri: index,
                            taskList: index == id ? [item.id] : []
                        })

                    }
                    phanTask = {
                        id: maNhom, title: `Nhóm  ${maNhom + 1} người`, value: valueNew
                    }

                }

            }


            // Duyệt qua tất cả các nhóm, nếu không phải là nhóm cần cập nhật thì xóa task ra khỏi danh sách tasks của chúng
            let newPhanTask = phanTask.value.map((n: TaskNhomType) => {
                if (n.viTri !== id) {
                    return { ...n, taskList: n.taskList.filter((taskId: number) => taskId !== item.id) };
                }
                return n;  // Giữ nguyên nhóm cần cập nhật
            });
            phanTask.value = newPhanTask;

            updateItem(lstPhanTask, phanTask);
        },
    });

    // xử lý task từng nhóm
    // lọc đúng vị trí phân task
    let userTask = phanTask && phanTask.value.find((n: TaskNhomType) => n.viTri == id);

    // lọc các vị trí để tìm task chưa phân
    let taskChuaPhan = [...lstTask];
    if (phanTask) {

        phanTask.value.map((item: TaskNhomType) => {
            item.taskList.map((idTask: number) => {
                let itemLstTask = taskChuaPhan.findIndex(n => n.id == idTask);
                taskChuaPhan.splice(itemLstTask, 1);
            })

        })
    }

    return id == -1 ?
        <div ref={ref} className='m-3 card p-0'>

            <div className="card-body">
                <blockquote className="blockquote mb-0">
                    {
                        taskChuaPhan.length > 0 ?
                            taskChuaPhan.map((item: DragItem) => {

                                return <DraggableItem id={item.id} name={item.name} />
                            }

                            )
                            :
                            <Empty description={<>Thả vào đây để xóa</>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }

                </blockquote>
            </div>

        </div>
        :

        <div ref={ref} className='m-3 col-3 card p-0'>

            <div className="card-header">
                Học viên {id}
            </div>
            <div className="card-body">
                <blockquote className="blockquote mb-0">
                    {userTask && userTask.taskList.map((n: number) => {

                        let item: DragItem = lstTask.find((m: ItemProps) => m.id == n)

                        return item && <DraggableItem id={item.id} name={item.name} />
                    })}  </blockquote>
            </div>

        </div>;
};


const PhanTaskUser = ({
    lstBaiTap,
    lstPhanTask,
    setLstPhanTask
}: Props) => {



    const [maNhom, setMaNhom] = useState(1);

    // loc phan task
    let phanTask = lstPhanTask.find((n: PhanTaskType) => n.id == maNhom);

    // lay so luong task
    let lstTask: ItemProps[] = [];
    lstBaiTap.map((item, index) => {
        let numTask = index + 1;
        lstTask.push({ id: numTask, name: "Yêu cầu " + numTask })
    })



    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Nhóm 2 người',
            children: <>
                <DndProvider backend={HTML5Backend}>
                    <div >

                        <DropBox id={-1} lstTask={lstTask} phanTask={phanTask} setLstPhanTask={setLstPhanTask} lstPhanTask={lstPhanTask} maNhom={maNhom} />

                        <div className='row'>

                            <DropBox id={1} lstTask={lstTask} phanTask={phanTask} setLstPhanTask={setLstPhanTask} lstPhanTask={lstPhanTask} maNhom={maNhom} />

                            <DropBox id={2} lstTask={lstTask} phanTask={phanTask} setLstPhanTask={setLstPhanTask} lstPhanTask={lstPhanTask} maNhom={maNhom} />

                        </div>
                    </div>
                </DndProvider>
            </>,
        },
        {
            key: '2',
            label: 'Nhóm 3 người',
            children: <>
                <DndProvider backend={HTML5Backend}>
                    <div >

                        <DropBox id={-1} lstTask={lstTask} phanTask={phanTask} setLstPhanTask={setLstPhanTask} lstPhanTask={lstPhanTask} maNhom={maNhom} />

                        <div className='row'>

                            <DropBox id={1} lstTask={lstTask} phanTask={phanTask} setLstPhanTask={setLstPhanTask} lstPhanTask={lstPhanTask} maNhom={maNhom} />

                            <DropBox id={2} lstTask={lstTask} phanTask={phanTask} setLstPhanTask={setLstPhanTask} lstPhanTask={lstPhanTask} maNhom={maNhom} />

                            <DropBox id={3} lstTask={lstTask} phanTask={phanTask} setLstPhanTask={setLstPhanTask} lstPhanTask={lstPhanTask} maNhom={maNhom} />
                        </div>
                    </div>
                </DndProvider>
            </>,
        },
        {
            key: '3',
            label: 'Nhóm 4 người',
            children: <>
                <DndProvider backend={HTML5Backend}>
                    <div >

                        <DropBox id={-1} lstTask={lstTask} phanTask={phanTask} setLstPhanTask={setLstPhanTask} lstPhanTask={lstPhanTask} maNhom={maNhom} />

                        <div className='row'>

                            <DropBox id={1} lstTask={lstTask} phanTask={phanTask} setLstPhanTask={setLstPhanTask} lstPhanTask={lstPhanTask} maNhom={maNhom} />

                            <DropBox id={2} lstTask={lstTask} phanTask={phanTask} setLstPhanTask={setLstPhanTask} lstPhanTask={lstPhanTask} maNhom={maNhom} />

                            <DropBox id={3} lstTask={lstTask} phanTask={phanTask} setLstPhanTask={setLstPhanTask} lstPhanTask={lstPhanTask} maNhom={maNhom} />

                            <DropBox id={4} lstTask={lstTask} phanTask={phanTask} setLstPhanTask={setLstPhanTask} lstPhanTask={lstPhanTask} maNhom={maNhom} />

                        </div>
                    </div>
                </DndProvider>
            </>,
        },
    ];

    const onChangeTab = (key: string) => {
        setMaNhom(+key)
    };

    return <>
    
        <span className='text-danger fst-italic'>*Kéo thả để phân cấu trúc yêu cầu cho nhóm </span>
        <Popconfirm
            title="Bạn có chắc muốn tiếp tục ?"
            onConfirm={() => {
                setLstPhanTask([]);
            }}
        >

            <button className='btn btn-sm btn-outline-primary' >Làm mới tất cả</button>
        </Popconfirm>
      
      
        <Tabs
            defaultActiveKey="1"
            tabPosition="left"
            onChange={onChangeTab}
            items={items}
        />
    </>
}

export default PhanTaskUser