import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import styled from "styled-components";
import { Skeleton } from "antd";

const ProjectDescriptionCss = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
  }
  .ProjectDescription-content {
    background-color: wheat;
    border-radius: 5px;
    min-height: 250px;
    padding: 20px 25px;
  }
`;

const ProjectDescription: React.FC = () => {
  const {  baiHoc } = useAppSelector(
    (state) => state.groupForTaskReducer
  );

  return (
    <ProjectDescriptionCss>
      <div className="header">
        {" "}
        <div className="heading ">
          <h4>Mô Tả Dự Án</h4>
        </div>
        <h4 className="heading">Deadline: Còn 3 ngày</h4>
      </div>
      {baiHoc?   <h5 className="ProjectDescription-content">{baiHoc?.moTa}</h5>:<Skeleton className="ProjectDescription-content" active/>}
   
    </ProjectDescriptionCss>
  );
};
export default ProjectDescription;
