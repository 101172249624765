import { Button, Drawer, Space, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useFormik } from "formik";
import * as Yup from "yup";
import { NguoiDungType } from "../../Types/nguoiDungType";
import { nguoiDungService } from "../../services/nguoiDungService";
import useQueryConfig from "../../hooks/useQueryConfig";
import { getNguoiDungPhanTrangApi } from "../../redux/UserReducer/userReducer";

type Props = {
  open: boolean;
  onClose: () => void;
};
const { Option } = Select;

const DrawerChinhSuaNguoiDung = ({ open, onClose }: Props) => {
  const { userChinhSua } = useAppSelector((state) => state.userReducer);

  const { arrNhomQuyen } = useAppSelector((state) => state.heThongReducer);
  const lstNhomQuyen: any[] = [];

  arrNhomQuyen?.forEach(item => {
    lstNhomQuyen.push({
      label: item.tenNhom || "",
      value: item.id
    })
  })




  const dispatch = useAppDispatch();
  const queryConfig = useQueryConfig();

  const formik = useFormik({
    initialValues: {
      hoTen: "",
      soDt: "",
      email: "",
      urls: "",
      nuocNgoai: true,
      guiMail: true,
      kinhNghiem: 0,
      tichCuc: 0,
      coin: 0,
      kichHoat: true,
      avatar: "",
      thongTinMoRong: "",
      maNhomQuyen: "",
      id: "",
      matKhau: "",
      ngayTao: "",
      maGioiThieu: "",
      iPaddress: "",
      ngayDangNhap: "",
      facebook:"",
      facebookId:"",
      maNguonDoiTac:""
    },
    validationSchema: Yup.object({
      hoTen: Yup.string().required("Không bỏ trống tên"),
      soDt: Yup.string().required("Không bỏ trống số điện thoại"),
      email: Yup.string()
        .email("Email chưa đúng định dạng")
        .required("Không bỏ trống email"),
    }),
    onSubmit: (values) => {

      let matKhau = values.matKhau == "" ? "" : values.matKhau;
      let thongTin = JSON.parse(values.thongTinMoRong);
      thongTin = {...thongTin, FacebookUrl: values.facebook}
      values.thongTinMoRong = JSON.stringify(thongTin);


      nguoiDungService
        .updateNguoiDung({
          ...values,
          matKhau: matKhau as string,
        })
        .then((res) => {
          dispatch(getNguoiDungPhanTrangApi(queryConfig));
          // nguoiDungService.checkPassNguoiDung();
          onClose();
        })
        .catch((err) => { });
    },
  });
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    values,
    initialValues,
  } = formik;

  useEffect(() => {
    if (userChinhSua) {

      formik.setValues(userChinhSua);
      // setValue for field matKhau
      formik.setFieldValue("matKhau", "");
      let thongTin = JSON.parse(userChinhSua?.thongTinMoRong);
      formik.setFieldValue("facebook", thongTin?.FacebookUrl);
    }
  }, [userChinhSua]);

  console.log(formik.values)
  return (
    <Drawer
      title="Chỉnh sửa thông tin người dùng"
      placement="right"
      onClose={onClose}
      open={open}
      size="large"
      width={720}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button onClick={onClose}>Đóng</Button>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            type="primary"
          >
            Lưu lại
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" hideRequiredMark onFinish={handleSubmit}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Họ tên">
              <Input
                name="hoTen"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Vui lòng nhập tên"
                value={values?.hoTen}
              />
              {touched.hoTen && errors.hoTen ? (
                <div className="mt-2 text-danger">{errors.hoTen}</div>
              ) : null}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email">
              <Input
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ width: "100%" }}
                placeholder="Vui lòng nhập email"
                value={values?.email}
              />
              {touched.email && errors.email ? (
                <div className="mt-2 text-danger">{errors.email}</div>
              ) : null}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Số Đt">
              <Input
                name="soDt"
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ width: "100%" }}
                placeholder="Vui lòng nhập số đt"
                value={values?.soDt}
              />
              {touched.soDt && errors.soDt ? (
                <div className="mt-2 text-danger">{errors.soDt}</div>
              ) : null}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Khu vực">
              <Select

                placeholder="Vui lòng chọn khu vực"
                value={values?.nuocNgoai}

                onChange={(value: boolean) => {
                  formik.setFieldValue("nuocNgoai", value);
                }}

                options={[
                  { value: true, label: "Nước ngoài" },
                  { value: false, label: "Việt Nam" }
                ]}
              />

            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Nhóm quyền" >
              <Select

                value={values?.maNhomQuyen}
                onChange={(value: string) => {
                  formik.setFieldValue("maNhomQuyen", value);
                }}
                options={lstNhomQuyen}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Facebook id" >
            <Input
                name="facebookId"
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ width: "100%" }}
                
                value={values?.facebookId}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Link facebook">
              <Input
                name="facebook"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.facebook}
                placeholder="Vui lòng nhập link facebook"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="matKhau" label="Mật khẩu">
              <Input.Password
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.matKhau}
                name="matKhau"
                placeholder="Vui lòng nhập mật khẩu"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default DrawerChinhSuaNguoiDung;
