import {chamCongType} from '../Types/chamCongType'
import { http } from '../util/config'
const GET_PATH: string = '/api/chamCongMT'

const themChamCong = (data:chamCongType|any)=>{
    return http.post(GET_PATH,data)
}
const getAllChamCong = ()=>{
    return http.get(GET_PATH)
}
const getChamCongByMentor = (id:string, month:string)=>{
    return http.get(`${GET_PATH}/${id}/${month}`)
}
const getChamCongTheoThang = (month:string)=>{
    return http.get(`${GET_PATH}/month/${month}`)
}
export const chamCongService={
    themChamCong,
    getAllChamCong,
    getChamCongByMentor,
    getChamCongTheoThang
}