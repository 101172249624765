import React from 'react'
import { NavLink } from 'react-router-dom'

type Props = {}

const BottomTab = (props: Props) => {
    return (
        <div style={{marginTop:'20vh'}}>
            <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', height: '10vh' }} className='row bg-dark text-light p-3 text-center display-4'>
                <div className='col-4'>
                    <NavLink className={'text-light'} to={'/login'}>
                        <i className='fa fa-user'></i>
                    </NavLink>
                </div>
                <div className='col-4'>
                    <NavLink className={'text-light'} to={'/'}>
                        <i className='fa fa-home'></i>
                    </NavLink>
                </div>
                <div className='col-4'>
                    <NavLink className={'text-light'} to={'/carts'}>
                        <i className='fa fa-cart-plus'></i>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default BottomTab