import { Button, Col, Form, Input, Row, Tooltip } from 'antd'
import React, { CSSProperties, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { useRef } from 'react'
import UseConnection from '../../hooks/useHub'
import { ChatModel, MESSModel } from '../../Types/groupChatType'
import {
  getGroupChatByDsChatByChuyenDeApi,
  postGroupChatApi,
  putGroupChatApi,
  sendmessChuyenDeApi,
  sendmessGFTApi
} from '../../redux/GroupChatReducer/groupChatReducer'
import jsonB from 'json-bigint'
import { config } from '../../util/config'
import { NguoiDungType } from '../../Types/nguoiDungType'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { HubConnection } from '@microsoft/signalr'
import { putoptionalApi } from '../../redux/IssueReducer/IssueReducer'
import { IssueModel } from '../../Types/issueType'

const SendMessageCSS = styled.div`
  margin-top: 1rem;
  .sendMessage {
    overflow: auto;
    border-radius: 10px;
    margin: auto;
  }

  .inputCss {
    overflow: auto;
    border-radius: 10px;
  }
`

export const getTodate = () => {
  const currentDate = new Date()
  return currentDate.toLocaleString().slice(0, 19)
}
interface props {
  conection: HubConnection | null
  callApi: () => void
  sendMessage: () => Promise<void>
  updateIssuee: () => Promise<void>
}

const SendMessage: React.FC<props> = ({ conection, callApi, sendMessage, updateIssuee }: props) => {
  const dispatch = useAppDispatch()
  const { issueState, issuesMentorPhuTrach } = useAppSelector((state) => state.IssueReducer)

  const { lastGroupChat, lastChat, groupChatsByChuyenDe } = useAppSelector((state) => state.groupChatReducer)

  const myUser = config.getStoreJson('USER_LOGIN') as NguoiDungType
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const paramChuyenDeId = Number(params.get('paramChuyenDeId'))
  const paramHocVienId = params.get('paramHocVienId')
  const paramGFTId = Number(params.get('paramGFTId'))
  const paramIssueId = Number(params.get('paramIssueId'))

  const [form] = Form.useForm()
  const inputRef = useRef<any>(null)
  // const [width, setWidth] = useState(window.innerWidth);
  const [inputValue, setInputValue] = useState('')
  const checkConn = () => {
    if (conection) {
      // Kết nối đã bắt đầu.
      console.log('checkConn succes')
      return true
    } else {
      console.log('checkConn fail')
      return false
    }
  }
  const findIssue = () => {
    if (paramChuyenDeId && paramHocVienId) {
      const index = issuesMentorPhuTrach.map((e) => e.id).findIndex((e) => e === paramIssueId)
      if (index !== -1) {
        return issuesMentorPhuTrach[index]
      }
    }
    return null
  }

  const onFinish = async (values: MESSModel) => {
    if (inputValue.trim().length > 0) {
      values.timeCreate = getTodate()
      values.isDelete = false
      values.userSendId = myUser.id
      if (paramChuyenDeId && paramHocVienId) {
        await dispatch(sendmessChuyenDeApi(paramHocVienId, paramChuyenDeId, paramIssueId, values))
        if (findIssue()) {
          const newIssue = {...findIssue()}as IssueModel;
          if (newIssue) {
            newIssue.trangThai = 'DANG_HO_TRO'
            await dispatch(putoptionalApi(newIssue.id, newIssue))
          }
        }
      } else if (paramGFTId) {
        await dispatch(sendmessGFTApi(paramGFTId, values))
      }
    }

    setInputValue('')
    handleClearInput()
    sendMessage()
    // callApi()
  }

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value)
  }

  const handleSubmit = () => {
    form.submit()
  }

  const handleClearInput = () => {
    form.setFieldsValue({
      Content: '',
      TimeCreate: '',
      IsDelete: false,
      UserChat: 'user'
    })
    inputRef.current?.focus()
  }

  return (
    <SendMessageCSS>
      <Form form={form} layout='inline' onFinish={onFinish} className='sendMessage'>
        <Row style={{ width: '100%', padding: ' 0 20px' }}>
          <Col span={18}>
            <Tooltip title={checkConn() ? '' : 'Kiểm tra lại đường truyền'}>
              <Form.Item name='Content'>
                <Input.TextArea
                  className='inputCss'
                  ref={inputRef}
                  value={inputValue}
                  autoFocus
                  onChange={handleInputChange}
                  onPressEnter={handleSubmit}
                  disabled={!checkConn()}
                />
              </Form.Item>
            </Tooltip>
          </Col>
          <Col span={3}>
            {' '}
            <Button
              type='primary'
              htmlType='submit'
              size='large'
              disabled={inputValue ? false : true}
              style={{ width: '100%', maxWidth: '150px' }}
            >
              Send
            </Button>
          </Col>
        </Row>
      </Form>
    </SendMessageCSS>
  )
}

export default SendMessage
