import React, { Dispatch, ReactNode, useState } from "react";
import { Drawer } from "antd";
import styled from "styled-components";

const CustomDrawerCss = styled.div`
  /* padding: 10px;
  .item {
    padding: 10px;
  } */
  .btnDanhSach {
    margin-bottom: 5px;
    width: 100%;
    background: transparent;
    color: white;
    padding: 10px;
    padding: 15px 10px;
    border: 1px solid rgb(49, 55, 74);
    transition: all 0.5s;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.3s;
    :hover{
      background: 
          rgb(212, 149, 72,0.2) ;
    }
  }
  .dschat {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 15px;
    gap: 2px;

    .person {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      
      width: 100%;
      padding: 15px 10px;
      border: 1px solid rgb(49, 55, 74);
      transition: all 0.3s;
      cursor: pointer;
      

      :hover {
       
        background: black;
        color:white
        .person__name {
          color: black;
        }
        .icon {
          color: blue;
        }
      }
      .person__name {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        

        color: #ffffff;
      }
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        .anticon {
          font-size: 20px;
        }
        .anticon-exclamation {
          transform: skew(-20deg);
        }
      }
      .icon.mess {
        .anticon {
          font-size: 20px;
          color: var(--color-yellow);
        }
      }
      :hover .icon.mess {
        .anticon {
          font-size: 20px;
          color: var(--color-bg);
        }
      }
      
    }
    .person.HasIssue{
     
        .icon {
          color:red;
          animation: 1s ease 0s infinite normal none running pulse;
        }
      
      :hover{
        background:var(--color-bg-yellow);
      /* .person__name {
          color: black;
        } */
        .icon.mess .anticon{
          color: var(--color-bg-red);;
          animation: 1s ease 0s infinite normal none running pulse;
        }
      }
    }
  }
`;

interface props {
  title?: string | React.ReactNode;
  open: boolean;
  setOpen: (b:boolean)=>void;
  children: React.ReactNode;
  placement?: "top" | "bottom" | "left" | "right";
  // content:JSX.Element
}

const CustomDrawer: React.FC<props> = ({
  open,
  setOpen,
  children,
  placement,
  title,
}) => {
  return (
    <>
      {" "}
      <Drawer
        headerStyle={{
          background: `#141824`,
          color: "white",
        }}
        style={{ color: "#ffffff" }}
        bodyStyle={{
          background: `#141824`,
          color: "white",
          padding:0
        }}
        title={title ? title : "Basic Drawer"}
        placement={placement ? placement : "right"}
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        {<CustomDrawerCss>{children}</CustomDrawerCss>}
      </Drawer>
    </>
  );
};

export default CustomDrawer;
