import React, { Fragment, useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import useHub from '../../hooks/useHub'
import { converTime } from '../../util/functionUtils'
import { Avatar, Badge, Button, Drawer, Modal, Popover, Progress, Skeleton, Tag, notification } from 'antd'
import ConntentMessage from './ContentMessage'
import SendMessage from './SendMessage'
import { MessageModel } from '../../Types/messageType'
import { StarOutlined, MessageOutlined, ExclamationOutlined, UserOutlined } from '@ant-design/icons'
import './GroupChatStyle.css'
import { config } from '../../util/config'
import {
  getBaiHocApi,
  getGroupForTaskApi,
  getThongTinMentorApi,
  layDsNguoiDungByStringApi
} from '../../redux/GroupForTaskReducer/GroupForTaskReducer'
import styled from 'styled-components'
import useAuth from '../../hooks/useAuth'
import { history } from '../../index'
import { useNavigate } from 'react-router-dom'
import CustomDrawer from '../Mentor/Task/customDrawer'
import { NguoiDungType } from '../../Types/nguoiDungType'
import UseConnection from '../../hooks/useHub'
import { useLocation, useParams } from 'react-router-dom'
import {
  getGroupChatByChuyenDeIdAndHocVienIdApi,
  getGroupChatByDsChatApi,
  getGroupChatByDsChatByChuyenDeApi,
  getGroupChatByDsChatGFTApi,
  setChats,
  setJoinRoom,
  setJoinRoom2
} from '../../redux/GroupChatReducer/groupChatReducer'
import ChuyenDe from '../../pages/ChuyenDe/ChuyenDe'
import { IssueModel } from '../../Types/issueType'
import moment from 'moment'
import { setIssueState } from '../../redux/IssueReducer/IssueReducer'
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { API_URL_DOMAIN } from '../../util/urlDomain'

const GroupChatCSS = styled.div`
  padding: 10px 20px;
  min-height: 100vh;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;

  /* background-color: rgb(238 204 178); */
  background: #0f111a;
  .button {
    cursor: pointer;
    transition: all 0.3s;
    :hover {
      color: rgb(255, 208, 81);
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    /* padding: 10px; */
    div {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  .head {
    height: 50px;
    border-bottom: 1px solid #31374a;
    font-weight: bold;
    .title {
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      p,
      span {
        font-size: 14px;
      }
    }
    .subject {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
      padding: 0 5px;
      color: #fff;
      .person__name {
        text-transform: uppercase;
      }
    }
  }
  .main {
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
    display: flex;
    .head {
      background: #141824;
      font-size: 16px;
    }

    .Content {
      width: 100%;
      border-top: 1px solid #31374a;
      border-bottom: 1px solid #31374a;
      height: 590px;

      /* text-align: center; */
      min-height: 250px;
    }
    .infor {
      /* height: 120vh; */
      width: 500px;
      height: 590px;
      border-radius: 15px 0px 0px 15px;
      border: 1px solid #31374a;

      text-align: center;
      align-items: center;

      color: #fff;
      .tinhHinhHocTap {
        padding: 2px;
        .chuyenDe {
          border: 1px solid #31374a;
          font-size: 13px;
          padding: 10px 0;
          margin-bottom: 2px;
          cursor: pointer;
        }
        .noiDungTheoChuyenDe {
          border: 1px solid #31374a;
          font-size: 13px;
          padding: 5px;
          .thongTin {
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px;
            p {
              font-size: 13px;
              color: #fff;
            }
            p:nth-child(2) {
              font-weight: bold;
              color: rgb(255, 205, 23);
            }
          }
        }
      }
    }

    .Slide {
      width: 450px;
      height: 590px;
      border-radius: 0 15px 15px 0;
      border: 1px solid #31374a;
      font-size: 13px;
      text-align: center;
      align-items: center;

      color: #fff;
      .yeuCau {
        padding: 1px;

        .canHoTro {
          font-size: 13px;
          text-decoration: underline;
          padding: 10px 5px;
          margin-bottom: 5px;
          font-weight: bold;
          cursor: pointer;
          border-bottom: 1px solid #31374a;
          border-radius: 3px;
        }
        .accordion-button:not(.collapsed) {
          color: #000;
          background-color: #fff3cd;
        }
        .noiDungCanHoTro {
          .noiDungCanHoTro_item {
            border-bottom: 1px solid #31374a;
            font-weight: bold;
            padding: 10px;
            cursor: pointer;
            transition: all 0.3s;
            :hover:not(.active) {
              background: #ffe69c;
              color: #141824;
            }
            &.active {
              background: #ffda6a;
              color: #31374a;
            }
          }
        }
      }
    }
  }
`
export const ModalCSS = styled.div`
  color: #ffffff;

  .tilte {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 40px;

    p,
    i {
      text-decoration: underline;
      font-weight: bold;
      font-size: 20px;
      color: #ffffff;
    }
  }
  .content {
    display: flex;
    gap: 20px;
    .left {
      background: #141824;
      border: 1px solid #31374a;
      border-radius: 5px;
      .head {
        color: #ffda6a;
        p {
          font-size: 20px;
        }
      }
      width: 50%;
      padding: 20px;
      .thongTin {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
        gap: 10px;
        .item {
          color: #ffffff;

          display: flex;
          align-items: center;
          gap: 10px;
          padding: 10px 0;
          p,
          i {
            font-size: 14px;
          }
        }
      }
    }
    .right {
      width: 50%;
      font-size: 14px;
      height: 500px;
      overflow-y: auto;

      .btnThongTin {
        width: 100%;
        font-weight: bold;
        background: #f7e6c4;
        border: 1px solid #31374a;
        border-radius: 5px;
        color: #000;
        padding: 10px;
        margin: auto;
        margin-bottom: 5px;
        transition: all 0.3s;
        :hover {
          text-decoration: underline;
        }
      }
      .main {
        /* text-decoration: underline; */
        font-size: 20px;
        background: #ffc207;
      }
      .sub {
        /* text-decoration: underline; */
        font-size: 18px;
        background: #ffdb69;
      }

      .collapse {
        width: 100%;
      }
      .noidung {
        /* padding: 5px; */
        .item {
          background: #ffffff;
          border-radius: 3px;
          color: #000;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          border: 1px solid #31374a;
          margin-bottom: 5px;
          p:last-child {
            font-weight: bold;
            color: #290661;
          }
        }
      }
    }
  }
`

export const typeOfIssue = {
  CAN_HO_TRO: 'CAN_HO_TRO',
  DANG_HO_TRO: 'DANG_HO_TRO',
  CHO_PHAN_HOI: 'CHO_PHAN_HOI',
  DA_HO_TRO: 'DA_HO_TRO',
  HO_TRO_LAI: 'HO_TRO_LAI'
}

const apiUrl = API_URL_DOMAIN

const GroupChat: React.FC = () => {
  const dispatch = useAppDispatch()
  const {
    checkRequireForStudents,
    baiHoc,
    arrDSNguoiDung,
    arrDanhSachHocVien,
    groupForTask,
    thongTinMentor,
    requireForStudents
  } = useAppSelector((state) => state.groupForTaskReducer)
  const { DSChuyenDeMentorPhuTrach } = useAppSelector((state) => state.chuyenDeReducer)
  // const { room, gftid, chuyendeid } = useParams();
  const { dsHocVienByMentorId } = useAppSelector((state) => state.nopBaiReducer)
  const { issueState, issuesMentorPhuTrach } = useAppSelector((state) => state.IssueReducer)

  const returnHocVienById = (ds: NguoiDungType[], id: string) => {
    if (ds) {
      const index = ds.findIndex((e) => e.id === id)
      return ds[index]
    }
    return null
  }
  const { connection,setConnection } =
 UseConnection();
 console.log(connection);

  const navigate = useNavigate()
  const myUser = config.getStoreJson('USER_LOGIN') as NguoiDungType
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const paramChuyenDeId = Number(params.get('paramChuyenDeId'))
  const paramHocVienId = params.get('paramHocVienId')
  const paramGFTId = Number(params.get('paramGFTId'))
  console.log('😀 ~ file: GroupChat.tsx:390 ~ paramGFTId:', paramGFTId)
  const paramIssueId = Number(params.get('paramIssueId'))


  const [roomArray, setRoom] = useState<string[]>([])

  // const [connection, setConnection] = useState<null | HubConnection>(null)

  const [api, contextHolder] = notification.useNotification()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const openNotification = (messages: MessageModel) => {
    api[messages.type]({
      message: messages.message,
      description: messages.description
    })
  }
  // const { checkRole, checkId } = useAuth();

  const handleGoBack = () => {
    navigate(-1)
  }

  //!Call api
  const GetApi = () => {
    const ApiCHat = config.getStoreJson("ApiChat")
    if(ApiCHat){
      const {chuyenDeId,hocVienId,issueId,gftId} =ApiCHat
     
      if(hocVienId===paramHocVienId && Number(chuyenDeId)===paramChuyenDeId)
      {
        if (paramChuyenDeId && paramHocVienId) {
          //get chat chuyen đê
          dispatch(getGroupChatByChuyenDeIdAndHocVienIdApi(paramChuyenDeId, paramHocVienId))
        } else if (paramGFTId) {
          //get chat gft
          dispatch(getGroupChatByDsChatGFTApi(paramGFTId))
        }
      }
      else if(paramGFTId==gftId){
        if (paramChuyenDeId && paramHocVienId) {
          //get chat chuyen đê
          dispatch(getGroupChatByChuyenDeIdAndHocVienIdApi(paramChuyenDeId, paramHocVienId))
        } else if (paramGFTId) {
          //get chat gft
          dispatch(getGroupChatByDsChatGFTApi(paramGFTId))
        }
      }
    }else{
      if (paramChuyenDeId && paramHocVienId) {
        //get chat chuyen đê
        dispatch(getGroupChatByChuyenDeIdAndHocVienIdApi(paramChuyenDeId, paramHocVienId))
      } else if (paramGFTId) {
        //get chat gft
        dispatch(getGroupChatByDsChatGFTApi(paramGFTId))
      }
    }
   
   
  }

  //? Connection
  const joinRoom = async () => {
    if(connection?.state==="Disconnected"||connection===null)
    try {
      const Newconnection = new HubConnectionBuilder()
        .withUrl(`${apiUrl}/chat`)
        .configureLogging(LogLevel.Information)
        .build()
      //Tạo sự kiên lắng nghe khi có người kết nối
      Newconnection.on('onJoinRoom', (userM) => {
        console.log('onJoinRoom1 task')
        if (myUser?.id !== userM?.userid) {
          console.log(userM?.userid ?? 'Có người vừa kết nối group task')
        }
        console.log(userM, 'Có người vừa tham giá nhóm chat !')
      })

      Newconnection.on('onUpdateIssue', () => {
        console.log('Issue đc cập nhập')
        GetApi()
      })
      //Sự kiện có tin nhắn đến
      Newconnection.on('ReceiveMessage', (userM) => {
        console.log('Có tn từ group task')
        GetApi()
      })

      //Sk khi có người out
      Newconnection.on('outReceiveMessage', (userM) => {
        if (myUser?.id === userM?.userid) {
          console.log('Bạn vừa tắt chat !')
        } else {
          console.log(`Tk ${userM?.userId} vừa tắt`)
        }
      })

      //ds chat
      Newconnection.on('UsersInRoom', (list) => {
        console.log('các người dùng khác', list)
      })
      //Sk chạy khi connection đóng !
      Newconnection.onclose((e) => {
        console.log('onClose')
        console.log(`Connectid ${connection?.connectionId} close`)
        console.log(connection?.state)
        setConnection(() => {
          return null
        })
      })
      //Start connection
      await Newconnection.start()
      let userConnection = null
      if (paramChuyenDeId && paramHocVienId) {
        userConnection = {
          userid: myUser.id,
          hocVienId: paramHocVienId,
          chuyenDeId: Number(paramChuyenDeId)
        }
        await Newconnection.invoke('JoinRoom2', userConnection)
      } else {
        userConnection = { userid: myUser.id, taskid: Number(paramGFTId) }
        // userConnection = { userid: myUser.id, room, taskid: Number(data.id) };
        await Newconnection.invoke('JoinRoom', userConnection)
      }
      console.log('kn thanh cong')
      if(Newconnection)
      setConnection(Newconnection)
      // success("Bạn vừa kết nối thành công chat !");
    } catch (error) {
      console.log(error)
      console.log('kn ko thành công')
      console.error('Lỗi báo BE')
    }
  }
  const joinRoomChuyeDe = async () => {
    if(connection?.state==="Disconnected"||connection===null)
    try {
      const Newconnection = new HubConnectionBuilder()
        .withUrl(`${apiUrl}/chat`)
        .configureLogging(LogLevel.Information)
        .build()
      //Tạo sự kiên lắng nghe khi có người kết nối
   
      Newconnection.on('onJoinRoom2', (userM) => {
        console.log('onJoinRoom2 chuyen de')
        if (myUser?.id !== userM?.userid) {
          console.log('Có người vừa kết nối group chuyên đề')
        }
        console.log(userM, 'Có người vừa tham giá nhóm chat !')
      })
      Newconnection.on('onUpdateIssue', () => {
        console.log('Issue đc cập nhập')
        GetApi()
      })
      //Sự kiện có tin nhắn đến
  
      Newconnection.on('ReceiveMessage2', (userM) => {
        console.log('Có tn group chuyên đêf')
        GetApi()
      })
      //Sk khi có người out
      Newconnection.on('outReceiveMessage', (userM) => {
        if (myUser?.id === userM?.userid) {
          console.log('Bạn vừa tắt chat !')
        } else {
          console.log(`Tk ${userM?.userId} vừa tắt`)
        }
      })

      //ds chat
      Newconnection.on('UsersInRoom', (list) => {
        console.log('các người dùng khác', list)
      })
      //Sk chạy khi connection đóng !
      Newconnection.onclose((e) => {
        console.log('onClose')
        console.log(`Connectid ${connection?.connectionId} close`)
        console.log(connection?.state)
        setConnection(() => {
          return null
        })
      })
      //Start connection
      await Newconnection.start()
      let userConnection = null
      if (paramChuyenDeId && paramHocVienId) {
        userConnection = {
          userid: myUser.id,
          hocVienId: paramHocVienId,
          chuyenDeId: Number(paramChuyenDeId)
        }
        await Newconnection.invoke('JoinRoom2', userConnection)
      } else {
        userConnection = { userid: myUser.id, taskid: Number(paramGFTId) }
        // userConnection = { userid: myUser.id, room, taskid: Number(data.id) };
        await Newconnection.invoke('JoinRoom', userConnection)
      }
      console.log('kn thanh cong')
      if(Newconnection)
      setConnection(Newconnection)
      // success("Bạn vừa kết nối thành công chat !");
    } catch (error) {
      console.log(error)
      console.log('kn ko thành công')
      console.error('Lỗi báo BE')
    }
  }

  const sendMessage = async () => {
    try {
      if (connection instanceof HubConnection)
        if (paramChuyenDeId && paramHocVienId) {
          await connection.invoke('SendMessage2', {
            userid: myUser?.id,
            chuyenDeId: Number(paramChuyenDeId)
          })
        } else {
          await connection.invoke('SendMessage', {
            userid: myUser?.id,
            taskid: Number(paramGFTId)
          })
        }
    } catch (e) {
      GetApi()
      console.log('send chat ko thanh cong', e)
    }
  }

  const updateIssuee = async () => {
    try {
      if (connection instanceof HubConnection)
        if (paramChuyenDeId && paramHocVienId) {
          await connection.invoke('updateIssuee2', {
            userid: myUser?.id,
            chuyenDeId: Number(paramChuyenDeId)
          })
        } else {
          await connection.invoke('updateIssuee', {
            userid: myUser?.id,
            taskid: Number(paramGFTId)
          })
        }
    } catch (e) {
      GetApi()
      console.log('Opp! Lỗi đẩy thông báo issue ...', e)
    }
  }

  const closeConnection = async () => {
    try {
      if (connection instanceof HubConnection) {
        await connection.stop()
        .then(() => {
          console.log('kết nối đã đóng')
        })
        .catch((er) => {
          console.log('lỗi kn', er)
        })
        console.log('đóng kết nối')
        // setConnection(null);
      }
    } catch (e) {
      console.log('lỗi trong quá trình đóng kết nối', e)
    }
  }

  // const handleClickRoom = (
  //   userid: string,
  //   room: string,
  //   taskid?: number,
  //   chuyenDeId?: number
  // ) => {
  //   const arr = JSON.parse(room);
  //   if (taskid) {
  //     if (arr.includes(userid)) {
  //       //! handleSetJoinRoom(room,taskid)
  //       // checkConnection()

  //       if (findConnection(room, taskid)) {
  //         //connection có tồn tại
  //         console.log("connet đã có");

  //         changeConn(findConnection(room, taskid)!);

  //         changeMyRoomRef(room, taskid);

  //         handleGetGroupChat(room, taskid);
  //       } else {
  //         //connect chưa tồn tại
  //         console.log("connet chưa có");

  //         joinRoom({ userid, room, taskid });
  //       }
  //       handleSetJoinRoom(room, taskid);
  //     }
  //   } else if (chuyenDeId) {
  //     if (arr.includes(userid)) {
  //       if (findConnection2(room, chuyenDeId)) {
  //         console.log("connet2 đã có");
  //         changeConn2(findConnection2(room, chuyenDeId)!);
  //         changeMyRoomRef2(room, chuyenDeId);
  //         handleGetGroupChatByChuyenDe(room, chuyenDeId);
  //       } else {
  //         console.log("connet2 chưa có");
  //         joinRoom2({ userid, room, chuyenDeId });
  //       }
  //       //todo: Cần xem xét
  //       handleSetJoinRoom2(room, chuyenDeId);
  //     }
  //   }
  // };

  // const showInfo = () => {
  //   console.log(paramChuyenDeId)
  //   if (paramChuyenDeId || roomArray?.length <= 2)
  //     return <div className="infor">
  //       <div className="head">
  //         <div className="title subject">
  //           <StarOutlined />
  //           <p>Tình Hinh Học Của {returnHocVienById(dsHocVienByMentorId, roomArray[0])?.hoTen}</p>
  //         </div>
  //       </div>
  //       <div className="tinhHinhHocTap">
  //         <div>
  //           <div
  //             className="chuyenDe"
  //             data-bs-toggle="collapse"
  //             data-bs-target="#tinhHinhHocTap"
  //             aria-expanded="true"
  //             aria-controls="tinhHinhHocTap"
  //           >
  //             Front End Từ Zero Tới Đi Làm
  //           </div>
  //         </div>
  //         <div className="collapse show" id="tinhHinhHocTap">
  //           <div className="noiDungTheoChuyenDe">
  //             <div className="thongTin">
  //               <p>Đã Học Tới Task</p>
  //               <p>Task 1</p>
  //             </div>
  //             <div className="thongTin">
  //               <p>Đang làm bài tập: </p>
  //               <p>Require 1</p>
  //             </div>
  //             <div className="thongTin">
  //               <p>Tiến độ hiện tại : </p>
  //               <p>70%</p>
  //             </div>
  //             <div className="thongTin">
  //               <p>Diểm Chuyên Cần</p>
  //               <p>88/100 </p>
  //             </div>
  //             <div className="thongTin">
  //               <p>Điêm Tích Cực</p>
  //               <p>100</p>
  //             </div>{" "}
  //             <div className="thongTin">
  //               <p>Điểm của bài tập trước : </p>
  //               <p>90/100</p>
  //             </div>{" "}
  //             <div className="thongTin">
  //               <p>Tổng Điểm Thành Phần : </p>
  //               <p>88/100 </p>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   else
  //     // if (paramTaskId && roomArray?.length >= 3)
  //     //   return <div className="infor">
  //     //     <div className="head">
  //     //       <div className="title subject">
  //     //         <StarOutlined />
  //     //         <p>Các Yêu Cầu Nhóm Cần làm</p>
  //     //       </div>
  //     //     </div>
  //     //     <div className="tinhHinhHocTap">
  //     //       <div className="noiDungTheoChuyenDe">
  //     //         <div className="thongTin">
  //     //           <p></p>
  //     //           <p>Task 1</p>
  //     //         </div>

  //     //       </div>
  //     //     </div>

  //     //   </div>

  //   return <div className="infor">
  //     <div className="head">
  //       <div className="title subject">
  //         {/* <StarOutlined />
  //           <p>Tình Hinh Học Của {returnHocVienById(dsHocVienByMentorId,roomArray[0])?.hoTen}</p> */}
  //       </div>
  //     </div>
  //   </div>
  // }

  // const handleOnClick = (room: string) => {
  //   const query = { taskId: paramTaskId!, room: room };
  //   history.push({
  //     pathname: "/chat",
  //     search: new URLSearchParams(query).toString(),
  //   });
  // };
  const checkTypeChat = () => {
    if (paramGFTId) return false
    if (paramChuyenDeId && paramHocVienId) return true
    return null
  }

  const resultTrangThai = (trangThai: string) => {
    let color = ''
    let content = ''
    switch (trangThai) {
      case 'CAN_HO_TRO':
        color = 'orange'
        content = 'Cần Hỗ Trợ'
        break
      case 'HO_TRO_LAI':
        color = 'magenta'
        content = 'Hỗ Trợ Lại'
        break
      case 'DANG_HO_TRO':
        color = 'danger'
        content = 'Đang Hỗ Trợ'
        break

      default:
        color = 'lime'
    }
    return { color, content }
  }

  //! COVERT

  const convertTenChuyenDe = (id: number) => {
    if (DSChuyenDeMentorPhuTrach.length) {
      const index = DSChuyenDeMentorPhuTrach.findIndex((e) => e.id === id)
      // console.log(id,index,DSChuyenDeMentorPhuTrach[0].id)
      if (index !== -1) return DSChuyenDeMentorPhuTrach[index].tenChuyenDe
    }
    return ''
  }

  const convertTenHocVien = (id: string) => {
    if (dsHocVienByMentorId?.length) {
      const index = dsHocVienByMentorId.findIndex((e) => e.id === id)
      if (index !== -1) return dsHocVienByMentorId[index].hoTen
    }
    return ''
  }

  const thongTinHocVien = () => (
    <>
      <ModalCSS>
        <div className='tilte'>
          <i className='fa-solid fa-user'></i>
          <p>Thông Tin Học Tập Wounyoung </p>
        </div>
        <div className='content'>
          <div className='left'>
            <div className='head'>
              <div className='d-flex justify-content-between align-items-center'>
                <p>Front End Từ Zero Tới Đi làm</p>
                <p>75%</p>
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              <p>Dự Kiến Hoàn Thành </p>
              <p>29/09/2023</p>
            </div>
            <Progress
              strokeColor={{ from: '#EEBE47', to: '#e9e0cb' }}
              trailColor={'#8C5D31'}
              showInfo={false}
              percent={75}
              status='active'
            />
            <div className='thongTin'>
              <div className='item'>
                <i className='fa-solid fa-star'></i>
                <p>Tổng số task đã hoàn thành (45/50) </p>
              </div>{' '}
              <div className='item'>
                <i className='fa-solid fa-star'></i>
                <p>Tổng số bài trắc nghiệm đã hoàn thành (4/5) </p>
              </div>{' '}
              <div className='item'>
                <i className='fa-solid fa-star'></i>
                <p>Tổng số bài test run code (4/5) </p>
              </div>{' '}
              <div className='item'>
                <i className='fa-solid fa-star'></i>
                <p>Số capstone project đã hoàn thành (2/3) </p>
              </div>{' '}
              <div className='item'>
                <i className='fa-solid fa-star'></i>
                <p>Số capstone project đã hoàn thành (2/3) </p>
              </div>
            </div>
          </div>
          <div className='right'>
            <button
              className='btnThongTin main'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#thongtin'
              aria-expanded='true'
              aria-controls='thongtin'
            >
              Front End Từ Zero Tới Đi Làm
            </button>
            <div className='collapse show' id='thongtin'>
              <button
                className='btnThongTin sub'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#captone'
                aria-expanded='true'
                aria-controls='captone'
              >
                Capstone
              </button>
              <div className='collapse show' id='captone'>
                <div className='noidung'>
                  <div className='item'>
                    <p>Capstone HTML5-CSS3 </p>
                    <p>100</p>
                  </div>{' '}
                  <div className='item'>
                    <p>Capstone BOOTSTRAP </p>
                    <p>100</p>
                  </div>{' '}
                  <div className='item'>
                    <p>Capstone REACTJS </p>
                    <p>100</p>
                  </div>{' '}
                  <div className='item'>
                    <p>Capstone Cuối Khóa </p>
                    <p>100</p>
                  </div>
                </div>
              </div>{' '}
              <button
                className='btnThongTin sub '
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#baitaptuan'
                aria-expanded='true'
                aria-controls='baitaptuan'
              >
                Bài Tập Tuần
              </button>
              <div className='collapse show' id='baitaptuan'>
                <div className='noidung'>
                  <div className='item'>
                    <p>Bài Tập Tuần 1</p>
                    <p>100</p>
                  </div>{' '}
                  <div className='item'>
                    <p>Bài Tập Tuần 3</p>
                    <p>100</p>
                  </div>{' '}
                  <div className='item'>
                    <p>ài Tập Tuần 4 </p>
                    <p>100</p>
                  </div>{' '}
                  <div className='item'>
                    <p>Bài Tập Tuần 5</p>
                    <p>100</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalCSS>
    </>
  )

  // const dsThanhVien = () => {
  //   if (paramChuyenDeId)
  //     return (
  //       <>
  //         <p>
  //           <button
  //             className="btnDanhSach"
  //             type="button"
  //             data-bs-toggle="collapse"
  //             data-bs-target="#collapseExample"
  //             aria-expanded="true"
  //             aria-controls="collapseExample"
  //           >
  //             Danh Sách Học Viên Mentor Phụ Trách
  //           </button>
  //         </p>
  //         <div className="collapse show" id="collapseExample">
  //           {/* <div className="dschat">
  //             {issuesMentorPhuTrach?.filter(e => e.trangThai !== 'DA_HO_TRO').map((is, index) => {
  //               return <div key={'hvmentor phu trac' + index} className="person" onClick={
  //                 () => {
  //                   dispatch(setChats([]))
  //                   dispatch(setIssueState(is));
  //                   const room = JSON.stringify([is.hocVienId, user.id]);
  //                   const query = {
  //                     chuyenDeId: is.chuyenDeId.toString(),
  //                     room,
  //                     issueId: is.id.toString(),
  //                   };
  //                   history.push({
  //                     pathname: "/chat",
  //                     search: new URLSearchParams(query).toString(),
  //                   });
  //                 }
  //               }>
  //                 <Badge count={1}>
  //                   <Avatar shape="circle" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
  //                 </Badge>
  //                 <p className="person__name">{convertTenHocVien(is.hocVienId)}</p>
  //                 <div className="icon mess">
  //                   <MessageOutlined />
  //                   <ExclamationOutlined />
  //                 </div>
  //               </div>
  //             })}
  //           </div> */}
  //         </div>
  //         {/* <p>

  //       <button
  //         className="btnDanhSach"
  //         type="button"
  //         data-bs-toggle="collapse"
  //         data-bs-target="#dshocvienthemvao"
  //         aria-expanded="true"
  //         aria-controls="dshocvienthemvao"
  //       >
  //         Nhóm Hiện Tại
  //       </button>
  //     </p> */}
  //         {/* <div className="collapse show" id="dshocvienthemvao">
  //       <div className="dschat">
  //         <div className="person">
  //           <Badge count={1}>
  //             <Avatar shape="circle" src="https://picsum.photos/100" />
  //           </Badge>
  //           <p className="person__name">Nhóm Task 32</p>
  //           <div className="icon mess">
  //             <MessageOutlined />
  //             <ExclamationOutlined />
  //           </div>
  //         </div>{" "}
  //       </div>
  //     </div> */}
  //       </>)
  //   //!Ds chat grouptask
  //   return (
  //     <>
  //       <p>
  //         <button
  //           className="btnDanhSach"
  //           type="button"
  //           data-bs-toggle="collapse"
  //           data-bs-target="#collapseExample"
  //           aria-expanded="true"
  //           aria-controls="collapseExample"
  //         >
  //           Danh Sách Thành Viên Trong Nhóm
  //         </button>
  //       </p>
  //       <div className="collapse show" id="collapseExample">
  //         <div className="dschat">
  //           {arrDSNguoiDung.map((person, ind) => {
  //             return (
  //               <Fragment key={person.id + "" + ind}>

  //                 <div
  //                   className="person"
  //                   onClick={() => {
  //                     handleOnClick(
  //                       JSON.stringify([person.id, thongTinMentor.id])
  //                     );
  //                   }}
  //                 >
  //                   <Badge count={1}>
  //                     <Avatar
  //                       shape="circle"
  //                       src="https://picsum.photos/100"
  //                     />
  //                   </Badge>
  //                   <p className="person__name">{person.hoTen}</p>
  //                   <div className="icon mess">
  //                     <MessageOutlined />
  //                     <ExclamationOutlined />
  //                   </div>
  //                 </div>
  //               </Fragment>
  //             );
  //           })}
  //         </div>
  //       </div>
  //       {/* <p>

  //         <button
  //           className="btnDanhSach"
  //           type="button"
  //           data-bs-toggle="collapse"
  //           data-bs-target="#dshocvienthemvao"
  //           aria-expanded="true"
  //           aria-controls="dshocvienthemvao"
  //         >
  //           Nhóm Hiện Tại
  //         </button>
  //       </p> */}
  //       {/* <div className="collapse show" id="dshocvienthemvao">
  //         <div className="dschat">
  //           <div className="person">
  //             <Badge count={1}>
  //               <Avatar shape="circle" src="https://picsum.photos/100" />
  //             </Badge>
  //             <p className="person__name">Nhóm Task 32</p>
  //             <div className="icon mess">
  //               <MessageOutlined />
  //               <ExclamationOutlined />
  //             </div>
  //           </div>{" "}
  //         </div>
  //       </div> */}
  //     </>
  //   );
  // };
  // const isuueDaHoTro = (issues: IssueModel[]) => {

  //   const data = [...issues].filter(e => e.hocVienId === issueState.hocVienId && e.trangThai === 'DA_HO_TRO').sort((a, b) => {
  //     const dateA = converTime(a.ngayTao);
  //     const dateB = converTime(b.ngayTao);

  //     if (dateA < dateB) return -1;
  //     if (dateA > dateB) return 1;
  //     return 0;
  //   });
  //   return data.map((e, indx) => {
  //     if (e.trangThai === 'DA_HO_TRO')
  //       return <Popover key={'issue da ho tro' + indx}
  //         placement="left"
  //         title={""}
  //         content={
  //           <>
  //             <p className="mb-2">{convertTenChuyenDe(e.chuyenDeId)}</p>
  //             <p>
  //               {e.tittle}
  //             </p>
  //           </>
  //         }
  //         arrow={true}
  //       >
  //         <div className="noiDungCanHoTro_item">
  //           <div className="d-flex justify-content-between my-1">
  //             <p>{e.noiDung}</p>

  //             <p>{converTime(e.ngayTao)}</p>
  //           </div>
  //           <p
  //             className="d-inline-block text-truncate"
  //             style={{ maxWidth: "250px" }}
  //           >
  //             {e.tittle}
  //           </p>
  //         </div>
  //       </Popover>
  //   })
  // }

  // const issue = () => {
  //   return (
  //     <Fragment>
  //       <div className="head">
  //         <div className="title subject">
  //           <StarOutlined />
  //           <span>
  //             Những câu hỏi của
  //             {returnHocVienById(dsHocVienByMentorId, issueState.hocVienId)
  //               ? returnHocVienById(dsHocVienByMentorId, issueState.hocVienId)
  //                 ?.hoTen
  //               : ""}
  //           </span>{" "}
  //         </div>
  //       </div>

  //       <div className="" id="accordionExample">
  //         <div className=" yeuCau">
  //           <div className="" id="headingOne">
  //             <p
  //               className="  accordion-button canHoTro"
  //               // type="button"
  //               data-bs-toggle="collapse"
  //               data-bs-target="#collapseOne"
  //               aria-expanded="true"
  //               aria-controls="collapseOne"
  //             >
  //               Câu Hỏi Cần Hỗ Trợ
  //             </p>
  //           </div>
  //           <div
  //             id="collapseOne"
  //             className="accordion-collapse collapse show"
  //             aria-labelledby="headingOne"
  //             data-bs-parent="#accordionExample"
  //           >
  //             <div className="">
  //               <div className="noiDungCanHoTro">
  //                 <Popover
  //                   placement="left"
  //                   title={""}
  //                   content={
  //                     <>
  //                       <p className="mb-2">{issueState?.noiDung}</p>
  //                       <p>

  //                         {issueState?.tittle}
  //                       </p>
  //                     </>
  //                   }
  //                   arrow={true}
  //                 >
  //                   <div className="noiDungCanHoTro_item active">
  //                     <p
  //                       className="d-inline-block text-truncate"
  //                       style={{ maxWidth: "250px" }}
  //                     >
  //                       {issueState.tittle}
  //                     </p>
  //                     <div className="d-flex justify-content-between my-1">
  //                       <Tag color={resultTrangThai(issueState.trangThai)?.color}>{issueState?.trangThai}</Tag>
  //                       <span>{converTime(issueState.ngayTao)}</span>
  //                     </div>
  //                   </div>{" "}
  //                 </Popover>
  //                 {/* <Popover
  //                   placement="left"
  //                   title={""}
  //                   content={
  //                     <>
  //                       <p className="mb-2">CSS3 - Xây Dựng Layout maiperly</p>
  //                       <p>
  //                         {" "}
  //                         Em đang bị lỗi ở phần head cần a hỗ trợ ở phần head
  //                       </p>
  //                     </>
  //                   }
  //                   arrow={true}
  //                 >
  //                   <div className="noiDungCanHoTro_item">
  //                     <p
  //                       className="d-inline-block text-truncate"
  //                       style={{ maxWidth: "250px" }}
  //                     >
  //                       Em đang bị lỗi ở phần head cần a hỗ trợ ở phần head
  //                     </p>
  //                     <div className="d-flex justify-content-between my-1">
  //                       <Tag color="magenta">Hỗ Trợ Lại</Tag>
  //                       <span>7:56 PM</span>
  //                     </div>
  //                   </div>
  //                 </Popover>
  //                 <Popover
  //                   placement="left"
  //                   title={""}
  //                   content={
  //                     <>
  //                       <p className="mb-2">CSS3 - Xây Dựng Layout maiperly</p>
  //                       <p>
  //                         {" "}
  //                         Em đang bị lỗi ở phần head cần a hỗ trợ ở phần head
  //                       </p>
  //                     </>
  //                   }
  //                   arrow={true}
  //                 >
  //                   <div className="noiDungCanHoTro_item">
  //                     <p
  //                       className="d-inline-block text-truncate"
  //                       style={{ maxWidth: "250px" }}
  //                     >
  //                       Em đang bị lỗi ở phần head cần a hỗ trợ ở phần head
  //                     </p>
  //                     <div className="d-flex justify-content-between my-1">
  //                       <Tag color="magenta">Hỗ Trợ Lại</Tag>
  //                       <span>7:56 PM</span>
  //                     </div>
  //                   </div>
  //                 </Popover> */}
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="yeuCau">
  //           <div className="" id="headingTwo">
  //             <p
  //               className=" accordion-button  collapsed canHoTro"
  //               // type="button"
  //               data-bs-toggle="collapse"
  //               data-bs-target="#collapseTwo"
  //               aria-expanded="false"
  //               aria-controls="collapseTwo"
  //             >
  //               Những Câu Hỏi Bạn Hỗ Trợ Rồi
  //             </p>
  //           </div>
  //           <div
  //             id="collapseTwo"
  //             className="accordion-collapse collapse"
  //             aria-labelledby="headingTwo"
  //             data-bs-parent="#accordionExample"
  //           >
  //             <div className="">
  //               <div className="noiDungCanHoTro">
  //                 {/* <Popover
  //                   placement="left"
  //                   title={""}
  //                   content={
  //                     <>
  //                       <p className="mb-2">CSS3 - Xây Dựng Layout maiperly</p>
  //                       <p>
  //                         {" "}
  //                         Em đang bị lỗi ở phần head cần a hỗ trợ ở phần head
  //                       </p>
  //                     </>
  //                   }
  //                   arrow={true}
  //                 >
  //                   <div className="noiDungCanHoTro_item">
  //                     <div className="d-flex justify-content-between my-1">
  //                       <p>1 Task 1 - Require 1</p>

  //                       <span>7:56 PM</span>
  //                     </div>
  //                     <p
  //                       className="d-inline-block text-truncate"
  //                       style={{ maxWidth: "250px" }}
  //                     >
  //                       Em đang bị lỗi ở phần head cần a hỗ trợ ở phần head
  //                     </p>
  //                   </div>{" "}
  //                 </Popover>
  //                 <Popover
  //                   placement="left"
  //                   title={""}
  //                   content={
  //                     <>
  //                       <p className="mb-2">CSS3 - Xây Dựng Layout maiperly</p>
  //                       <p>
  //                         {" "}
  //                         Em đang bị lỗi ở phần head cần a hỗ trợ ở phần head
  //                       </p>
  //                     </>
  //                   }
  //                   arrow={true}
  //                 >
  //                   <div className="noiDungCanHoTro_item">
  //                     <div className="d-flex justify-content-between my-1">
  //                       <p>1 Task 1 - Require 1</p>

  //                       <span>7:56 PM</span>
  //                     </div>
  //                     <p
  //                       className="d-inline-block text-truncate"
  //                       style={{ maxWidth: "250px" }}
  //                     >
  //                       Em đang bị lỗi ở phần head cần a hỗ trợ ở phần head
  //                     </p>
  //                   </div>{" "}
  //                 </Popover>
  //                 <Popover
  //                   placement="left"
  //                   title={""}
  //                   content={
  //                     <>
  //                       <p className="mb-2">CSS3 - Xây Dựng Layout maiperly</p>
  //                       <p>
  //                         {" "}
  //                         Em đang bị lỗi ở phần head cần a hỗ trợ ở phần head
  //                       </p>
  //                     </>
  //                   }
  //                   arrow={true}
  //                 >
  //                   <div className="noiDungCanHoTro_item ">
  //                     <div className="d-flex justify-content-between my-1">
  //                       <p>1 Task 1 - Require 1</p>

  //                       <span>7:56 PM</span>
  //                     </div>
  //                     <p
  //                       className="d-inline-block text-truncate"
  //                       style={{ maxWidth: "250px" }}
  //                     >
  //                       Em hiện đang bị lỗi ở phần head cần a hỗ trợ ở phần head
  //                       2
  //                     </p>
  //                   </div>
  //                 </Popover> */}
  //                 {isuueDaHoTro(issuesMentorPhuTrach)}
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </Fragment>
  //   );
  // };

  useEffect(() => {
    GetApi()

    const call = setInterval(GetApi, 30000)
    return () => {
      clearInterval(call)
    }
  }, [])

  useEffect(() => {
    if (connection instanceof HubConnection) {
      closeConnection()
      console.log('Close con')
    }

    if(paramChuyenDeId&&paramHocVienId)
    joinRoomChuyeDe()
    else
    if(paramGFTId){
      joinRoom()
    }

    return () => {
      closeConnection()
    }
  }, [paramChuyenDeId,paramGFTId,paramHocVienId,paramIssueId])


  return (
    <GroupChatCSS>
      {contextHolder}
      <div className='title p-3'>
        <div className='button' onClick={handleGoBack}>
          <i className='fa-solid fa-arrow-left'></i> <span>Back</span>
        </div>{' '}
        <div
          className='button'
          onClick={() => {
            // setOpenDS(true);
          }}
        >
          {/* <span> Danh Sách {paramTaskId ? 'Thành Viên Nhóm' : 'Học Viên Phụ Trách'}</span>{" "} */}
          <i className='fa-solid fa-arrow-right'></i>
        </div>
      </div>
      <div className='main'>
        {/* {showInfo()} */}
        <div className='Content '>
          <div className='head d-flex justify-content-between align-items-center text-light'>
            <div className='subject px-4'>
              <Avatar shape='circle' src='https://picsum.photos/100' />

              <p className='person__name'>
                {roomArray?.length >= 3
                  ? 'Nhóm Task' + ' ' + groupForTask.id
                  : roomArray?.length === 2
                  ? returnHocVienById(dsHocVienByMentorId, issueState.hocVienId)?.hoTen
                  : ''}
              </p>
            </div>
            {/* {paramChuyenDeId ? <Popover arrow placement="left" content={paramChuyenDeId ? "Profile Học Viên" : ""}>
              <div className="button px-4 py-2" onClick={showModal}>
                {" "}
                <i className="fa-solid fa-ellipsis-vertical"></i>
              </div>
            </Popover> : ''} */}
          </div>

          <ConntentMessage updateIssuee={updateIssuee} callApi={GetApi} />
          {/* <button onClick={closeConnection}>Hủy Kết Nối</button> */}
          <SendMessage conection={connection} callApi={GetApi} sendMessage={sendMessage} updateIssuee={updateIssuee} />
        </div>
        <div className='Slide'>
          {/* Cần sử lý  */}
          {/* {paramChuyenDeId ? issue() : <div className="head"></div>} */}
        </div>
      </div>
      {/* <CustomDrawer
        title=' '
        open={openDS}
        setOpen={setOpenDS}
        key={"danh sach"}
        children={dsThanhVien()}
      /> */}
      <Modal
        className='thongtinhocvien'
        width={950}
        title=''
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer=''
      >
        {/* {paramChuyenDeId ? thongTinHocVien() : ''} */}
      </Modal>
    </GroupChatCSS>
  )
}

export default GroupChat
