import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import parse from 'html-react-parser';
import { HeartOutlined, SmileOutlined } from "@ant-design/icons";
import { Image, Input, InputNumber, Skeleton, notification, Space } from "antd";
import { CKEditor } from "ckeditor4-react";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { putMentorChamBai } from "../../../../../redux/NopBaiReducer/nopBaiReducer";
import { config } from "../../../../../util/config";
import { NguoiDungType } from "../../../../../Types/nguoiDungType";
import { MentorChamBaiModel, trangThai } from "../../../../../Types/nopBaiType";
import { getDsNopBaiByGroupApi } from "../../../../../redux/NopBaiReducer/nopBaiReducer";
import { MessageModel } from "../../../../../Types/messageType";


type NotificationType = 'success' | 'info' | 'warning' | 'error';


const { TextArea } = Input;

//*style
const ContentRequireForTaskCSS = styled.div`
  width: 95%;
  margin: auto;
  padding: 10px 10px;
  background-color: rgb(198, 147, 121);
  border-radius: 5px;
  color: #000000;

  .marginBottom {
    margin-bottom: 10px;
  }
  .input {
    width: 95%;
  }
  .diemthuong {
    text-align: center;
    background-color: wheat;
    padding: 15px 10px;
    border-radius: 10px;

    display: flex;
    justify-content: space-between;

    .diemthuong_item {
      width: 30%;
      color: rgb(198, 147, 121);
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
  .cauhoi {
    padding: 15px 10px;
    border-radius: 10px;
    background-color: wheat;
    .cauhoi_noidung {
      margin: 10px 0;
    }
  }
  .bailam {
    padding: 15px 10px;
    border-radius: 10px;
    background-color: wheat;
    .bailam_noidung {
      margin: 10px 0;

      .bailam_item {
        width: 100%;
        display: flex;
        align-items: center;

        .bailam_item-subject {
          width: 25%;
        }
        .bailam_item-content {
          width: 75%;
        }
      }
    }
  }
  .chamdiem {
    padding: 15px 10px;
    border-radius: 10px;
    background-color: wheat;

    .chamdiem_item {
      display: flex;
      align-items: center;

      .chamdiem_item-title {
        width: 25%;
      }
      .chamdiem_item-input {
        width: 75%;
      }
    }
  }
  .danhgia {
    padding: 15px 10px;
    border-radius: 10px;
    background-color: wheat;
    .danhgia_noidung {
      margin: 10px 0;
    }
    .danhgia_item {
      display: flex;
      /* align-items: center; */
      .cke_top,.cke_bottom {
        background-color: rgba(198, 147, 121, 0.172);
      }
      .btn-success{
        margin-right: 5px;
      }
      .danhgia_item-title {
        width: 25%;
      }
      .danhgia_item-input {
        width: 75%;
      }
    }
  }
`;

// type TypeData = {
//   [key: string]: any;
// };
interface props {
  active: any;
  setActive: any;
}
const ContentRequireForTask: React.FC<props> = (props) => {
  const dispatch = useAppDispatch();
  const { nopBaiCurrent, dsbailamtheoids } = useAppSelector(
    (state) => state.nopBaiReducer
  );
  const { message, type, description } = useAppSelector(state => state.messageReducer)

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (mess: MessageModel) => {
    api[mess.type]({
      message: mess.message,
      description: mess.description
    });
  };

  useEffect(() => {
    message ?? openNotificationWithIcon({ type, message, description })

  }, [message])

  const myUser = config.getStoreJson("USER_LOGIN") as NguoiDungType;
  const [diem, setDiem] = useState<number | ''>(0);

  const [danhGia, setDanhGia] = useState("");
  // const [data, setData] = useState<TypeData>({});
  const { requireForStudents, groupForTask, baiHoc, currentRequire } =
    useAppSelector((state) => state.groupForTaskReducer);
  // console.log(requireForStudents);
  // console.log(dsbailamtheoids);
  // console.log(danhGia)

  useEffect(() => {
    setDanhGia('');
    setDiem('');

  }, [nopBaiCurrent, props.active])

  const onEditorChange = (evt: any) => {
    // console.log(evt.editor.getData())
    // setData((prevData) => ({
    //   ...prevData,
    //   [evt.editor.name]: evt.editor.getData(),
    // }));
    setDanhGia(evt.editor.getData());
  };

  const handleEditorChange = (event: any, editor: any) => {
    const data = editor.getData();
    // console.log(data); // Lấy dữ liệu đã nhập từ trình soạn thảo
  };

  const handlechange = (e: any) => {
    setDanhGia(e.target.value);
  };
  const handleChangeDiem = (e: any) => {
    setDiem(e);
  };



  const chamBai = async (status: trangThai) => {
    // console.log(status);

    const MentorChamBai: MentorChamBaiModel = {
      id: nopBaiCurrent.id,
      diem: Number(diem),
      nguoiCham: myUser.id,
      nhanXet: danhGia,
      trangThai: status,
    };
    // console.log(MentorChamBai);
    if (diem) {
      await dispatch(putMentorChamBai(myUser.id, MentorChamBai));
      await dispatch(
        getDsNopBaiByGroupApi(groupForTask.id, groupForTask.mentorId)
      );
      props.setActive('')
      // props.setActive('')}
    }
  };

  return (
    <Fragment>
      {contextHolder}
      {props.active ? (
        dsbailamtheoids.length ? (
          <ContentRequireForTaskCSS>
            {/* <div className="diemthuong marginBottom">
    <div className="diemthuong_item">
      {" "}
      <HeartOutlined /> 10 XP
    </div>
    <div className="diemthuong_item">
      {" "}
      <SmileOutlined /> 1 COIN
    </div>
  </div> */}
            <div className="cauhoi marginBottom">
              <h6 className="cauhoi_noidung heading">
                Task {nopBaiCurrent.requireId}: Hãy download tài nguyên bên dưới
                và làm theo yêu cầu sau{" "}
              </h6>
              <p>{parse('')}</p>
              <ul>
                <li>
                  Link Hinh Ảnh và resource <a href="#">tại đây</a>
                </li>
                <li>Đầu tiên bạn tạo cấu trúc như hình bên dưới </li>
              </ul>
              <div className="cauhoi_huongdan">
                {" "}
                <Image alt="" src="https://i.pravatar.cc/300?img=2" />
              </div>
              <h6 className="cauhoi_noidung">
                Yêu cầu: Sử dụng html5 css để tạo giao diện header như kết quả
              </h6>
            </div>
            <div className="bailam marginBottom">
              <div className="bailam_noidung">
                {dsbailamtheoids[0] ? (
                  <div className="bailam_item">
                    <div className="bailam_item-subject">
                      <p>Link dự án :</p>
                    </div>
                    <div className="bailam_item-content">
                      <a
                        target="_blank"
                        href={`${dsbailamtheoids[0]}`}
                      >{`${dsbailamtheoids[0]}`}</a>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {dsbailamtheoids[1] ? (
                  <div className="bailam_item">
                    {" "}
                    <div className="bailam_item-subject">
                      <p>Link deploy :</p>
                    </div>
                    <div className="bailam_item-content">
                      <a
                        target="_blank"
                        href={`${dsbailamtheoids[1]}`}
                      >{`${dsbailamtheoids[1]}`}</a>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {dsbailamtheoids[2] ? (
                  <div className="bailam_item">
                    {" "}
                    <div className="bailam_item-subject">
                      <p>Link demo :</p>
                    </div>
                    <div className="bailam_item-content">
                      <a
                        target="_blank"
                        href={`${dsbailamtheoids[2]}`}
                      >{`${dsbailamtheoids[2]}`}</a>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="chamdiem marginBottom">
              <div className="chamdiem_item">
                <h6 className="chamdiem_item-title">Chấm Điểm</h6>
                <div className="chamdiem_item-input">
                  <InputNumber
                    placeholder="Cho điểm từ 1 đến 100"
                    className="input"
                    min={0}
                    max={100}
                    value={diem}
                    onChange={handleChangeDiem}
                  />
                </div>
              </div>
            </div>
            <div className="danhgia marginBottom">
              <div className="danhgia_item marginBottom">
                <h6 className="danhgia_item-title">Dánh Giá</h6>
                <div className="danhgia_item-input">
                  {/* <Input.TextArea
                    placeholder="Hãy Gữi Đánh "
                    className="input"
                    // ref={inputRef}
                    value={danhGia}
                    onChange={handlechange}
                    // onPressEnter={handleSubmit}
                    // disabled={!connection}
                  /> */}
                  <CKEditor

                    data="<p>Mô tả của bạn</p>"
                    config={{
                      versionCheck: false,
                      toolbarGroups: [
                        { name: 'document', groups: ['mode', 'document', 'doctools'] },
                        { name: 'clipboard', groups: ['clipboard', 'undo'] },
                        { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
                        { name: 'forms' },
                        '/',
                        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
                        { name: 'links' },
                        { name: 'insert' },
                        '/',
                        { name: 'styles' },
                        { name: 'colors' },
                        { name: 'tools' },
                        { name: 'others' },
                        { name: 'about' }
                      ],
                      extraPlugins: 'codesnippet', // Thêm plugin codesnippet vào danh sách extraPlugins
                    }}
                    onChange={onEditorChange}
                  />
                </div>
              </div>

              {diem ? (
                <div className="danhgia_item justify-content-end">
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      chamBai("DA_CHAM");
                    }}
                  >
                    Chấm Bài
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      chamBai("NOP_LAI");
                    }}
                  >
                    Nộp Lại
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </ContentRequireForTaskCSS>
        ) : (
          <Skeleton className="column-content" active />
        )
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default ContentRequireForTask;
