import { Button, Col, Divider, Input, InputNumber, InputRef, Radio, RadioChangeEvent, Row, Select, Slider, Space, message } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { CategoryModel, RuncodeData } from '../../Types/runCodeType';
import { PlusOutlined } from '@ant-design/icons';
import { fetchCategories } from '../../redux/RunCodeReducer/runcodeReducer';
import { runcodeService } from '../../services/runcodeService';
import { useDispatch } from 'react-redux';
import { DispatchType } from '../../redux/configStore';

// import "../../assets/css/radio-button.css"

const { TextArea } = Input;

// type 
type TypeData = {
  [key: string]: any
}

type Props = {
  data: RuncodeData,
  setData: Dispatch<SetStateAction<RuncodeData | any>>
  arrCategories: CategoryModel[],
  category: number[],
  setCategory: Dispatch<SetStateAction<number[]>>,
  reward: TypeData,
  setReward: Dispatch<SetStateAction<TypeData[]>>,

};

const ThongTinChiTiet = ({ data, category, arrCategories, setData, setCategory, reward, setReward }: Props) => {
  const [locale, setLocale] = useState('vi');
  const [name, setName] = useState('');
  const inputRef = useRef<InputRef>(null);
  const dispatch: DispatchType = useDispatch();

  const handleNewCategory = (value: number[]) => {
    // Xử lý thêm category
    let newCategories: number[] = []; // Gán giá trị mặc định là một mảng rỗng
    value.forEach((categoryId) => {
      const selectedCategory = arrCategories.find((category) => category.id === categoryId);
      if (selectedCategory) {
        newCategories.push(Number(selectedCategory.id)); // Thêm phần tử vào mảng newCategories
      }
    })

    setCategory(newCategories);
  }

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    // Xử lý thêm category
    e.preventDefault();
    const newItem = { name: name };
    runcodeService.createNewCategory(newItem).then(res => {
      dispatch(fetchCategories());
      message.success("Thêm thành công");
    }).catch(err => {
      message.error("Lỗi Báo IT");
    })
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };


  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onRadioChange = (event: RadioChangeEvent) => {
    setLocale(event.target.value);
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData((prevData: RuncodeData) => ({ ...prevData, [name]: value }));
  };

  const onChangeTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setData((prevData: RuncodeData) => ({ ...prevData, [name]: value }));
  };

  const onChangeValue = (value: any, name: string) => {
    setData((prevData: RuncodeData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const onChangeReward = (value: any, name: string) => {
    setReward(prevData => ({
      ...prevData,
      [name]: value
    }));
  }

  const {
    question_title_vi,
    problem_vi,
    explanation_vi,
    constraint_input_vi,
    constraint_output_vi,
    question_title_en,
    problem_en,
    explanation_en,
    constraint_input_en,
    constraint_output_en,
    input_format,
    output_format,
    sample_input,
    sample_output,
    _level,
  } = data;


  return (
    <>
      <div className="detail-type">
        <h6>CHỌN NGÔN NGỮ</h6>
        <Radio.Group value={locale} onChange={onRadioChange} buttonStyle="outline" size="large" className='my-3'>
          <Radio.Button value="vi">Tiếng Việt</Radio.Button>
          {/* <div className="arrow"><i className="fas fa-long-arrow-alt-right"></i></div> */}
          <Radio.Button value="en">Tiếng Anh</Radio.Button>
        </Radio.Group>
      </div>

      {/* Các trường dữ liệu liên quan đến câu hỏi */}
      <div className="question-fields">
        <h6>CÂU HỎI</h6>
        {locale === 'vi' ? (
          <Row gutter={16}>
            <Col span={12}>
              <div className="mb-3">
                <label className="form-label">Tiêu đề câu hỏi</label>
                <Input onChange={onChangeInput} name="question_title_vi" value={question_title_vi} />
              </div>
            </Col>
            <Col span={12}>
              <div className="mb-3">
                <label className="form-label">Đề bài</label>
                <Input onChange={onChangeInput} name="problem_vi" value={problem_vi} />
              </div>
            </Col>

            <Col span={8} className='me-3'>
              <Row>
                <Col span={8} className='pe-3'>
                  <label className="form-label">Coin </label>
                  <InputNumber value={reward.coin} style={{ width: "100%" }} min={1} onChange={(value) => onChangeReward(value, "coin")} />
                </Col>
                <Col span={8} className='pe-3'>
                  <label className="form-label">Kinh Nghiệm </label>
                  <InputNumber value={reward.kinhNghiem} style={{ width: "100%" }} min={1} onChange={(value) => onChangeReward(value, "kinhNghiem")} />
                </Col>
                <Col span={8} className='pe-3'>
                  <label className="form-label">Tích cực </label>
                  <InputNumber value={reward.tichCuc} style={{ width: "100%" }} min={1} onChange={(value) => onChangeReward(value, "tichCuc")} />
                </Col>
              </Row>
            </Col>

            <Col span={6}>
              <div className="me-5 mb-3">
                <label className="form-label">Category</label>
                <Select
                  mode='multiple'
                  style={{
                    width: '100%',
                  }}
                  maxTagCount='responsive'
                  placeholder="Please select category"
                  onChange={handleNewCategory}
                  value={category}
                  filterOption={(inputValue, option) =>
                    option?.label?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1
                  }
                  dropdownRender={(menu) => {
                    return (
                      <>
                        {menu}
                        <Divider
                          style={{
                            margin: '8px 0',
                          }}
                        />
                        <Space
                          style={{
                            padding: '0 8px 4px',
                          }}
                        >
                          <Input
                            placeholder="Please enter item"
                            ref={inputRef}
                            value={name}
                            onChange={onNameChange}
                          />
                          <Button type="text" disabled={!name} icon={<PlusOutlined />} onClick={addItem}>
                            Add category
                          </Button>
                        </Space>
                      </>
                    )
                  }}
                  options={arrCategories?.map((item) => {
                    return ({
                      label: item.name,
                      value: Number(item.id),
                    })
                  })}
                />
              </div>
            </Col>

            <Col span={6}>
              <div className="mb-3">
                <label className="form-label">Level</label>
                <Slider
                  marks={{
                    1: 'Easy',
                    2: 'Medium',
                    3: 'Hard'
                  }}
                  min={1}
                  max={3}
                  onChange={(value) => onChangeValue(value, "_level")}
                  value={_level}
                />
              </div>
            </Col>

            <Col span={24}>
              <div className="mb-3">
                <label className="form-label">Giải thích</label>
                <TextArea onChange={onChangeTextArea} name="explanation_vi" value={explanation_vi} />
              </div>
            </Col>
          </Row>
        ) : (
          <Row gutter={16}>
            <Col span={12}>
              <div className="mb-3">
                <label className="form-label">Question Title</label>
                <Input onChange={onChangeInput} name="question_title_en" value={question_title_en} />
              </div>
            </Col>
            <Col span={12}>
              <div className="mb-3">
                <label className="form-label">Problem</label>
                <Input onChange={onChangeInput} name="problem_en" value={problem_en} />
              </div>
            </Col>

            <Col span={8} className='me-3'>
              <Row>
                <Col span={8} className='pe-3'>
                  <label className="form-label">Coin </label>
                  <InputNumber value={reward.coin} style={{ width: "100%" }} min={1} onChange={(value) => onChangeReward(value, "coin")} />
                </Col>
                <Col span={8} className='pe-3'>
                  <label className="form-label">Kinh Nghiệm </label>
                  <InputNumber value={reward.kinhNghiem} style={{ width: "100%" }} min={1} onChange={(value) => onChangeReward(value, "kinhNghiem")} />
                </Col>
                <Col span={8} className='pe-3'>
                  <label className="form-label">Tích cực </label>
                  <InputNumber value={reward.tichCuc} style={{ width: "100%" }} min={1} onChange={(value) => onChangeReward(value, "tichCuc")} />
                </Col>
              </Row>
            </Col>

            <Col span={6}>
              <div className="me-5 mb-3">
                <label className="form-label">Category</label>
                <Select
                  mode='multiple'
                  style={{
                    width: '100%',
                  }}
                  maxTagCount='responsive'
                  placeholder="Please select category"
                  onChange={handleNewCategory}
                  value={category}
                  filterOption={(inputValue, option) =>
                    option?.label?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1
                  }
                  dropdownRender={(menu) => {
                    return (
                      <>
                        {menu}
                        <Divider
                          style={{
                            margin: '8px 0',
                          }}
                        />
                        <Space
                          style={{
                            padding: '0 8px 4px',
                          }}
                        >
                          <Input
                            placeholder="Please enter item"
                            ref={inputRef}
                            value={name}
                            onChange={onNameChange}
                          />
                          <Button type="text" disabled={!name} icon={<PlusOutlined />} onClick={addItem}>
                            Add category
                          </Button>
                        </Space>
                      </>
                    )
                  }}
                  options={arrCategories?.map((item) => {
                    return ({
                      label: item.name,
                      value: Number(item.id),
                    })
                  })}
                />
              </div>
            </Col>
            <Col span={6}>
              <div className="mb-3">
                <label className="form-label">Level</label>
                <Slider
                  marks={{
                    1: 'Easy',
                    2: 'Medium',
                    3: 'Hard'
                  }}
                  min={1}
                  max={3}
                  onChange={(value) => onChangeValue(value, "_level")}
                  value={_level}
                />
              </div>
            </Col>
            <Col span={24}>
              <div className="mb-3">
                <label className="form-label">Explanation</label>
                <TextArea onChange={onChangeTextArea} name="explanation_en" value={explanation_en} />
              </div>
            </Col>
          </Row>
        )}
      </div>


      {/* Các trường dữ liệu khác */}
      <div className="other-fields">
        <h6>CÁC TRƯỜNG DỮ LIỆU KHÁC</h6>
        <Row gutter={16}>
          <Col span={12}>
            <div className="mb-3">
              <label className="form-label">Định dạng input</label>
              <Input onChange={onChangeInput} name="input_format" value={input_format} />
            </div>
          </Col>
          <Col span={12}>
            <div className="mb-3">
              <label className="form-label">Định dạng output</label>
              <Input onChange={onChangeInput} name="output_format" value={output_format} />
            </div>
          </Col>
          <Col span={12}>
            <div className="mb-3">
              <label className="form-label">Ví dụ input</label>
              <TextArea onChange={onChangeTextArea} name="sample_input" value={sample_input} />
            </div>
          </Col>
          <Col span={12}>
            <div className="mb-3">
              <label className="form-label">Ví dụ output</label>
              <TextArea onChange={onChangeTextArea} name="sample_output" value={sample_output} />
            </div>
          </Col>
        </Row>
      </div>

      {/* Các trường dữ liệu liên quan đến ràng buộc */}
      <div className="constraint-fields">
        <h6>RÀNG BUỘC</h6>
        {/* Tiếng Việt */}
        {locale === 'vi' && (
          <Row gutter={16}>
            <Col span={12}>
              <div className="mb-3">
                <label className="form-label">Ràng buộc input</label>
                <TextArea onChange={onChangeTextArea} name="constraint_input_vi" value={constraint_input_vi} />
              </div>
            </Col>
            <Col span={12}>
              <div className="mb-3">
                <label className="form-label">Ràng buộc output</label>
                <TextArea onChange={onChangeTextArea} name="constraint_output_vi" value={constraint_output_vi} />
              </div>
            </Col>
          </Row>
        )}

        {/* Tiếng Anh */}
        {locale === 'en' && (
          <Row gutter={16}>
            <Col span={12}>
              <div className="mb-3">
                <label className="form-label">Input Constraint</label>
                <TextArea onChange={onChangeTextArea} name="constraint_input_en" value={constraint_input_en} />
              </div>
            </Col>
            <Col span={12}>
              <div className="mb-3">
                <label className="form-label">Output Constraint</label>
                <TextArea onChange={onChangeTextArea} name="constraint_output_en" value={constraint_output_en} />
              </div>
            </Col>
          </Row>
        )}
      </div>

    </>
  );
};

export default ThongTinChiTiet;
