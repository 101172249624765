import {
  Card,
  Col,
  DatePicker,
  DatePickerProps,
  message,
  Popconfirm,
  Radio,
  Row,
  Segmented,
  Select,
  Tag,
} from "antd";
import { SegmentedValue } from "antd/es/segmented";
import Table, { ColumnsType } from "antd/es/table";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useSearchParams } from "react-router-dom";
import { getBaiHocApi } from "../../redux/BaiHocReducer/BaiHocReducer";
import { DispatchType, RootState } from "../../redux/configStore";
import { baiHocService } from "../../services/baiHocService";
import { BaiHocModel } from "../../Types/baiHocType";
import "ace-builds/webpack-resolver";
// then the mode, theme & extension
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow_night";
import "ace-builds/src-noconflict/ext-searchbox";
import AceEditor from "react-ace";
import { getListTag, listLoaiTaskCreate } from "../../util/SelectOption";
import { listTypeTracNghiem } from "../../constant/task.constant";
import StepBaiTapNop from "../StepBaiTapNop/StepBaiTapNop";
import { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";

const { RangePicker } = DatePicker;

type Props = {
  opSegmented: listType[];
  setTaskDetail: Dispatch<SetStateAction<BaiHocModel>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

type listType = {
  value: string | number;
  label: string | JSX.Element;
};

const ListTaskReview = ({ opSegmented, setTaskDetail, setOpen }: Props) => {
  const entities = require("entities");
  //filter trac nghiem
  const [filterTracNghiem, setFilterTracNghiem] = useState<string>(
    listTypeTracNghiem.fillInblank
  );
  const [ngayBatDau, setNgayBatDau] = useState<string>("");
  const [ngayKetThuc, setNgayKetThuc] = useState<string>("");

  const { arrBaiHoc } = useSelector((state: RootState) => state.baiHocReducer);
  let [searchParams, setSearchParams] = useSearchParams();
  const [maLoaiLoc, setMaLoaiLoc] = useState<SegmentedValue>("");

  const dispatch: DispatchType = useDispatch();
  const { arrTag } = useSelector((state: RootState) => state.chuyenDeReducer);

  // xoa cau hoi
  const xoaTask = (id: number) => {
    baiHocService
      .xoaBaiHoc(id)
      .then((res) => {
        dispatch(getBaiHocApi());

        message.success("Xóa thành công");
      })
      .catch((err) => {
        message.error("Lỗi Báo IT");
      });
  };

  const columns: ColumnsType<BaiHocModel> = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Tiêu đề",
      dataIndex: "tenBaiHoc",
    },
    {
      title: "Level",
      dataIndex: "capDo",
    },
    {
      title: "Thời lượng",
      render: (_, record) => {
        let loaiTask = listLoaiTaskCreate.find((n) => n.value == record.maLoai);
        if (loaiTask)
          return (
            <Tag>
              {record.thoiLuong} {loaiTask.time}
            </Tag>
          );
      },
    },
    // {
    //   title: "Tag skill",
    //   render: (_, record) => {
    //     const listTag = getListTag(arrTag, "SKILL");
    //     let arrTagJson: string[] =
    //       record.tagSkill && JSON.parse(record.tagSkill);
    //     return arrTagJson?.map((item) => {
    //       return (
    //         <Tag color="magenta">
    //           {listTag.find((n) => n.value == item)?.label}
    //         </Tag>
    //       );
    //     });
    //   },
    // },
    // {
    //   title: "Tag",
    //   render: (_, record) => {
    //     const listTag = getListTag(arrTag, "EXTEND");
    //     let arrTagJson: string[] = record.tag && JSON.parse(record.tag);

    //     return arrTagJson?.map((item) => {
    //       return (
    //         <Tag color="magenta">
    //           {listTag.find((n) => n.value == item)?.label}
    //         </Tag>
    //       );
    //     });
    //   },
    // },
    {
      title: "Loại task",
      render: (_, record) => {
        let loaiTask = listLoaiTaskCreate.find((n) => n.value == record.maLoai);
        if (loaiTask) return <Tag color="blue">{loaiTask.label}</Tag>;
      },
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <>
            <button
              className="btn btn-sm btn-success mx-2"
              onClick={() => {
                setOpen(true);
                setTaskDetail(record);
              }}
            >
              <i className="fas fa-edit"></i>
            </button>
            <Popconfirm
              title="Bạn có chắc muốn xóa ?"
              onConfirm={() => xoaTask(record.id)}
            >
              <button className="btn btn-sm btn-danger">
                <i className="fas fa-trash"></i>
              </button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  // const [dataTask, setDataTask] = useState<BaiHocModel[] | []>();

  // useEffect(() => {
  //   if (arrBaiHoc) {
  //     setDataTask(arrBaiHoc);
  //   }
  // }, [arrBaiHoc]);

  let dataTask = arrBaiHoc?.filter(n=>n.maLoai == "QUIZ" ||n.maLoai == "CAPS" ||n.maLoai == "DUAN" ||n.maLoai == "BAITAP");

  let tieuDe = searchParams.get("td");
  let tagFilter = searchParams.get("tag");

  dataTask =
    dataTask &&
    dataTask?.filter(
      (n) =>
        n.tenBaiHoc
          ?.toLowerCase()
          .indexOf(tieuDe ? tieuDe.toLowerCase() : "") != -1
    );
  if (maLoaiLoc != "") {
    dataTask = dataTask && dataTask?.filter((n) => n.maLoai == maLoaiLoc);
  }

  if (ngayBatDau !== "" && ngayKetThuc !== "") {
    dataTask =
      dataTask &&
      dataTask?.filter((n) => {
        const dateBaiHoc = moment(n.ngayTao).format("YYYY-MM-DD");
        return dateBaiHoc >= ngayBatDau && dateBaiHoc <= ngayKetThuc;
      });
  }

  let cusOpSegmented = [
    // {
    //   label: <> Tất cả</>,
    //   value: "",
    // },
    ...opSegmented,
  ];

  let cusListTag = [
    { label: "Tất cả", value: "n" },
    { label: "Chưa có tag", value: "" },
    ...getListTag(arrTag, "EXTEND"),
  ];

  if (tagFilter != "n") {
    if (tagFilter == "")
      dataTask = dataTask && dataTask.filter((n) => n.tag == "[]");
    else
      dataTask =
        dataTask &&
        dataTask.filter(
          (n) => n.tag?.indexOf(tagFilter ? tagFilter : "") != -1
        );
  }

  const renderReviewTracNghiem = () => {
    return dataTask?.map((item) => {
      if (
        item.maLoai === "QUIZ" &&
        item.noiDung &&
        JSON.parse(item.noiDung)[0].maLoaiBaiTap === filterTracNghiem
      ) {
        const noiDung = JSON.parse(item.noiDung)[0];
  
        
        return (
          <Col span={24} className="mb-3">
            <Card
              title={
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <span>Id: {item.id}</span>
                    <div>
                      <p className="mb-0">
                        Ngày tạo: {moment(item.ngayTao).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </div>

                  <div>
                    <p className="mb-0 fs-5">Loại câu: {item.tenBaiHoc}</p>
                  </div>

                  <div>
                    <NavLink
                      className="btn btn-danger"
                      to={`/baihoc?idCauHoi=${item.id}`}
                    >
                      <i className="fas fa-edit"></i>
                    </NavLink>
                  </div>
                </div>
              }
              headStyle={{
                backgroundColor: "#2f8281",
                color: "white",
                fontSize: "18px",
              }}
              bordered={true}
              bodyStyle={{ border: "1px solid gray", padding: "20px 10px" }}
            >
              <div className="d-flex">
                <div style={{ width: "10%" }}>
                  <div className="mb-4">
                    <h4 className="text-danger text-uppercase">
                      Level: <span className="fs-3">{item.capDo}</span>
                    </h4>
                  </div>
                  <div className="mb-4">
                    <h4 className="mb-3">Tag Skill</h4>
                    {item.tagSkill &&
                      JSON.parse(item.tagSkill).map((tag: string) => {
                        return <Tag color="magenta">{tag}</Tag>;
                      })}
                  </div>
                  <div>
                    <h4 className="mb-3">Trạng thái</h4>
                    <Select
                      options={[
                        { value: "chưa chuẩn", label: "Chưa chuẩn" },
                        { value: "đã chuẩn", label: "Đã chuẩn" },
                        { value: "chưa kiểm tra", label: "Chưa kiểm tra" },
                      ]}
                      value={
                        item.metaData &&
                        item.metaData !== "" &&
                        JSON.parse(item.metaData)?.verify
                      }
                      onChange={(value) => {
                        baiHocService
                          .suaBaiHoc({
                            ...item,
                            metaData: JSON.stringify({
                              verify: value,
                            }),
                          })
                          .then((res) => {
                            dispatch(getBaiHocApi());
                            message.success("Cập nhật trạng thái thành công");
                          })
                          .catch((err) => {
                            console.log(err);
                            message.error("Lỗi Báo IT");
                          });
                      }}
                    />

                    {/* <Radio.Group
                      optionType="button"
                      buttonStyle="solid"
                      value={
                        item.metaData &&
                        item.metaData !== "" &&
                        JSON.parse(item.metaData)?.verify
                      }
                      onChange={(event) => {
                        baiHocService
                          .suaBaiHoc({
                            ...item,
                            metaData: JSON.stringify({
                              verify: event.target.value,
                            }),
                          })
                          .then((res) => {
                            dispatch(getBaiHocApi());
                            message.success("Cập nhật trạng thái thành công");
                          })
                          .catch((err) => {
                            console.log(err);
                            message.error("Lỗi Báo IT");
                          });
                      }}
                    >
                      <Radio value={"chưa chuẩn"}>Chưa chuẩn</Radio>
                      <Radio value={"đã chuẩn"}>Đã chuẩn</Radio>
                      <Radio value={"chưa kiểm tra"}>Chưa kiểm tra</Radio>
                    </Radio.Group> */}
                  </div>
                </div>
                <div style={{ width: "90%" }}>
                  <h3>{noiDung.tieuDe}</h3>
                  <AceEditor
                    name="inPut"
                    mode="javascript"
                    theme="monokai"
                    width="100%"
                    height="300px"
                    fontSize={13}
                    // onChange={(value) =>
                    //   onInputChange(
                    //     { target: { name: "inPut", value } },
                    //     baiTap
                    //   )
                    // }
                    value={noiDung.inPut && entities.decodeHTML(noiDung?.inPut)}
                    readOnly={true}
                  />
                  <Row gutter={16} className="mt-2">
                    {noiDung.maLoaiBaiTap === "fill_input" && (
                      <Col span={24}>
                        <div className="rounded-3 py-2 px-3 border border-1 border-dark fs-5 bg-success text-white h-100">
                          {noiDung.dapAn[0]}
                        </div>
                      </Col>
                    )}
                    {noiDung.cauTraLoi.map((item: any, index: number) => {
                      const checkDapAn = noiDung.dapAn.includes(item.ma);
                      return (
                        <Col span={12} className="my-3" key={index}>
                          <div
                            className={
                              checkDapAn
                                ? "rounded-3 py-2 px-3 border border-1 border-dark fs-5 bg-success text-white h-100"
                                : "rounded-3 py-2 px-3 border border-1 border-dark fs-5 h-100"
                            }
                          >
                            {item.noiDung}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </div>
            </Card>
          </Col>
        );
      }
    });
  };
  const renderReviewTask = () => {
    if (dataTask) {
      switch (maLoaiLoc) {
        case "QUIZ":
          return (
            <>
              <h5>Lọc theo loại câu hỏi</h5>
              <Segmented
                options={[
                  listTypeTracNghiem.fillInblank,
                  listTypeTracNghiem.fillInput,
                  listTypeTracNghiem.multipleChoice,
                  listTypeTracNghiem.single,
                  listTypeTracNghiem.fillInblankCss,
                ]}
                size="large"
                className="mb-3"
                onChange={(value) => {
                  // console.log(value);
                  setFilterTracNghiem(value as string);
                }}
              />
              <Row gutter={16}>{renderReviewTracNghiem()}</Row>
            </>
          );
        case "CAPS":
          return <StepBaiTapNop maLoai={"CAPS"} dataTask={dataTask} />;
        case "BAITAP":
          return <StepBaiTapNop maLoai={"BAITAP"} dataTask={dataTask} />;
        default:
          return <Table columns={columns} dataSource={dataTask} />;
      }
    }
  };

  return (
    <>
      <Segmented
        size="large"
        options={cusOpSegmented}
        value={maLoaiLoc}
        onChange={(value: SegmentedValue) => setMaLoaiLoc(value)}
      />
      <div className=" row">
        <div className="col-2 py-2">
          <label className="form-label">Lọc tiêu đề: </label>
          <div className="input-group mb-3">
            <input
              id="txt-search"
              type="text"
              className="form-control"
              placeholder="Tiêu đề"
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                let element: any = document.querySelector("#txt-search");
                searchParams.set("td", element.value);
                setSearchParams(searchParams);
              }}
            >
              Search
            </button>
          </div>
        </div>
        <div className="col-2 py-2">
          <label className="form-label">Lọc Tag: </label>

          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder="Chọn tag skill"
            optionFilterProp="children"
            onChange={(value) => {
              searchParams.set("tag", value);
              setSearchParams(searchParams);
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={cusListTag}
          />
        </div>
        <div className="col-2 py-2">
          <label className="form-label">Lọc Theo Ngày: </label>
          <RangePicker
            onChange={(
              value: DatePickerProps["value"] | RangePickerProps["value"],
              dateString: [string, string] | string
            ) => {
              // console.log(dinhDangNgayCheck(value));
              setNgayBatDau(dateString[0]);
              setNgayKetThuc(dateString[1]);
            }}
            format={"YYYY-MM-DD"}
          />
        </div>
        <div className="col-12">{renderReviewTask()}</div>
      </div>
    </>
  );
};

export default ListTaskReview;
