import { InboxOutlined } from '@ant-design/icons'
import { Divider, Input, Popconfirm, Switch, TreeSelect, UploadProps, message } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import { CKEditor } from 'ckeditor4-react'
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { API_URL_VIDEO } from '../../util/urlDomain'
import { TreeNode } from 'antd/es/tree-select'
import { useDispatch } from 'react-redux'
import { getDsBaiHocOnlineApi, getDsChuongHocOnlineApi, getDsKhoaHocOnlineApi, getDsLoTrinhOnlineApi } from '../../redux/BaiHocReducer/BaiHocReducer'
import { DispatchType } from '../../redux/configStore'
import { useSelector } from 'react-redux'
import { fileService } from '../../services/fileService'

type Props = {
    noiDungTaiLieu: string,
    setNoiDungTaiLieu: Dispatch<SetStateAction<string>>,
    videoCyberLearn: boolean,
    setVideoCyberLearn: any,
    setData: any,
    data: any

}

const XemVideo = ({
    noiDungTaiLieu,
    setNoiDungTaiLieu,
    videoCyberLearn,
    setVideoCyberLearn,
    setData,
    data
}: Props) => {

    const [key, setKey] = useState(Date.now());
    const dispatch: DispatchType = useDispatch();

    useEffect(() => {

        setKey(Date.now());
        dispatch(getDsLoTrinhOnlineApi())
        dispatch(getDsKhoaHocOnlineApi())
        dispatch(getDsChuongHocOnlineApi())
        dispatch(getDsBaiHocOnlineApi())

    }, [])


    //set data ck editor bth
    const onEditorChange = (evt: any) => {

        setNoiDungTaiLieu(evt.editor.getData());

    }

    const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {

        setNoiDungTaiLieu(event.target.value);

    }
    const props: UploadProps = {
        name: 'file',
        multiple: true,
        action: API_URL_VIDEO,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                if (noiDungTaiLieu) {
                    fileService.xoaVideoFtpService("techx", noiDungTaiLieu);
                }
                setNoiDungTaiLieu(info.file.response[0])

            } else if (status === 'error') {
                // message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        },
    };


    const dsLoTrinhOnline = useSelector((state: any) => state.baiHocReducer.dsLoTrinhOnline)
    const dsKhoaHocOnline = useSelector((state: any) => state.baiHocReducer.dsKhoaHocOnline)
    const dsChuongHocOnline = useSelector((state: any) => state.baiHocReducer.dsChuongHocOnline)
    const dsBaiHocOnline = useSelector((state: any) => state.baiHocReducer.dsBaiHocOnline)


    const treeData: any[] = dsLoTrinhOnline.map((itemLoTrinh: any) => {

        const lstKhoaHoc = itemLoTrinh && itemLoTrinh.danhSachKhoaHoc ? JSON.parse(itemLoTrinh.danhSachKhoaHoc) : [];

        const childrenKhoaHoc = lstKhoaHoc.map((itemKhoa: any) => {
            const khoaHoc = dsKhoaHocOnline.find((n: any) => n.id === itemKhoa);
            const lstChuongHoc = khoaHoc ? JSON.parse(khoaHoc.danhSachChuong) : [];

            const childrenChuongHoc = lstChuongHoc.map((itemChuong: any) => {
                const chuongHoc = dsChuongHocOnline.find((n: any) => n.id === itemChuong);
                const lstBaiHoc = chuongHoc ? JSON.parse(chuongHoc.danhSachMaBaiHoc) : [];

                const childrenBaiHoc = lstBaiHoc.map((itemBai: any) => {
                    const baiHoc = dsBaiHocOnline.find((n: any) => n.id === itemBai);
                    if (baiHoc && baiHoc.maLoaiBaiHoc === "VIDEO_FPT") {
                        return {
                            value: JSON.stringify(baiHoc),
                            title: baiHoc.tenBaiHoc
                        };
                    }
                    return null;
                }).filter((b: any) => b !== null);

                return {
                    value: itemChuong,
                    title: chuongHoc ? chuongHoc.tenChuongHoc : 'Unknown Chapter',
                    children: childrenBaiHoc,
                    selectable: false
                };
            });

            return {
                value: itemKhoa,
                title: khoaHoc ? khoaHoc.tenKhoaHoc : 'Unknown Course',
                children: childrenChuongHoc,
                selectable: false
            };
        });

        return {
            value: "0-" + itemLoTrinh.id,
            title: itemLoTrinh.tenLoTrinh,
            children: childrenKhoaHoc,
            selectable: false
        };
    })


    const layNoiDungOnline = () => {

        return <TreeSelect
            showSearch
            style={{ overflow: 'auto', width: "100%" }}
            listHeight={500}
            placeholder="Please select"

            allowClear
            treeNodeFilterProp="title"
            onChange={(item) => {
                let baiHoc = JSON.parse(item)

                let model = {
                    tenBaiHoc: baiHoc.tenBaiHoc,
                    thoiLuong: baiHoc.thoiLuong,
                    moTa: baiHoc.moTa,
                    ghiChu: baiHoc.ghiChu
                }

                setData({ ...data, ...model })

                setNoiDungTaiLieu(baiHoc?.noiDung)

            }}
            treeData={treeData}
        />

    }

    // console.log(videoCyberLearn)

    return (
        <div>
            <label>
                CyberLearn: <Switch checked={videoCyberLearn} onChange={(value) => { setVideoCyberLearn(value) }} />

            </label>


            <Divider />
            {videoCyberLearn ?
                <>
                    {layNoiDungOnline()}
                    <Input disabled value={noiDungTaiLieu} width={"100%"} />

                </>

                :


                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>

                </Dragger>
            }




        </div>
    )
}

export default XemVideo