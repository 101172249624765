// src/components/Calendar.tsx
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Calendar.css'; // Import CSS tùy chỉnh của bạn
import { Modal, Select, Upload, UploadFile, UploadProps, message } from 'antd';
import { API_URL_DOMAIN } from '../../../util/urlDomain';
import { RcFile } from 'antd/es/upload';
import { uploadImageService } from '../../../services/uploadImagService';
import { PlusOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { chamCongService } from '../../../services/chamCongMentorService';
import { getAllChamCongThangApi } from '../../../redux/ChamCongMT/ChamCongMTReducer';

interface EventData {
    ngayTao: string;
    hocVienId: string;
}

interface ConvertedData {
    [formattedDate: string]: string[];
}
const CalenderChamCong: React.FC = () => {
    const { dsHocVienByMentorId } = useAppSelector((state) => state.nopBaiReducer)
    const { arrChamCongThang } = useAppSelector((state) => state.ChamCongMTReducer)
    //loại bỏ tiếng việt
    const removeVietnameseTones = (str:string) => {
        return str
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/đ/g, 'd')
            .replace(/Đ/g, 'D');
    };
    //img
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('')
    // Data mẫu với định dạng ngày dd/mm/yyyy
    const sampleEvents = {
    };

    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [events, setEvents] = useState<{ [key: string]: string[] }>(sampleEvents);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleDateChange = (date: Date) => {
        setSelectedDate(date);
        setModalIsOpen(true);
    };
    //select hocvien
    const [hocvienId,setHocVienId] = useState<string>("")
    const handleChangeHocVien = (id:string) => { 
        setHocVienId(id)
     }
    let userLogin = localStorage.getItem("USER_LOGIN");
    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // const formData = new FormData(event.currentTarget);
        // const eventName = formData.get('event') as string;
        // if (selectedDate) {
        //     const dateKey = formatDate(selectedDate);
        //     setEvents((prevEvents) => ({
        //         ...prevEvents,
        //         [dateKey]: [...(prevEvents[dateKey] || []), eventName],
        //     }));
        // }
        const obj = {
            "id": 0,
            "hinhAnh": imageUrl,
            "metaData": "",
            "mentorId":  userLogin ? JSON.parse(userLogin).id : null,
            "hocVienId": hocvienId,
            "ngayTao":new Date(),
            "daXoa":false
          }
          console.log(obj)
          chamCongService.themChamCong(obj).then(res=>{
            closeModal()
            message.success("Thêm thành công");
          }).catch(err => {
            message.error("Lỗi Báo IT");

        })
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const formatDate = (date: Date): string => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Tháng bắt đầu từ 0
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const isToday = (date: Date): boolean => {
        const today = new Date();
        return date.toDateString() === today.toDateString();
    };

    const tileClassName = ({ date, view }: { date: Date; view: string }) => {
        if (view === 'month') {
            const dateKey = formatDate(date);
            if (events[dateKey]) {
                return 'busy-day';
            }
        }
        return '';
    };
    //img

    const getBase64 = (file: RcFile): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const handleCancel = () => setPreviewOpen(false);

    const handleChange: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
        try {
            setFileList(newFileList);
            if (newFileList.length > 0 && newFileList[0].originFileObj) {
                const imageUrl = await uploadImageService.uploadImage(newFileList[0].originFileObj);
                setImageUrl(imageUrl);
            }
        } catch (error) {
            // Xử lý lỗi nếu có
            console.error("Đã xảy ra lỗi:", error);
        }
    };
    const handleRemove = (file: UploadFile) => {
        const index = fileList.indexOf(file);
        const newFileList = [...fileList];
        newFileList.splice(index, 1);
        setImageUrl("")
        setFileList([]);
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    const convertData = (data: EventData[]): ConvertedData => {
        const result: ConvertedData = {};
    
        data.forEach((event: EventData) => {
            const date: Date = new Date(event.ngayTao);
            const formattedDate: string = date.toLocaleDateString('en-GB'); // Format as DD/MM/YYYY
    
            if (!result[formattedDate]) {
                result[formattedDate] = [];
            }
    
            result[formattedDate].push(`${event.hocVienId}`);
        });
    
        return result;
    };
  const dispatch = useAppDispatch()
    useEffect(()=>{
              const now = new Date().toLocaleTimeString(); // JavaScript months are 0-based
              const mentorId =  userLogin ? JSON.parse(userLogin).id : null;
              dispatch(getAllChamCongThangApi(mentorId,now))
              
    },[])
    useEffect(()=>{
        if(arrChamCongThang){
            const newevent = convertData(arrChamCongThang)
            setEvents(newevent)
        }
    },[arrChamCongThang])
    return (
        <div>
            <Calendar
                onClickDay={handleDateChange}
                tileClassName={tileClassName}
            />
            <Modal
                open={modalIsOpen}
                onCancel={closeModal}
                title="Chấm công"
                footer={null}
            >
                <h3>Ngày {selectedDate ? formatDate(selectedDate) : ''}</h3>
                <ul>
                    {(events[selectedDate ? formatDate(selectedDate) : ''] || []).map((event, index) => (
                        <li key={index}>{dsHocVienByMentorId.find(hv=>hv.id==event)?.hoTen}</li>
                    ))}
                </ul>
                {selectedDate && isToday(selectedDate) && (
                    <form onSubmit={handleFormSubmit}>
                        <div className="col-6 mb-3">
                            <label className="form-label">Học viên</label>
                            <Select
                            placeholder="Tìm kiếm và chọn"
                                showSearch
                                style={{ width: '100%' }}
                                // Custom filter function to match input with the option label (case-insensitive and tone-insensitive)
                                filterOption={(input, option) => {
                                    const normalizedInput = removeVietnameseTones(input.toLowerCase());
                                    const normalizedLabel = removeVietnameseTones((option?.label ?? '').toLowerCase());
                                    return normalizedLabel.includes(normalizedInput);
                                }}
                                // Custom sort function to sort options alphabetically (case-insensitive and tone-insensitive)
                                filterSort={(optionA, optionB) => {
                                    const normalizedLabelA = removeVietnameseTones((optionA?.label ?? '').toLowerCase());
                                    const normalizedLabelB = removeVietnameseTones((optionB?.label ?? '').toLowerCase());
                                    return normalizedLabelA.localeCompare(normalizedLabelB);
                                }}
                                onChange={handleChangeHocVien}
                                // Mapping data to options for the Select component
                                options={dsHocVienByMentorId.map((item) => ({
                                    value: item.id,
                                    label: item.hoTen
                                }))}
                            />

                        </div>
                        <div className="col-6 mb-3">
                            <div>
                                <Upload
                                    // {...props}
                                    name="image"
                                    listType="picture-circle"
                                    action={`${API_URL_DOMAIN}/api/file/image`}
                                    className="upload-list-inline"
                                    maxCount={1}
                                    onPreview={handlePreview}
                                    onChange={handleChange}
                                    onRemove={handleRemove}
                                    fileList={fileList}
                                >
                                    {fileList.length > 1 ? null : uploadButton}
                                </Upload>
                                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </div>
                        </div>
                        <button className='btn btn-primary' type="submit">Thêm</button>
                    </form>
                )}
            </Modal>
        </div>
    );
};

export default CalenderChamCong;
