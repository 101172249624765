import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { loginApi } from "../../redux/UserReducer/userReducer";
import { useAppDispatch } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

export type UserLoginForm = {
  email: string;
  matKhau: string;
};

type Props = {};

const Login = (props: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const frmLogin = useFormik<UserLoginForm>({
    initialValues: {
      email: "",
      matKhau: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("email is invalid!")
        .required("email cannot be blank!"),
      matKhau: Yup.string().required("password cannot be blank!"),
    }),
    onSubmit: (value: UserLoginForm) => {
      dispatch(loginApi(value, navigate, messageApi));
    },
  });

  return (
    <>
      {contextHolder}
      <div className="d-flex justify-content-center">
        <form className="container" onSubmit={frmLogin.handleSubmit}>
          <h3>Login</h3>
          <div className="form-group">
            <p>email</p>
            <input
              className="form-control"
              name="email"
              id="email"
              onChange={frmLogin.handleChange}
              onBlur={frmLogin.handleBlur}
            />
            {frmLogin.errors.email && (
              <p className="text-danger">{frmLogin.errors.email}</p>
            )}
          </div>
          <div className="form-group">
            <p>password</p>
            <input
              className="form-control"
              name="matKhau"
              id="matKhau"
              type={"password"}
              onChange={frmLogin.handleChange}
              onBlur={frmLogin.handleBlur}
            />
            {frmLogin.errors.matKhau && (
              <p className="text-danger">{frmLogin.errors.matKhau}</p>
            )}
          </div>
          <div className="form-group mt-2">
            <button className="btn btn-success" type="submit">
              submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
