import { ChuyenDeModel, DangKyGoi } from "../Types/chuyenDeType";
import { http } from "../util/config";

const GET_PATH: string = '/api/chuyende';
const GET_PATH_DANGKY: string = '/api/dangkyhoc';

const layDanhSachChuyenDe = () => {
    return http.get(GET_PATH);
}
const layDanhSachChuyenDeHoc = () => {
    return http.get(GET_PATH + "/get-chuyen-de");
}

const themChuyenDe = (data: ChuyenDeModel | any) => {
    return http.post(GET_PATH, data);
}
const suaChuyenDe = (data: ChuyenDeModel | any) => {
    return http.put(`${GET_PATH}/${data.id}`, data);
}
const xoaChuyenDe = (id: number) => {
    return http.delete(`${GET_PATH}/${id}`);
}
const getChuyenDeMentor = (mentorId: string) => {
    return http.get(`${GET_PATH}/chuyendementor?mentorId=${mentorId}`)
}

const getChuyenDeMentorPhuTrach = (mentorId: string) => {
    return http.get(`${GET_PATH}/chuyendementorphutrach?mentorId=${mentorId}`)
}
const getChuyenDeMentorPhuTrachV2 = (mentorId: string) => {
    return http.get(`${GET_PATH}/chuyendementorphutrachV2?mentorId=${mentorId}`)
}

const ghiDanhUser = (model: any) => {
    return http.post(`${GET_PATH_DANGKY}/ghi-danh`, model);
}
const suaGhiDanhUser = (model: any) => {
    return http.put(`${GET_PATH_DANGKY}/${model.id}`, model);
}
const dangKyHocUser = (userId: string) => {
    return http.get(`${GET_PATH_DANGKY}/get-theo-user/${userId}`);
}


const dungHocUser = (model: any) => {
    return http.post(`${GET_PATH_DANGKY}/dung-hoc-chuyende`, model);
}
const giaHanHocUser = (model: any) => {
    return http.post(`${GET_PATH_DANGKY}/gia-han-chuyende`, model);
}

const cloneChuyenDe = (model: any) => {
    return http.post(`${GET_PATH}/clone-chuyende`, model);

}


const getUserChuyenDe = (id: number) => {
    return http.get(`${GET_PATH_DANGKY}/get-user-ghidanh/${id}`);

}

const xoaHocUser = (model: any) => {
    return http.post(`${GET_PATH_DANGKY}/xoa-hoc-chuyende`, model);
}

const layChuyenDeChiTiet = (chuyenDeId: number) => {
    return http.get(`${GET_PATH}/get-chuyen-de-chi-tiet/${chuyenDeId}`);
}
const ghiDanhPackage = (obj:DangKyGoi)=>{
    return http.post(`${GET_PATH_DANGKY}/ghiDanhPackage`,obj)
}
export const chuyenDeService = {
    layChuyenDeChiTiet,
    getUserChuyenDe,
    cloneChuyenDe,
    layDanhSachChuyenDeHoc,
    layDanhSachChuyenDe,
    themChuyenDe,
    suaChuyenDe,
    xoaChuyenDe,
    getChuyenDeMentorPhuTrach,
    getChuyenDeMentorPhuTrachV2,
    suaGhiDanhUser,
    ghiDanhUser,
    dangKyHocUser,
    giaHanHocUser,
    dungHocUser,
    getChuyenDeMentor,
    xoaHocUser,
    ghiDanhPackage
}
