import styled from 'styled-components'

export const HeaderCSS = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 950px;

  /* height: 80px; */
  width: 100%;

  background: #141824;
  border-bottom: 1px solid rgb(49, 55, 74);
  background-color: var(--color-sub-title);

  .cursor_pointed {
    cursor: pointer;
  }

  .bg__header {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    padding: 0px;
    /* gap: 950px; */
    background-color: var(--color-sub-title);

    height: 60px;
    width: 100%;

    .header__logo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;

      width: 260px;
      height: 90px;

      cursor: pointer;

      .logo {
        width: auto;
        height: 100%;
        background-color: transparent;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }

    .menuItem {
      color: #ccd4e0;
      transition: all 0.3s;

      display: flex;
      justify-content: center;
      align-items: center;
    }
    .themes {
      width: 65px;
      height: 60px;
    }

    .btnHover {
      :hover {
        color: white;
        /* border-color: #b1b1b1; */
        background-color: #1d2631;
      }
    }
    .menu {
      height: 60px;
      width: 65px;
      border-right: 1px solid rgb(49, 55, 74);
      border-left: 1px solid rgb(49, 55, 74);
    }

    .header__nav {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      gap: 10px;

      width: 430px;
      height: 60px;

      ul {
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0;
      }
      ul li {
        width: 130px;
      }
      ul a {
        text-decoration: none;
        font-family: 'Inter';
        font-style: normal;
        /* font-weight: 700; */
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        text-decoration-line: underline;

        color: #ffffff;
      }
    }
    .search {
      height: 60px;
      flex: auto;
        position: relative;
      padding: 10px 10px 10px 45px;
      i{
         
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);

          color: #ccd4e0;
        }
      input {
        width: 100%;
        height: 100%;
        background: transparent;
        color: #ffffff;
        border: none;
        padding: 0 10px;

       


      }
    }
    .thongBao {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 65px;
      border-right: 1px solid rgb(49, 55, 74);

      .nofication {
        cursor: pointer;

        .anticon.anticon-bell {
          color: white;
          font-size: 25px;
        }
        :not(.active) {
          .anticon.anticon-bell {
            animation: shake 3s infinite;
            color: rgb(255, 208, 81);
            font-size: 25px;
          }
        }
      }

      .header__avatar {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px;

        width: 120px;
        height: 50px;

        cursor: pointer;

        background: radial-gradient(215.31% 215.31% at 57.96% -48.44%, #b1b1b1 0%, rgba(177, 177, 177, 0) 100%)
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        border-radius: 10px;
        transition: all 0.3s;

        .avatar__img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        span {
          width: 60px;
          height: 40px;

          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          /* or 100% */

          display: flex;
          align-items: center;
          text-align: center;
          margin-left: 5px;

          color: #ffffff;
        }
        :hover {
          background: radial-gradient(
            215.31% 215.31% at 57.96% -48.44%,
            rgb(123 118 118) 0%,
            rgba(177, 177, 177, 0) 100%
          );
        }
      }
    }
    .user {
      width: 65px;
      height: 60px;
      border-right: 1px solid rgb(49, 55, 74);
    }
    .setting {
      width: 65px;
      height: 60px;
    }

    @keyframes shake {
      0% {
        transform: rotate(0);
      }
      25% {
        transform: rotate(10deg);
      }
      50% {
        transform: rotate(-10deg);
      }
      75% {
        transform: rotate(10deg);
      }
      100% {
        transform: rotate(0);
      }
      /* 100% { transform: rotate(0); } */
    }
  }
`
export const PopconfirmBodyCss = styled.div`
  width: 400px;
  color: #ffffff;
  .popconfirm__item {
    border: 1px solid #31374a;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
`
