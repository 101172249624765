import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { DispatchType, RootState } from '../../redux/configStore';
import { Button, Input, InputNumber, Popconfirm, Table, Tag, Tooltip, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CodeDemoType } from './Type/CodedemoType';
import { addNewCodeDemoApi, getAllCodeDemoApi } from '../../redux/UserReducer/userReducer';
import { useSelector } from 'react-redux';
import moment from 'moment';
import * as signalR from "@microsoft/signalr";
import { API_URL_DOMAIN } from '../../util/urlDomain';

type Props = {}

const CodeDemo = (props: Props) => {
    const [gioXem, setGioXem] = useState(1)
    const [nuocNgoai, setNuocNgoai] = useState(0)
    const { lstCodeDemo } = useSelector((state: RootState) => state.userReducer)
    const dispatch: DispatchType = useDispatch()

    const columns: ColumnsType<CodeDemoType> = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            render:(value,rec,idx)=>{
                return <div className='d-flex justify-content-between'>
                <span>{value}</span>
                <Tooltip trigger='click' title="Đã copy">
                     <Button className='ms-2' onClick={() => {
                                            navigator.clipboard.writeText(value);
                                        }}>Copy</Button>
                </Tooltip>
                </div>
            }
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'ngayTao',
            key: 'ngayTao',
            render: (value, record, index) => {
                return moment(value).format('DD/MM/YYYY HH:mm:ss')
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'suDung',
            key: 'suDung',
            render: (value, record, index) => {
                return !value ? <Tag color='green'>Chưa sử dụng</Tag> : <Tag color='red'>Đã sử dụng</Tag>
            },
        },
        {
            title: 'Quốc gia',
            dataIndex: 'nuocNgoai',
            key: 'nuocNgoai',
            render: (value, record, index) => {
                return !value ? <span className='px-1 bg-danger'>
                    <i className="fa fa-star text-warning"></i>
                </span> : <span className='px-1 bg-light'>
                    <i className="fa-solid fa-earth-americas"></i>
                </span>
            },
        },
        {
            title: 'Thời gian học (giờ)',
            dataIndex: 'gioXem',
            key: 'gioXem',
            render: (value) => {
                return <Tag color='default'>{value} giờ</Tag>
            }
        },
    ];
    const confirm = (e: React.MouseEvent<HTMLElement,MouseEvent>|undefined) => {
        dispatch(addNewCodeDemoApi(0,gioXem))
    };
    const cancel = (e: React.MouseEvent<HTMLElement,MouseEvent>|undefined) => {
        dispatch(addNewCodeDemoApi(1,gioXem))
    };
      useEffect(() => {
        dispatch(getAllCodeDemoApi());
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${API_URL_DOMAIN}/chat`)
            .configureLogging(signalR.LogLevel.Information)
            .build();

        connection.start()
            .then(() => console.log("SignalR Connected"))
            .catch(err => console.error("SignalR Connection Error: ", err));

        connection.on("getAllCodeDemo", message => {
            dispatch(getAllCodeDemoApi());
        });

        // Cleanup function
        return () => {
            connection.off("getAllCodeDemo");
            connection.stop();
        };
    }, []);
    return (
        <div>
            <div className="content__top">
                <div className="row py-5">
                    <span className='col-2'><b>Thời gian xem (/giờ):</b></span>
                    <div className="col-1">
                        <InputNumber min={0} max={36} value={gioXem} onChange={(value) => { setGioXem(Number(value)) }} />
                    </div>
                    <div className="col-1">
                        <Popconfirm
                            title={<p className='text-white'>Chọn loại mã ?</p>}
                            onConfirm={confirm}
                            onCancel={cancel}
                            okText="Trong nước"
                            cancelText="Nước ngoài"
                        >
                            <Button color='danger'>Tạo mã</Button>
                        </Popconfirm>

                    </div>
                </div>
            </div>
            <Table columns={columns} dataSource={lstCodeDemo} />
        </div>
    )
}

export default CodeDemo