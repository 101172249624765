import React, { useEffect } from "react";
import PopupChat from "./components/PopupChat";
import "./style.css";
import TabsChat from "./components/TabsChat/TabsChat";
import {  } from "../../redux/ChuyenDeReducer/chuyenDeReducer";
import { AuthProvider } from "../../hooks/useChatHub";
import { useAppDispatch } from "../../redux/hooks";



const StackChat = ({ showDrawer }:{showDrawer:()=>void}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // dispatch(getDSChuyenDeHocVienDangKyApi());
    // dispatch(getIssueHocVienTheoTungChuyenDeApi())
    // dispatch(getDsGroupForTaskHocVienApi());
  }, []);

  return (
    <div className="StackChat">
      <AuthProvider>
        <PopupChat
          showDrawer={showDrawer}
        />
        <TabsChat />
      </AuthProvider>



    </div>
  );
};

export default StackChat;
