import styled from "styled-components";

export const StudentCSS = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  mix-blend-mode: normal;
  width: 100%;

  background: radial-gradient(
    147.25% 147.25% at 53.02% 119.75%,
    #946433 15.56%,
    #7a4631 19.13%,
    #161415 47.19%
  );
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 5px;

    color: #ffffff;
    font-size: 20px;
  }

  .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 40px;
    gap: 10px;

    .overview {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      gap: 10px;

      border-radius: 10px;

      border: 1px solid #dbb845;

      .overview__left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 15px;
        gap: 10px;

        .event {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px 5px;
          background: radial-gradient(
            36.7% 36.7% at 50.15% 49.89%,
            rgba(0, 0, 0, 0.08) 0%,
            rgba(205, 180, 48, 0.08) 100%
          );
          border-radius: 10px;
          .title {
            font-size: 14px;
          }
          .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0px;

            .item {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding: 5px;
              gap: 10px;
            }
          }
        }
      }
      .overview__right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;

        background: radial-gradient(
            36.7% 36.7% at 50.15% 49.89%,
            rgba(0, 0, 0, 0.08) 0%,
            rgba(205, 180, 48, 0.08) 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        border-radius: 0px 15px 5px 0px;
        
      }
    }
  }

  .bottom {
  }
`;
