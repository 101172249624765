import { Data, categories } from "../Types/runCodeType";
import { https } from "../util/config";


const questionServ = {
  getQuestionList: () => {
    return https.get(`/getAllQuestion`);
  },
  getQuestionByID: (id: number) => {
    return https.get(`/getQuestionID/${id}`);
  },
  getCategory: () => {
    return https.get(`/categories`);
  },
  deleteQuestion: (id: number) => {
    return https.delete(`/deleteQuestion/${id}`)
  },
};

const getQuestionList = () => {
  return https.get(`/getAllQuestion`);
}

const getCategoryList = () => {
  return https.get(`/categories`);
}

const getQuestionByID = (id: number) => {
  return https.get(`/getQuestionID/${id}`);
}

const deleteQuestion = (id: number) => {
  return https.delete(`/deleteQuestion/${id}`)
}

const addQuestion = (data: Data | any) => {
  return https.post('/createQuestion', data);
};

const updateQuestion = (question_id: number, dataUpdate: Data | any) => {
  return https.put(`/updateQuestion/${question_id}`, dataUpdate);
};

const createNewCategory = (newCategory: categories | any) => {
  return https.post('/categories', newCategory);
};


export const runcodeService = {
  questionServ,
  getQuestionList,
  getQuestionByID,
  addQuestion,
  deleteQuestion,
  getCategoryList,
  createNewCategory,
  updateQuestion
}
