import React, { useEffect, useRef } from 'react';

const NotificationSound = ({ playSound }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    if (playSound && audioRef.current) {
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch(error => {
            console.log('Playback failed:', error);
          });
        }
      }
    }, [playSound]);

  return (
    <audio ref={audioRef} src="/notifica.wav" preload="auto" />
  );
};

export default NotificationSound;
