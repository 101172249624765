import axios from 'axios';
import { API_URL_DOMAIN_ONLINE } from '../util/urlDomain';


let token = ""

export const get = (url:any, ...params:any) => {
    return axios({
        baseURL: API_URL_DOMAIN_ONLINE,
        url: url,
        params: params,
        method:'GET',
        responseType: 'json',
        responseEncoding: 'utf8',
        headers: {
            'apikey':"U490fasTWF184524gdJFweUaCYBEADM",
            'Authorization': 'Bearer '+token,
            'Content-Type': 'application/json'
        }
    })
}

export const post = (url:any, data:any) => {
    return axios({
        baseURL: API_URL_DOMAIN_ONLINE,
        url: url,
        method:'POST',
        responseType: 'json',
        responseEncoding: 'utf8',
        headers: {
            'apikey':"U490fasTWF184524gdJFweUaCYBEADM",
            'Authorization': 'Bearer '+token,

            'Content-Type': 'application/json'
        },
        // timeout: 1000,
        data: JSON.stringify(data)
        
    })
}

export const put = (url:any, data:any) => {
    return axios({
        baseURL: API_URL_DOMAIN_ONLINE,
        url: url,
        method:'PUT',
        responseType: 'json',
        responseEncoding: 'utf8',
        headers: {
            'apikey':"U490fasTWF184524gdJFweUaCYBEADM",
            'Authorization': 'Bearer '+token,

            'Content-Type': 'application/json'
        },
        // timeout: 1000,
        data: JSON.stringify(data)
    })
}

export const remove = (url:any, ids:any) => {
    return axios({
        baseURL: API_URL_DOMAIN_ONLINE,
        url: url,
        method:'DELETE',
        responseType: 'json',
        responseEncoding: 'utf8',
        headers: {
            'apikey':"U490fasTWF184524gdJFweUaCYBEADM",
            'Authorization': 'Bearer '+token,

             'Content-Type': 'application/json'
        },
        data: JSON.stringify(ids)
        // timeout: 1000
    })
}

export const postForm = (url:any, data:any) => {
    return axios({
        baseURL: API_URL_DOMAIN_ONLINE,
        url: url,
        method:'POST',
        responseType: 'json',
        responseEncoding: 'utf8',
        headers: {
            'apikey':"U490fasTWF184524gdJFweUaCYBEADM",
            'Authorization': 'Bearer '+token,

            'Content-Type': 'application/json'
        },
        // timeout: 1000,
        data: data
        
    })
}

export const putForm = (url:any, data:any) => {
    // console.log(url,"url","data",data)
    return axios({
        baseURL: API_URL_DOMAIN_ONLINE,
        url: url,
        method:'POST',
        responseType: 'json',
        responseEncoding: 'utf8',
        headers: {
            'apikey':"U490fasTWF184524gdJFweUaCYBEADM",
            'Authorization': 'Bearer '+token,

            'Content-Type': 'application/json'
        },
        // timeout: 1000,
        data: data
        
    })
}

export const BaseApiOnline = {
    get,
    post,
    put,
    remove,
    postForm,
    putForm
}