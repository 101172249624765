import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { getAllQLCCApi } from '../../redux/ChamCongMT/ChamCongMTReducer'
import _, { set } from 'lodash'
import { DatePicker, DatePickerProps, Modal, Table, TableColumnsType, Tag } from 'antd'
import { chamCongType } from '../../Types/chamCongType'
import dayjs from 'dayjs'
import { API_URL_DOMAIN } from '../../util/urlDomain'

type Props = {}
interface DataType {
    key: number,
    mentorId: string,
    items: DateItems[]
}
interface DateItems {
    date: string,
    items: chamCongType[]
}
const QuanLiChamCongMT = (props: Props) => {
    let { qlChamCong } = useAppSelector((state) => state.ChamCongMTReducer)
    const [arrRen, setArrRen] = useState<any[]>()
    const dispatch = useAppDispatch()
    const [month, setmonth] = useState<string | undefined>(new Date().toLocaleTimeString())
    const [showImg, setShowImg] = useState<boolean>(false)
    const [urlImg, setUrlUmg] = useState<string>("")
    const columns: TableColumnsType<DataType> = [
        {
            title: 'STT',
            key: 'stt',
            render: (_, rec, idx) => <span>{idx + 1}</span>
        },

        Table.EXPAND_COLUMN,
        {
            title: 'Mentor',
            dataIndex: 'mentorId',
            key: 'mentorId',
            render: (text, rec, idx) => <span>{text}</span>
        },
        {
            title: 'Số ngày chấm công /tháng',
            dataIndex: 'items',
            key: 'items',
            render: (text, rec, idx) => <span>{text.length}</span>
        },

    ];

    useEffect(() => {
        const now = new Date().toLocaleTimeString(); // JavaScript month
        dispatch(getAllQLCCApi(month ? month : now))

    }, [month])
    useEffect(() => {
        if (qlChamCong) {
            const groupedData = _.groupBy(qlChamCong, 'mentorId');

            // Convert the grouped data into an array of objects
            const extractDate = (datetime: string) => datetime.split('T')[0];
            const groupedArray = Object.keys(groupedData).map((mentorId, idx) => ({
                key: idx,
                mentorId: mentorId,
                items: Object.keys(_.groupBy(groupedData[mentorId], item => extractDate(item.ngayTao))).map(date => ({
                    date: date,
                    items: _.groupBy(groupedData[mentorId], item => extractDate(item.ngayTao))[date]
                }))
            }));
            console.log(groupedArray)
            setArrRen(groupedArray)
        }
    }, [qlChamCong])
    const onChange: DatePickerProps['onChange'] = (date) => {
        setmonth(date?.toISOString())
    };
    const handleShowImg = (hinhAnh: string) => {
        setShowImg(true)
        setUrlUmg(hinhAnh)
    }
    const closeImg = () => {
        setShowImg(false)
    }
    return (
        <div>
            <div className="top py-5">
                <label htmlFor="">Chọn tháng</label>
                <DatePicker defaultValue={dayjs(new Date())} onChange={onChange} picker="month" />
            </div>
            <Table
                columns={columns}
                expandable={{
                    expandedRowRender: (record) => {
                        const columns2: TableColumnsType<DateItems> = [
                            {
                                title: 'Stt',
                                dataIndex: 'date',
                                key: 'stt',
                                render: (_, rec, idx) => <span>{idx + 1}</span>
                            },
                            {
                                title: 'Date',
                                dataIndex: 'date',
                                key: 'date',
                            },
                            {
                                title: 'Số lần hỗ trợ',
                                dataIndex: 'items',
                                key: 'items',
                                render: (text, rec, idx) => {
                                    return <div className="flex">
                                        {text.map((ten: chamCongType, idx: number) => {
                                            return <Tag style={{ cursor: 'pointer' }} color='green-inverse' key={idx} onClick={() => {
                                                handleShowImg(ten.hinhAnh)
                                            }}>{ten.hocVienId}</Tag>
                                        })}
                                    </div>
                                },
                            },
                        ];

                        return <Table columns={columns2} dataSource={record.items} size='small' />;
                    },
                }}
                dataSource={arrRen}
            />

            <Modal open={showImg} title={"Preview hình ảnh"} footer={null} onCancel={closeImg}>
                <img alt="example" style={{ width: '100%' }} src={`${API_URL_DOMAIN}/${urlImg}`} />
            </Modal>
        </div>
    )
}

export default QuanLiChamCongMT