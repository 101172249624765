import { DatePicker, DatePickerProps, Modal, Popconfirm, Select, Table, Tag, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { NguoiDungType } from '../../Types/nguoiDungType';
import { useSelector } from 'react-redux';
import { DispatchType, RootState } from '../../redux/configStore';
import { listType } from '../ChuyenDe/TaoMonHoc';
import { chuyenDeService } from '../../services/chuyenDeService';
import { DangKyHocModel } from '../../Types/chuyenDeType';
import { ColumnsType } from 'antd/es/table';
import { dinhDangNgay } from '../../util/dateFormat';
import dayjs from 'dayjs';
import { getQuaUserApi } from '../../redux/VatPhamReducer/vatPhamReducer';
import { useDispatch } from 'react-redux';
import { VatPham } from '../../Types/vatPhamType';
import { vatPhamService } from '../../services/vatPhamService';

type Props = {

  thongTinUser: NguoiDungType
}

const DanhSachDoiQua = ({ thongTinUser }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [data, setData] = useState<any>({});

  const dispatch: DispatchType = useDispatch();

  const { lstQuaUser } = useSelector((state: RootState) => state.vatPhamReducer);

  const showModal = () => {
    dispatch(getQuaUserApi(thongTinUser.id));

    setIsModalOpen(true);


  };



  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange = (value: string | number, name: string) => {
    setData({
      ...data,
      [name]: value
    })
  };

  // cột của table
  const columns: ColumnsType<VatPham> = [
    {
      title: "Ảnh",
      dataIndex: "hinhAnh",
      key: "id",
      render: (id, record, index) => (
        <div>
          <img width={100} src={record.hinhAnh} />
        </div>
      ),
    },
    {
      title: "Tên quà",
      dataIndex: "tenVatPham",
      key: "hoTen",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Số lượng",
      dataIndex: "soLuong",
      key: "hoTen",
      render: (text) => <b>{text}</b>,
    },

    {
      title: "Hành động",
      dataIndex: "id",
      key: "id",
      render: (id, record, index) => (
        <div>
          <Popconfirm
            title="Bạn có chắc muốn tiếp tục ?"
            onConfirm={() => {

              let model = {
                id: 0,
                hanhDong: "",
                nguoiDungId: thongTinUser.id,
                ngayTao: new Date(),
                content: record.id.toString()
              }

              vatPhamService.doiQuaService(model).then(item => {
                dispatch(getQuaUserApi(thongTinUser.id));
                message.success("Đã lưu !");
              }).catch();
            }}
          >
            <button className='btn btn-sm btn-primary'>Chấp nhận đổi</button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div className="text-center">
      <button className="btn btn-sm btn-outline-success" onClick={showModal}><i className="fa-solid fa-gifts"></i> Xem</button>

      <Modal title="Đổi quà"
        open={isModalOpen}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <div className='row'>
          <div className='col-8'>


          </div>
          <div className='col-2'>


          </div>
          <div className='col-2'>

          </div>
          <div className='col-12 mt-4'>
            <Table
              pagination={false}
              columns={columns}
              dataSource={lstQuaUser}
            />

          </div>
        </div>

      </Modal>
    </div>
  )
}

export default DanhSachDoiQua