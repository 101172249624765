import React from 'react'

type Props = {}

const TaoLiveEvent = (props: Props) => {
  return (
    <div>TaoLiveEvent</div>
  )
}

export default TaoLiveEvent
