import { Table } from "antd";
import React from "react";
import { NguoiDungType, SearchNguoiDungType } from "../../Types/nguoiDungType";
import { ColumnsType } from "antd/es/table";
import { useAppSelector } from "../../redux/hooks";
import { createSearchParams, useNavigate } from "react-router-dom";
import useQueryConfig from "../../hooks/useQueryConfig";

type Props = {
  columns: ColumnsType<NguoiDungType>;
  userPhanTrang: NguoiDungType[] | null | undefined;
  filterNguoiDung?: SearchNguoiDungType;
};

const TableQuanLiUser = ({
  columns,
  userPhanTrang,
  filterNguoiDung,
}: Props) => {
  const navigate = useNavigate();
  const queryConfig = useQueryConfig();
  const { totalRowPhanTrang } = useAppSelector((state) => state.userReducer);

  return (
    <Table
      pagination={{
        pageSize: filterNguoiDung?.pageSize,
        total: totalRowPhanTrang || 0,
        // onShowSizeChange: (current, size) => {
        //   navigate({
        //     pathname: "/quan-li-user",
        //     search: createSearchParams({
        //       ...queryConfig,
        //       pageSize: size.toString(),
        //     }).toString(),
        //   });
        // },
        onChange: (page, pageSize) => {
          navigate({
            pathname: "/quan-li-user",
            search: createSearchParams({
              ...queryConfig,
              pageIndex: page.toString(),
              pageSize: pageSize.toString(),
            }).toString(),
          });
        },
      }}
      columns={columns}
      dataSource={userPhanTrang || []}
      size="small"
    />
  );
};

export default TableQuanLiUser;
