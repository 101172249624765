import { Button, Input, Select, Space } from "antd";
import React, { useState } from "react";
import { NguoiDungType, SearchNguoiDungType } from "../../Types/nguoiDungType";
import { createSearchParams, useNavigate } from "react-router-dom";
import omit from "lodash.omit";
import { QueryConfig } from "../../hooks/useQueryConfig";
import FormTaoGoi from "./FormTaoGoi";
const { Search } = Input;
type Props = {
  queryConfig: QueryConfig;
};

const options: Array<{
  label: string;
  value: string;
}> = [
  {
    value: "email",
    label: "Email",
  },
  {
    value: "hoTen",
    label: "Họ Tên",
  },
  {
    value: "soDt",
    label: "Số Đt",
  },
];

const FilterGoi = ({ queryConfig }: Props) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectFilter, setSelectFilter] = useState<string | undefined>("hoTen");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Space direction="horizontal" size="middle">
      <Space.Compact>
          <button
            className="btn btn-success"
            style={{ fontSize: "14px" }}
            onClick={showModal}
          >
            Thêm gói <i className="fas fa-plus"></i>
          </button>
        </Space.Compact>
      
        <FormTaoGoi
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          model={undefined}
        />
      </Space>
    </div>
  );
};

export default FilterGoi;
