import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { http } from '../../util/config'
import { DispatchType } from '../configStore'
import { type } from 'os'
import { danhGiaService } from '../../services/danhGiaService'
import { DanhGiaModel } from '../../Types/danhGia'

type DanhGiaType = {
  dsDanhGiaMentor: []
  dsDanhGiaByNguoiDungId: DanhGiaModel[]
}
const initialState: DanhGiaType = {
  dsDanhGiaMentor: [],
  dsDanhGiaByNguoiDungId: []
}

const danhGiaReducer = createSlice({
  name: 'danhGiaReducer',
  initialState,
  reducers: {
    getDanhGia: (state: DanhGiaType, action: PayloadAction<[]>) => {
      state.dsDanhGiaMentor = [...action.payload]
    },
    getDanhSachDanhGiaByNguoiDung: (state: DanhGiaType, action: PayloadAction<DanhGiaModel[]>) => {
        state.dsDanhGiaByNguoiDungId=[...action.payload]
    }
        
    }
})

export const { getDanhGia,getDanhSachDanhGiaByNguoiDung } = danhGiaReducer.actions
export default danhGiaReducer.reducer

export const GetDanhSachDanhGiaByNguoiDungIdApi = (nguoiDungId: string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await danhGiaService.layDanhSachDanhGiaByNguoiDung(nguoiDungId);
        if(result){
            const action : PayloadAction<DanhGiaModel[]> =  getDanhSachDanhGiaByNguoiDung(result.data.content)
            dispatch(action);
        }
     
    } catch (error) {
      console.log(error)
    }
  }
}
