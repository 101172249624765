import { useEffect, useRef, useState } from 'react'
import { DashBoardCSS } from '../../../assets/style/DashBoardCss'
import './style.css'
import {
  StarOutlined,
  MessageOutlined,
  ExclamationOutlined,
  CaretRightOutlined,
  SearchOutlined
} from '@ant-design/icons'
import {
  Avatar,
  Badge,
  Button,
  Collapse,
  CollapseProps,
  Drawer,
  Empty,
  Input,
  InputRef,
  Rate,
  Select,
  Skeleton,
  Space,
  Table,
  Tag,
  Tooltip
} from 'antd'
import { history } from '../../../index'
import Search from 'antd/es/input/Search'

// import ChamBai from '../../../components/Mentor/Dashboard/ChamBai'
import SegmentedTemp from '../../../components/Mentor/Dashboard/SegmentedTemp'
import TableData from '../../../components/Mentor/Dashboard/TableData'
// import { IssueHocVienModel } from "../../../Types/issueHocVien";
import Highlighter from 'react-highlight-words'

import CustomDrawer from '../../../components/Mentor/Task/customDrawer'
import { config } from '../../../util/config'
import { NguoiDungType } from '../../../Types/nguoiDungType'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  GetDSByMentorIdApi,
  GetDsNopBaiByMentorIdApi,
  GetNopBaisByNguoiDungViewModelApi
} from '../../../redux/NopBaiReducer/nopBaiReducer'
import { GetGroupForTaskByMentorIdApi } from '../../../redux/GroupForTaskReducer/GroupForTaskReducer'
import { getDSChuyenDeMentorPhuTrachApi } from '../../../redux/ChuyenDeReducer/chuyenDeReducer'
import { ChuyenDeMentorPhuTrachModel } from '../../../Types/chuyenDeType'
import { getDSMonhocTheoDSIdApi } from '../../../redux/MonHocReducer/monHocReducer'
import { getDsLuyenTapByListIdApi } from '../../../redux/LuyenTapReducer/LuyenTapReducer'
import { MonHocViewMentorModel } from '../../../Types/monHocType'
import { LuyenTapViewMentorModel } from '../../../Types/luyenTapType'
import { getBaiHocByListIdApi } from '../../../redux/BaiHocReducer/BaiHocReducer'
import { IssueModel } from '../../../Types/issueType'
import { getIssueAllMentorPhuTrachApi, setIssueState } from '../../../redux/IssueReducer/IssueReducer'
import moment from 'moment'
import TableChuyenDe from '../../../components/Mentor/Dashboard/TableChuyenDe'
import { random } from 'lodash'
import UseConnection from '../../../hooks/useHub'
import CustomTableData from '../../../components/Mentor/Dashboard/CustomTableData'
import type { FilterConfirmProps } from 'antd/es/table/interface'
import type { ColumnType, ColumnsType } from 'antd/es/table'
import { NopBaiViewMentor } from '../../../Types/nopBaiType'
import ComponentAddApi from '../../../hoc/ComponentAddApi'
import { TableCSS } from '../../../assets/style/CustomAntdCss'
import { GroupForTaskModel } from '../../../Types/groupForTask'
import TableChamBai from '../../../components/Mentor/Dashboard/TableChamBai'
import { number, string } from 'yup'
import { DanhGiaRankModelMentor } from '../../../Types/danhGia'
import { typeOfIssue } from '../../../components/GroupChat/GroupChat'
import styled from 'styled-components'
import {
  ChangeActiveChat1,
  ChangeActiveChat2,
  changeCurrentActive,
  setOpenDrawerAciton,
  updateDataChat1,
  updateDataChat2
} from '../../../redux/StackChatReducer/StackChatReducer'
import { DataGroupChat, groupchatchuyendehocvienId } from '../../../Types/groupChatType'
import DanhSachChat, { areObjectsEqual, isObjectInArray } from '../../../components/DanhSachChat/DanhSachChat'
import { current } from '@reduxjs/toolkit'
import DiemDanh from './DiemDanh'
import DanhSachThanhVien from './DanhSachThanhVien'
import ChuyenDeChiTiet from './ChuyenDeChiTiet'
import DanhGia from './DanhGia'
import CalenderChamCong from '../ChamCong/CalenderChamCong'
export const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae']
export const randomColor = () => {
  const randomIndex = Math.floor(Math.random() * ColorList.length)
  return ColorList[randomIndex]
}
export interface NopBaiViewMentorWithKey extends NopBaiViewMentor {
  key: number
}
export interface GFTWithKey extends GroupForTaskModel {
  key: number
}
export type dataIndex = keyof NopBaiViewMentor
export type dataIndex2 = keyof GroupForTaskModel

export const converTime = (time: string, count?: number) => {
  if (count) return moment(time).add(count, 'days').format('DD/MM/YYYY')

  return moment(time).format('DD/MM/YYYY')
}
// type DataIndex = keyof DataType;
export const customSorter = (a: NopBaiViewMentor, b: NopBaiViewMentor) => {
  if (a.hanNopBai === '') {
    return -1 // Đặt record rỗng lên đầu danh sách
  }
  if (b.hanNopBai === '') {
    return 1 // Đặt record rỗng xuống cuối danh sách
  }
  // Thực hiện so sánh bình thường cho các record khác
  return moment(converTime(a.hanNopBai), 'DD/MM/YYYY').unix() - moment(converTime(b.hanNopBai), 'DD/MM/YYYY').unix()
}
export const mergeArraysMonHocFromChuyenDe = (chuyende: ChuyenDeMentorPhuTrachModel[]): number[] => {
  const mergedArray: number[] = []
  for (const iterator of chuyende) {
    mergedArray.push(...iterator.danhSachNoiDung.danhSachMonHoc)
  }
  return mergedArray
}
export const mergeArraysBaiHoc = (ds: MonHocViewMentorModel[], ds2: LuyenTapViewMentorModel[]) => {
  const newDs: number[] = []
  for (const iterator of ds) {
    newDs.push(
      ...iterator.danhSachNoiDung
        .filter((e) => {
          if (
            e.maLoaiNhiemVu === 'BAITAP' ||
            e.maLoaiNhiemVu === 'CAPS' ||
            e.maLoaiNhiemVu === 'DUAN' ||
            e.maLoaiNhiemVu === 'HOMEWORK'
          )
            return e
        })
        .map((e) => parseInt(e.noiDungNhiemVu))
    )
  }
  const newDs2: number[] = []
  for (const iterator of ds2) {
    newDs.push(
      ...iterator.danhSachNoiDung
        .filter((e) => {
          if (
            e.maLoaiNhiemVu === 'BAITAP' ||
            e.maLoaiNhiemVu === 'CAPS' ||
            e.maLoaiNhiemVu === 'DUAN' ||
            e.maLoaiNhiemVu === 'HOMEWORK'
          )
            return e
        })
        .map((e) => parseInt(e.noiDungNhiemVu))
    )
  }
  return Array.from(new Set([...newDs, ...newDs2]))
}
export const renderOptionMonth = () => {
  const currentMonth = new Date().getMonth() + 1
  // console.log(currentMonth);
  const monthsBefore: string[] = []
  //  console.log(new Date().getFullYear());

  for (let i = 0; i < 4; i++) {
    const previousMonth = (currentMonth - i + 12) % 12 // Lấy modulo 12 để xử lý trường hợp tháng 0 (tháng 12)
    // const monthName = new Date(0, previousMonth).toLocaleString('default', { month: 'long' });
    monthsBefore.push(`Tháng ${previousMonth !== 0 ? previousMonth : 12}`)
  }
  return monthsBefore.reverse()
}
export const lastName = (hoTen: string) => {
  const arr = hoTen.split(' ')
  return arr[arr.length - 1] ?? 'No name'
}
const DashBoard = () => {
  const dispatch = useAppDispatch()
  const { ActiveChat1, ActiveChat2, ActiveCurrent, dataChat1, dataChat2 } = useAppSelector(
    (state) => state.StackChatReducer
  )
  const pinLC = JSON.parse(config.getStore('pin'))

  const { DSChuyenDeMentorPhuTrach } = useAppSelector((state) => state.chuyenDeReducer)

  const { dsHocVienByMentorId, dsNopBaiByMentorId, dsNopBaiByMentorIdViewMentor, dsHocVienByMentorIdSet } =
    useAppSelector((state) => state.nopBaiReducer)



  const { issuesMentorPhuTrach } = useAppSelector((state) => state.IssueReducer)
  const { dsDanhGiaByNguoiDungId } = useAppSelector((state) => state.danhGiaReducer)
  const { groupForTaskViewMentorModel } = useAppSelector((state) => state.groupForTaskReducer)
  const { dsLuyenTapByListId } = useAppSelector((state) => state.LuyenTapReducer)
  const { dsMonhocTheoDSId } = useAppSelector((state) => state.monHocReducer)
  const [month, setMonth] = useState<string | number>(renderOptionMonth()[renderOptionMonth().length - 1])
  const [pin, setPin] = useState<IssueModel>(
    pinLC
      ? pinLC
      : {
        id: 0,
        chuyenDeId: 0,
        hocVienId: '',
        ngayTao: '',
        noiDung: '',
        title: '',
        trangThai: '',
        path: ''
      }
  )

  const [filter, setFilter] = useState<string | number>('ngayTao')
  const [filterData, setFilterData] = useState<IssueModel[]>([])

  const [search, setSearch] = useState('')
  const [type, SetType] = useState<number>(0)
  const [typeCurrent, SetTypetypeCurrent] = useState<number>(0)
  // const [openDrawer, setOpenDrawer] = useState(false)
  const { openDrawer } = useAppSelector((s) => s.StackChatReducer)
  const setOpenDrawer = (b: boolean) => {
    dispatch(setOpenDrawerAciton(b))
  }
  const setCloseDrawer = () => {
    dispatch(setOpenDrawerAciton(false))
  }
  const [openDanhGia,setOpenDanhGia] = useState<boolean>(false)
  const hanldeOpenDanhGia=() => { 
    setOpenDanhGia(true)
  }
  const handleCloseDanhGia=() => { 
    setOpenDanhGia(false)
  }

  // const [openModal, setOpenModal] = useState(false)

  const user = config.getStoreJson('USER_LOGIN') as NguoiDungType
  // const { connection, setConnection } = UseConnection()
  // console.log(connection)

  //!Table
  const [searchText, setSearchText] = useState('')
  const [searchText2, setSearchText2] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [searchedColumn2, setSearchedColumn2] = useState('')
  const searchInput = useRef<InputRef>(null)

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: dataIndex
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleSearch2 = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: dataIndex2
  ) => {
    confirm()
    setSearchText2(selectedKeys[0])
    setSearchedColumn2(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex: dataIndex): ColumnType<NopBaiViewMentor> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
          {/* <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button> */}
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  })
  const getColumnSearchProps2 = (dataIndex: dataIndex2): ColumnType<GroupForTaskModel> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch2(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch2(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
          {/* <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button> */}
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  })
  const PushDataTabChat = async (e: DataGroupChat) => {
    // if(myUser){
    //   if(e?.dsMentor)
    //   await config.setStoreJson("ApiChat2",{chuyenDeId:e?.id,hocVienId:myUser?.id})
    //   else if(e?.danhSachHocVien)
    //   await config.setStoreJson("ApiChat1",{gftId:e?.id})
    // }



    if (areObjectsEqual(e, dataChat1)) {
      dispatch(ChangeActiveChat1())
    } else if (areObjectsEqual(e, dataChat2)) {
      dispatch(ChangeActiveChat2())
    } else {
      if (ActiveCurrent) {
        dispatch(updateDataChat2(e))
        !ActiveChat2 && dispatch(ChangeActiveChat2())
        dispatch(changeCurrentActive())
      } else {
        dispatch(updateDataChat1(e))
        !ActiveChat1 && dispatch(ChangeActiveChat1())
        dispatch(changeCurrentActive())
      }
    }
    // if (areObjectsEqual(e, dataChat1)) {
    //   !ActiveChat1 && dispatch(ChangeActiveChat1());
    // } else if (areObjectsEqual(e, dataChat2)) {
    //   !ActiveChat2 && dispatch(ChangeActiveChat2());
    // } else {
    //   if (ActiveCurrent) {
    //     dispatch(updateDataChat2(e));
    //     dispatch(ChangeActiveChat2());
    //     dispatch(changeCurrentActive());
    //   } else {
    //     dispatch(updateDataChat1(e));
    //     dispatch(ChangeActiveChat1());
    //     dispatch(changeCurrentActive());
    //   }
    // }
  }
  const issueChat = (chuyenDeId: number, hocVienId: string) => {
    const inde = issuesMentorPhuTrach.findIndex((e) => e.chuyenDeId === chuyenDeId && e.hocVienId === hocVienId)
    if (inde >= 0) return issuesMentorPhuTrach[inde]
    return null
  }


  const onSearchBt = (value: string) => {
    setSearch(value)
    setFilter('tenHocVien')
  }
  const handleChangeSelect = (value: string) => {
    SetType(Number(value))
  }

  const showDrawer = () => {
    setOpenDrawer(true)
  }

  const onChangeCollaps = (key: string | string[]) => {
    // console.log(key)
  }

  const converTime = (time: string, count?: number) => {
    if (count) return moment(time).add(count, 'days').format('DD/MM/YYYY')

    return moment(time).format('DD/MM/YYYY')
  }

  const convertTenChuyenDe = (id: number) => {
    if (DSChuyenDeMentorPhuTrach.length) {
      const index = DSChuyenDeMentorPhuTrach.findIndex((e) => e.id === id)
      if (index !== -1) return DSChuyenDeMentorPhuTrach[index].tenChuyenDe
    }
    return ''
  }
  const convertTenHocVien = (id: string) => {
    if (dsHocVienByMentorId.length) {
      const index = dsHocVienByMentorId.findIndex((e) => e.id === id)
      if (index !== -1) return dsHocVienByMentorId[index].hoTen
    }
    return ''
  }
  const convertArrTenHocVien = (arr:string)=>{
    let dshv: string[] = []
        try {
          dshv = [...JSON.parse(arr)]
        } catch (error) {
          console.log(error)
        }
        return dshv.length > 0 ? dshv.map((e) => convertTenHocVien(e)).join(' - ') : ''
  }

  const shalowCompare = (hocVien1: IssueModel, hocVien2: IssueModel) => {
    const values1 = Object.values(hocVien1)
    const values2 = Object.values(hocVien2)

    return values1.every((value, index) => value === values2[index])
  }
  const resultTrangThai = (trangThai: string) => {
    let color = ''
    let content = ''
    switch (trangThai) {
      case 'CAN_HO_TRO':
        color = 'orange'
        content = 'Cần Hỗ Trợ'
        break
      case 'DA_HO_TRO':
        color = 'blue'
        content = 'Đã Hỗ Trợ'
        break
      case 'HO_TRO_LAI':
        color = 'magenta'
        content = 'Hỗ Trợ Lại'
        break
      case 'DANG_HO_TRO':
        color = '#108ee9'
        content = 'Đang Hỗ Trợ'
        break
      case 'CHO_PHAN_HOI':
        color = '#f50'
        content = 'Chờ Phản Hồi'
        break

      default:
        color = 'lime'
    }
    return { color, content }
  }
  const showData = (data: IssueModel[]) => {
    let count = 0
    return data.map((e, i) => {
      count++
      return (
        <div className={`content__item ${shalowCompare(e, pin) ? 'pinted' : ''}`} key={'content__item' + i}>
          <div
            className='col-10'
            onClick={async () => {
              await dispatch(setIssueState(e))

              await config.setStoreJson('ApiChat', {
                chuyenDeId: e.chuyenDeId.toString(),
                hocVienId: e.hocVienId,
                paramIssueId: e.id.toString()
              })
              // const room = JSON.stringify([e.hocVienId, user.id])
              const query = {
                paramIssueId: e.id.toString(),
                paramChuyenDeId: e.chuyenDeId.toString(),
                paramHocVienId: e.hocVienId
              }
              history.push({
                pathname: '/chat',
                search: new URLSearchParams(query).toString()
              })
            }}
          >
            <div className='content__item-row'>
              <span>
                {' '}
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[search]}
                  autoEscape
                  textToHighlight={count + ' . ' + convertTenHocVien(e.hocVienId)}
                />
              </span>
              <h6>{convertTenChuyenDe(e.chuyenDeId)}</h6>

              <Tag color={resultTrangThai(e.trangThai).color}>{resultTrangThai(e.trangThai).content}</Tag>
            </div>

            <div className='content__item-row'>
              <span className='text-truncate'>{e.title}</span>
              <div className='date'>{converTime(e.ngayTao)}</div>
            </div>
          </div>
          <div className='col-2 text-center pin'>
            <i
              className='fa-regular fa-bookmark'
              onClick={() => {
                setPin((prev) => {
                  if (shalowCompare(prev, e)) {
                    localStorage.removeItem('pin')
                    return {
                      id: 0,
                      chuyenDeId: 0,
                      hocVienId: '',
                      ngayTao: '',
                      noiDung: '',
                      title: '',
                      trangThai: '',
                      path: ''
                    }
                  }

                  localStorage.setItem('pin', JSON.stringify(e))
                  return e
                })
              }}
            ></i>
          </div>
        </div>
      )
    })
  }

  const moveItemToTop = (data: IssueModel[], itemToKeepOnTop: IssueModel) => {
    const index = data.findIndex((item) => shalowCompare(item, itemToKeepOnTop))
    if (index > -1) {
      data.splice(index, 1)
      data.unshift(itemToKeepOnTop)
    }
    return data
  }

  const sortByFieldAscending = (field: keyof IssueModel, data: IssueModel[]) => {
    const array = [...data]
    array.sort((a, b) => {
      if (a[field] < b[field]) return -1
      if (a[field] > b[field]) return 1

      return 0
    })
    return array
  }


  //! const mergeArraysLuyenTapFromChuyenDe = (
  //   chuyende: ChuyenDeMentorPhuTrachModel[]
  // ): number[] => {
  //   const mergedArray: number[] = [];
  //   for (const iterator of chuyende) {
  //     mergedArray.push(...iterator.danhSachNoiDung.danhSachPractice);
  //   }
  //   return mergedArray;
  // };

  const countTotal = (chuyenDeId: number, taskId: number, monHocId: number, maLoai: string, loaiModule: string) => {
    return dsNopBaiByMentorIdViewMentor.filter(
      (e) =>
        e.chuyenDeId === chuyenDeId &&
        // e.taskId === taskId &&
        e.monHocId === monHocId &&
        // e.maLoai === maLoai &&
        e.loaiModule === loaiModule
      // e.trangThai ==='CHO_CHAM'
    ).length
  }
  const countChuaCham = (chuyenDeId: number, taskId: number, monHocId: number, maLoai: string, loaiModule: string) => {
    return dsNopBaiByMentorIdViewMentor.filter(
      (e) =>
        e.chuyenDeId === chuyenDeId &&
        // e.taskId === taskId &&
        e.monHocId === monHocId &&
        // e.maLoai === maLoai &&
        e.loaiModule === loaiModule &&
        e.trangThai === 'CHO_CHAM'
    ).length
  }

  const newColumns: ColumnsType<NopBaiViewMentor> = [
    // STT
    // Tên bài
    // Môn học
    // Tổng bài
    // Chưa chấm
    // Hạn nộp
    // Loại bài
    // Module
    {
      title: 'STT',
      key: 'STT',
      render: (_, __, index) => {
        return index + 1
      }
    },
    // {
    //   title: 'Tên bài',
    //   dataIndex: 'tenNhiemVu',
    //   key: 'tenNhiemVu',
    //   width: '20%',
    //   ...getColumnSearchProps('tenNhiemVu'),
    //   sorter: (a, b) => a.taskId - b.taskId
    // },
    {
      title: 'Môn học',
      dataIndex: 'tenMonHoc',
      key: 'tenMonHoc',
      ...getColumnSearchProps('tenMonHoc'),
      sorter: (a, b) => a.monHocId - b.monHocId
      // sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Tổng bài nộp',
      key: 'tongBai',
      sorter: (a, b) =>
        countTotal(a.chuyenDeId, a.taskId, a.monHocId, a.maLoai, a.loaiModule) -
        countTotal(b.chuyenDeId, b.taskId, b.monHocId, b.maLoai, b.loaiModule),
      render: (value, record, index) => {
        const { chuyenDeId, maLoai, monHocId, taskId, loaiModule } = record
        return countTotal(chuyenDeId, taskId, monHocId, maLoai, loaiModule)
      }
    },
    {
      title: 'Chưa chấm',
      key: 'chuaCham',
      sorter: (a, b) =>
        countChuaCham(a.chuyenDeId, a.taskId, a.monHocId, a.maLoai, a.loaiModule) -
        countChuaCham(b.chuyenDeId, b.taskId, b.monHocId, b.maLoai, b.loaiModule),
      render: (value, record) => {
        const { chuyenDeId, maLoai, monHocId, taskId, loaiModule } = record
        return <span className={`${countChuaCham(chuyenDeId, taskId, monHocId, maLoai, loaiModule)>0?'dotChuaCham':''}`}>{countChuaCham(chuyenDeId, taskId, monHocId, maLoai, loaiModule)}</span>
      },
      defaultSortOrder: 'descend'
    },
    // {
    //   title: 'Hạn nộp ',
    //   dataIndex: 'hanNopBai',
    //   key: 'hanNopBai',
    //   // ...getColumnSearchProps('hanNopBai'),
    //   sorter: customSorter,
    //   // sortDirections: ['descend', 'ascend'],
    //   render: (value) => converTime(value) ?? value
    // },
    // {
    //   title: 'Loại bài',
    //   dataIndex: 'maLoai',
    //   key: 'maLoai',
    //   // ...getColumnSearchProps('hanNopBai'),
    //   render: (_, r) => _,
    //   sorter: (a, b) => a.hanNopBai.length - b.hanNopBai.length,
    //   sortDirections: ['descend', 'ascend'],
    //   onFilter: (value: string | number | boolean, record) => {
    //     const stringValue = String(value)
    //     return String(record.maLoai).indexOf(stringValue) === 0
    //   },
    //   filters: [
    //     {
    //       text: 'Bài Tập',
    //       value: 'BAITAP'
    //     },
    //     {
    //       text: 'Dự Án',
    //       value: 'DUAN'
    //     },
    //     {
    //       text: 'Capstone',
    //       value: 'CAPS'
    //     }
    //   ]
    // },
    // {
    //   title: 'Module',
    //   dataIndex: 'loaiModule',
    //   key: 'loaiModule',
    //   ...getColumnSearchProps('hanNopBai'),
    //   // render:(_,r)=>_,
    //   sorter: (a, b) => a.loaiModule.length - b.loaiModule.length,
    //   sortDirections: ['descend', 'ascend']
    // }
  ]
  const newColumns2: ColumnsType<GroupForTaskModel> = [
    // STT
    // Tên bài
    // Môn học
    // Tổng bài
    // Chưa chấm
    // Hạn nộp
    // Loại bài
    // Module
    {
      title: 'STT',
      key: 'STT',
      render: (_, __, index) => {
        return index + 1
      }
    },
    {
      title: 'Danh sách học viên',
      key: 'danhSachHocVien',
      dataIndex: 'danhSachHocVien',
      render: (_, record) => {
        let dshv: string[] = []
        try {
          dshv = [...JSON.parse(_)]
        } catch (error) {
          console.log(error)
        }
        return dshv.length > 0 ? dshv.map((e) => convertTenHocVien(e)).join(' - ') : _
      }
    },
    {
      title: 'Môn học',
      dataIndex: 'monHocId',
      key: 'monHocId',
      width: '20%',
      ...getColumnSearchProps2('monHocId'),
      sorter: (a, b) => a.taskId - b.taskId
    },
    {
      title: 'Bài Học',
      dataIndex: 'taskId',
      key: 'taskId',
      ...getColumnSearchProps2('taskId'),
      sorter: (a, b) => a.monHocId - b.monHocId
      // sortDirections: ['descend', 'ascend']
    },

    {
      title: 'Ngày tạo',
      dataIndex: 'ngayTao',
      key: 'ngayTao',
      // ...getColumnSearchProps('hanNopBai'),
      // sorter: customSorter,
      // sortDirections: ['descend', 'ascend'],
      render: (value) => converTime(value) ?? value
    }
    // {
    //   title: 'Loại bài',
    //   dataIndex: 'maLoai',
    //   key: 'maLoai',
    //   // ...getColumnSearchProps('hanNopBai'),
    //   render: (_, r) => _,
    //   sorter: (a, b) => a.hanNopBai.length - b.hanNopBai.length,
    //   sortDirections: ['descend', 'ascend'],
    //   onFilter: (value: string | number | boolean, record) => {
    //     const stringValue = String(value)
    //     return String(record.maLoai).indexOf(stringValue) === 0
    //   },
    //   filters: [
    //     {
    //       text: 'Bài Tập',
    //       value: 'BAITAP'
    //     },
    //     {
    //       text: 'Dự Án',
    //       value: 'DUAN'
    //     },
    //     {
    //       text: 'Capstone',
    //       value: 'CAPS'
    //     }
    //   ]
    // },
    // {
    //   title: 'Module',
    //   dataIndex: 'loaiModule',
    //   key: 'loaiModule',
    //   ...getColumnSearchProps('hanNopBai'),
    //   // render:(_,r)=>_,
    //   sorter: (a, b) => a.loaiModule.length - b.loaiModule.length,
    //   sortDirections: ['descend', 'ascend']
    // }
  ]

  const dataFilter2 = (data: GroupForTaskModel[]) => {


    const newData = data.filter((e, i, self) => {
      return (
        i ===
        self.findIndex(
          (item) => item.id === e.id

          // &&item.hanNopBai === e.hanNopBai
        )
      )
    })
    return newData.map((item, index) => {
      return { ...item, key: index + 1 }
    })
  }

  const itemCollaps = () => {

    return DSChuyenDeMentorPhuTrach?.map((e, i) => {

      const newDS = [...dsNopBaiByMentorIdViewMentor.filter((cd) => cd.chuyenDeId === e.id)]
      const newDSChuaChamOrNopLai = [...newDS.filter((nb) => nb.trangThai === 'CHO_CHAM')]

      return {
        key: (i + 1).toString(),
        label: (
          <div className='d-flex justify-content-between align-items-center'>
            <span>{e.tenChuyenDe}</span>
            <span>
              {newDSChuaChamOrNopLai && newDSChuaChamOrNopLai.length > 0 ? (
                <Tooltip placement='topLeft' title={`Có ${newDSChuaChamOrNopLai.length} bài tập cần chấm !`}>
                  <span className='spanBaitapCham'>{newDSChuaChamOrNopLai.length}</span>
                </Tooltip>
              ) : (
                ''
              )}
            </span>
          </div>
        ),
        children:
          <div className='row'>

            <DiemDanh chuyenDeId={e.id} metaData={e.metaData} />
            <DanhSachThanhVien chuyenDeId={e.id} metaData={e.metaData} />

            <ChuyenDeChiTiet chuyenDeId={e.id} metaData={e.metaData} listNopBai={newDS} />

            <TableChamBai
              IsBaiTap={true}
              key={e.tenChuyenDe + ' ' + (i + 1).toString()}
              columns={newColumns}
              dataSource={newDS}
            // expandable={expandable}
            />

          </div>
        // children: <div className='children_bg'><TableData showModal={showModal}/></div>
      }
    })
  }


  const itemCollaps2 = () => {
    return DSChuyenDeMentorPhuTrach?.map((e, i) => {
      return {
        key: (i + 1).toString(),
        label: e.tenChuyenDe,
        children: (
          <CustomTableData
            columns={newColumns2}
            gft={true}
            dataSource={dataFilter2(groupForTaskViewMentorModel.filter((gft) => gft.chuyenDeId === e.id))}
            key={'gft' + i}
          />
        )
      }
    })
  }
  const lamTronSo = (value: number) => {
    const phanNguyen = Math.floor(value)
    const phanLe = value - phanNguyen

    if (phanLe >= 0.25 && phanLe < 0.5) {
      return phanNguyen + 0.25
    } else if (phanLe >= 0.5 && phanLe < 0.75) {
      return phanNguyen + 0.5
    } else if (phanLe >= 0.75) {
      return phanNguyen + 1
    } else {
      return phanNguyen
    }
  }
  const tinhTrungBinhCongCuaMangNumber = (arr: number[]) => {
    if (arr.length > 0) return lamTronSo(arr.reduce((sum, num) => sum + num, 0) / arr.length)
    return 0
  }

  const renderMonthRank = (thang: string) => {
    const currentYear = new Date().getFullYear()
    const arr = thang.split(' ')
    const monthChosse = +arr[arr.length - 1]
    const arrDanhGiaByMonth = dsDanhGiaByNguoiDungId.filter((e) => {
      if (e) {
        const date = new Date(e.ngayTao)
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        if (month === monthChosse)
          if ((monthChosse >= 5 && year === currentYear) || (monthChosse < 5 && year >= currentYear - 1)) return e
      }
    })
    const rank = arrDanhGiaByMonth.map((e) => e.danhGiaRank)
    const rankParse = rank
      .map((e) => {
        try {
          const a = JSON.parse(e) as DanhGiaRankModelMentor
          return {
            chamBaiDungHan: a.chamBaiDungHan ?? 0,
            chiaSeKienThuc: a.chiaSeKienThuc ?? 0,
            hoTroHocVien: a.hoTroHocVien ?? 0,
            kienThucCao: a.kienThucCao ?? 0,
            nangDong: a.nangDong ?? 0,
            thanThien: a.thanThien ?? 0
          } as DanhGiaRankModelMentor
        } catch (error) {
          return null
        }
      })
      .filter((e): e is DanhGiaRankModelMentor => e !== null)

    if (rankParse && rankParse.length > 0) {
      const arrChiaSeKienThuc = rankParse.map((e) => e.chiaSeKienThuc)
      const arrHoTroHocVien = rankParse.map((e) => e.hoTroHocVien)
      const arrChamBaiDungHan = rankParse.map((e) => e.chamBaiDungHan)
      const arrkienThucCao = rankParse.map((e) => e.kienThucCao)
      const arrnangDong = rankParse.map((e) => e.nangDong)
      const arrthanThien = rankParse.map((e) => e.thanThien)
      if (rankParse.length > 0)
        return (
          <div key={'rank ' + thang} className='month-rank'>
            <div className='month-rank_item'>
              <span> Chia Sẽ kiến thức </span>

              <Rate
                disabled
                allowHalf
                defaultValue={tinhTrungBinhCongCuaMangNumber(arrChiaSeKienThuc)}
                style={{ gap: '5px' }}
              />
            </div>

            <div className='month-rank_item'>
              <span> Hỗ Trợ Học Viên </span>

              <Rate
                disabled
                allowHalf
                defaultValue={tinhTrungBinhCongCuaMangNumber(arrHoTroHocVien)}
                style={{ gap: '5px' }}
              />
            </div>
            <div className='month-rank_item'>
              <span> Kiến Thức Cao </span>

              <Rate
                disabled
                allowHalf
                defaultValue={tinhTrungBinhCongCuaMangNumber(arrkienThucCao)}
                style={{ gap: '5px' }}
              />
            </div>
            <div className='month-rank_item'>
              <span> Chấm Bài Đúng Hạn</span>

              <Rate
                disabled
                allowHalf
                defaultValue={tinhTrungBinhCongCuaMangNumber(arrChamBaiDungHan)}
                style={{ gap: '5px' }}
              />
            </div>
            <div className='month-rank_item'>
              <span> Thân Thiện </span>

              <Rate
                disabled
                allowHalf
                defaultValue={tinhTrungBinhCongCuaMangNumber(arrthanThien)}
                style={{ gap: '5px' }}
              />
            </div>
            <div className='month-rank_item'>
              <span> Năng Động</span>

              <Rate
                disabled
                allowHalf
                defaultValue={tinhTrungBinhCongCuaMangNumber(arrnangDong)}
                style={{ gap: '5px' }}
              />
            </div>
          </div>
        )
    } else
      return (
        <div className='month-rank'>
          <div className='month-rank_item'>
            <span>Tháng này bạn chưa được đánh giá !</span>
          </div>
        </div>
      )
  }

  // const callAPI = async () => {
  //   if (user) {
  //     dispatch(GetDsNopBaiByMentorIdApi(user.id))
  //     //?lấy thông tin mentor
  //     dispatch(GetDSByMentorIdApi(user.id))
  //     //?lấy thông tin nhóm task theo mentor
  //     dispatch(GetGroupForTaskByMentorIdApi(user.id))
  //     //?Lấy isuue mentor phụ trach
  //     dispatch(getIssueAllMentorPhuTrachApi(user.id))
  //     //?chuyendementorphutrach
  //     dispatch(getDSChuyenDeMentorPhuTrachApi(user.id))

  //     dispatch(GetNopBaisByNguoiDungViewModelApi(user.id))
  //   }
  // }

  useEffect(() => {
    if (
      DSChuyenDeMentorPhuTrach &&
      DSChuyenDeMentorPhuTrach.length > 0 &&
      typeCurrent !== DSChuyenDeMentorPhuTrach[0].id
    ) {
      SetType(DSChuyenDeMentorPhuTrach[0].id)
      SetTypetypeCurrent(DSChuyenDeMentorPhuTrach[0].id)
    }
  }, [DSChuyenDeMentorPhuTrach])
  useEffect(() => {
    let fill: keyof IssueModel
    switch (filter) {
      case 'chuyenDeId':
        fill = 'chuyenDeId'
        break
      case 'hocVienId':
        fill = 'hocVienId'
        break
      case 'id':
        fill = 'id'
        break
      case 'ngayTao':
        fill = 'ngayTao'
        break
      case 'noiDung':
        fill = 'noiDung'
        break
      case 'title':
        fill = 'title'
        break
      case 'trangThai':
        fill = 'trangThai'
        break

      default:
        fill = 'ngayTao'
    }
    if (search) fill = 'hocVienId'

    setFilterData(moveItemToTop(sortByFieldAscending(fill, issuesMentorPhuTrach), pin))
  }, [filter, pin, issuesMentorPhuTrach])

  //Call api liên quan
  // useEffect(() => {
  //   if (user.id) {
  //     // dispatch(GetNopBaisByNguoiDungViewModelApi(user.id));
  //     callAPI()
  //   }
  //   const fetch = setInterval(callAPI, 30000)

  //   return () => {
  //     clearInterval(fetch)
  //   }
  // }, [user.id])
  //!call api
  const { callAPI } = ComponentAddApi()

  return (
    <DashBoardCSS>
      <div className='dshv'>
        <a onClick={showDrawer}>
          Danh Sách Học Viên Phụ Trách <i className='fa-solid fa-arrow-right'></i>
        </a>
      </div>

      <div className='head'>
        <div className='title'>
          {/* <StarOutlined /> */}
          <svg width={35} height={35} viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='17.5' cy='17.5' r='17.5' fill='#664DC9' fillOpacity='0.2' />
            <path
              d='M17.5 12.25L18.9938 15.143L22.1875 15.607L19.8438 17.7188L20.625 20.8438L17.5 19.0859L14.375 20.8438L15.1562 17.7188L12.8125 15.607L16.0937 15.143L17.5 12.25Z'
              fill='#664DC9'
            />
            <path
              d='M18.8562 29.4375L17.5 28.6562L20.625 23.1875H25.3125C25.5178 23.1878 25.7211 23.1476 25.9108 23.0692C26.1005 22.9908 26.2729 22.8757 26.418 22.7305C26.5632 22.5854 26.6783 22.413 26.7567 22.2233C26.8351 22.0336 26.8753 21.8303 26.875 21.625V12.25C26.8753 12.0447 26.8351 11.8414 26.7567 11.6517C26.6783 11.462 26.5632 11.2896 26.418 11.1445C26.2729 10.9993 26.1005 10.8842 25.9108 10.8058C25.7211 10.7274 25.5178 10.6872 25.3125 10.6875H9.6875C9.48222 10.6872 9.2789 10.7274 9.08919 10.8058C8.89948 10.8842 8.72711 10.9993 8.58196 11.1445C8.4368 11.2896 8.32172 11.462 8.24331 11.6517C8.1649 11.8414 8.12469 12.0447 8.125 12.25V21.625C8.12469 21.8303 8.1649 22.0336 8.24331 22.2233C8.32172 22.413 8.4368 22.5854 8.58196 22.7305C8.72711 22.8757 8.89948 22.9908 9.08919 23.0692C9.2789 23.1476 9.48222 23.1878 9.6875 23.1875H16.7188V24.75H9.6875C8.8587 24.75 8.06384 24.4208 7.47779 23.8347C6.89174 23.2487 6.5625 22.4538 6.5625 21.625V12.25C6.5624 11.8396 6.64316 11.4332 6.80017 11.054C6.95718 10.6748 7.18736 10.3303 7.47756 10.0401C7.76777 9.74986 8.1123 9.51968 8.49149 9.36267C8.87068 9.20566 9.27709 9.1249 9.6875 9.125H25.3125C25.7229 9.1249 26.1293 9.20566 26.5085 9.36267C26.8877 9.51968 27.2322 9.74986 27.5224 10.0401C27.8126 10.3303 28.0428 10.6748 28.1998 11.054C28.3568 11.4332 28.4376 11.8396 28.4375 12.25V21.625C28.4375 22.4538 28.1083 23.2487 27.5222 23.8347C26.9362 24.4208 26.1413 24.75 25.3125 24.75H21.5352L18.8562 29.4375Z'
              fill='#664DC9'
            />
          </svg>

          <h4>Đánh Giá </h4>
        </div>
        <div className='head_content'>
          <div className='danhgia '>
            <div className='month'>
              <SegmentedTemp option={renderOptionMonth()} value={month} setValue={setMonth} key={'month'} />
            </div>
            {dsDanhGiaByNguoiDungId.length > 0 && month ? (
              renderMonthRank(month.toString())
            ) : (
              <div className='month-rank'>
                <div className='month-rank_item'>
                  <span>Hiện chưa có đánh giá nào cho bạn !</span>
                </div>
              </div>
            )}
          </div>
          <div className='thongtin '>
            <div className='profile'>
              <div className='avatar'>
                {/* <Avatar size={96} icon={<UserOutlined />} /> */}
                <Avatar
                  size={96}
                  src={
                    user.avatar ? (
                      user.avatar
                    ) : (
                      <img src={'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg'} alt='avatar' />
                    )
                  }
                />
              </div>
              <div className='infor'>
                <div className='tongdanhgia' onClick={hanldeOpenDanhGia}>Tổng Đánh Giá</div>
                <div className='nhanxet'>very good</div>
              </div>
            </div>
            <div className='thongtinchitiet'>
              <span>{user?.hoTen}</span>
              <span>{user?.email}</span>
              <span>{user?.soDt}</span>
              <span>Kinh Nghiệm : {user?.coin}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='mid'>
        <div className='title'>
          <svg width={35} height={35} viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='17.5' cy='17.5' r='17.5' fill='#664DC9' fillOpacity='0.2' />
            <path
              d='M17.5 12.25L18.9938 15.143L22.1875 15.607L19.8438 17.7188L20.625 20.8438L17.5 19.0859L14.375 20.8438L15.1562 17.7188L12.8125 15.607L16.0937 15.143L17.5 12.25Z'
              fill='#664DC9'
            />
            <path
              d='M18.8562 29.4375L17.5 28.6562L20.625 23.1875H25.3125C25.5178 23.1878 25.7211 23.1476 25.9108 23.0692C26.1005 22.9908 26.2729 22.8757 26.418 22.7305C26.5632 22.5854 26.6783 22.413 26.7567 22.2233C26.8351 22.0336 26.8753 21.8303 26.875 21.625V12.25C26.8753 12.0447 26.8351 11.8414 26.7567 11.6517C26.6783 11.462 26.5632 11.2896 26.418 11.1445C26.2729 10.9993 26.1005 10.8842 25.9108 10.8058C25.7211 10.7274 25.5178 10.6872 25.3125 10.6875H9.6875C9.48222 10.6872 9.2789 10.7274 9.08919 10.8058C8.89948 10.8842 8.72711 10.9993 8.58196 11.1445C8.4368 11.2896 8.32172 11.462 8.24331 11.6517C8.1649 11.8414 8.12469 12.0447 8.125 12.25V21.625C8.12469 21.8303 8.1649 22.0336 8.24331 22.2233C8.32172 22.413 8.4368 22.5854 8.58196 22.7305C8.72711 22.8757 8.89948 22.9908 9.08919 23.0692C9.2789 23.1476 9.48222 23.1878 9.6875 23.1875H16.7188V24.75H9.6875C8.8587 24.75 8.06384 24.4208 7.47779 23.8347C6.89174 23.2487 6.5625 22.4538 6.5625 21.625V12.25C6.5624 11.8396 6.64316 11.4332 6.80017 11.054C6.95718 10.6748 7.18736 10.3303 7.47756 10.0401C7.76777 9.74986 8.1123 9.51968 8.49149 9.36267C8.87068 9.20566 9.27709 9.1249 9.6875 9.125H25.3125C25.7229 9.1249 26.1293 9.20566 26.5085 9.36267C26.8877 9.51968 27.2322 9.74986 27.5224 10.0401C27.8126 10.3303 28.0428 10.6748 28.1998 11.054C28.3568 11.4332 28.4376 11.8396 28.4375 12.25V21.625C28.4375 22.4538 28.1083 23.2487 27.5222 23.8347C26.9362 24.4208 26.1413 24.75 25.3125 24.75H21.5352L18.8562 29.4375Z'
              fill='#664DC9'
            />
          </svg>
          <h4>Task Và Các Bài Tập Cần Chấm</h4>
        </div>
        <div className='baitap'>
          <div className='d-flex justify-content-between w-100'></div>

          <div className='noiDung'>
            {/* // TODOTODOTODOTODOTODOTODOTODOTODO */}
            {/* {dsNopBaiByMentorId.length > 0 ? (
              <TableData showModal={showModal} />
            ) : (
              <Skeleton active style={{ background: '#797979' }} />
            )} */}
            <Collapse
              className='collapChuyenDe'
              items={itemCollaps()}

              onChange={onChangeCollaps}
            />
            ;
          </div>
        </div>
      </div>
      <div className='mid'>
        <div className='title'>
          <svg width={35} height={35} viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='17.5' cy='17.5' r='17.5' fill='#664DC9' fillOpacity='0.2' />
            <path
              d='M17.5 12.25L18.9938 15.143L22.1875 15.607L19.8438 17.7188L20.625 20.8438L17.5 19.0859L14.375 20.8438L15.1562 17.7188L12.8125 15.607L16.0937 15.143L17.5 12.25Z'
              fill='#664DC9'
            />
            <path
              d='M18.8562 29.4375L17.5 28.6562L20.625 23.1875H25.3125C25.5178 23.1878 25.7211 23.1476 25.9108 23.0692C26.1005 22.9908 26.2729 22.8757 26.418 22.7305C26.5632 22.5854 26.6783 22.413 26.7567 22.2233C26.8351 22.0336 26.8753 21.8303 26.875 21.625V12.25C26.8753 12.0447 26.8351 11.8414 26.7567 11.6517C26.6783 11.462 26.5632 11.2896 26.418 11.1445C26.2729 10.9993 26.1005 10.8842 25.9108 10.8058C25.7211 10.7274 25.5178 10.6872 25.3125 10.6875H9.6875C9.48222 10.6872 9.2789 10.7274 9.08919 10.8058C8.89948 10.8842 8.72711 10.9993 8.58196 11.1445C8.4368 11.2896 8.32172 11.462 8.24331 11.6517C8.1649 11.8414 8.12469 12.0447 8.125 12.25V21.625C8.12469 21.8303 8.1649 22.0336 8.24331 22.2233C8.32172 22.413 8.4368 22.5854 8.58196 22.7305C8.72711 22.8757 8.89948 22.9908 9.08919 23.0692C9.2789 23.1476 9.48222 23.1878 9.6875 23.1875H16.7188V24.75H9.6875C8.8587 24.75 8.06384 24.4208 7.47779 23.8347C6.89174 23.2487 6.5625 22.4538 6.5625 21.625V12.25C6.5624 11.8396 6.64316 11.4332 6.80017 11.054C6.95718 10.6748 7.18736 10.3303 7.47756 10.0401C7.76777 9.74986 8.1123 9.51968 8.49149 9.36267C8.87068 9.20566 9.27709 9.1249 9.6875 9.125H25.3125C25.7229 9.1249 26.1293 9.20566 26.5085 9.36267C26.8877 9.51968 27.2322 9.74986 27.5224 10.0401C27.8126 10.3303 28.0428 10.6748 28.1998 11.054C28.3568 11.4332 28.4376 11.8396 28.4375 12.25V21.625C28.4375 22.4538 28.1083 23.2487 27.5222 23.8347C26.9362 24.4208 26.1413 24.75 25.3125 24.75H21.5352L18.8562 29.4375Z'
              fill='#664DC9'
            />
          </svg>
          <h4>Chấm công</h4>
        </div>
        <div className='baitap'>
          <div className='d-flex justify-content-between w-100'>

          </div>

          <div className='noiDung'>
            <div className="row">
              <div className="col-12">
                <CalenderChamCong/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
                open={openDanhGia}
                onClose={handleCloseDanhGia}
                key={'danh sach hs'}
                headerStyle={{
                  background: `#141824`,
                  color: "white",
                }}
                style={{ color: "#ffffff" }}
                bodyStyle={{
                  background: `#141824`,
                  color: "white",
                  padding:0
                }}
                placement={"right"}
                width={'auto'}
                title={<h3 style={{ color: '#ffffff' }}> Đánh giá Học Viên</h3>}
                children={<DanhGia data={dsDanhGiaByNguoiDungId}/>}
              />
      
    </DashBoardCSS>
  )
}
export default DashBoard
