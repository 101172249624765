import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { groupChatService } from "../../services/groupChatService"
import jsonB from "json-bigint";
import { type } from "os";
import { ChatModel, DataGroupChat, GroupChatModel, MESSModel, MessModel } from "../../Types/groupChatType";
import { GroupForTaskModel, GroupForTaskModel2 } from "../../Types/groupForTask";
import { DispatchType } from "../configStore";


type stackChatType={
  openDrawer:boolean
    ActiveChat1:boolean
    ActiveChat2:boolean
    ActiveCurrent:boolean
    dsChatCurent1:any[]
    dsMentor:any[]
    dsGroupTask:GroupForTaskModel[]
    dsChatCurent2:any[]

    dataChat1:DataGroupChat|null
    dataChat2:DataGroupChat|null

    groupChats: GroupChatModel[]
    groupChatsByChuyenDe:GroupChatModel[]
    chats:ChatModel[]
    lastGroupChat:GroupChatModel
    lastChat:ChatModel[]
     groupChats2: GroupChatModel[]
    groupChatsByChuyenDe2:GroupChatModel[]
    chats2:ChatModel[]
    lastGroupChat2:GroupChatModel
    lastChat2:ChatModel[]
}

const initialState : stackChatType = {
  openDrawer:false,
  ActiveChat1: false,
  ActiveChat2: false,
  //ActiveCurrent:false là active1 và ngược lại là active2
  ActiveCurrent: false,
  dsChatCurent1:[],
  dsMentor:[],
  dsGroupTask:[],
  dsChatCurent2:[],

  dataChat1: null,
  dataChat2: null,

  //tab1
  groupChats: [],
  groupChatsByChuyenDe: [],
  chats: [],
  lastGroupChat: {
    id: 1,
    danhSachChat: "",
    noiDungChat: "",
    ngayTao: "",
    groupForTaskId: 0,
    chuyenDeId: 0,
    issueId: 0
  },
  lastChat: [],
// 


  //tab2
  groupChats2: [],
  groupChatsByChuyenDe2: [],
  chats2: [],
  lastGroupChat2: {
    id: 1,
    danhSachChat: "",
    noiDungChat: "",
    ngayTao: "",
    groupForTaskId: 0,
    chuyenDeId: 0,
    issueId: 0
  },
  lastChat2: [],

};
const stackChatReducer = createSlice({
  name: "stackchat",
  initialState,
  reducers: {
    
    setOpenDrawerAciton: (state:stackChatType,action:PayloadAction<boolean>) => {
      state.openDrawer = action.payload;
    },
    ChangeActiveChat1: (state:stackChatType) => {
      state.ActiveChat1 = !state.ActiveChat1;
    },
    ChangeActiveChat2: (state:stackChatType) => {
      state.ActiveChat2 = !state.ActiveChat2;
    },
    updateDataChat1: (state:stackChatType,action:PayloadAction<DataGroupChat>) => {
      state.dataChat1 = { ...action.payload };
    },
    updateDataChat2: (state:stackChatType,action:PayloadAction<DataGroupChat>) => {
      state.dataChat2 = { ...action.payload };
    },
    changeCurrentActive: (state:stackChatType) => {
      state.ActiveCurrent = !state.ActiveCurrent
    },

    //tab1
    getGroupChatByDsChat: (state:stackChatType, action:PayloadAction<GroupChatModel[]>) => {
      state.groupChats = action.payload
      if (state.groupChats && state.groupChats.length != 0) {
        state.chats = action.payload
          .map(gc => jsonB.parse(gc.noiDungChat))
          .flat()
        state.lastGroupChat = state.groupChats[state.groupChats.length - 1]
        const lastChat = [...jsonB.parse(state.lastGroupChat.noiDungChat)]
        state.lastChat = lastChat
      }
      else
        state.chats = [];
    },
    getGroupChatByDsChatByChuyenDeId: (state:stackChatType, action:PayloadAction<GroupChatModel[]>) => {
      state.groupChatsByChuyenDe = action.payload
   
      if (
        state.groupChatsByChuyenDe &&
        state.groupChatsByChuyenDe.length != 0
      ) {
        state.chats = action.payload
          .map(gc => jsonB.parse(gc.noiDungChat))
          .flat()
        state.lastGroupChat =
          state.groupChatsByChuyenDe[state.groupChatsByChuyenDe.length - 1]
        const lastChat = [...jsonB.parse(state.lastGroupChat.noiDungChat)]
        state.lastChat = lastChat

        //cập nhập ds chat

      }
      else
      state.chats=[];
    },
    // setChats: (state:stackChatType, action) => {
    //   state.chats = action.payload
    // },
    addGroupChat: (state:stackChatType, action) => {
      // console.log(action.payload)
    },
    editGroupChat: (state:stackChatType, action) => {
      // console.log(action.payload)
    },


    //Tab2
    getGroupChatByDsChat2: (state:stackChatType, action:PayloadAction<GroupChatModel[]>) => {
      state.groupChats2 = action.payload
      if (state.groupChats2 && state.groupChats2.length != 0) {
        state.chats2 = action.payload
          .map(gc => jsonB.parse(gc.noiDungChat))
          .flat()
        state.lastGroupChat2 = state.groupChats2[state.groupChats2.length - 1]
        const lastChat = [...jsonB.parse(state.lastGroupChat2.noiDungChat)]
        state.lastChat2 = lastChat
      }
      else
      state.chats2=[];
    },
    getGroupChatByDsChatByChuyenDeId2: (state:stackChatType, action:PayloadAction<GroupChatModel[]>) => {
      state.groupChatsByChuyenDe2 = action.payload
      if (
        state.groupChatsByChuyenDe2 &&
        state.groupChatsByChuyenDe2.length != 0
      ) {
        state.chats2 = action.payload
          .map(gc => jsonB.parse(gc.noiDungChat))
          .flat()
        state.lastGroupChat2 =
          state.groupChatsByChuyenDe2[state.groupChatsByChuyenDe2.length - 1]
        const lastChat = [...jsonB.parse(state.lastGroupChat2.noiDungChat)]
        state.lastChat2 = lastChat
      }
      else
      state.chats2=[];
    },
    // setChats2: (state:stackChatType, action) => {
    //   state.chats2 = action.payload
    // },
    addGroupChat2: (state:stackChatType, action: PayloadAction<GroupChatModel>) => {
      // console.log(action.payload)
    },
    editGroupChat2: (state:stackChatType, action) => {
      // console.log(action.payload)
    },
    // addUserId2: (state:stackChatType, action) => {
    //   state.userId = action.payload
    //   localStorage.setItem("userId2", jsonB.stringify(action.payload))
    // },
    // setJoinRoom2: (state, action) => {
    //   state.joinRoom = { ...action.payload }
    //   localStorage.setItem("currentRoom2", JSON.stringify(action.payload))
    // },
    // setJoinRoomCd2: (state, action) => {
    //   state.joinRoomCd2 = { ...action.payload }
    //   localStorage.setItem("currentRoom2", JSON.stringify(action.payload))
    // },
    setDsChatCurrent1:(state:stackChatType,action)=>{
      state.dsChatCurent1=[...action.payload]
    },
    setDsChatCurrent2:(state:stackChatType,action)=>{
      state.dsChatCurent2=[...action.payload]
    },
    setDsMentor:(state:stackChatType,action)=>{
      state.dsMentor=[...action.payload]
    },
    setDSGroupTask:(state:stackChatType,action)=>{
      state.dsGroupTask=[...action.payload]
    }


  },
});
export const {
  setOpenDrawerAciton,
  ChangeActiveChat1,
  ChangeActiveChat2,
  updateDataChat1,
  updateDataChat2,
  changeCurrentActive,
  //! tab1
  getGroupChatByDsChat,
  getGroupChatByDsChatByChuyenDeId,
  addGroupChat,
  editGroupChat,
 
  // setChats,

  //!tab2

  getGroupChatByDsChat2,
  getGroupChatByDsChatByChuyenDeId2,
  addGroupChat2,
  editGroupChat2,

  // setChats2,
  setDsChatCurrent1,
  setDsChatCurrent2,
  setDsMentor,
  setDSGroupTask
} = stackChatReducer.actions;

export default stackChatReducer.reducer;


//!--------- action async ------------
//!Chat 1
export const getGroupChatByDsChatApi = (room:string, taskid:number) => {
  return async (dispatch:DispatchType) => {
    try {
      const result = await groupChatService.getGroupChatByDSChat(room, taskid)

      const action = getGroupChatByDsChat(result.data.content)
      dispatch(action)
    } catch (error) {
      // console.log("getGroupChatByDsChatApi", error)
    }
  }
}
export const getGroupChatByDsChatByChuyenDeApi = (room:string, chuyenDeId:number) => {
  return async (dispatch:DispatchType) => {
    try {
      const result = await groupChatService.getGroupChatByChuyenDeId(
        room,
        chuyenDeId
      )

      const action = getGroupChatByDsChatByChuyenDeId(result.data.content)
      dispatch(action)
    } catch (error) {
      // console.log("getGroupChatByDsChatByChuyenDeApi", error)
    }
  }
}
export const postGroupChatApi = (groupChat:GroupChatModel) => {
  return async (dispatch:DispatchType) => {
    try {
      const res = await groupChatService.addGroupChat(groupChat)
      const action = addGroupChat(res.status)
      dispatch(action)
    } catch (err) {
      // console.error("postGroupChatApi", err)
    }
  }
}
export const putGroupChatApi = (groupChatId:number, groupChat:GroupChatModel) => {
  return async (dispatch:DispatchType) => {
    try {
      const res = await groupChatService.editGroupChat(groupChatId, groupChat)
      const action = editGroupChat(res.status)
      dispatch(action)
    } catch (err) {
      // console.error("putGroupChatApi", err)
    }
  }
}
export const getGroupChatByChuyenDeIdAndHocVienIdApi = (chuyenDeId:number, hocVienId:string) => {
  return async (dispatch:DispatchType) => {
    try {
      const res = await groupChatService.getGroupChatByChuyenDeIdAndHocVienId(chuyenDeId, hocVienId);
      if (res !== null) {
        dispatch(getGroupChatByDsChatByChuyenDeId(res.data.content));
      }
      else {
        dispatch(getGroupChatByDsChatByChuyenDeId([]))
      }
    } catch (error) {
      dispatch(getGroupChatByDsChatByChuyenDeId([]))
      // console.error("getGroupChatByDsChatByChuyenDeId", error)
    }
  }
}
export const GetByDsChatGFTApi = (gftId:number) => {
  return async (dispatch:DispatchType) => {
    try {
      const res = await groupChatService.GetByDsChatGFT(gftId);
      if (res !== null) {
        dispatch(getGroupChatByDsChat(res.data.content));
      }
      else
        dispatch(getGroupChatByDsChat([]));

    } catch (error) {
      // console.error("getGroupChatByDsChat", error)
      dispatch(getGroupChatByDsChat([]));
    }
  }
}



// //*Send chat dùng chung
export const sendmessChuyenDeApi = (hocVienId:string, chuyenDeId:number, issueId:number, mess:MESSModel) => {
  return async  (dispatch:DispatchType)=> {
    try {
      const res = await groupChatService.sendmessChuyenDe(hocVienId, chuyenDeId, issueId, mess);
      if (res?.status == 200 || res?.status == 201){

      }
      else{

      }
    } catch (error) {
      // console.error("sendmessChuyenDe falil")
    }
  }
}
export const sendmessGFTApi = (gftId:number, mess:MESSModel) => {
  return async (dispatch:DispatchType) => {
    try {
      const res = await groupChatService.sendmessGFT(gftId, mess);
      if (res?.status == 200 || res?.status == 201){

      }
      else{}
    } catch (error) {
    }
  }
}




// //! tabchat2

export const getGroupChatByDsChatApi2 = (room:string, taskid:number) => {
  return async (dispatch:DispatchType) => {
    try {
      const result = await groupChatService.getGroupChatByDSChat(room, taskid)

      const action = getGroupChatByDsChat2(result.data.content)
      dispatch(action)
    } catch (error) {
      
    }
  }
}
export const getGroupChatByDsChatByChuyenDeApi2 = (room:string, chuyenDeId:number) => {
  return async (dispatch:DispatchType) => {
    try {
      const result = await groupChatService.getGroupChatByChuyenDeId(
        room,
        chuyenDeId
      )

      const action = getGroupChatByDsChatByChuyenDeId2(result.data.content)
      dispatch(action)
    } catch (error) {
      
    }
  }
}
export const postGroupChatApi2 = (groupChat:GroupChatModel) => {
  return async (dispatch:DispatchType) => {
    try {
      const res = await groupChatService.addGroupChat(groupChat)
      const action: PayloadAction<GroupChatModel> = addGroupChat2(res.data.content)
      dispatch(action)
    } catch (err) {
      
    }
  }
}
export const putGroupChatApi2 = (groupChatId:number, groupChat:GroupChatModel) => {
  return async (dispatch:DispatchType) => {
    try {
      const res = await groupChatService.editGroupChat(groupChatId, groupChat)
      const action = editGroupChat2(res.status)
      dispatch(action)
    } catch (err) {
      
    }
  }
}
export const getGroupChatByChuyenDeIdAndHocVienIdApi2 = (chuyenDeId:number, hocVienId:string) => {
  return async (dispatch:DispatchType) => {
    try {
      const res = await groupChatService.getGroupChatByChuyenDeIdAndHocVienId(chuyenDeId, hocVienId);
      if (res !== null) {
        dispatch(getGroupChatByDsChatByChuyenDeId2(res.data.content));
      }
      else {
        dispatch(getGroupChatByDsChatByChuyenDeId2([]));
      }
    } catch (error) {
      dispatch(getGroupChatByDsChatByChuyenDeId2([]));
      
    }
  }
}

export const GetByDsChatGFTApi2 = (gftId:number) => {
  return async (dispatch:DispatchType) => {
    try {
      const res = await groupChatService.GetByDsChatGFT(gftId);
      if (res !== null) {
        dispatch(getGroupChatByDsChat2(res.data.content));
      }
      else {
        dispatch(getGroupChatByDsChat2([]));

      }
    } catch (error) {
      
      dispatch(getGroupChatByDsChat2([]));
    }
  }
}

export const GetDsMentorApi=(ds:string[])=>{
  return async (dispatch:DispatchType) => {
    try {
      const rs = await groupChatService.getDsNguoiDung(ds);
      dispatch(setDsMentor(rs.data.content))
    } catch (error) {
      
    }
  }
}
export const GetDsGroupTaskApi=(ds:string[])=>{
  return async (dispatch:DispatchType) => {
    try {
      const rs = await groupChatService.getDsNguoiDung(ds);
      dispatch(setDSGroupTask(rs.data.content))
    } catch (error) {
      
    }
  }
}