import { Spin, Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
// import { tenNhiemVu } from "../../../utils/taskUtils";

let columns = [
  {
    key: "tagSkill",
    dataIndex: "tagSkill",
    title: "Skill",
  },
  {
    key: "maLoai",
    dataIndex: "maLoai",
    title: "Loại nhiệm vụ"
  },
  {
    key: "tienDo",
    dataIndex: "tienDo",
    title: "Tiến độ",
  },
  {
    key: "thoiGianLam",
    dataIndex: "thoiGianLam",
    title: "Thời gian làm",
    render: (text) => `${text} phút`,
  },
  {
    key: "diem",
    dataIndex: "diem",
    title: "Điểm",
  },
];

export default function TableScore() {
    let {bangDiemUser,loadBangDiem} = useSelector((state)=>state.ThongKeFreeReducer)
    let chuyenDe = bangDiemUser?.project?.chiTietChuyenDe[0]
  return (
    <Spin spinning={loadBangDiem}>
        {chuyenDe && <div className="text-title space-y-5">
      <p className="title">{chuyenDe.tenChuyenDe}</p>
      <div className="d-flex align-items-center text-xl px-10 py-3 bg-light justify-content-between text-dark">
        <span>Điểm trung bình: {chuyenDe.diemTrungBinh} điểm </span>
        <span>Thời gian học: {chuyenDe.thoiGianHoc} phút</span>
        <span>Phần trăm hoàn thành: {chuyenDe.phanTramHoanThanh} %</span>
      </div>
      <Table pagination={{ pageSize: 10 }} columns={columns} dataSource={chuyenDe.danhSachSkill} />
    </div>}
    </Spin>
  );
}
