import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DispatchType } from "../configStore";

import {
  MentorChamBaiModel,
  NopBaiModel,
  NopBaiViewMentor,
} from "../../Types/nopBaiType";
import { nopbaiService } from "../../services/nopbaiService";
import { MessageModel } from "../../Types/messageType";
import { setMessage } from "../MessageReducer/MessageReducer";
import { NguoiDungSetType, NguoiDungType } from "../../Types/nguoiDungType";
import { nguoiDungService } from "../../services/nguoiDungService";
import { MonHocModel } from "../../Types/monHocType";
import { monHocService } from "../../services/monHocService";
import { message } from "antd";

type nopBaiState = {
  NopBaiState: NopBaiModel;
  nopBaiCurrent: NopBaiModel;
  dsNopBaiByGroup: NopBaiModel[];
  dsNopBaiByMentorId: NopBaiModel[];
  dsNopBaiByMentorIdViewMentor: NopBaiViewMentor[];
  dsbailamtheoids: string[];
  dsHocVienByMentorId: NguoiDungType[];
  dsHocVienByMentorIdSet:NguoiDungSetType[];
  dsMonHocByChuyenDe: MonHocModel[];
  dsNopBaiByTaskId:NopBaiModel[];
  loadingDSNopBai:boolean
};

const initialState: nopBaiState = {
  NopBaiState: {
    chuyenDeId: 0,
    loaiModule: "",
    monHocId: 0,
    baiLam: "",
    daXoa: false,
    diem: 0,
    hanNopBai: "",
    id: 0,
    maLoai: "",
    ngayTao: "",
    nguoiCham: "",
    nguoiDungId: "",
    nhanXet: "",
    requireId: 0,
    taskId: 0,
    trangThai: "",
  },
  nopBaiCurrent: {
    chuyenDeId: 0,
    loaiModule: "",
    monHocId: 0,
    baiLam: "",
    daXoa: false,
    diem: 0,
    hanNopBai: "",
    id: 0,
    maLoai: "",
    ngayTao: "",
    nguoiCham: "",
    nguoiDungId: "",
    nhanXet: "",
    requireId: 0,
    taskId: 0,
    trangThai: "",
  },
  dsNopBaiByGroup: [],
  dsNopBaiByMentorId: [],
  dsNopBaiByMentorIdViewMentor: [],
  dsbailamtheoids: [],
  dsHocVienByMentorId: [],
  dsHocVienByMentorIdSet:[],
  dsMonHocByChuyenDe: [],
  dsNopBaiByTaskId:[],
  loadingDSNopBai:false
};

const nopBaiReducer = createSlice({
  name: "monHocReducer",
  initialState,
  reducers: {
    setNopBaiState: (
      state: nopBaiState,
      action: PayloadAction<NopBaiModel>
    ) => {
      state.NopBaiState = { ...action.payload };
    },
    getDsNopBaiByGroup: (
      state: nopBaiState,
      action: PayloadAction<NopBaiModel[]>
    ) => {
      if (action.payload.length) {
        state.dsNopBaiByGroup = [
          ...action.payload.sort((a, b) => a.requireId - b.requireId),
        ];
      }
    },
    getNopBaiCurrent: (
      state: nopBaiState,
      action: PayloadAction<NopBaiModel>
    ) => {
      state.nopBaiCurrent = action.payload;
      if (action.payload.baiLam)
        state.dsbailamtheoids = JSON.parse(action.payload.baiLam);
    },
    GetDsNopBaiByMentorId: (
      state: nopBaiState,
      action: PayloadAction<NopBaiModel[]>
    ) => {
      state.dsNopBaiByMentorId = [...action.payload];
    },
    GetNopBaisByNguoiDungViewModel: (
      state: nopBaiState,
      action: PayloadAction<NopBaiViewMentor[]>
    ) => {
      state.dsNopBaiByMentorIdViewMentor = [...action.payload];
    },
    GetDSByMentorId: (
      state: nopBaiState,
      action: PayloadAction<NguoiDungType[]>
    ) => {
      state.dsHocVienByMentorId = [...action.payload];
    },
    UpdateDSByMentorId:(   state: nopBaiState,
      action: PayloadAction<NguoiDungSetType[]>)=>{
        state.dsHocVienByMentorIdSet=[...action.payload]
      },
    GetMonHocsByListId: (
      state: nopBaiState,
      action: PayloadAction<MonHocModel[]>
    ) => {
      state.dsMonHocByChuyenDe = [...action.payload];
    },
    GetDSNopBaiByTaskId: (
      state: nopBaiState,
      action: PayloadAction<NopBaiModel[]>
    ) => {
      state.dsNopBaiByTaskId = [...action.payload];
    },
  },
});

export const {
  setNopBaiState,
  getDsNopBaiByGroup,
  getNopBaiCurrent,
  GetDsNopBaiByMentorId,
  GetNopBaisByNguoiDungViewModel,
  GetDSByMentorId,
  UpdateDSByMentorId,
  GetMonHocsByListId,
  GetDSNopBaiByTaskId
} = nopBaiReducer.actions;
export default nopBaiReducer.reducer;
//--------- action async ------------

export const getDsNopBaiByGroupApi = (gtaskId: number, mentorId: string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await nopbaiService.GetDSByGtaskId(gtaskId, mentorId);
      const action: PayloadAction<NopBaiModel[]> = getDsNopBaiByGroup(
        result.data.content
      );
      dispatch(action);
    } catch (error) {
      console.log("lỗi getDsNopBaiByGroupApi");
      console.log(error);
    }
  };
};

export const putMentorChamBai = (
  mentorId: string,
  MentorChamBai: MentorChamBaiModel
) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await nopbaiService.mentorChamBai(mentorId, MentorChamBai);

      if (result.status === 200) {
        const action: PayloadAction<MessageModel> = setMessage({
          type: "success",
          message: "Chấm Bài Thành Công",
          description: "",
        });
        dispatch(action);
      } else {
        const action: PayloadAction<MessageModel> = setMessage({
          type: "error",
          message: "Chấm Bài Không Thành Công",
          description: "Có Thể Hệ Thống Đang Có Vấn Đề !",
        });
        dispatch(action);
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const GetDsNopBaiByMentorIdApi = (mentorId: string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await nopbaiService.GetNopBaisByNguoiDungServiceV2(mentorId);
      const action: PayloadAction<NopBaiModel[]> = GetDsNopBaiByMentorId(
        result.data.content
      );
      // console.log(action)
      dispatch(action);
    } catch (error) {
      console.log("lỗi GetDsNopBaiByMentorIdApi");
      console.log(error);
    }
  };
};

export const GetNopBaisByNguoiDungViewModelApi = (mentorId: string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await nopbaiService.GetMentorChambaiViewmodel(
        mentorId
      );
      console.log(result)
      const action: PayloadAction<NopBaiViewMentor[]> =
        GetNopBaisByNguoiDungViewModel(result.data.content);
      // console.log(action)
      dispatch(action);
    } catch (error) {
      console.log("lỗi GetNopBaisByNguoiDungViewModelApi");
      console.log(error);
    }
  };
};
export const GetDSByMentorIdApi = (mentorId: string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await nguoiDungService.GetDSByMentorIdServiceV2(mentorId);
      const action: PayloadAction<NguoiDungType[]> = GetDSByMentorId(
        result.data.content
      );
      // console.log(action)
      dispatch(action);
    } catch (error) {
      console.log("lỗi GetNopBaisByNguoiDungApi");
      console.log(error);
    }
  };
};
export const GetDSByMentorIdAddChuyenDeIdApi = (mentorId: string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await nguoiDungService.GetDSByMentorIdAddChuyenDeIdServiceV2(mentorId);
      const action: PayloadAction<NguoiDungSetType[]> = UpdateDSByMentorId(
        result.data.content
      );
      // console.log(action)
      dispatch(action);
    } catch (error) {
      console.log("lỗi GetDSByMentorIdAddChuyenDeIdApi");
      console.log(error);
    }
  };
};
export const GetMonHocsByListIdApi = (ds: number[]) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await monHocService.GetMonHocsByListIdService(ds);
      const action: PayloadAction<MonHocModel[]> = GetMonHocsByListId(
        result.data.content
      );
      // console.log(action)
      dispatch(action);
    } catch (error) {
      console.log("lỗi GetMonHocsByListIdApi");
      console.log(error);
    }
  };
};

// export const putMentorChamBai = (mentorId: string, MentorChamBai: MentorChamBaiModel) => {
//   return async (dispatch: DispatchType) => {
//     try {
//       const result = await nopbaiService.mentorChamBai(mentorId, MentorChamBai);
//       if (result.status === 200) {
//         const action: PayloadAction<MessageModel> = setMessage({ type: 'success', message: 'Chấm Bài Thành Công', description: '' })
//         dispatch(action);
//       }

//       else {
//         const action: PayloadAction<MessageModel> = setMessage({ type: 'error', message: 'Chấm Bài Không Thành Công', description: 'Có Thể Hệ Thống Đang Có Vấn Đề !' })
//         dispatch(action);
//       }

//     } catch (error) {
//       message.error("Lỗi báo BE")
//     }
//   }
// }
export const GetDSHVNopBaiApi = (chuyenDeId: number,monHocId: number,taskId: number) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await nopbaiService.GetDSHVNopBaiService(chuyenDeId,monHocId,taskId);
      const action: PayloadAction<NopBaiModel[]> = GetDSNopBaiByTaskId(
        result.data.content
      );
      dispatch(action);
    } catch (error) {
      console.log("lỗi GetDSHVNopBaiApi");
      console.log(error);
    }
  };
};
export const XoaNopBaiApi = (id: number) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await nopbaiService.XoaNopBaiService(id);
      let {chuyenDeId,monHocId,taskId} = result.data.content;
      dispatch(GetDSHVNopBaiApi(chuyenDeId,monHocId,taskId));
    } catch (error) {
      console.log("lỗi XoaNopBaiApi");
      console.log(error);
    }
  };
};