import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import { Image, Skeleton } from "antd";
import styled from "styled-components";

const ThumbnailCSS = styled.div`
    width: 300px;
    height: 300px;
    border-radius: 5px;
    .ant-skeleton.ant-skeleton-element.ant-skeleton-active.Thumbnail_img.css-dev-only-do-not-override-9ntgx0{
        width: 100%;
        height: 100%;
        .ant-skeleton-image.Thumbnail_img{
            width: 100%;
            height: 100%;
            border-radius: 5%;
        }
    
    }
`

const Thumbnail :React.FC =()=>{
    const {baiHoc}= useAppSelector(state=>state.groupForTaskReducer)
//    console.log(baiHoc.tenBaiHoc)
 
    return(
        <ThumbnailCSS>
            {baiHoc?  <Image  src="https://i.pravatar.cc/300?img=2" alt="...!" />:      <Skeleton.Image className="Thumbnail_img"   active/>}
          
      
        </ThumbnailCSS>
    )

}
export default Thumbnail;