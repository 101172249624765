import { Button, Input, Select, Space, Tooltip } from "antd";
import React, { useState } from "react";
import { NguoiDungType, SearchNguoiDungType } from "../../Types/nguoiDungType";
import { createSearchParams, useNavigate } from "react-router-dom";
import omit from "lodash.omit";
import { QueryConfig } from "../../hooks/useQueryConfig";
import FormTaoNguoiDung from "../FormTaoNguoiDung/FormTaoNguoiDung";
const { Search } = Input;
type Props = {
  queryConfig: QueryConfig;
};

const options: Array<{
  label: string;
  value: string;
}> = [
  {
    value: "email",
    label: "Email",
  },
  {
    value: "hoTen",
    label: "Họ Tên",
  },
  {
    value: "soDt",
    label: "Số Đt",
  },
];

const FilterTable = ({ queryConfig }: Props) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectFilter, setSelectFilter] = useState<string | undefined>("hoTen");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Space direction="horizontal" size="middle">
      <Space.Compact>
          <button
            className="btn btn-success"
            style={{ fontSize: "14px" }}
            onClick={showModal}
          >
            Thêm người dùng <i className="fas fa-plus"></i>
          </button>
        </Space.Compact>
        <Space.Compact>
          <Select
            onSelect={(value) => {
              // navigate({
              //   pathname: "/quan-li-user",
              //   search: createSearchParams({
              //     ...queryConfig,
              //     loaiFilter: value,
              //   }).toString(),
              // });
              setSelectFilter(value);
            }}
            defaultValue={selectFilter}
            options={options}
          />
          <Search
            placeholder="input search text"
            // onSearch={onSearch}
            style={{ width: 200 }}
            onSearch={(value) => {
             
              navigate({
                pathname: "/quan-li-user",
                search: createSearchParams({
                  ...queryConfig,
                  filter: selectFilter as string,
                  tuKhoa: value,
                }).toString(),
              });
            }}
          />
        </Space.Compact>
        <Space.Compact>
         
          <Tooltip trigger='click' title="Đã copy">
          <button className='ms-2 btn btn-primary' onClick={() => {
            navigator.clipboard.writeText(`https://login.codezuni.com/signup`);
          }}>Form đăng ký mới</button>
        </Tooltip>
        </Space.Compact>
        <FormTaoNguoiDung
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          handleOk={handleOk}
        />
      </Space>
    </div>
  );
};

export default FilterTable;
