import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { NopBaiModel } from '../../../Types/nopBaiType'
import { ColumnsType } from 'antd/es/table'
import { TableCSS } from '../../../assets/style/CustomAntdCss'
import { NopBaiViewMentor } from '../../../Types/nopBaiType'
import { history } from '../../../index'
import { GFTWithKey, NopBaiViewMentorWithKey } from '../../../pages/Mentor/Dashboard/DashBoard'

interface props {
  showModal?: () => void
  columns?: ColumnsType<any> | undefined
  dataNB?: NopBaiModel[]
  dataSource?: readonly any[] | undefined
  newData?:readonly any[] | undefined
  setNopBai?: React.Dispatch<React.SetStateAction<NopBaiModel>>
  IsBaiTap?: boolean
  IsTask?: boolean
  gft?: boolean
  loadingTable?: boolean
  setData?: React.Dispatch<React.SetStateAction<NopBaiViewMentor | null>>
}
function CustomTableData({ columns, dataSource,newData, IsBaiTap, IsTask, showModal, loadingTable, setData, gft }: props) {
  const [loading, setLoading] = useState(true)
  const pushTask = (taskId: number,idmonhoc:number,chuyendeid:number, tenNhiemVu: string) => {
    console.log(taskId)
    history.push({
      pathname: '/baitap/' + taskId +'/'+ idmonhoc + '/'+ chuyendeid+'/' + tenNhiemVu
    })
  }
  const pushGFT = (gftId: number) => {
    console.log(gftId)
    history.push({
      pathname: '/task/' + gftId
    })
  }

  useEffect(() => {
    if (dataSource?.length) setLoading(false)
    else {
      setTimeout(() => {
        setLoading(false)
      }, 20000)
    }
  }, [dataSource, dataSource?.length])

  return (
    <TableCSS>
      <Table
        loading={loading}
        onRow={(record, rowIndex) => {
          const { key, ...nopBai }: NopBaiViewMentorWithKey | GFTWithKey = record
          return {
            onClick: (event) => {
              if (nopBai && IsBaiTap && 'tenNhiemVu' in nopBai) {
                console.log(nopBai)
                pushTask(nopBai.taskId,nopBai.monHocId,nopBai.chuyenDeId ,nopBai.tenNhiemVu)
              } else if (gft&&nopBai) {
                pushGFT(nopBai.id)
              }
              // else if(IsTask&&showModal&&setData){
              //   console.log(nopBai);
              //   setData(nopBai)
              //   showModal()
              // }
            }, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {} // mouse leave row
          }
        }}
        // onRow={()=>{

        // })}
        columns={columns}
        dataSource={dataSource}
        style={{ background: 'transparent' }}
        expandable={{}}
      />
    </TableCSS>
  )
}

export default CustomTableData
