import { http } from "../util/config";

const GET_PATH = "/api/lich-su-hoc-tap";
//lấy dạng obj 3 giá trị ngày tuần tháng
//những người chưa kích hoạt
const GetThongKeFree = (date:string) => {
    return http.get(`${GET_PATH}/thong-ke-free?currentDate=${date}`)
}
//những người đã kích hoạt (học viên cũ)
const GetThongKeFreeUser = (date:string) => {
    return http.get(`${GET_PATH}/thong-ke-free-user?currentDate=${date}`)
}
//lấy hết cả 2 cộng lại
const GetThongKeFreeAll = (date:string) => {
    return http.get(`${GET_PATH}/thong-ke-free-all?currentDate=${date}`)
}
//LẤY DATA HIỂN THỊ CHART
const DemTKTheoNgayUser = (date:string) => {
    return http.get(`${GET_PATH}/dem-theo-ngay-user?currentDate=${date}`)
}
const DemTKTheoNgay = (date:string) => {
    return http.get(`${GET_PATH}/dem-theo-ngay?currentDate=${date}`)
}
const DemTKTheoNgayAll = (date:string) => {
    return http.get(`${GET_PATH}/dem-theo-ngay-all?currentDate=${date}`)
}

//LẤY DATA CHI TIẾT HIỂN THỊ TABLE
const DataTKRowNgay = (date:string) => {
    return http.get(`${GET_PATH}/data-thong-ke-ngay?currentDate=${date}`)
}
const DataTKRowTuan = (date:string) => {
    return http.get(`${GET_PATH}/data-thong-ke-tuan?currentDate=${date}`)
}
const DataTKRowThang = (date:string) => {
    return http.get(`${GET_PATH}/data-thong-ke-thang?currentDate=${date}`)
}
const DataTKRowNgayUser = (date:string) => {
    return http.get(`${GET_PATH}/data-thong-ke-ngay-user?currentDate=${date}`)
}
const DataTKRowTuanUser = (date:string) => {
    return http.get(`${GET_PATH}/data-thong-ke-tuan-user?currentDate=${date}`)
}
const DataTKRowThangUser = (date:string) => {
    return http.get(`${GET_PATH}/data-thong-ke-thang-user?currentDate=${date}`)
}



export const thongKeFreeService = {
    GetThongKeFree,
    GetThongKeFreeAll,
    GetThongKeFreeUser,
    DemTKTheoNgay,
    DemTKTheoNgayAll,
    DemTKTheoNgayUser,
    DataTKRowNgay,
    DataTKRowNgayUser,
    DataTKRowThang,
    DataTKRowThangUser,
    DataTKRowTuan,
    DataTKRowTuanUser
}