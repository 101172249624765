import { http } from "../util/config";
import { GroupForTaskModel } from "../Types/groupForTask";


const GET_PATH:string = "/api/groupfortask";

const getListGroupForTask =  () => {
    return http.get(GET_PATH);
}
// hello
const addGroupForTask = (data: GroupForTaskModel|any) => {
    return http.post(GET_PATH, data);
}

const getGroupForTaskById = (id: number) => {
    return http.get(GET_PATH + "/" + id);

}

const editGroupForTask = (id: number, data: GroupForTaskModel|any) => {
    return http.put(GET_PATH + "/" + id, data);
}

const delGroupForTask = (id: number) => {
    return http.delete(GET_PATH + "/" + id);
}

const getBaiHocById = (id: number) => { 
    return http.get("/api/baihoc/" + id);
}

const getThongTinNguoiDung = (id:string)=>{
    return http.get("/api/nguoidung/"+id);
}

const GetGroupForTaskByMentorId = (mentorId:string)=>{
    return http.get(`${GET_PATH}/bymentor?mentorId=${mentorId}`)
}



export const groupForTaskService = { 
    getListGroupForTask,
    addGroupForTask,
    getGroupForTaskById,
    editGroupForTask,
    delGroupForTask,
    getBaiHocById,
    getThongTinNguoiDung,
    GetGroupForTaskByMentorId
}