import { DatePicker, DatePickerProps, Modal, Popconfirm, Select, Table, Tag, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { NguoiDungType } from '../../Types/nguoiDungType';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/configStore';
import { listType } from '../ChuyenDe/TaoMonHoc';
import { chuyenDeService } from '../../services/chuyenDeService';
import { DangKyHocModel } from '../../Types/chuyenDeType';
import { ColumnsType } from 'antd/es/table';
import { dinhDangNgay } from '../../util/dateFormat';
import dayjs from 'dayjs';
import { setDsMentor } from '../../redux/StackChatReducer/StackChatReducer';

type Props = {

    thongTinUser: NguoiDungType
}

const GhiDanh = ({ thongTinUser }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [data, setData] = useState<any>({});
    const [arrMentor, setArrMentor] = useState<string[]>([]);

    const { arrChuyenDe } = useSelector((state: RootState) => state.chuyenDeReducer);
    const { danhSachMentor } = useSelector((state: RootState) => state.userReducer);

    const showModal = () => {

        setIsModalOpen(true);

        chuyenDeService.dangKyHocUser(thongTinUser.id).then(res => {
            setData({
                ...data,
                arrDangKy: res.data.content
            })
        })
    };


    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onChange = (value: string | number, name: string) => {
        setData({
            ...data,
            [name]: value
        })
        if(name=="maChuyenDe"){

        console.log('machuyende: ', value);
            let cdItem = arrChuyenDe?.find(item=>item.id==value)
            console.log('cdItem: ', cdItem);
            let dsmentor =cdItem?.danhSachMentor ? JSON.parse(cdItem?.danhSachMentor):[]
            console.log('dsmentor: ', dsmentor);
            setArrMentor(dsmentor)
        }
    };
    const onChangeDangKyHoc = (value: string | number, record:any) => {
        // record.danhSachMentor=value
       let obj = {...record,danhSachMentor:JSON.stringify(value)}
    chuyenDeService.suaGhiDanhUser(obj).then(res => {
        console.log(res)
        message.success("Cập nhật thành công");
        chuyenDeService.dangKyHocUser(obj.nguoiDungId).then(res => {
            setData({
                ...data,
                arrDangKy: res.data.content
            })
        })
    }).catch(err => {
        console.log(err)
        message.error(err.response.data.message);
    });

    };
    const lstChuyenDe: any[] = [];

    arrChuyenDe?.forEach(item => {
        lstChuyenDe.push({
            label: item.tenChuyenDe || "",
            value: item.id
        })
    })

    const ghiDanhUser = () => {


        if (!data.maChuyenDe || !data.soThang) {
            message.error("Hãy chọn chuyên đề và số tháng !");
            return;
        }

        let model = {
            chuyenDeId: data.maChuyenDe,
            nguoiDungId: thongTinUser.id,
            soThang: data.soThang,
            danhSachMentor:JSON.stringify(data.dsMentor)
        }

        chuyenDeService.ghiDanhUser(model).then(res => {
            console.log(res)
            message.success("Ghi danh thành công");
            chuyenDeService.dangKyHocUser(model.nguoiDungId).then(res => {
                setData({
                    ...data,
                    arrDangKy: res.data.content
                })
            })
        }).catch(err => {
            console.log(err)
            message.error("Lỗi");
        });
    }

    const optMonth = [{ value: 0, label: "Tháng" }];

    for (let index = 1; index < 13; index++) {
        optMonth.push({
            value: index, label: index + ' tháng'
        })

    }


    const columns: ColumnsType<any> = [

        {
            title: 'Chuyên đề',
            key: 'chuyenDe',
            dataIndex: 'chuyenDe',
            render: (_, record) => {
                let chuyenDe = arrChuyenDe?.find(n => n.id == record.chuyenDeId);

                return chuyenDe?.tenChuyenDe;
            }
        },
        {
            title: 'Ngày ghi danh',
            key: "ngayBatDauHoc",
            render: (_, record) => {

                return dinhDangNgay(record.ngayBatDauHoc);
            }
        },
        {
            title: 'Mentor',
            key: "danhSachMentor",
            render: (_, record) => {
                let chuyenDe= arrChuyenDe?.find(n => n.id == record.chuyenDeId);
                let mentorChuyenDe:[] = JSON.parse(chuyenDe?.danhSachMentor||'[]')
                let dsMentor = JSON.parse(record.danhSachMentor || '[]');
                
                return (
                    <Select
                        mode="multiple"
                        defaultValue={dsMentor}
                        style={{ width: "100%" }}
                        options={mentorChuyenDe?.map((item:string) => {
                            let text = danhSachMentor.find(mt => mt.id == item);
                            return {
                                label: text?.hoTen,
                                value: item
                            };
                        })}
                        onChange={(value) => onChangeDangKyHoc(value, record)}
                    />
                );
            }
        },
        
        {
            title: <>

                Ngày kết thúc <br />
                <span className='text-danger'>Chọn ngày để gia hạn</span>
            </>,
            key: "ngayKetThuc",
            render: (_, record) => {
                let dNow = new Date();

                return <>
                    {
                    record.trangThai == 1 ?
                    <Tag color='blue'>Tạm dừng</Tag>
                    :
                    dNow >= new Date(record.ngayKetThuc) ? <Tag color='red'>Hết hạn</Tag> : <Tag color='green'>Còn hạn</Tag>
                    
                    }
                    <p className='mb-2'></p>

                    <DatePicker defaultValue={dayjs(record.ngayKetThuc)} format="DD/MM/YYYY" onChange={(date, dateString) => onChangeDate(date, dateString, record.id)} />
                    <p className='mt-2'></p>

                    <Popconfirm
                        title="Dừng học người dùng"
                        description="Bạn có chắc muốn tiếp tục?"
                        onConfirm={() => {

                            let model = {
                                dangKyHocId: record.id,
                                ngayGiaHan: new Date()
                            }

                            chuyenDeService.dungHocUser(model).then(res => {
                                message.success("Dừng học thành công");
                                chuyenDeService.dangKyHocUser(thongTinUser.id).then(res => {
                                    setData({
                                        ...data,
                                        arrDangKy: res.data.content
                                    })
                                })
                            }).catch(err => {
                                console.log(err)
                                message.error(err.response.data.message);
                            });
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <button className='btn btn-sm btn-danger'>Dừng học</button>
                    </Popconfirm>
                </>

            }
        }
    ];



    const onChangeDate: any = (date: any, dateString: any, dangKyHocId: any) => {

        let model = {
            dangKyHocId,
            ngayGiaHan: date
        }


        chuyenDeService.giaHanHocUser(model).then(res => {
            message.success("Gia hạn thành công");
            chuyenDeService.dangKyHocUser(thongTinUser.id).then(res => {
                setData({
                    ...data,
                    arrDangKy: res.data.content
                })
            })
        }).catch(err => {
            console.log(err)
            message.error(err.response.data.message);
        });
    };

    return (
        <div className="text-center">
            <button className="btn btn-sm btn-success" onClick={showModal}>Xem</button>

            <Modal title="Lớp, chuyên đề đã ghi danh"
                open={isModalOpen}
                onCancel={handleCancel}
                width={'75%'}
                footer={null}
            >
                <div className='row'>

                    <div className='col-6'>

                        <Select
                            showSearch
                            placeholder="Select a person"
                            optionFilterProp="children"
                            onChange={(value) => onChange(value, "maChuyenDe")}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={lstChuyenDe}
                            style={{ width: "100%" }}
                        />

                    </div>
                    <div className='col-2'>
                        <Select
                        mode="multiple"
                        placeholder="Chọn mentor phụ trách"
                            style={{ width: "100%" }}
                            options={arrMentor.map((item) => {
                                let text = danhSachMentor.find(mt => mt.id == item);
                                return {
                                    label: text?.hoTen,
                                    value: item
                                };
                            })}
                            onChange={(value) => onChange(value, "dsMentor")}

                        />
                    </div>
                    <div className='col-2'>

                        <Select
                            defaultValue={0}
                            style={{ width: "100%" }}
                            options={optMonth}
                            onChange={(value) => onChange(value, "soThang")}

                        />
                    </div>
                    <div className='col-2'>
                        <button className='btn btn-sm btn-primary' onClick={ghiDanhUser}>Ghi danh</button>
                    </div>

                    
                    <div className='col-12 mt-4'>
                        <label className='text-danger'>Ghi danh học viên bên menu QL học tập</label>
                        <Table columns={columns} dataSource={data.arrDangKy} />

                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default GhiDanh