import styled from 'styled-components'

export const DashBoardCSS = styled.section`
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
  min-height: 100vh;
  padding-bottom: 100px;
  background: #1b1c32;
  mix-blend-mode: normal;
  position: relative;

  .NoiDungDsHocVien {
    .inputSearch {
      height: 60px;
      flex: 1 1 auto;
      position: relative;
      padding: 10px 10px 10px 45px;

      i {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        color: rgb(204, 212, 224);

        transition: all 0.3s ease 0s;
        display: flex;
      }
      input {
        width: 100%;
        height: 100%;
        background: transparent;
        color: rgb(255, 255, 255);
        border: none;
        padding: 0px 10px;
      }
    }
   
  }
  .spanTrangThai {
    width: 90px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  .spanTrangThai.daCham {
    background-color: var(--color-bg-green-25);
    border: 1px solid var(--color-bg-green);
    color: var(--color-bg-green);
  }
  .spanTrangThai.choCham {
    background-color: var(--color-bg-yellow-25);
    border: 1px solid var(--color-bg-yellow);
    color: var(--color-bg-yellow);
  }
  .spanTrangThai.dangLam {
    background-color: var(--color-bg-infor-25);
    border: 1px solid var(--color-bg-infor);
    color: var(--color-bg-infor);
  }
  .spanTrangThai.nopLai {
    background-color: var(--color-bg-blue-25);
    color: var(--color-bg-blue);
    border: 1px solid var(--color-bg-blue);
  }

  .spanBaitapCham {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--color-bg-red);
    position: relative;

  }
  .dotChuaCham{
    position:relative
  }
  .dotChuaCham::before{
    content: '';
    position: absolute;
    top: 0%;
    right: -10px;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    animation: pulse 1s infinite alternate
  }
  .Overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(196 184 184 / 84%);
    z-index: 1000;
  }

  .title {
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    padding: 20px 0;
    gap: 5px;
  }
  .filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    /* gap: 20px; */

    width: 100%;
    /* height: 32px; */

    background: rgb(52, 63, 77);
    border-radius: 5px;
  }
  .inputSearch {
    .ant-input {
      color: rgb(15, 17, 26);
      ::placeholder {
        color: #31374a;
      }
      :focus {
        border-color: var(--color-border);
      }
    }
    button.ant-btn {
      /* color: white; */
    }
  }
  .active {
    background: #ffcd17;
    border: 2px solid #f5bc7a;
    border-radius: 5px;
  }
  .pin {
    color: #ffd051;
    transition: 0.3s;
    :hover {
      color: #fff961;
    }
  }
  .dshv {
    width: 90%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 20px 0;
    a {
      gap: 10px;
      color: white;
      cursor: pointer;
      transition: all 0.3s;
      :hover {
        color: #ffd051;
      }
      i {
        margin-left: 5px;
      }
    }
  }

  .semi-head {
    align-items: center;

    width: 80%;
    max-width: 1200px;
    margin: auto;
  }

  .head {
    align-items: center;
    min-height: 1px;
    width: 95%;
    max-width: 1440px;
    margin: auto;
    .head_content {
      /* padding: 20px 0; */
      gap: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    /* width: 1106px; */
    /* height: 300px; */

    .danhgia {
      display: flex;
      flex-direction: column;
      /* justify-content: space-between; */
      align-items: flex-start;
      padding: 20px;
      gap: 20px;
      width: 70%;
      /* gap: 7px; */

      /* width: 681px; */
      height: 250px;

      background-color: var(--color-bg);

      border: 1px solid #31374a;

      border-radius: 15px;

      .month {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;

        width: 100%;
        height: 60px;

        background: var(--color-sub-bg);
        border-radius: 10px;

        .month-item {
          height: 28px;
          width: 20%;
          text-align: center;
          font-weight: bolder;
          color: #ffffff;
          /* cursor: pointer; */

          transition: all 0.3s;
          :not(.active):hover {
            color: rgb(213 203 203);
            cursor: pointer;
          }
        }
      }
      .month-rank {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 0px;
        /* gap: 5px; */

        width: 100%;
        height: 100%;

        background: var(--color-sub-bg);
        border-radius: 15px;

        .month-rank_item {
          color: #ffffff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 48%;
          padding: 5px 10px;
          text-align: center;
          .ant-rate {
            .ant-rate-star-first {
              span {
                color: rgb(255, 205, 23);
              }
            }
            .ant-rate-star.ant-rate-star-half.ant-rate-star-active {
              .ant-rate-star-second {
                span {
                  color: rgb(250 219 21 / 13%);
                }
              }
            }
            .ant-rate-star.ant-rate-star-zero {
              .ant-rate-star-second {
                span {
                  color: rgb(250 219 21 / 13%);
                }
              }
            }
          }

          /* span:last-child {
            color: rgb(255, 205, 23);
          } */

          /* .anticon.anticon-star {
            background: linear-gradient(180deg, #ff0c0c 0%, #fa00ff 100%);
          } */
        }
      }
    }

    .thongtin {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px;
      gap: 10px;

      /* width: 400px; */
      height: 250px;

      background: var(--color-bg);
      border-radius: 15px;
      border: 1px solid #31374a;
      .profile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        gap: 10px;
        /* padding: 10px; */

        width: 100%;
        /* height: 133px; */

        border-radius: 15px;
        .avatar {
        }
        .infor {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0px 15px;
          gap: 20px;

          /* width: 100; */
          /* height: 103px; */

          /* span {
            font-size: 18px;
            line-height: 22px;
            display: flex;
            align-items: center;

            color: #ffffff;
          } */

          .tongdanhgia {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0 10px;
            gap: 10px;

            /* width: 246px; */
            width: 100%;
            height: 32px;

            background-color: #ffcd17;
            border-radius: 5px;

            color: #070632;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;
            transition: all 0.3s;
            :hover {
              background-color: #ffb710;
            }
          }
          .nhanxet {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 7px;
            gap: 10px;
            text-transform: capitalize;
            font-weight: 400;
            /* width: 246px; */
            /* width: 100%; */
            /* height: 32px; */

            background: rgba(255, 204, 133, 0.08);
            border-radius: 10px;

            color: #ffcc85;
            border: 1px solid rgb(255, 204, 133);
            font-size: 12px;
          }
        }
      }
      .thongtinchitiet {
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 5px;

        color: #ffffff;
        font-weight: bold;
      }
    }
  }

  .ant-empty-description {
    color: white;
  }

  .mid {
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start; */

    width: 95%;
    max-width: 1440px;
    margin: 20px auto;

    /* height: 430px; */

    .baitap {
      gap: 15px;

      border-radius: 15px;
      border: 1px solid #31374a;

      .filter {
        width: 100%;

        .filter__item {
          width: 12%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 2px;
          gap: 3.85px;
          color: #ffffff;
          font-size: 11px;
          transition: all 0.3s;

          :not(.active):hover {
            color: rgb(213 203 203);
            cursor: pointer;
          }
        }
      }

      .noiDung {
        width: 100%;
        padding: 56px 43px;
        background-color: var(--color-bg);
        border-radius: 5px;

        .collapChuyenDe {
          background-color: var(--color-sub-bg);
          color: white;
          border: none;
          border-radius: 8px;
          overflow: hidden;

          .ant-collapse-item {
            border-color: var(--color-border);

            .ant-collapse-header {
              color: white;
              background-color: var(--color-main-title);
              align-items: center;

              :hover {
                opacity: 0.8;
              }
            }
            .ant-collapse-content.ant-collapse-content-active {
              background-color: var(--color-sub-bg);
              border: none;
              .ant-collapse-content-box {
              }
            }
            .ant-collapse-content.ant-collapse-content-inactive {
              background: transparent;
              border-top: none;
            }
            .ant-collapse-content {
              .ant-collapse-content-box {
                padding: 33px 31px;
              }
            }
            /* .children_bg{
            } */
          }
        }


        .collapChuyenDeCaps {
          background-color: var(--color-sub-bg);
          color: white;
          border: none;
          border-radius: 8px;
          overflow: hidden;

          .ant-collapse-item {
            border-color: var(--color-border);

            .ant-collapse-header {
              color: white;
              background-color: #093052;
              align-items: center;

              :hover {
                opacity: 0.8;
              }
            }
            .ant-collapse-content.ant-collapse-content-active {
              background-color: var(--color-sub-bg);
              border: none;
              .ant-collapse-content-box {
              }
            }
            .ant-collapse-content.ant-collapse-content-inactive {
              background: transparent;
              border-top: none;
            }
            .ant-collapse-content {
              .ant-collapse-content-box {
                padding: 33px 31px;
              }
            }
            /* .children_bg{
            } */
          }
        }
      }
    }
  }
  .bot {
    width: 95%;
    max-width: 1440px;
    margin: 20px auto;
    .hotro {
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
      gap: 15px;
      background: var(--color-bg);

      /* width: 700px; */
      height: 500px;
      border-radius: 15px;
      border: 1px solid #31374a;

      .filter {
        width: 100%;
        background: var(--color-sub-bg);

        .filter__item {
          width: 20%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 2px;
          gap: 3.85px;
          color: #ffffff;
          font-weight: bold;
          font-size: 16px;
          :not(.active):hover {
            color: rgb(213 203 203);
            cursor: pointer;
          }
          &.active {
            color: black;
          }
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 0px;
        gap: 10px;
        background: var(--color-sub-bg);
        margin-left: 0;

        width: 100%;
        /* overflow-y: scroll; */

        border-radius: 5px;

        /* table {
          width: 100%;
          .ant-table-tbody .ant-table-row.rowLe {
            background-color: red;
          }

          .ant-table-tbod .ant-table-row.rowChan {
            background-color: brown;
          }
        } */

        .content__item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 20px;
          gap: 20px;
          transition: all 0.5s;
          .col-10 {
            cursor: pointer;
          }
          .col-2 {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          i {
            font-size: 16px;
            padding: 5px;
            background: transparent;
            border-radius: 5px;
          }

          width: 100%;
          /* height: 70px; */

          color: #ffffff;

          background: rgb(20, 24, 36);
          border: 3px solid rgb(49, 55, 74);
          transition: all 0.5s;
          border-radius: 5px;

          :hover {
            border: 3px solid #ffb710;
          }

          .content__item-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 5px;

            .status.thongtinnhom {
              background: #ffffff;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 5px;

              color: #3636ff;

              padding: 0 5px;
            }
          }
        }
        .content__item.pinted {
          background-color: #ffcd17;
          transition: all 0.5s;
          i {
            color: white;
          }

          color: black;
          :hover {
            border-color: #fff3cd;
          }
        }
      }
    }
    @keyframes pulse {
      0%,
      100% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
    }
  }
`
export const searchCSS = styled.div`
  height: 60px;
  flex: 1 1 auto;
  position: relative;
  padding: 10px 10px 10px 45px;

  i {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    color: rgb(204, 212, 224);

    transition: all 0.3s ease 0s;
    display: flex;
  }
  input {
    width: 100%;
    height: 100%;
    background: transparent;
    color: rgb(255, 255, 255);
    border: none;
    padding: 0px 10px;
  }
`