import { http } from "../util/config";
import { GroupChatModel, MESSModel, MessModel } from "../Types/groupChatType";

const GET_PATH: string = "/api/groupchat";

const getListGroupChat = () => {
  return http.get(GET_PATH);
};

const addGroupChat = (data: GroupChatModel) => {
  return http.post(GET_PATH, data);
};

const getGroupChatById = (groupChatId: number) => {
  return http.get(`${GET_PATH}/${groupChatId}`);
};

const editGroupChat = (groupChatId: number, data: GroupChatModel) => {
  return http.put(`${GET_PATH}/${groupChatId || data.id}`, data);
};
const delGroupChat = (groupChatId: number) => {
  return http.delete(`${GET_PATH}/${groupChatId}`);
};
const getGroupChatByDSChat = (dschat: string, gftid: number) => {
  return http.get(`${GET_PATH}/getDsChat`, { params: { dschat, gftid } });
};
const getGroupChatByChuyenDeId = (dschat: string, chuyenDeId: number) => {
  return http.get(`${GET_PATH}/getDsChatByChuyenDeId`, { params: { dschat, chuyenDeId } });
};
const getGroupChatByDSChatGFT = (gftId: number) => {
  return http.get(`${GET_PATH}/GetByDsChatGFT`,{params:{gftId}})
};
const getGroupChatByChuyenDeIdAndHocVienId = (chuyenDeId:number,hocVienId:string)=>{
  return http.get(`${GET_PATH}/getDsChatByChuyenDeIdAndHocVienId`,{params:{chuyenDeId,hocVienId}})
  // return http.get(`${GET_PATH}/getDsChatByChuyenDeIdAndHocVienId?chuyenDeId=${chuyenDeId}&hocVienId=${hocVienId}`)
}
const sendmessChuyenDe=(hocVienId:string,chuyenDeId:number,issueId:number,data:MESSModel)=>{
  return http.post(`${GET_PATH}/sendmessChuyenDe`,data,{params:{hocVienId,chuyenDeId,issueId}})
  // return http.post(`${GET_PATH}/sendmessChuyenDe?hocVienId=${hocVienId}&chuyenDeId=${chuyenDeId}&issueId=${issueId}`,data)
}

const sendmessGFT = (gftId:number,mess:MESSModel)=>{
  return http.post(`${GET_PATH}/sendmessGFT`,mess,{params:{gftId}})
}

const GetByDsChatGFT=(gftId:number)=>{
  return http.get(`${GET_PATH}/GetByDsChatGFT`,{params:{gftId}})
}

const getDsNguoiDung = (ds:string[])=>{
  return http.post(`/api/nguoidung/laydanhsachnguoidungbystring`,ds)
}




export const groupChatService = {
  getListGroupChat,
  addGroupChat,
  getGroupChatById,
  editGroupChat,
  delGroupChat,
  getGroupChatByDSChat,
  getGroupChatByChuyenDeId,
  getGroupChatByDSChatGFT,
  getGroupChatByChuyenDeIdAndHocVienId,
  sendmessChuyenDe,
  sendmessGFT,
  GetByDsChatGFT,
  getDsNguoiDung
};
