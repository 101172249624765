import { Col, Drawer, InputNumber, Row, Select, Space, message, Button, Form, Input, Upload, UploadFile, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { nguoiDungService } from "../../services/nguoiDungService";
import useQueryConfig from "../../hooks/useQueryConfig";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getNguoiDungPhanTrangApi } from "../../redux/UserReducer/userReducer";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/configStore";
import moment from "moment";
import { packageService } from "../../services/quanyGoiService";
import { PackageType } from "../../Types/quanLyGoi";
import { getAllArrGoiApi } from "../../redux/quanLiGoiReducer/quanLiGoiReducer";
import { API_URL_DOMAIN } from "../../util/urlDomain";
import { RcFile, UploadProps } from "antd/es/upload";
import { UploadOutlined } from "@ant-design/icons";
import { uploadImageService } from "../../services/uploadImagService";
type Props = {
  isModalOpen: boolean;
  handleCancel: () => void;
  model: PackageType | undefined;
};
interface ChuyenDeOption {
  value: number;
  label: string | any;
}
const FormTaoGoi = ({ isModalOpen, handleCancel, model }: Props) => {

  const { arrNhomQuyen } = useAppSelector((state) => state.heThongReducer);
  let { arrChuyenDe } = useSelector((state: RootState) => state.chuyenDeReducer)
  const lstNhomQuyen: any[] = [];

  arrNhomQuyen?.forEach(item => {
    lstNhomQuyen.push({
      label: item.tenNhom || "",
      value: item.id
    })
  })
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('')
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
};

const handleCancelImg = () => setPreviewOpen(false);

const handleChangeImg: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
    try {
        setFileList(newFileList);
        if (newFileList.length > 0 && newFileList[0].originFileObj) {
            const imageUrl = await uploadImageService.uploadImage(newFileList[0].originFileObj);
            setImageUrl(imageUrl);
        }
    } catch (error) {
        // Xử lý lỗi nếu có
        console.error("Đã xảy ra lỗi:", error);
    }
};

const handleRemove = (file: UploadFile) => {
    const index = fileList.indexOf(file);
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setImageUrl("")
    setFileList([]);
};
const createUploadFileFromURL = (url: string): UploadFile => {
  const file: UploadFile = {
      uid: '-1',
      name: url.split("/images/")[1],
      status: 'done',
      url: `${API_URL_DOMAIN}${url}`,
  };
  return file;
};

  const [messageApi, contextHolder] = message.useMessage();
  const queryConfig = useQueryConfig();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const formik = useFormik({
    initialValues: {
      id: model ? model.id : 0,
      tenGoi: model ? model.tenGoi : '',
      hocPhi: 0,
      thang: 0,
      danhSachKhoaHoc: [],
      ngayTao: new Date().toISOString(),
      daXoa: false,
      hinhAnh:""
    },
    validationSchema: Yup.object({
      tenGoi: Yup.string().required("Không bỏ trống tên"),
      hocPhi: Yup.number().required("Không bỏ trống học phí"),
      thang: Yup.number().required("Không bỏ trống tháng học"),
      danhSachKhoaHoc: Yup.array().min(1, "Không bỏ trống danh sách khóa học")
    }),
    onSubmit: (values) => {
      let data: PackageType = {
        tenGoi: values.tenGoi,
        id: values.id,
        hocPhi: values.hocPhi,
        thang: values.thang,
        danhSachKhoaHoc: JSON.stringify(values.danhSachKhoaHoc),
        ngayTao: values.ngayTao,
        daXoa: values.daXoa,
        hinhAnh:imageUrl?imageUrl:""
      }
      if (data.id != 0) {
        packageService.editPackageService(data)

          .then((res) => {
            formik.resetForm();
            form.resetFields();
            dispatch(getAllArrGoiApi());
            messageApi.open({
              type: "success",
              content: "Cập nhật gói thành công",
            });
            handleCancel();
          })
          .catch((err) => {
            messageApi.error({
              type: "error",
              content: "Có lỗi xảy ra, vui lòng thử lại",
            });
          });
      }
      else {
        packageService.postDanhSachGoi(data)

          .then((res) => {
            formik.resetForm();
            form.resetFields();
            dispatch(getAllArrGoiApi());
            messageApi.open({
              type: "success",
              content: "Tạo gói thành công",
            });
            handleCancel();
          })
          .catch((err) => {
            messageApi.error({
              type: "error",
              content: "Có lỗi xảy ra, vui lòng thử lại",
            });
          });
      }
    },
  });
  const [option, setOption] = useState<ChuyenDeOption[]>([])
  useEffect(() => {
    if (arrChuyenDe) {
      const updatedOptions = arrChuyenDe.map(chuyenDe => ({ value: chuyenDe.id, label: chuyenDe.tenChuyenDe }));
      setOption(updatedOptions);
    }
  }, [arrChuyenDe])
  useEffect(() => {
    if (model) {
      form.setFieldValue("tenGoi", model.tenGoi)
      form.setFieldValue("id", model.id)
      form.setFieldValue("hocPhi", model.hocPhi)
      form.setFieldValue("thang", model.thang)
      form.setFieldValue("danhSachKhoaHoc", JSON.parse(model.danhSachKhoaHoc))
      form.setFieldValue("daXoa", model.daXoa)

      formik.setFieldValue("tenGoi", model.tenGoi)
      formik.setFieldValue("id", model.id)
      formik.setFieldValue("hocPhi", model.hocPhi)
      formik.setFieldValue("thang", model.thang)
      formik.setFieldValue("danhSachKhoaHoc", JSON.parse(model.danhSachKhoaHoc))
      formik.setFieldValue("daXoa", model.daXoa)
      const file = model&&model.hinhAnh && createUploadFileFromURL(model.hinhAnh);

      setImageUrl(model.hinhAnh)
        // Gán đối tượng UploadFile vào mảng fileList
        const updatedFileList =file? [file]:[];

        // Cập nhật state fileList với mảng mới
        setFileList(updatedFileList);

    }else{
      setImageUrl(null)
    }

  }, [model])

  const { handleChange, handleBlur, errors, touched, values, handleSubmit } = formik;
  return (
    <>
      {contextHolder}
      <Drawer
        title={model ? "Sửa thông tin" : "Thêm Gói"}
        placement="right"
        onClose={() => {
          handleCancel();
        }}
        open={isModalOpen}
        size="large"
        width={720}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={handleCancel}>Đóng</Button>
            <Button
              onClick={() => {
                handleSubmit();
              }}
              type="primary"
            >
              Lưu lại
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="tenGoi" label="Tên gói" initialValue={values.tenGoi}>
                <Input
                  value={values.tenGoi}
                  name="tenGoi"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Vui lòng nhập tên"
                />
                {touched.tenGoi && errors.tenGoi ? (
                  <div className="mt-2 text-danger">{errors.tenGoi}</div>
                ) : null}
              </Form.Item>
            </Col>

          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="Danh sách khóa học">
                <Select

                  value={formik.values.danhSachKhoaHoc}
                  mode="multiple"
                  placeholder="Vui lòng chọn khóa học"

                  onChange={(value: any) => {
                    formik.setFieldValue("danhSachKhoaHoc", value);
                  }}
                  options={option}
                />

              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="hocPhi" label="Học phí">
                <InputNumber
                  value={formik.values.hocPhi + ""}
                  name="hocPhi"
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} // Formats the input as currency
                  parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : ''} // Parses the input value

                  onChange={(value) => { formik.setFieldValue("hocPhi", Number(value)) }}
                  onBlur={handleBlur}
                  style={{ width: "100%" }}
                  placeholder="Vui lòng nhập học phí"
                />
                {touched.hocPhi && errors.hocPhi ? (
                  <div className="mt-2 text-danger">{errors.hocPhi}</div>
                ) : null}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="thang" label="Số tháng">
                <InputNumber
                  value={formik.values.thang}
                  type="number"
                  onChange={(value) => { formik.setFieldValue("thang", Number(value)) }}
                  min={0}
                  max={100}
                  style={{ width: "100%" }}
                  placeholder="Vui lòng nhập số tháng"
                  name="thang"
                  onBlur={handleBlur}
                />
                {touched.thang && errors.thang ? (
                  <div className="mt-2 text-danger">{errors.thang}</div>
                ) : null}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Ảnh đại diện">
                <Upload
                  name="hinhAnh"
                  listType="picture-circle"
                  action={`${API_URL_DOMAIN}/api/file/image`}
                  className="upload-list-inline"
                  maxCount={1}
                  onPreview={handlePreview}
                  onChange={handleChangeImg}
                  onRemove={handleRemove}
                  fileList={fileList}
                >
                  {fileList.length > 0 ? null : (
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                  )}
                </Upload>
                <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handleCancelImg}>
                  <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Drawer>
    </>
  );
};

export default FormTaoGoi;
