import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { baiHocService } from "../../services/baiHocService";
import { http } from "../../util/config";
import { DispatchType } from "../configStore";
import { BaiHocModel, BaiHocViewMentorModel } from "../../Types/baiHocType";
import moment from "moment";
import { loTrinhOnlineService } from "../../services/loTrinhOnlineService";

type baiHocState = {
  arrBaiHoc: BaiHocModel[] | null;
  dsBaiHocByDsId: BaiHocViewMentorModel[]
  dsIdBaiHoc: number[]
  dsBaiHocOnline: any[]
  dsLoTrinhOnline: any[]
  dsKhoaHocOnline: any[]
  dsChuongHocOnline: any[]
};
const initialState: baiHocState = {
  arrBaiHoc: [],
  dsBaiHocByDsId: [],
  dsIdBaiHoc: [],


  dsLoTrinhOnline: [],
  dsKhoaHocOnline: [],
  dsChuongHocOnline: [],
  dsBaiHocOnline: []
};

const baiHocReducer = createSlice({
  name: "baiHocReducer",
  initialState,
  reducers: {
    getBaiHocAction: (
      state: baiHocState,
      action: PayloadAction<BaiHocModel[]>
    ) => {
      state.arrBaiHoc = [...action.payload];
    },
    getBaiHocByListId: (state: baiHocState, action: PayloadAction<BaiHocViewMentorModel[]>) => {
      state.dsBaiHocByDsId = [...state.dsBaiHocByDsId, ...action.payload]
      state.dsIdBaiHoc = [...state.dsBaiHocByDsId, ...action.payload].map(e => e.id)
    },




    getDsLoTrinhOnline: (state: baiHocState, action: PayloadAction<BaiHocViewMentorModel[]>) => {
      state.dsLoTrinhOnline = action.payload
    },

    getDsKhoaHocOnline: (state: baiHocState, action: PayloadAction<BaiHocViewMentorModel[]>) => {
      state.dsKhoaHocOnline = action.payload
    },

    getDsChuongHocOnline: (state: baiHocState, action: PayloadAction<BaiHocViewMentorModel[]>) => {
      state.dsChuongHocOnline = action.payload
    },

    getDsBaiHochOnline: (state: baiHocState, action: PayloadAction<BaiHocViewMentorModel[]>) => {
      state.dsBaiHocOnline = action.payload
    }
  },
});

export const { getBaiHocAction, getBaiHocByListId,
  getDsLoTrinhOnline, getDsKhoaHocOnline, getDsChuongHocOnline, getDsBaiHochOnline

} = baiHocReducer.actions;
export default baiHocReducer.reducer;
//--------- action async ------------

export const getDsLoTrinhOnlineApi = () => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await loTrinhOnlineService.layLoTrinhOnLineService();
      const action: PayloadAction<any[]> = getDsLoTrinhOnline(
        result.data.content
      );
      dispatch(action);
    } catch (error) {
      console.log('lo trinh', error);
    }
  };
};


export const getDsKhoaHocOnlineApi = () => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await loTrinhOnlineService.layKhoaHocOnLineService();
      const action: PayloadAction<any[]> = getDsKhoaHocOnline(
        result.data.content
      );
      dispatch(action);
    } catch (error) {
      console.log('lo trinh', error);
    }
  };
};

export const getDsChuongHocOnlineApi = () => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await loTrinhOnlineService.layChuongHocOnLineService();
      const action: PayloadAction<any[]> = getDsChuongHocOnline(
        result.data.content
      );
      dispatch(action);
    } catch (error) {
      console.log('lo trinh', error);
    }
  };
};

export const getDsBaiHocOnlineApi = () => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await loTrinhOnlineService.layBaiHocOnLineService();
      const action: PayloadAction<any[]> = getDsBaiHochOnline(
        result.data.content
      );
      dispatch(action);
    } catch (error) {
      console.log('lo trinh', error);
    }
  };
};




export const getBaiHocApi = () => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await baiHocService.layDanhSachBaiHoc();
      const action: PayloadAction<BaiHocModel[]> = getBaiHocAction(
        result.data.content
      );
      dispatch(action);
    } catch (error) {
      console.log('getBaiHocApi', error);
    }
  };
};

export const getBaiHocByListIdApi = (ds: number[]) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await baiHocService.postDanhSachBaiHocByListId(ds);

      const action: PayloadAction<BaiHocViewMentorModel[]> = getBaiHocByListId(
        result.data.content
      );
      dispatch(action);
    } catch (error) {
      console.log('getBaiHocByListIdApi', error);
    }
  };
};
export const updateDemoBaiHocApi = (ds: string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await baiHocService.updateBaiHocDemoByLIstId(ds);
    } catch (error) {
      console.log('getBaiHocByListIdApi', error);
    }
  };
}; 
