import { http } from "../util/config";
import { RequireForStudentModel } from "../Types/RequireForStudent";

const GET_PATH = "/api/requireforstudent";

const getRequireForStudents = () => {
  return http.get(GET_PATH);
};

const addRequireForStudent = (data: RequireForStudentModel | any) => {
  return http.post(GET_PATH, data);
};

const getRequireForStudentById = (id: number) => {
  return http.get(`${GET_PATH}/${id}`);
};

const editRequireForStudent = (
  id: number,
  data: RequireForStudentModel | any
) => {
  return http.put(`${GET_PATH}/${id || data.id}`, data);
};
const delRequireForStudent = (id: number) => {
  return http.delete(`${GET_PATH}/${id}`);
};
const getRequireForStudentByTaskId = (id: number) => {
  return http.get(`${GET_PATH}/bygroupfortaskid/${id}`);
};
const postOrPutRequireForStudent = (list: RequireForStudentModel[]) =>{
  return http.post(`${GET_PATH}/postorput/`, list);

}
const GetDSRequireForStudentByDsGFTid= (ds:number[])=>{
  return http.post(GET_PATH+'/dsrequirebygftids',ds)
}

export const requireForStudentService = {
  getRequireForStudents,
  addRequireForStudent,
  getRequireForStudentById,
  editRequireForStudent,
  delRequireForStudent,
  getRequireForStudentByTaskId,
  postOrPutRequireForStudent,
  GetDSRequireForStudentByDsGFTid
};
