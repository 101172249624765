import React, { Fragment, useEffect, useState } from 'react'
import { TaskCss } from '../../../assets/style/TaskCss'
import { history } from '../../../index'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import XDrop from '../../../components/GroupForTask/LayoutForPInformation/component/drop'
import { IData } from '../../../Types/groupChatType'
import XDrag from '../../../components/GroupForTask/LayoutForPInformation/component/drag'
import XColumn from '../../../components/GroupForTask/LayoutForPInformation/component/column'
import { onChange } from '../../../components/GroupForTask/LayoutForPInformation/static/utils'
import { deleteTask } from '../../../components/GroupForTask/LayoutForPInformation/static/utils'
import { addTask } from '../../../components/GroupForTask/LayoutForPInformation/static/utils'
import { NguoiDungGroupForTask } from '../../../Types/groupForTask'
import { RequireForStudentModel } from '../../../Types/RequireForStudent'
import { ITask } from '../../../Types/groupChatType'
import ThongTinHocVien from '../../../components/Mentor/Chat/ThongTinHocVien'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { useLocation, useParams } from 'react-router-dom'
import { StarOutlined, MessageOutlined, ExclamationOutlined } from '@ant-design/icons'
import {
  getBaiHocApi,
  getGroupForTaskApi,
  getRequireForStudentByTaskIdApi,
  getThongTinMentorApi,
  layDsNguoiDungByStringApi,
  postOrPutRequireForStudentApi,
  setRequireForStudentsByJson,
  setRequires
} from '../../../redux/GroupForTaskReducer/GroupForTaskReducer'
import {
  getDsNopBaiByGroupApi,
  putMentorChamBai,
  GetNopBaisByNguoiDungViewModelApi,
  setNopBaiState
} from '../../../redux/NopBaiReducer/nopBaiReducer'
import CustomDrawer from '../../../components/Mentor/Task/customDrawer'
import { Avatar, Badge, Button, Modal, Popover, Space, Table, Tag, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { LoaiModuleType, MentorChamBaiModel, NopBaiModel, trangThai } from '../../../Types/nopBaiType'
import ChamBai from '../../../components/Mentor/Dashboard/ChamBai'
import { config } from '../../../util/config'
import JoinRoom from '../../../components/GroupChat/JoinRoom'
import { TableCSS } from '../../../assets/style/CustomAntdCss'
import { NguoiDungType } from '../../../Types/nguoiDungType'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/configStore'
import { getUserChuyenDeApi } from '../../../redux/ChuyenDeReducer/chuyenDeReducer'

interface DataType {
  key: string
  id: number
  ngayTao: string
  daXoa: boolean
  nguoiDungId: string
  chuyenDeId: number
  monHocId: number
  taskId: number
  requireId: number
  baiLam: string
  diem: number
  nguoiCham: string
  nhanXet: string
  hanNopBai: string
  maLoai: string
  trangThai: trangThai
  loaiModule: LoaiModuleType
  tenHocVien?: string
}

const Task: React.FC = () => {
  const dispatch = useAppDispatch()
  //set id GROUP_FOR_TASK vào local
  const { gftid } = useParams()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const paramGFTId = params.get('gftid')
  config.setStore('GROUP_FOR_TASK', gftid)
  const [openThemSuaThanhVien, setOpenThemSuaThanhVien] = useState(false)
  const [openDSTV, setOpenDSTV] = useState(false)
  const [openSwap, setOpenSwap] = useState(false)

  const [openModal, setOpenModal] = useState(false)

  const [confirmLoading, setConfirmLoading] = useState(false)
  const [chamlaiLoading, setChamlaiLoading] = useState(false)

  const [dataSource, setDataSource] = useState<DataType[]>([])


  const [data, setData] = useState<IData>({
    tasks: {},
    columns: {},
    columnOrder: []
  })

  const {
    groupForTask,
    baiHoc,
    arrDanhSachHocVien,
    requires,
    requireForStudents,
    checkRequireForStudents,
    arrDSNguoiDung,
    currentRequire,
    thongTinMentor
  } = useAppSelector((state) => state.groupForTaskReducer)

  const { dsNopBaiByGroup, dsNopBaiByMentorIdViewMentor } = useAppSelector((state) => state.nopBaiReducer)
  const user = config.getStoreJson('USER_LOGIN') as NguoiDungType
  const convertName = (id: string, data: NguoiDungGroupForTask[]) => {
    if (data.length) {
      const index = data.findIndex((e) => e.id === id)
      return data[index].hoTen
    }
    return 'UNNOKK'
  }

  const { arrUserChuyenDe } = useSelector((state: RootState) => state.chuyenDeReducer);

  useEffect(() => {
    dispatch(getUserChuyenDeApi(Number(groupForTask.chuyenDeId)))

  }, []);


  const columns: ColumnsType<DataType> = [
    // {
    //   title: 'Tên học viên',
    //   dataIndex: 'nguoiDungId',
    //   key: 'nguoiDungId',
    //   // fixed: 'left',
    //   width: '150px',
    //   sorter: (a, b) => a.nguoiDungId.localeCompare(b.nguoiDungId),
    //   render: (text) => <a>{arrDSNguoiDung ? convertName(text, arrDSNguoiDung) : text}</a>
    // },
    {
      title: 'Yêu cầu',
      width: '100px',
      render: (_, { requireId }) => (
        <>
          <Tag color='purple'>
            Yêu cầu {requireId}
          </Tag>
        </>
      )
    },
    {
      title: 'Điểm',
      dataIndex: 'diem',
      key: 'diem',
      width: '15%'
    },
    {
      title: 'Module',
      dataIndex: 'loaiModule',
      key: 'loaiModule',
      width: '15%'
    },
    {
      title: 'Trạng Thái',
      key: 'trangThai',
      dataIndex: 'trangThai',
      width: '15%',
      fixed: 'right',
      render: (_, { trangThai }) => (
        <>
          <Tag color={trangThai === 'CHO_CHAM' ? 'cyan' : trangThai === 'NOP_LAI' ? 'blue' : 'error'} key={trangThai}>
            {trangThai.toUpperCase()}
          </Tag>
        </>
      )
    }
  ]

  const handOpenDs = () => {
    setOpenDSTV(!openDSTV)
  }

  const showModal = () => {
    setOpenModal(true)
  }

  const handleCancelModal = () => {
    setOpenModal(false)
  }


  const handleOnClick = async () => {
    if (gftid) {
      const query = {
        paramGFTId: gftid
      }
      if (query) {
        await config.setStoreJson("ApiChat", { gftId: gftid })
        await history.push({
          pathname: '/chat',
          search: new URLSearchParams(query).toString()
        })
      }
    }
  }

  const buildDataDND = (dsHocVien: NguoiDungGroupForTask[], requireForStudents: RequireForStudentModel[]) => {
    const requiresObj: Record<string, ITask> = requireForStudents.reduce((obj: Record<string, ITask>, item) => {
      obj[item.requireId.toString()] = {
        id: item.requireId.toString(),
        content: item
      }
      return obj
    }, {})
    const columnsObj: Record<string, { id: string; title: string; taskIds: string[] }> = dsHocVien.reduce(
      (obj: Record<string, { id: string; title: string; taskIds: string[] }>, item) => {
        obj[item.id] = {
          id: item.id,
          title: item.hoTen,
          taskIds: requireForStudents.filter((e) => e.studentId === item.id).map((rq) => rq.requireId)
          // .filter((e) => e.studentId === item)
          // .map((rq) => rq.requireId.toString()),
        }
        return obj
      },
      {}
    )

    const columnOrder: string[] = []

    for (const key in columnsObj) {
      columnOrder.push(key)
    }

    const data: IData = {
      tasks: requiresObj,
      columns: columnsObj,
      columnOrder
    }
    return { ...data }
  }

  const converToRequireForStuden = (data: IData): RequireForStudentModel[] => {
    const tasksArray: RequireForStudentModel[] = Object.values(data.tasks).map((task) => task.content)
    return [...tasksArray]
  }

  const onDragEnd = (result: DropResult) => {
    const { source, destination, draggableId, type } = result
    if (!destination) return
    if (onChange(source, destination)) return
    if (type === 'TASK') {
      let newData = deleteTask(data, source)
      newData = addTask(newData, destination, draggableId)
      setData(newData)
      handleDivideTask(converToRequireForStuden(newData))
    }
    if (type === 'COLUMN') {
      let columnOrder = [...data.columnOrder]
      columnOrder.splice(source.index, 1)
      columnOrder.splice(destination.index, 0, draggableId)
      data.columnOrder = columnOrder
      setData({ ...data })
    }
  }

  const handleDivideTask = async (ds: RequireForStudentModel[]) => {
    await dispatch(postOrPutRequireForStudentApi(ds))
    await dispatch(getRequireForStudentByTaskIdApi(Number(gftid)))
  }
  const btnActionAddOrSwap = (
    <div className='d-flex gap-1'>
      <button className='btn btn-success'>ADD</button>
      <button
        className='btn btn-primary'
        onClick={() => {
          setOpenSwap(!openSwap)
        }}
      >
        SWAP
      </button>
    </div>
  )

  let [searchName, setSearchName] = useState("");

  const dsChinhSuaHocVien = () => {

    let arrUserTemp = searchName != "" ? arrUserChuyenDe.filter((item: any) => item.hoTen.toLowerCase().indexOf(searchName.toLowerCase()) != -1 || item.email.toLowerCase().indexOf(searchName.toLowerCase()) != -1) : arrUserChuyenDe

    console.log(arrUserTemp)
    return (
      <>
        <p>
          <button
            className='btnDanhSach'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#collapseExample'
            aria-expanded='false'
            aria-controls='collapseExample'
          >
            Danh Sách Học Viên Có Thể Join Vào
          </button>
        </p>

        <div className='collapse show' id='collapseExample'>
          <div className='dschat'>
            <input className='form-control' placeholder='Nhập tên hoặc email' onChange={event => setSearchName(event.target.value)} />
            {
              arrUserTemp.map((item: any) => {
                return <div className='person'>

                  <label className=''>{item.hoTen} <br /> {item.email}</label>
                  <div className='action'>

                    <button className='btn btn-info'>Thêm</button>


                  </div>
                </div>
              })
            }



          </div>
        </div>
        <p>
          {/* <a
            className=""
            data-bs-toggle="collapse"
            href="#collapseExample"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            Link with href
          </a> */}
          <button
            className='btnDanhSach'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#dshocvienthemvao'
            aria-expanded='false'
            aria-controls='dshocvienthemvao'
          >
            Danh Sách Học Viên Hiện Tại
          </button>
        </p>
        <div className='collapse show' id='dshocvienthemvao'>
          <div className='dschat'>
            <div className='person'>
              <Badge count={1}>
                <Avatar shape='circle' src='https://picsum.photos/100' />
              </Badge>
              <p className='person__name'>wonyoung</p>
              <div className='action'>
                <button className='btn btn-outline-danger'>KICK</button>
              </div>
            </div>{' '}
            <div className='person'>
              <Badge count={0}>
                <Avatar shape='circle' src='https://picsum.photos/100' />
              </Badge>
              <p className='person__name'>wonyoung</p>
              <div className='action'>
                <button className='btn btn-outline-danger'>KICK</button>
              </div>
            </div>{' '}
            <div className='person'>
              <Badge count={0}>
                <Avatar shape='circle' src='https://picsum.photos/100' />
              </Badge>
              <p className='person__name'>wonyoung</p>
              <div className='action'>
                <button className='btn btn-outline-danger'>KICK</button>
              </div>
            </div>
          </div>
        </div>
        <CustomDrawer
          title={<h6 style={{ color: 'white', textDecoration: 'underline' }}>Swap Thành Viên Nhóm</h6>}
          open={openSwap}
          placement='left'
          setOpen={setOpenSwap}
          key={'swap thanh vien'}
          children={dsThanhVienSwap()}
        />
      </>
    )
  }

  const dsThanhVien = () => {
    return (
      <>
        <p>
          <button
            className='btnDanhSach'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#collapseExample'
            aria-expanded='true'
            aria-controls='collapseExample'
          >
            Danh Sách Thành Viên Trong Nhóm
          </button>
        </p>
        <div className='collapse show' id='collapseExample'>
          <div className='dschat'>
            {arrDSNguoiDung.map((person, ind) => {
              return (
                <Fragment key={person.id + '' + ind}>
                  <Popover
                    placement='left'
                    arrow={true}
                    title={''}
                    content={
                      <>
                        <ThongTinHocVien hovVien={person} key={person.id + 'tt' + ind} />
                      </>
                    }
                  >
                    <div
                      className='person'
                    >
                      <Badge count={1}>
                        <Avatar shape='circle' src='https://picsum.photos/100' />
                      </Badge>
                      <p className='person__name'>{person.hoTen}</p>
                      <div className='icon mess'>
                        <MessageOutlined />
                        <ExclamationOutlined />
                      </div>
                    </div>
                  </Popover>
                </Fragment>
              )
            })}

            {/* <div className="person">
              <Badge count={0}>
                <Avatar shape="circle" src="https://picsum.photos/100" />
              </Badge>
              <p className="person__name">wonyoung</p>
              <div className="icon ">
                <MessageOutlined />
              
              </div>
            </div> */}
          </div>
        </div>
        <p>
          <button
            className='btnDanhSach'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#dshocvienthemvao'
            aria-expanded='true'
            aria-controls='dshocvienthemvao'
          >
            Nhóm Hiện Tại
          </button>
        </p>
        <div className='collapse show' id='dshocvienthemvao'>
          <div className='dschat'>
            <div
              className='person'
              onClick={() => {
                handleOnClick()
              }}
            >
              <Badge count={1}>
                <Avatar shape='circle' src='https://picsum.photos/100' />
              </Badge>
              <p className='person__name'>Chat Nhóm Group Task {gftid}</p>
              <div className='icon mess'>
                <MessageOutlined />
                <ExclamationOutlined />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  const dsThanhVienSwap = () => {
    return (
      <>
        <p>
          <button
            className='btnDanhSach'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#dsSwap'
            aria-expanded='true'
            aria-controls='dsSwap'
          >
            Danh Sách Thành Viên Trong Nhóm
          </button>
        </p>
        <div className='collapse show' id='dsSwap'>
          <div className='dschat'>
            <div className='person'>
              <Avatar shape='circle' src='https://picsum.photos/100' />

              <p className='person__name'>wonyoung</p>
              <div className='action'>
                <button className='btn btn-outline-primary'>SWAP</button>
              </div>
            </div>{' '}
            <div className='person'>
              <Badge count={0}>
                <Avatar shape='circle' src='https://picsum.photos/100' />
              </Badge>
              <p className='person__name'>wonyoung</p>
              <div className='action'>
                <button className='btn btn-outline-primary'>SWAP</button>
              </div>
            </div>{' '}
            <div className='person'>
              <Badge count={0}>
                <Avatar shape='circle' src='https://picsum.photos/100' />
              </Badge>
              <p className='person__name'>wonyoung</p>
              <div className='action'>
                <button className='btn btn-outline-primary'>SWAP</button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  useEffect(() => {
    //fetch data  của groupForTask bởi id truyền vào
    dispatch(getGroupForTaskApi(Number(gftid)))

    //fetch data  của requireForStudent bởi id truyền vào
    dispatch(getRequireForStudentByTaskIdApi(Number(gftid)))
  }, [gftid])
  useEffect(() => {
    // if (groupForTask.taskId) dispatch(getBaiHocApi(groupForTask.taskId))

    if (groupForTask.mentorId) dispatch(getThongTinMentorApi(groupForTask.mentorId))

    if (arrDanhSachHocVien.length) dispatch(layDsNguoiDungByStringApi(arrDanhSachHocVien))

    if (groupForTask.mentorId) dispatch(getDsNopBaiByGroupApi(Number(gftid), groupForTask.mentorId))
  }, [groupForTask])
  useEffect(() => {
    if (baiHoc.noiDung && !checkRequireForStudents)
      dispatch(
        setRequireForStudentsByJson(baiHoc.noiDung)
        // setRequireForStudentsByJson(data4)
      )
    if (baiHoc.noiDung) {
      dispatch(setRequires(baiHoc.noiDung))
      // dispatch(setRequires(data4));
    }
  }, [baiHoc])

  useEffect(() => {
    const data = { ...buildDataDND(arrDSNguoiDung, requireForStudents) }
    setData(data)
  }, [requireForStudents, arrDSNguoiDung])

  useEffect(() => {
    if (dsNopBaiByGroup.length) {
      setDataSource(() => {

        let uniqueRequireIdSet = new Set();
        let filteredArray = dsNopBaiByGroup.filter(obj => {

          if (uniqueRequireIdSet.has(obj.requireId)) {
            return false;
          } else {
            uniqueRequireIdSet.add(obj.requireId);
            return true;
          }
        });
        //Render

        return filteredArray.map((e, i) => {
          return { ...e, key: '' + i }
        })
      })
    }
  }, [dsNopBaiByGroup])



  return (
    <TaskCss>
      <div className='head'>
        <span >

        </span>
        <span onClick={handOpenDs}>
          Danh Sách Thành Viên Nhóm <i className='fa-solid fa-arrow-right'></i>
        </span>
      </div>
      <div className='body'>
        <div className='top d-flex'>
          <div className='thongtinduan col-3'>
            <div className='title'>
              <i className='fa-solid fa-list'></i>
              <p>Thông Tin Dự Án</p>
            </div>
            <div className='content'>
              <div className='content__item'>
                <p>Task</p>
                <p>Task 1</p>
              </div>
              <div className='content__item'>
                <p>Deadline</p>
                <p>Còn 28 ngày</p>
              </div>
              <div className='content__item'>
                <p>Tổng Số Nhiệm Vụ</p>
                <p>3</p>
              </div>
              <div className='content__item'>
                <p>Số nhiệm vụ đã chia</p>
                <p>3</p>
              </div>
              <div className='content__item'>
                <p>Số Thành Viên Tối Đa</p>
                <p>3</p>
              </div>
              <div className='content__item'>
                <p>Số Thành Viên Hiện Tại</p>
                <p>2</p>
              </div>
            </div>
          </div>

          <div className='phancongviec col-9'>
            <div className='d-flex justify-content-around align-items-center'>
              <div className='title'>
                <i className='fa-solid fa-bars-staggered'></i> Bảng Phân Công Các Công Việc
              </div>
              <div
                className='action d-flex'
                onClick={() => {
                  setOpenThemSuaThanhVien(true)
                }}
              >
                Sửa Thành Viên Dự Án
              </div>
            </div>
            <div className='chiacongviec'>
              <DragDropContext onDragEnd={onDragEnd}>
                <XDrop className='column-content' droppableId='all-columns' type='COLUMN' direction='horizontal'>
                  {data.columnOrder.map((columnId, index) => {
                    const column = data.columns[columnId]
                    const tasks = column.taskIds.map((taskId) => data.tasks[taskId])
                    return (
                      <XDrag
                        className={`col-${12 / data.columnOrder.length}`}
                        key={columnId}
                        draggableId={columnId}
                        index={index}
                        dragAll={false}
                      >
                        <XColumn column={column} tasks={tasks} />
                      </XDrag>
                    )
                  })}
                </XDrop>
              </DragDropContext>
            </div>
          </div>
        </div>
        <div className='bottom'>
          <div className='table'>
            <TableCSS>
              <Table
                // scroll={{ x: 1500, y: 300 }}
                columns={columns}
                dataSource={dataSource}
                className='tableGFT'
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      const { key, ...nopBai } = record

                      dispatch(setNopBaiState(nopBai))

                      showModal()
                    } // click row
                    // onDoubleClick: (event) => {
                    //   console.log("2 click");
                    // }, // double click row
                    // onContextMenu: (event) => {}, // right button click row
                    // onMouseEnter: (event) => {}, // mouse enter row
                    // onMouseLeave: (event) => {}, // mouse leave row
                  }
                }}
              />
            </TableCSS>
          </div>
        </div>
      </div>
      <CustomDrawer
        title={<h6 style={{ color: 'white', textDecoration: 'underline' }}>Thêm Sửa Danh Sách Thành Viên Nhóm</h6>}
        open={openThemSuaThanhVien}
        placement='left'
        setOpen={setOpenThemSuaThanhVien}
        key={'Them sưa thanh vien'}
        children={dsChinhSuaHocVien()}
      />
      <CustomDrawer
        title={<h6 style={{ color: 'white', textDecoration: 'underline' }}>Danh Sách Thành Viên Nhóm</h6>}
        open={openDSTV}
        placement='right'
        setOpen={setOpenDSTV}
        key={'danh sach thanh vien'}
        children={dsThanhVien()}
      />
      <ChamBai open={openModal} setOpen={setOpenModal} onCancel={handleCancelModal} key={'cahm bai'} />
    </TaskCss>
  )
}
export default Task
