import { Badge, Button, Calendar, Col, Divider, Drawer, Input, InputRef, Modal, Row, Skeleton, Space, Tag, Tooltip, message } from 'antd'
import { ColumnsType } from 'antd/es/table';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Table } from 'antd';
import { DispatchType, RootState } from '../../../redux/configStore';
import { useDispatch } from 'react-redux';
import { getChuyenDeHocApi, getDiemDanhLopApi, getUserChuyenDeApi } from '../../../redux/ChuyenDeReducer/chuyenDeReducer';
import { useSelector } from 'react-redux';
import { dinhDangNgay, dinhDangNgayCheck } from '../../../util/dateFormat';
import { classService } from '../../../services/classService';
import { FilterConfirmProps } from 'antd/es/table/interface';
import { ColumnType } from 'antd/es/list';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { nguoiDungService } from '../../../services/nguoiDungService';
import dayjs, { Dayjs } from 'dayjs';

type Props = {
    chuyenDeId: number,
    metaData: any
}
type TypeData = {
    [key: string]: any
}


const DanhSachThanhVien = ({
    chuyenDeId,
    metaData
}: Props) => {

    // search

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    const [childrenDrawer, setChildrenDrawer] = useState(false);


    const showChildrenDrawer = () => {
        setChildrenDrawer(true);
    };

    const onChildrenDrawerClose = () => {
        setChildrenDrawer(false);
    };


    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: any,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: any): any => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    // end search


    const [open, setOpen] = useState(false);
    const { arrUserChuyenDe, arrDiemDanhLop } = useSelector((state: RootState) => state.chuyenDeReducer);
    const [userInfo, setUserInfo] = useState<any>(null);
    const [diemDanhUser, setDiemDanhUser] = useState<any>(null);

    const [data, setData] = useState<TypeData>({});

    const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {

        const { name, value } = event.target;

        setData(prevData => ({
            ...prevData,
            [name]: value
        }));

    }


    const dispatch: DispatchType = useDispatch();


    let dNow = new Date();

    const [openDraw, setOpenDraw] = useState(false);

    const showDrawer = () => {
        setOpenDraw(true);
    };

    const onClose = () => {
        setOpenDraw(false);
    };


    const columns: ColumnsType<any> = [
        {
            title: 'Họ tên',
            ...getColumnSearchProps('hoTen'),
            dataIndex: "hoTen",
            render: (_, record) => {

                return <label>
                    {record.hoTen}
                </label>
            }
        },
        {
            title: 'Ngày gia nhập',
            render: (_, record) => {

                return <label>
                    <Tag>  {dinhDangNgay(record.ngayBatDauHoc)}</Tag>
                </label>
            }
        },
        {
            title: 'Hoạt động',
            render: (_, record) => {

                return <label>
                    <button className=' btn btn-sm btn-success' onClick={() => {
                        showDrawer();
                        setUserInfo(record);
                        setDiemDanhUser(null);

                        nguoiDungService.getDiemDanhUser(record.nguoiDungId).then(res => {
                            setDiemDanhUser(res.data.content)

                        })
                    }}>Xem chi tiết</button>
                </label>
            }
        },

    ];



    let metaDatachuyenDe = metaData ? JSON.parse(metaData) : null;

    // canlendar
    const getListData = (value: any) => {


        const format = 'DD/MM/YYYY HH:mm:ss';


        let listData;
        diemDanhUser.map((item: any) => {
            const date = dayjs(item.ngayDiemDanh, format);
            const metaData = item.metaData ? JSON.parse(item.metaData) : null

            if (dinhDangNgayCheck(date) == dinhDangNgayCheck(value)) {
                listData = [
                    { content: metaData.coMat == true && 'Có mặt', timeOnline: metaData.coMat == true && `${date.hour()}:${date.minute()}`, ghiChu: metaData.ghiChu },
                ];
            }


        })


        return listData || [];
    };


    const dateCellRender = (value: any) => {

        const listData = getListData(value);

        return <div className='row'>
            <div className='col-8'>
                {listData.map((item: any) =>
                    <>

                        {item.content && < Tag color="green">
                            {item.content}
                        </Tag >
                        }
                        {
                            item.timeOnline && < Tag color='red'>
                                Online: {item.timeOnline}
                            </Tag >
                        }
                        <br />
                        <Badge status="error" text={item.ghiChu} />
                    </>
                )}
            </div>
            <div className='col-4'>
                <Tooltip title="Ghi chú">
                    <Tag color='blue' onClick={() => onSelect(value)}><i className='fa fa-edit'></i></Tag>
                </Tooltip>
            </div>
        </div>

    }


    const cellRender: any['cellRender'] = (current: any, info: any) => {
        if (info.type === 'date') return dateCellRender(current);
        // if (info.type === 'month') return monthCellRender(current);
        return info.originNode;

    };
    let thongTinMoRong: any = {};
    if (userInfo) {
        thongTinMoRong = JSON.parse(userInfo.thongTinMoRong);
    }


    const onSelect = (newValue: Dayjs) => {
        showChildrenDrawer()

        setData({ ...data, time: newValue, userId: userInfo.nguoiDungId })
    };

    const saveData = () => {
        let model = {
            nguoiDungId: data.userId,
            time: data.time,
            ghiChu: data.ghiChu
        }

        nguoiDungService.themDiemDanhghiChu(model).then(res => {
            nguoiDungService.getDiemDanhUser(data.userId).then(res => {
                setDiemDanhUser(res.data.content)

            })
            message.success("Đã lưu");
        }).catch(err => {

            message.error(err.response.data.message)
        })
    }

    return (
        <div className='col-2'>
            {
                metaDatachuyenDe && metaDatachuyenDe.loaiChuyenDe == "INTERN" && <button className='btn btn-sm btn-success mb-3' onClick={() => {
                    setOpen(true)
                    dispatch(getUserChuyenDeApi(Number(chuyenDeId)))
                    dispatch(getDiemDanhLopApi(Number(chuyenDeId)))

                }}> Danh sách </button>
            }


            <Modal
                title="Điểm danh"

                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={"60%"}
                footer={null}
            >
                {arrUserChuyenDe ?
                    <Table
                        columns={columns}
                        dataSource={arrUserChuyenDe}

                    />

                    :

                    <Skeleton />
                }


            </Modal>

            <Drawer title="Thông tin user" placement="right" width="60%" onClose={onClose} open={openDraw}>
                <Row >
                    <Col span={8}>
                        <h5>
                            <b>Họ tên: </b> {userInfo?.hoTen}
                        </h5>
                    </Col>
                    <Col span={8}>
                        <h5>
                            <b>Email: </b> {userInfo?.email}
                        </h5>
                    </Col>
                    <Col span={8}>
                        {thongTinMoRong.FacebookUrl ?
                            <a className='btn btn-sm btn-primary' href={thongTinMoRong.FacebookUrl}>Facebook</a>
                            :
                            <h5>Chưa có facebook</h5>
                        }
                    </Col>
                </Row>
                <Divider />
                {diemDanhUser ?
                    <>
                        <Calendar cellRender={cellRender} />
                    </>
                    :
                    <Skeleton />
                }

                <Drawer
                    title="Thông tin"
                    width="30%"
                    closable={false}
                    onClose={onChildrenDrawerClose}
                    open={childrenDrawer}
                >
                    <Row >
                        <Col span={24} >
                            <h5>
                                <b>Họ tên: </b> {userInfo?.hoTen}
                            </h5>
                        </Col>
                        <Divider />

                        <Col span={24}>
                            <h5>
                                <b>Email: </b> {userInfo?.email}
                            </h5>
                        </Col>
                        <Divider />

                        <Col span={24}>
                            {thongTinMoRong.FacebookUrl ?
                                <a className='btn btn-sm btn-primary' href={thongTinMoRong.FacebookUrl}>Facebook</a>
                                :
                                <h5>Chưa có facebook</h5>
                            }
                        </Col>
                    </Row>
                    <Divider />
                    <b>Nhập Ghi chú: </b>
                    <textarea className='form-control' onChange={(e: any) => onChangeInput(e)} name="ghiChu" value={data.ghiChu}></textarea>
                    <Divider />
                    <button className='btn btn-primary' onClick={saveData}> Lưu</button>
                </Drawer>
            </Drawer>
        </div>
    )
}

export default DanhSachThanhVien