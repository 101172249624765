import { PackageType } from "../Types/quanLyGoi";
import { http } from "../util/config";

const GET_PATH: string = "/api/package";

const getDanhSachGoi=() => { 
    return http.get(`${GET_PATH}`);
}
const postDanhSachGoi=(data:PackageType) => { 
    return http.post(`${GET_PATH}`,data);
}
const deletePackageService=(id:number) => { 
    return http.delete(`${GET_PATH}/${id}`);
}
const editPackageService=(data:PackageType) => { 
    return http.put(`${GET_PATH}`,data);
}
const getUserGoiService=(data:string,key:string) => { 
    return http.post(`/api/dangkyhoc/get-users-chuyendeIds?ids=${data}&key=${key}`);
}
const giaHanGoiService=(data:any) => { 
    return http.post(`/api/dangkyhoc/giaHanGoi`,data);
}
export const packageService={
    getDanhSachGoi,
    postDanhSachGoi,
    deletePackageService,
    editPackageService,
    getUserGoiService,
    giaHanGoiService
}