import { BaiHocModel } from "../Types/baiHocType";
import { BaiTestModel } from "../Types/heThongType";
import { http } from "../util/config";


// bai test
const layDanhSachBaiTest = () => {
  return http.get("/api/bai-test/get-bai-test");
};


const layExportExelTest = (model: BaiTestModel[]) => {
  return http.post("/api/bai-test/report-test", model);
};

// lay nhom quyen

const layNhomQuyen = () => {
  return http.get("/api/nhom-quyen");
};

export const heThongService = {
  layDanhSachBaiTest,
  layExportExelTest,
  layNhomQuyen
};
