import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChatModel, GroupChatModel, MESSModel,MessModel } from '../../Types/groupChatType'
import { DispatchType } from '../configStore'
import jsonB from 'json-bigint'
import { groupChatService } from '../../services/groupChatService'
import { HubConnection } from '@microsoft/signalr'

interface groupChatState {
  connection:any
  groupChats: GroupChatModel[]
  groupChatsByChuyenDe: GroupChatModel[]
  chats: ChatModel[]
  lastGroupChat: GroupChatModel
  lastChat: ChatModel[]
  userId: number
  joinRoom: { room: string; taskid: number }
  joinRoom2: { room: string; chuyenDeId: number }
}

const initialState: groupChatState = {
  connection:null,
  groupChats: [],
  groupChatsByChuyenDe: [],
  chats: [],
  lastGroupChat: {
    id: 1,
    danhSachChat: '',
    noiDungChat: '',
    ngayTao: '',
    groupForTaskId: 0,
    chuyenDeId: 0,
    issueId: 0
  },
  lastChat: [],
  userId: 0,
  joinRoom: { room: '', taskid: 0 },
  joinRoom2: { room: '', chuyenDeId: 0 }
}

const groupChatReducer = createSlice({
  name: 'groupChat',
  initialState,
  reducers: {
    setConnection:(state:groupChatState,action:PayloadAction<any>)=>{
      state.connection={...action.payload};
      console.log('change Conn ',state.connection);
    },
    getGroupChatByDsChat: (state: groupChatState, action: PayloadAction<GroupChatModel[]>) => {
      state.groupChats = [...action.payload]
      console.log(state.groupChats && state.groupChats.length != 0, 'task')
      if (state.groupChats && state.groupChats.length !== 0) {
        state.chats = action.payload.map((gc) => jsonB.parse(gc.noiDungChat)).flat()
        state.lastGroupChat = state.groupChats[state.groupChats.length - 1]
        const lastChat = [...jsonB.parse(state.lastGroupChat.noiDungChat)]
        state.lastChat = lastChat
      }
      else
      state.chats=[];
    },
    getGroupChatByDsChatByChuyenDeId: (state: groupChatState, action: PayloadAction<GroupChatModel[]>) => {
      state.groupChatsByChuyenDe = [...action.payload]
      console.log(state.groupChatsByChuyenDe && state.groupChatsByChuyenDe.length != 0, 'chuyende')
      if (state.groupChatsByChuyenDe && state.groupChatsByChuyenDe.length > 0) {
        state.chats = action.payload.map((gc) => jsonB.parse(gc.noiDungChat)).flat()
        state.lastGroupChat = state.groupChatsByChuyenDe[state.groupChatsByChuyenDe.length - 1]
        const lastChat = [...jsonB.parse(state.lastGroupChat.noiDungChat)]
        state.lastChat = lastChat
      } else state.chats = []
    },
    setChats: (state: groupChatState, action: PayloadAction<ChatModel[]>) => {
      state.chats = action.payload
    },
    addGroupChat: (state: groupChatState, action: PayloadAction<number>) => {
      console.log(action.payload)
    },
    editGroupChat: (state: groupChatState, action: PayloadAction<number>) => {
      console.log(action.payload)
    },
    addUserId: (state: groupChatState, action: PayloadAction<number>) => {
      state.userId = action.payload
      localStorage.setItem('userId', jsonB.stringify(action.payload))
    },
    setJoinRoom: (state: groupChatState, action: PayloadAction<{ room: string; taskid: number }>) => {
      state.joinRoom = { ...action.payload }
      localStorage.setItem('currentRoom', JSON.stringify(action.payload))
    },
    setJoinRoom2: (state: groupChatState, action: PayloadAction<{ room: string; chuyenDeId: number }>) => {
      state.joinRoom2 = { ...action.payload }
      localStorage.setItem('currentRoom', JSON.stringify(action.payload))
    }
  }
})

export const {
  setConnection,
  getGroupChatByDsChat,
  getGroupChatByDsChatByChuyenDeId,
  addGroupChat,
  editGroupChat,
  addUserId,
  setJoinRoom,
  setJoinRoom2,
  setChats
  // setConnectionId,
  // addConnection,
  // removeConnection,
} = groupChatReducer.actions
export default groupChatReducer.reducer

//!--------- action async ------------
export const getGroupChatByDsChatGFTApi = (taskid: number) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await groupChatService.getGroupChatByDSChatGFT(taskid)
      console.log(result);
      if (result) {
        const action: PayloadAction<GroupChatModel[]> = getGroupChatByDsChat(result.data.content)
        dispatch(action)
      } else dispatch(setChats([]))
    } catch (error) {
      dispatch(setChats([]))
      console.log('getGroupChatByDsChatApi', error)
    }
  }
}
export const getGroupChatByChuyenDeIdAndHocVienIdApi = (chuyenDeId: number, hocVienId: string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await groupChatService.getGroupChatByChuyenDeIdAndHocVienId(chuyenDeId, hocVienId)

      if (result) {
        const action: PayloadAction<GroupChatModel[]> = getGroupChatByDsChat(result.data.content)
        dispatch(action)
      } else dispatch(setChats([]))
    } catch (error) {
      dispatch(setChats([]))
      console.log('getGroupChatByChuyenDeIdAndHocVienIdApi', error)
    }
  }
}
export const sendmessChuyenDeApi = (hocVienId:string, chuyenDeId:number, issueId:number, mess:MESSModel) => {
  return async () => {
    try {
      const res = await groupChatService.sendmessChuyenDe(hocVienId, chuyenDeId, issueId, mess);
      if (res?.status == 200 || res?.status == 201)
        console.log('Send mess success');
      else
        console.log('Send mess false', res?.status);
    } catch (error) {
      console.error("sendmessChuyenDe falil")
    }
  }
}
export const sendmessGFTApi = (gftId:number, mess:MESSModel) => {
  return async () => {
    try {
      const res = await groupChatService.sendmessGFT(gftId, mess);
      if (res?.status == 200 || res?.status == 201)
        console.log('Send mess success');
      else
        console.log('Send mess false', res?.status);
    } catch (error) {
      console.error("sendmessChuyenDe falil", error)
    }
  }
}







export const getGroupChatByDsChatApi = (room: string, taskid: number) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await groupChatService.getGroupChatByDSChat(room, taskid)

      const action: PayloadAction<GroupChatModel[]> = getGroupChatByDsChat(result.data.content)
      dispatch(action)
    } catch (error) {
      console.log('getGroupChatByDsChatApi', error)
    }
  }
}
//*Láy ds groupchat by chuyende
export const getGroupChatByDsChatByChuyenDeApi = (room: string, chuyenDeId: number) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await groupChatService.getGroupChatByChuyenDeId(room, chuyenDeId)

      const action: PayloadAction<GroupChatModel[]> = getGroupChatByDsChatByChuyenDeId(result.data.content)
      dispatch(action)
    } catch (error) {
      console.log('getGroupChatByDsChatByChuyenDeApi', error)
    }
  }
}
export const postGroupChatApi = (groupChat: GroupChatModel) => {
  return async (dispatch: DispatchType) => {
    try {
      const res = await groupChatService.addGroupChat(groupChat)
      const action: PayloadAction<number> = addGroupChat(res.status)
      dispatch(action)
    } catch (err) {
      console.error('postGroupChatApi', err)
    }
  }
}
export const putGroupChatApi = (groupChatId: number, groupChat: GroupChatModel) => {
  return async (dispatch: DispatchType) => {
    try {
      const res = await groupChatService.editGroupChat(groupChatId, groupChat)
      const action: PayloadAction<number> = editGroupChat(res.status)
      dispatch(action)
    } catch (err) {
      console.error('putGroupChatApi', err)
    }
  }
}
