import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { baiHocService } from "../../services/baiHocService";
import { http } from "../../util/config";
import { DispatchType } from "../configStore";
import { BaiHocModel, BaiHocViewMentorModel } from "../../Types/baiHocType";
import moment from "moment";
import { loTrinhOnlineService } from "../../services/loTrinhOnlineService";
import { LichSuHocTapView, LichSuHocTapViewModel } from "../../Types/lichSuHocTap";
import { lichSuHocTapService } from "../../services/lichSuHocTapService";

type lshtState = {
  dsLichSuHocTap:LichSuHocTapView[]
};
const initialState: lshtState = {
 dsLichSuHocTap:[]
};

const lichSuHocTapReducer = createSlice({
  name: "lichSuHocTapReducer",
  initialState,
  reducers: {
    getLSHTAction: (
      state: lshtState,
      action: PayloadAction<LichSuHocTapView[]>
    ) => {
      state.dsLichSuHocTap = [...action.payload];
    }
  },
});

export const { 
    getLSHTAction
} = lichSuHocTapReducer.actions;
export default lichSuHocTapReducer.reducer;
//--------- action async ------------

export const getDsLSHTApi = (chuyenDeId:number,monHocId:number) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await lichSuHocTapService.layLichSuHocTapMonHocService(chuyenDeId,monHocId);
      const action: PayloadAction<any[]> = getLSHTAction(
        result.data.content
      );
      dispatch(action);
    } catch (error) {
      console.log('lo trinh', error);
    }
  };
};
export const updateDsLSHTApi = (obj:LichSuHocTapViewModel) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await lichSuHocTapService.updateLichSuHocTapMonHocService(obj);
      dispatch(getDsLSHTApi(obj.chuyenDeId,obj.monHocId));
    } catch (error) {
      console.log('lo trinh', error);
    }
  };
};


