import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card, DatePicker, DatePickerProps, Popconfirm, Tag, Tooltip } from "antd";
import { NoiDungMonHoc } from "../../Types/monHocType";
import { BaiHocModel } from "../../Types/baiHocType";
import { listLoaiTask } from "../../util/SelectOption";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/configStore";
import dayjs from "dayjs";
import { fileService } from "../../services/fileService";
import { loTrinhOnlineService } from "../../services/loTrinhOnlineService";
import { DownloadVideo } from "../VideoPlayer/DownloadVideo";


type Props = {
    baiHoc: any,

    STT: number
    setSourceRecord: any,
    setChuongHoc: any,
    showModal: any,
    item: any,
    baiHocDetail: any,
    updateTaskVaoMonHoc: any
}

export const SortableItemVideo = ({
    baiHoc, STT,
    setSourceRecord, setChuongHoc, showModal, item, baiHocDetail,
    updateTaskVaoMonHoc
}: Props) => {


    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: baiHoc?.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: 999,
        cursor: "grab"
    }
    
    let userLogin = localStorage.getItem("USER_LOGIN");
    let nhomQuyen = userLogin ? JSON.parse(userLogin).maNhomQuyen : null;

    return (
        <div className=" m-3 row ">
            <div className='p-3 border col-10 bg-light  drag-hover' ref={setNodeRef} style={style} {...attributes} {...listeners}>
                {STT} - {baiHoc?.tenBaiHoc}
            </div>
            <div className="col-2 border bg-light py-3 rounded-end d-flex">

                <button className='btn btn-sm ms-2 btn-outline-success ' onClick={() => {
                    let layCyberLearn = false;

                    if (baiHoc && baiHoc.metaData) {
                        let metaData = JSON.parse(baiHoc.metaData)
                        if (metaData.videoCyberLearn && metaData.videoCyberLearn)
                            layCyberLearn = true

                    }

                    if (baiHoc && baiHoc.noiDung) {
                        if (layCyberLearn) {
                            loTrinhOnlineService.getVideoFtpOnlineService(baiHoc.noiDung).then(res => {
                                setSourceRecord(res.data)
                            })
                        } else {

                            fileService.layDuongDanVideoService(baiHoc.noiDung).then(res => {

                                setSourceRecord(res.data)
                            }).catch(err => console.log(err))
                        }
                    }
                }}>
                    <i className='fas fa-eye'></i>
                </button>
                {nhomQuyen == "ADMIN" && <>
                    <DownloadVideo item={baiHoc} />

                    <button
                        className='btn btn-sm btn-success mx-2'
                        onClick={() => {
                            setChuongHoc(item)

                            showModal(baiHoc?.id)

                        }}
                    >
                        <i className='fas fa-edit'></i>
                    </button>
                    <Popconfirm
                        title='Bạn có chắc muốn xóa ?'
                        onConfirm={() => {
                            if (baiHoc && baiHoc?.id != baiHocDetail.id) {
                                // remove bai hoc ra khoi chuong

                                updateTaskVaoMonHoc(baiHoc, 'VIDEO', true, item.chuongHoc)
                            }
                        }}
                    >
                        <button className='btn btn-sm btn-danger'>
                            <i className='fas fa-times'></i>
                        </button>
                    </Popconfirm>
                </>}
            </div>
        </div>
    );
}
