import React, { useEffect, useState } from "react";
import "./style.css";
import { Avatar, Badge, Collapse, Select, Tooltip } from "antd";
import { CloseOutlined, ExclamationOutlined, MessageOutlined, UserOutlined } from "@ant-design/icons";
import {
  ChangeActiveChat1,
  ChangeActiveChat2,
  changeCurrentActive,
  setOpenDrawerAciton,
  updateDataChat1,
  updateDataChat2,
} from "../../redux/StackChatReducer/StackChatReducer";
import LoadAvatar from "./components/LoadAvatar";
import { config } from "../../util/config";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { lastName, randomColor } from "../../pages/Mentor/Dashboard/DashBoard";
import { DataGroupChat, groupchatchuyendehocvienId } from "../../Types/groupChatType";
import { ChuyenDeMentorPhuTrachModel } from "../../Types/chuyenDeType";
import { getHinhAnh, getHinhAnh2, getHinhAnhFB } from "../../util/GetHinhanh";

const { Panel } = Collapse;
export const areObjectsEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) {
    return true; // Cùng một tham chiếu đến đối tượng
  }

  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return false; // Một trong hai không phải là đối tượng
  }

  if (obj1 === null || obj2 === null) {
    return false; // Một trong hai là null
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false; // Số lượng thuộc tính khác nhau
  }

  for (let key of keys1) {
    if (!areObjectsEqual(obj1[key], obj2[key])) {
      return false; // Kiểm tra đệ quy cho các thuộc tính lồng nhau
    }
  }

  return true; // Nếu tất cả các kiểm tra trước đó không trả về false, thì hai đối tượng giống nhau.
};

export const isObjectInArray = (array: any[], element: any): boolean => {
  for (const item of array) {
    if (areObjectsEqual(item, element)) {
      return true;
    }
  }
  return false;
};

export const findObjectIndexInArray = (array: any[], element: any): number => {
  return array.findIndex((item) => areObjectsEqual(item, element));
};
const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
// Tạo một số nguyên ngẫu nhiên từ 0 đến 3
const randomNumber = Math.floor(Math.random() * ColorList.length); // 0, 1, 2 hoặc 3

type DanhSachChatType = {
  open: boolean
  closeDrawer: () => void
}
const DanhSachChat: React.FC<DanhSachChatType> = ({ open, closeDrawer }) => {
  const dispatch = useAppDispatch();
  let myUser = config.getStoreJson("USER_INFOR_TECH_X");
  const { ActiveChat1, ActiveChat2, ActiveCurrent, dataChat1, dataChat2 } =
    useAppSelector((state) => state.StackChatReducer);
  const { dsHocVienByMentorId, dsHocVienByMentorIdSet, } = useAppSelector(state => state.nopBaiReducer)
  const { DSChuyenDeMentorPhuTrach } = useAppSelector(state => state.chuyenDeReducer)
  const { issuesMentorPhuTrach } = useAppSelector((state) => state.IssueReducer)
  const { groupForTaskViewMentorModel } = useAppSelector((state) => state.groupForTaskReducer)
  // const { issueHocVienTheoTungChuyenDe } = useAppSelector((state) => state.IssueReducer);
  // const { DSChuyenDeHocVienDangKy } = useAppSelector((state) => state.chuyenDeReducer);
  // const { dsGroupForTaskHocVien } = useAppSelector((state) => state.groupForTaskReducer);
  const [search, setSearch] = useState('')
  const [type, SetType] = useState<number>(0)
  const [countIssue, setCountIssue] = useState<number>(0)
  const [typeCurrent, SetTypetypeCurrent] = useState<number>(0)
  const styleDraw = {
    animation: open ? "" : "",
  };
  const convertTenHocVien = (id: string) => {
    if (dsHocVienByMentorId.length) {
      const index = dsHocVienByMentorId.findIndex((e) => e.id === id)
      if (index !== -1) return dsHocVienByMentorId[index].hoTen
    }
    return ''
  }
  const convertArrTenHocVien = (arr: string) => {
    let dshv: string[] = []
    try {
      dshv = [...JSON.parse(arr)]
    } catch (error) {
      // console.log(error)
    }
    return dshv.length > 0 ? dshv.map((e) => convertTenHocVien(e)).join(' - ') : ''
  }
  const onSearchBt = (value: string) => {
    setSearch(value)
  }
  const handleChangeSelect = (value: string) => {
    // console.log(`selected ${value}`)
    SetType(Number(value))
  }

  const issueChat = (chuyenDeId: number, hocVienId: string) => {
    const inde = issuesMentorPhuTrach.findIndex((e) => e.chuyenDeId === chuyenDeId && e.hocVienId === hocVienId)
    if (inde >= 0) return issuesMentorPhuTrach[inde]
    return null
  }
  const Showds = () => {
    const newDs = dsHocVienByMentorIdSet
      .map((e) => {
        const index = dsHocVienByMentorId.findIndex((nd) => nd.id === e.nguoiDungId)
        if (index !== -1) {
          return { ...dsHocVienByMentorId[index], chuyenDeId: e.chuyenDeId }
        } else return null
      })
      .filter((e): e is Exclude<typeof e, null> => e !== null)


    const renderOptione = () => {
      return DSChuyenDeMentorPhuTrach.map((e) => {
        return { value: e.id, label: e.tenChuyenDe }
      })
    }
    const rendergft = () => {
      const sortedItems = [...groupForTaskViewMentorModel].sort((a, b) => {
        if (!a.isDone && b.isDone) {
          return -1; // a lên đầu
        } else if (a.isDone && !b.isDone) {
          return 1; // b lên đầu
        }
        return 0; // Giữ nguyên thứ tự
      });
      return sortedItems?.map((gr, idx) => {
        return (
          <div
            key={'hvmentor phu trac' + idx}
            className={`person ${gr.isDone ? "" : "HasIssue"}`}
            onClick={() => {

              handleClickGroupChuyenDe(gr)
              // setOpenDrawer(false)
            }}
          >
            <Badge count={0} style={{ position: 'relative' }}>

              <Avatar
                shape='circle'

                src={getHinhAnh2(gr)}
              />
              {!gr.isDone && <div className="dot"></div>}

            </Badge>
            <Tooltip title={`${gr?.tenBaiHoc} ${convertArrTenHocVien(gr?.danhSachHocVien)}`}>
              <p className='person__name'>{`${gr?.tenBaiHoc} ${convertArrTenHocVien(gr?.danhSachHocVien)}`}</p>
            </Tooltip>
            <div className='icon mess'>
              {!gr.isDone && <ExclamationOutlined />}
            </div>
          </div>
        )
      })
    }
    return (
      <Collapse className='collapse_gr'
        defaultActiveKey={["1","2"]}
      >
        <Collapse.Panel
          className="draw_group_chat"
          header="Danh Sách Theo Task"
          key="1"
        >
          <div className='dschat'>
            {rendergft()}
          </div>
        </Collapse.Panel>

        <Collapse.Panel
          className="draw_group_chat"
          header="Danh Sách Theo Chuyên Đề"
          key="2"
        >
          <div className='NoiDungDsHocVien'>
            {/* <Search
          className='inputSearch'
          placeholder='Filter Theo Tên Học Viên ...'
          onChange={(e) => {
            onSearchBt(e.target.value)
          }}
          value={search}
          style={{ width: '100%' }}
        /> */}
            <div className='inputSearch'>
              <i className='fa-solid fa-magnifying-glass menuItem cursor_pointed btnHover'></i>
              <input
                placeholder='Filter Theo Tên Học Viên ...'
                onChange={(e) => {
                  onSearchBt(e.target.value)
                }}
                value={search}
              // onChange={handleInputChange} onKeyPress={handleKeyPress}
              />
            </div>


            <Badge count={countIssue}>
              <Select
                defaultValue={
                  DSChuyenDeMentorPhuTrach && DSChuyenDeMentorPhuTrach.length
                    ? DSChuyenDeMentorPhuTrach[0].tenChuyenDe
                    : 'Chưa có chuyên đề phụ trách'
                }
                style={{ width: 345 }}
                onChange={handleChangeSelect}
                options={renderOptione() ?? []}
              />
            </Badge>
            <div className='dschat'>
              {newDs
                .filter((e: any) => e.chuyenDeId === type)
                .filter((student: any) => student.hoTen.toLowerCase().includes(search.toLowerCase()))
                .map((hv, index) => {
                  // console.log(hv);
                  if (issueChat(hv.chuyenDeId, hv.id)) {
                    return (
                      <div
                        key={'hvmentor phu trac' + index}
                        className={`person HasIssue`}
                        onClick={() => {

                          const index = DSChuyenDeMentorPhuTrach.findIndex((e) => e.id == type)
                          if (index !== -1) {
                            const cd: ChuyenDeMentorPhuTrachModel = DSChuyenDeMentorPhuTrach[index]
                            const newGroupchat: groupchatchuyendehocvienId = { ...cd, hocVienId: hv.id, avatar: hv.avatar, hoTen: hv.hoTen }
                            handleClickGroupChuyenDe(newGroupchat)
                          }

                          // setOpenDrawer(false)
                        }}
                      >
                        <Badge count={0} style={{ position: 'relative' }}>
                          {hv?.avatar ? (
                            <Avatar shape='circle' src={getHinhAnhFB(hv.avatar)} />
                          ) : (
                            <Avatar
                              shape='circle'
                              style={{
                                backgroundColor: randomColor(),
                                fontSize: '10px'
                              }}
                            >
                              {lastName(hv?.hoTen)}
                            </Avatar>
                          )}
                          <div className="dot"></div>
                        </Badge>
                        <p className='person__name'>{hv?.hoTen}</p>
                        <div className='icon mess'>
                          {/* <MessageOutlined /> */}
                          <ExclamationOutlined />
                        </div>
                      </div>
                    )
                  }
                })}
              {newDs
                .filter((e: any) => e.chuyenDeId === type)
                .filter((student: any) => student.hoTen.toLowerCase().includes(search.toLowerCase()))
                .map((hv, index) => {
                  // console.log(hv);
                  if (!issueChat(hv.chuyenDeId, hv.id)) {
                    return (
                      <div
                        key={'hvmentor phu trac' + index}
                        className={`person`}
                        onClick={() => {

                          const index = DSChuyenDeMentorPhuTrach.findIndex((e) => e.id == type)
                          if (index !== -1) {
                            const cd: ChuyenDeMentorPhuTrachModel = DSChuyenDeMentorPhuTrach[index]
                            const newGroupchat: groupchatchuyendehocvienId = { ...cd, hocVienId: hv.id, avatar: hv.avatar, hoTen: hv.hoTen }
                            handleClickGroupChuyenDe(newGroupchat)
                          }

                          // setOpenDrawer(false)
                        }}
                      >
                        <Badge count={0} className="relative">
                          {hv?.avatar ? (
                            <Avatar shape='circle' src={hv?.avatar} />
                          ) : (
                            <Avatar
                              shape='circle'
                              style={{
                                backgroundColor: randomColor(),
                                fontSize: '10px'
                              }}
                            >
                              {lastName(hv?.hoTen)}
                            </Avatar>
                          )}

                        </Badge>
                        <p className='person__name'>{hv?.hoTen}</p>
                        <div className='icon mess'>
                          <MessageOutlined />
                        </div>
                      </div>
                    )
                  }
                })}
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>
    )
  }

  // const renderGroupAvatar= (arr)=>{
  //  return arr.map((e,i)=>{
  //     return    <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
  //   })
  // }

  const PushDataTabChat = async (e: DataGroupChat) => {
    // if(myUser){
    //   if(e?.dsMentor)
    //   await config.setStoreJson("ApiChat2",{chuyenDeId:e?.id,hocVienId:myUser?.id})
    //   else if(e?.danhSachHocVien)
    //   await config.setStoreJson("ApiChat1",{gftId:e?.id})
    // }
    if (areObjectsEqual(e, dataChat1)) {
      dispatch(ChangeActiveChat1());
    } else if (areObjectsEqual(e, dataChat2)) {
      dispatch(ChangeActiveChat2());
    } else {
      if (ActiveCurrent) {
        dispatch(updateDataChat2(e));
        !ActiveChat2 && dispatch(ChangeActiveChat2());
        dispatch(changeCurrentActive());
      } else {
        dispatch(updateDataChat1(e));
        !ActiveChat1 && dispatch(ChangeActiveChat1());
        dispatch(changeCurrentActive());
      }
    }
    // if (areObjectsEqual(e, dataChat1)) {
    //   !ActiveChat1 && dispatch(ChangeActiveChat1());
    // } else if (areObjectsEqual(e, dataChat2)) {
    //   !ActiveChat2 && dispatch(ChangeActiveChat2());
    // } else {
    //   if (ActiveCurrent) {
    //     dispatch(updateDataChat2(e));
    //     dispatch(ChangeActiveChat2());
    //     dispatch(changeCurrentActive());
    //   } else {
    //     dispatch(updateDataChat1(e));
    //     dispatch(ChangeActiveChat1());
    //     dispatch(changeCurrentActive());
    //   }
    // }


  };

  const handleClickGroupChuyenDe = async (e: DataGroupChat | any) => {

    await PushDataTabChat(e);
    const danhSachPopup = config.getStoreJson("LIST_POPUP") as DataGroupChat[];
    if (danhSachPopup) {

      if (!isObjectInArray(danhSachPopup, e)) {
        // console.log('check popup',!danhSachPopup.includes(e),!isObjectInArray(danhSachPopup,e));
        // console.log('thêm vào ' );
        danhSachPopup.unshift(e);
      }
      if (danhSachPopup.length > 4) {
        // console.log('xoa di');
        danhSachPopup.pop()
      }
      config.setStoreJson("LIST_POPUP", [...danhSachPopup]);
    }
    else
      config.setStoreJson("LIST_POPUP", [e]);




    //CheckTab

    // Nếu ko có issue
    //Đóng draw
    closeDrawer();
  };


  useEffect(() => {
    if (
      DSChuyenDeMentorPhuTrach &&
      DSChuyenDeMentorPhuTrach.length > 0 &&
      typeCurrent !== DSChuyenDeMentorPhuTrach[0].id
    ) {
      SetType(DSChuyenDeMentorPhuTrach[0].id)
      SetTypetypeCurrent(DSChuyenDeMentorPhuTrach[0].id)
    }
  }, [DSChuyenDeMentorPhuTrach])
  useEffect(() => {
    const newDs = dsHocVienByMentorIdSet?.map((e) => {
      const index = dsHocVienByMentorId.findIndex((nd) => nd.id === e.nguoiDungId)
      if (index !== -1) {
        return { ...dsHocVienByMentorId[index], chuyenDeId: e.chuyenDeId }
      } else return null
    })
      .filter((e): e is Exclude<typeof e, null> => e !== null)
    const count: number = newDs.filter((e: any) => e.chuyenDeId === type && issueChat(Number(type), e.id)).length
    // console.log('count', count)
    setCountIssue(count)
  }, [type])
  return (
    <>{Showds()}</>
  );
  // else
  // return <></>
};

export default DanhSachChat;
