import React from "react";
import styled from "styled-components";
import { useAppSelector } from "../../../redux/hooks";
import {
  GroupOutlined,
  FileDoneOutlined,
  FrownOutlined,
  FundProjectionScreenOutlined,
  PicRightOutlined,
  FieldTimeOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Skeleton } from "antd";
import { RequireForStudentModel } from "../../../Types/RequireForStudent";
import { dinhDangNgayCheck } from "../../../util/dateFormat";

const ProjectGuideCSS = styled.section`
  .table {
    --bs-table-striped-bg: rgb(190 86 17 / 20%);
  }
`;

const ProjectGuide: React.FC = () => {
  const { arrDanhSachHocVien, requireForStudents, arrDSNguoiDung } =
    useAppSelector((state) => state.groupForTaskReducer);

  const {dsNopBaiByGroup}= useAppSelector(state=>state.nopBaiReducer)  
  // console.log(requireForStudents);

  const checkNopBai = (requireId:number)=>{
    return dsNopBaiByGroup.map(e=>e.requireId).includes(requireId)
  }

  const showNameHocVien = (hocvienId: string) => {
    // console.log(hocvienId);
    // console.log(arrDSNguoiDung);
    const index = arrDSNguoiDung.findIndex((e) => e.id === hocvienId);
    if (index !== -1) return arrDSNguoiDung[index].hoTen;
    return "Chưa cập nhật";
  };

  const showRowTable = (arr: RequireForStudentModel[]) => {
    return arr.map((rq, index) => {
      return (
        <tr key={`tr cham bai ${index}`}>
          <td>{rq.requireId}</td>
          <td>{dinhDangNgayCheck(rq.deadline)}</td>
          <td>{showNameHocVien(rq.studentId)}</td>
          <td>
            {checkNopBai(Number(rq.requireId)) ? (
              <FileDoneOutlined
                style={{ color: "#52c41a" }}
                twoToneColor="#52c41a"
              />
            ) : (
              <FrownOutlined
                style={{ color: "#eb2f96" }}
                twoToneColor="#eb2f96"
              />
            )}
          </td>
        </tr>
      );
    });
  };



  return (
    <ProjectGuideCSS>
      <h4 className="heading">
        <GroupOutlined /> Chấm Bài
      </h4>
      {arrDanhSachHocVien && arrDanhSachHocVien.length ? (
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Task</th>
              <th scope="col">Deadline</th>
              <th scope="col">Member</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <PicRightOutlined />
              </td>
              <td>
                <FieldTimeOutlined />
              </td>
              <td>
                <TeamOutlined />
              </td>
              <td>
                <FundProjectionScreenOutlined />
              </td>
            </tr>
            {showRowTable(requireForStudents)}
          </tbody>
        </table>
      ) : (
        <Skeleton active />
      )}
    </ProjectGuideCSS>
  );
};
export default ProjectGuide;
