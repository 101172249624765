import {
  Button,
  Popconfirm,
  Switch,
  Tabs,
  TabsProps,
  Tag,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import TableQuanLiUser from "../TableQuanLiUser/TableQuanLiUser";
import { NguoiDungType } from "../../Types/nguoiDungType";
import { FacebookOutlined } from "@ant-design/icons";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getNguoiDungChinhSuaAction,
  getNguoiDungPhanTrangApi,
} from "../../redux/UserReducer/userReducer";
import useQueryConfig from "../../hooks/useQueryConfig";
import { nguoiDungService } from "../../services/nguoiDungService";
import { createSearchParams, useNavigate } from "react-router-dom";
import GhiDanh from "../GhiDanh/GhiDanh";
import { URL_PAGE_LEARN } from "../../util/urlDomain";
import DanhSachDoiQua from "./DanhSachDoiQua";
import { NhomQuyenModel } from "../../Types/heThongType";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const TabsQuanLiUser = ({ open, setOpen }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const queryConfig = useQueryConfig();
  const { userPhanTrang } = useAppSelector((state) => state.userReducer);
  //gọi lấy danh sách người dùng
  useEffect(() => {
    dispatch(getNguoiDungPhanTrangApi(queryConfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryConfig.keyword,
    queryConfig.pageIndex,
    queryConfig.pageSize,
    queryConfig.keyword,
  ]);

  // xoá người dùng
  const handleDeleteUser = (id: number) => {
    nguoiDungService
      .deleteNguoiDung(id)
      .then((res) => {
        dispatch(getNguoiDungPhanTrangApi(queryConfig));
        messageApi.open({
          type: "success",
          content: "Xoá Thành Công",
        });
      })
      .catch((err) => {
        messageApi.open({
          type: "success",
          content: "Có lỗi xảy ra vui lòng thử lại",
        });
      });
  };

  // update trạng thái người dùng
  const handleUpdateKichHoat = (data: NguoiDungType) => {
    nguoiDungService
      .updateNguoiDung(data)
      .then((res) => {


        dispatch(getNguoiDungPhanTrangApi(queryConfig));
        messageApi.open({
          type: "success",
          content: "Cập nhật trạng thái thành công",
        });
      })
      .catch((err) => {
        messageApi.open({
          type: "success",
          content: "Có lỗi xảy ra vui lòng thử lại",
        });
      });
  };

  // cột của table
  const columns: ColumnsType<NguoiDungType> = [
    {
      title: "Họ Tên",
      dataIndex: "hoTen",
      key: "hoTen",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Nguồn",
      dataIndex: "maNguonDoiTac",
      key: "maNguonDoiTac",
      render: (text) => text && <Tag color="green">{text}</Tag>,
    },
    {
      title: "Số Đt",
      dataIndex: "soDt",
      key: "soDt",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Facebook",
      render: (text) => {
        let thongTin = JSON.parse(text.thongTinMoRong);

        return (
          <a href={thongTin.FacebookUrl} target="_blank" style={{ textDecoration: "none" }}>
            <Tag icon={<FacebookOutlined />} color="#3b5999">
              Facebook
            </Tag>
          </a>
        )
      }
    },
    {
      title: "Ngày tạo",
      dataIndex: "ngayTao",
      key: "ngayTao",
      render: (text) => (
        <div>
          <span>{moment(text).format("DD-MM-YYYY")}</span>
        </div>
      ),
    },
    {
      title: 'Tạo form',
      dataIndex: 'id',
      key: 'taoForm',
      render: (id, rec, idx) => {
        return <Tooltip trigger='click' title="Đã copy">
          <Button className='ms-2' icon={<i className="fa-solid fa-arrow-up-from-bracket"></i>} onClick={() => {
            navigator.clipboard.writeText(`https://login.codezuni.com/form-tuvan?id=${id}`);
          }}>Copy</Button>
        </Tooltip>
      }
    },
    // {
    //   title: <div className="text-center">Link giới thiệu</div>,
    //   dataIndex: "gioiThieu",
    //   key: "gioiThieu",
    //   render: (content, record, index) => {

    //     return <div className="text-center">
    //       <Tooltip title="Đã copy" trigger="click">

    //         <button className="btn btn-sm btn-primary" onClick={() => {
    //           navigator.clipboard.writeText(`${URL_PAGE_LEARN}/signup?reffer=${record.id}`);

    //         }}><i className="fa-solid fa-share"></i> Lấy Link</button>
    //       </Tooltip>

    //     </div>

    //   },
    // },
    {
      title: <div className="text-center">Kích hoạt</div>,
      dataIndex: "kichHoat",
      key: "kichHoat",
      render: (content, record, index) => {
        // console.log(record);
        return (
          <div className="text-center">
            <Switch
              checked={content ? true : false}
              onChange={(checked) => {
                handleUpdateKichHoat({ ...record, kichHoat: checked, matKhau: "" });
              }}
            />
          </div>
        );
      },
    },
    {
      title: <div className="text-center">Đã ghi danh</div>,
      key: "ghiDAnh",
      render: (_, record) => {

        return <GhiDanh thongTinUser={record} />

      },
    },
    {
      title: <div className="text-center">Đổi quà</div>,
      key: "doiQua",
      render: (_, record) => {

        return <DanhSachDoiQua thongTinUser={record} />

      },
    },
    {
      title: "Hành động",
      dataIndex: "id",
      key: "id",
      render: (id, record, index) => (
        <div>
          <Button
            onClick={() => {
              setOpen(!open);
              dispatch(getNguoiDungChinhSuaAction({ ...record, matKhau: "" }));
            }}
            type="primary"
            icon={<i className="fas fa-pen"></i>}
          ></Button>
          <Popconfirm
            title="Xoá học viên"
            description="Chắc chắn muốn xoá học viên này ?"
            onConfirm={() => {
              handleDeleteUser(id);
            }}
            // onCancel={cancel}
            okText="Có"
            cancelText="Không"
          >
            <Button
              danger
              className="ms-2"
              type="primary"
              icon={<i className="fas fa-trash"></i>}
            // loading={loadings[2]}
            // onClick={() => enterLoading(2)}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];
  const { arrNhomQuyen } = useAppSelector((state) => state.heThongReducer);

  const items: TabsProps["items"] = [];

  if (arrNhomQuyen) {
    let arrQuyen = [...arrNhomQuyen]

    arrQuyen?.sort((a: NhomQuyenModel, b: NhomQuyenModel) => b.id.localeCompare(a.id)).map(item => {
      items.push({
        key: item.id,
        label: item.tenNhom,
        children: (
          <TableQuanLiUser columns={columns} userPhanTrang={userPhanTrang}  />
        ),
      },)
    })
  }



  return (
    <>
      {contextHolder}
      <Tabs
        defaultActiveKey="0"
        items={items}
        onChange={(key: string) => {
          navigate({
            pathname: "/quan-li-user",
            search: createSearchParams({
              ...queryConfig,
              pageSize: "10",
              pageIndex: "1",
              filter: "hoTen",
              maNhomQuyen: key,
            }).toString(),
          });
        }}
      />
    </>
  );
};

export default TabsQuanLiUser;
