import React, { createContext, useContext, useRef, useState } from "react";
import {
  HubConnectionBuilder,
  LogLevel,
  HubConnection,
} from "@microsoft/signalr";
import { MessModel, MessModelByChuyenDe } from "../Types/groupChatType";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getGroupChatByDsChatApi, getGroupChatByDsChatByChuyenDeApi } from "../redux/GroupChatReducer/groupChatReducer";

import { ACCESS_TOKEN } from "../util/config";
import { MessageModel, UserConnection } from "../Types/messageType";
import { API_URL_DOMAIN } from "../util/urlDomain";
import { number, string } from "yup";
import { NguoiDungGroupForTask } from "../Types/groupForTask";
import { ConnectionModal, ConnectionModal2 } from "../Types/connectionType";
import { message } from "antd";

interface AuthProviderProps {
  children: React.ReactNode;
}
export interface Context {
  connection:null|HubConnection
  setConnection: React.Dispatch<React.SetStateAction<null|HubConnection>>
}

const AuthContext = createContext<Context>({
  connection: null,
  setConnection:()=>Promise<void>
});

const apiUrl = API_URL_DOMAIN;

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [connection,setConnection]= useState<null|HubConnection>(null)
  //Khai báo values context
  const Values = {
    connection,
    setConnection
  };

  return <AuthContext.Provider value={Values}>{children}</AuthContext.Provider>;
};

export default function UseConnection() {
  return useContext(AuthContext);
}
