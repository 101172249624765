import React from 'react';
import { Navigate, Route, RouteProps } from 'react-router-dom';
import { getStoreJson, removeStore } from './config';
import { NguoiDungType } from '../Types/nguoiDungType';
import { nguoiDungService } from '../services/nguoiDungService';

interface PrivateRouteProps  {
  path?:string,
  role: "ADMIN"|"MENTOR"|"NONE"|"GIANGVIEN"; // Vai trò cần kiểm tra
  element: JSX.Element,
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ role,element,path, ...rest }) => {
  const user = getStoreJson("USER_LOGIN") as NguoiDungType; // Giả sử vai trò của người dùng được lưu trong biến ROLE

  // Kiểm tra xem người dùng có đúng vai trò không

  if(!user)
  {
   return <Navigate to="/login" />;
  } 
  // else {
  //   try {
  //     nguoiDungService.checkPassNguoiDung().then((res) => {
  //       if (res?.data?.content === "0") {
  //         removeStore("USER_LOGIN");
  //         removeStore('path')
  //         return <Navigate to="/login" />;
  //       }
  //     });
  //   } catch (error) {
  //     console.log('checkPassNguoiDung',error);
  //     removeStore('path')
  //     return <Navigate to="/login" />;
  //   }
  // }

  // if (user?.maNhomQuyen === 'ADMIN') {
  //   if (window.location.pathname === "/") {
  //     // Nếu vai trò là "ADMIN" và path là "/", điều hướng đến "/home"
  //     return <Navigate to="/home" />;
  //   }
  // } else if (user?.maNhomQuyen === 'MENTOR') {
  //   if (window.location.pathname === "/") {
  //     // Nếu vai trò là "MENTOR" và path là "/", điều hướng đến "/mentor"
  //     return <Navigate to="/mentor" />;
  //   }
  // }

  // if (user?.maNhomQuyen !== role) {
  //   if (user?.maNhomQuyen === 'ADMIN') {
  //     return <Navigate to="/home" />;
  //   }
  
  
  //   // Nếu không đúng vai trò, điều hướng về trang Dashboard nếu là Mentor
  //   else if (user?.maNhomQuyen === 'MENTOR')
  //   return <Navigate to="/mentor" />;
  //   // else if (user?.maNhomQuyen === 'LECTURE')
  //   // return <Navigate to="/ql-chuyende" />;
    
  // } 


  // Điều hướng về trang Home nếu là Admin
  


  return element;
};

export default PrivateRoute;
