import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PackageType } from "../../Types/quanLyGoi";
import { DispatchType } from "../configStore";
import { packageService } from "../../services/quanyGoiService";
import { message } from "antd";
import { NguoiDungType } from "../../Types/nguoiDungType";

type quanLyGoiState = {
    arrGoi: PackageType[] | null,
    arrHV: NguoiDungType[]|null
}

const initialState: quanLyGoiState = {
    arrGoi: [],
    arrHV: []
}

const quanLyGoiReducer = createSlice({
    name: "quanLiGoiReducer",
    initialState,
    reducers: {
        getListGoiAction: (
            state: quanLyGoiState,
            action: PayloadAction<PackageType[]>
        ) => {
            state.arrGoi = [...action.payload]
        },
        getArrHVPackageAction: (
            state: quanLyGoiState,
            action: PayloadAction<NguoiDungType[]>
        ) => {
            state.arrHV = [...action.payload]
        }
    }
})
export const {
    getListGoiAction,
    getArrHVPackageAction
} = quanLyGoiReducer.actions;
export default quanLyGoiReducer.reducer;
export const getAllArrGoiApi = () => {
    return async (dispatch: DispatchType) => {
        const result = await packageService.getDanhSachGoi();

        const action: PayloadAction<any[]> = getListGoiAction(
            result.data.content
        );
        dispatch(action);
    };
};
export const getUserByPackage = (data: string,key:string) => {
    return async (dispatch: DispatchType) => {
        try {
            const result = await packageService.getUserGoiService(data,key);

            const action: PayloadAction<any[]> = getArrHVPackageAction(
                result.data.content
            );
            dispatch(action);
        }
        catch (err) {

        }
    };
};
export const giaHanGoi = (obj:any) => {
    return async (dispatch: DispatchType) => {
        try {
            await packageService.giaHanGoiService(obj);
            message.success("Gia hạn thành công")
        }
        catch (err) {
            
            message.success("Lỗi báo IT ")
        }
    };
};