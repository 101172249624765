import {
  CheckCircleOutlined,
  CloseOutlined,
  MinusOutlined,
  SendOutlined
} from '@ant-design/icons'
import { HubConnection } from '@microsoft/signalr'
import { Avatar, Button, Form, Popover, Tooltip, message, notification } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import InputEmoji from 'react-input-emoji'
import {
  ChangeActiveChat1,
  ChangeActiveChat2,
  GetByDsChatGFTApi,
  GetByDsChatGFTApi2,
  getGroupChatByChuyenDeIdAndHocVienIdApi,
  getGroupChatByChuyenDeIdAndHocVienIdApi2,
  sendmessChuyenDeApi,
  sendmessGFTApi
  // setChats,
  // setChats2,
} from '../../../../redux/StackChatReducer/StackChatReducer'
import { findObjectIndexInArray } from '../../../DanhSachChat/DanhSachChat'
// import { getDSChuyenDeHocVienDangKyApi } from "../../../../redux/ChuyenDeReducer/chuyenDeReducer.tsx";
import {
  getIssueAllMentorPhuTrachApi,
  // getIssueHocVienTheoTungChuyenDeApi,
  // postIssueApi,
  putoptionalApi
} from '../../../../redux/IssueReducer/IssueReducer'
// import { getDsGroupForTaskHocVienApi } from "../../../../redux/GroupForTaskReducer/GroupForTaskReducer.tsx";

import moment from 'moment'
import UseChatConnection from '../../../../hooks/useChatHub'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { getHinhAnhFB } from '../../../../util/GetHinhanh'
import { config } from '../../../../util/config'
import { API_URL_DOMAIN } from '../../../../util/urlDomain'


import { connection } from '../../../..'
import {
  ChatModel,
  DataGroupChat,
  MESSModel,
  groupchatchuyendehocvienId
} from '../../../../Types/groupChatType'
import { GroupForTaskModel2 } from '../../../../Types/groupForTask'
import { TrangThaiIssue } from '../../../../Types/issueType'
import { DangKyHocType, NguoiDungType } from '../../../../Types/nguoiDungType'
import { GetGroupForTaskByMentorIdApi } from '../../../../redux/GroupForTaskReducer/GroupForTaskReducer'
import NotificationSound from '../../../NotificaSound/NotificationSound'
import { chuyenDeService } from '../../../../services/chuyenDeService'

const now = moment()
export const formattedDate = now.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')

//*so sanh ngày truyền vào có format ngay thang nam trung với ngày hiện tại hay không
export const isSameDate = (dateA: string) => {
  const currentDate = new Date()
  const date = new Date(dateA)

  // So sánh ngày, tháng, năm
  return (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getDate() === currentDate.getDate()
  )
}
export const convertTime = (dateTimeString: string) => {
  // const dateTime = new Date(dateTimeString)
  // const hour = dateTime.getHours()
  // const minute = dateTime.getMinutes()
  // let formattedHour = hour % 12
  // if (formattedHour === 0) {
  //   formattedHour = 12
  // }
  // const period = hour >= 12 ? 'PM' : 'AM'
  // return `${formattedHour}:${minute < 10 ? '0' + minute : minute} ${period}`
  var date = new Date(dateTimeString);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var period = hours < 12 ? 'AM' : 'PM';
    hours = hours % 12;
    hours = hours ? hours : 12; // Đặt giờ thành 12 nếu hours === 0
    // Thêm số 0 phía trước nếu minute < 10
    return hours + ':' + minutes + ' ' + period;
}

export const getTodate = () => {
  const currentDate = new Date()
  return currentDate.toLocaleString()
}

export const typeOfIssue = {
  CAN_HO_TRO: 'CAN_HO_TRO',
  DANG_HO_TRO: 'DANG_HO_TRO',
  CHO_PHAN_HOI: 'CHO_PHAN_HOI',
  DA_HO_TRO: 'DA_HO_TRO',
  HO_TRO_LAI: 'HO_TRO_LAI'
}
const apiUrl = API_URL_DOMAIN

type TabChatProps = {
  activeChat: boolean
  data: DataGroupChat
  current: boolean
}
const TabChat: React.FC<TabChatProps> = ({ activeChat, data, current }) => {
  console.log('current: ', current);
  console.log('data: ', data);
  console.log('activeChat: ', activeChat);
  
  const [playSound, setPlaySound] = useState(false);
  const { messages, sendNotify, addConn, delConn, changeConn, findConnection } = UseChatConnection()
  current ? config.setStoreJson('ApiChat2', data) : config.setStoreJson('ApiChat1', data)
  const [openChat, setOpenChat] = useState(true)
  const { ActiveChat1, ActiveChat2 } = useAppSelector((state) => state.StackChatReducer)
  const [conection, setConnection] = useState<HubConnection | null>(null)
  const inputRef = useRef(null)
  const [openModal, setOpenModal] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  const dispatch = useAppDispatch()
  const dspopup = config.getStoreJson('LIST_POPUP')
  const [form] = Form.useForm()
  const [inputValue, setInputValue] = useState('')
  const { chats, chats2, dsGroupTask, dsMentor } = useAppSelector((state) => state.StackChatReducer)
  const { dsHocVienByMentorId } =
    useAppSelector((state) => state.nopBaiReducer)
  const [checkImgGuest, setcheckImgGuest] = useState(true)
  const [checkImgUser, setcheckImgUser] = useState(true)
  const previousNotificationRef = useRef('')
  const convertTenHocVien = (id: string) => {
    if (dsHocVienByMentorId.length) {
      const index = dsHocVienByMentorId.findIndex((e) => e.id === id)
      if (index !== -1) return dsHocVienByMentorId[index].hoTen
    }
    return ''
  }
  const openNotification = (message: string, description: string) => {
    const newNotification = `${message}-${description}`
    if (previousNotificationRef.current !== newNotification) {
      notification.open({
        message,
        // icon:<></>,
        description,
        placement: 'topLeft',
        duration: 5,
        className: 'NotificationTABCHAT',
        onClose: () => {
          setTimeout(() => {
            previousNotificationRef.current = ''
          }, 10000)
        }
      })
      previousNotificationRef.current = newNotification
    }
  }
  // const Rank = {rank1:5,rank2:5,rank3:5,rank4:5,rank6:5}/
  const [valueRank1, setValueRank1] = useState(3)
  const [valueRank2, setValueRank2] = useState(3)
  const [valueRank3, setValueRank3] = useState(3)
  const [valueRank4, setValueRank4] = useState(3)
  const [valueRank5, setValueRank5] = useState(3)
  const [valueRank6, setValueRank6] = useState(3)

  let myUser = config.getStoreJson('USER_LOGIN') as NguoiDungType

  const { issuesMentorPhuTrach } = useAppSelector((state) => state.IssueReducer)
 
  // const { dsGroupForTaskHocVien } = useAppSelector((state) => state.groupForTaskReducer);
  // const { DSChuyenDeMentorPhuTrach, DSChuyenDeHocVienDangKy } = useAppSelector((state) => state.chuyenDeReducer);

  const [issue, setIssue] = useState('')
  const [typeIssue, setTypeIssue] = useState('')
  // console.log("😀 ~ file: TabChat.jsx:112 ~ TabChat ~ typeIssue:", typeIssue)
  const desc = ['20%', '40%', '60%', '80%', '100%']
  const messageRef = useRef<any>()

  //-------------------regex link------------------------------
  const replaceWithLinks = (text: string) => {
    const regex = /(?:https?|ftp):\/\/[\n\S]+/gi;
    return text.replace(regex, function (match) {
      return '<a href="' + match + '">' + match + '</a>';
    });
  }

  //-----------------end regex link---------------------------------


  //TODO-----------------------------------
  const checkTypeChat = (a: any, b: any) => {
    if ('dsMentor' in data) return a
    else if ('danhSachHocVien' in data)
      // else if (data?.danhSachHocVien)x
      return b
    return null
  }
  let newdata: groupchatchuyendehocvienId
  let newdata2: GroupForTaskModel2

  newdata = { ...data } as groupchatchuyendehocvienId
  newdata2 = { ...data } as GroupForTaskModel2

  const checkConn = () => {
    if (conection && conection.state === 'Connected') {
      return true
    } else {
      return false
    }
  }

  const joinRoom = async () => {
    try {
      //Tạo sự kiên lắng nghe khi có người kết nối
      connection.on('onJoinRoom', (userM) => {
        if (myUser?.id !== userM?.userid) {
          message.success('Có người vừa tham gia group task ' + data?.id, 5)
        }
      })
      connection.on('onJoinRoom2', (userM) => {
        if (myUser?.id !== userM?.userid) {
          message.success('Có người vừa kết nối group chuyên đề' + data?.id)
        }
      })
      connection.on('onUpdateIssue', () => {
        loadApi()
      })
      //Sự kiện có tin nhắn đến
      connection.on('ReceiveMessage', (userM) => {
        loadApi()
      })
      connection.on('ReceiveMessage2', (userM) => {
        loadApi()
      })
      //Sk khi có người out
      connection.on('outReceiveMessage', (userM) => {
        if (myUser?.id === userM?.userid) {
          message.info('Bạn vừa tắt chat !')
        } else {
          // message.info(`Tk ${userM?.userId} vừa tắt`);
        }
      })
      connection.on('outReceiveMessage2', (userM) => {
        if (myUser?.id === userM?.userid) {
          message.info('Bạn vừa tắt chat !')
        } else {
          // message.info(`Tk ${userM?.userId} vừa tắt`);
        }
      })

      //ds chat
      connection.on('UsersInRoom', (list) => {

      })
      //Sk chạy khi connection đóng !
      // connection.onclose((e) => {
      //   console.log(`Connectid ${connection?.connectionId} close`)
      //   setConnection(null)
      //   delConn(data)
      // })
      //Start connection
      await connection.start()
      let userConnection = null
      const newD = data as groupchatchuyendehocvienId
      if (checkTypeChat(true, false) && newD.hocVienId) {
        userConnection = {
          userid: myUser.id,
          hocVienId: newD.hocVienId,
          chuyenDeId: Number(data.id)
        }
        await connection.invoke('JoinRoom2', userConnection)
      } else {
        userConnection = { userid: myUser.id, taskid: Number(data.id) }
        // userConnection = { userid: myUser.id, room, taskid: Number(data.id) };
        await connection.invoke('JoinRoom', userConnection)
      }
      message.success('Connected!!', 5)
      setConnection(connection)
      addConn(data, connection)
    } catch (error) {
      // success("Bạn vừa kết nối thành công chat !");
      // message.error('Fail Connection')
    }
  }

  const sendMessage = async () => {
    try {
      if (conection instanceof HubConnection)
        if (checkTypeChat(true, false)) {
          await conection.invoke('SendMessage2', {
            userid: myUser?.id,
            room:dsUser,
            chuyenDeId: Number(data?.id),
            dsUser:dsUser
          })
        } else {
          await conection.invoke('SendMessage', {
            userid: myUser?.id,
            room:dsUser,
            taskid: Number(data?.id)
          })
        }
    } catch (e) {
      loadApi()
    }
  }

  const updateIssuee = async () => {
    try {
      if (conection instanceof HubConnection)
        if (checkTypeChat(true, false)) {
          await conection.invoke('updateIssuee2', {
            userid: myUser?.id,
            chuyenDeId: Number(data?.id)
          })
        } else {
          await conection.invoke('updateIssuee', {
            userid: myUser?.id,
            taskid: Number(data?.id)
          })
        }
    } catch (e) {
      loadApi()
    }
  }

  // const sendUsersConnected = async (room) => {
  //   try {
  //     if (conection instanceof HubConnection)
  //       await conection.invoke("SendUsersConnected", room);
  //   } catch (error) {
  //   }
  // };

  const closeConnection = async () => {
    try {
      if (conection instanceof HubConnection) {
        await conection.stop()
        console.log('đóng kết nối')
        setConnection(null)
      }
    } catch (e) {
      console.log('lỗi trong quá trình đóng kết nối', e)
    }
  }

  //!Tìm issue tương ứng theo chuyên đề
  const issueChat = (chuyenDeId: number, hocVienId: string) => {
    const inde = issuesMentorPhuTrach.findIndex((e) => e.chuyenDeId === chuyenDeId && e.hocVienId === hocVienId)
    if (inde >= 0) return issuesMentorPhuTrach[inde]
    return null
  }
  const checkIssueMentorSupport = () => {
    // checkTypeChat(true, false) === true &&issueChat(data.id, newdata.hocVienId)  && issueChat(data.id, newdata.hocVienId)?.trangThai === 'DA_HO_TRO'||issueChat(data.id, newdata.hocVienId)?.trangThai === 'CHO_PHAN_HOI'
    if (checkTypeChat(true, false) === true && issueChat(data.id, newdata.hocVienId)) {
      if (
        issueChat(data.id, newdata.hocVienId)?.trangThai === 'CAN_HO_TRO' ||
        issueChat(data.id, newdata.hocVienId)?.trangThai === 'DANG_HO_TRO' ||
        issueChat(data.id, newdata.hocVienId)?.trangThai === 'CHO_PHAN_HOI' ||
        issueChat(data.id, newdata.hocVienId)?.trangThai === 'HO_TRO_LAI'
      ) {
        return true
      }
      return false
    }
  }

  const handleLoadImg = () => { }
  //Check chat chuyen de
  const checkIssue = () => {
    if (newdata?.dsMentor) {
      //check có issue ko
      const index = issuesMentorPhuTrach?.findIndex(
        (e) => e?.chuyenDeId == newdata?.id && e?.hocVienId == newdata?.hocVienId
      );
      if (index >= 0) {
        setIssue(issuesMentorPhuTrach[index].title);
        setTypeIssue(issuesMentorPhuTrach[index].trangThai)

      } else {
        setIssue("");
        setTypeIssue("")
      }

      //Cập nhập nội dung chat cho content
    } else {
      setIssue("");
      setTypeIssue("")
    }

  };

  const checkIssueChoPhanHoi = () => {
    if (typeIssue == typeOfIssue.CHO_PHAN_HOI) return true
    return false
  }

  const loadApi = async () => {
    const ApiCHat = current ? config.getStoreJson('ApiChat2') : config.getStoreJson('ApiChat1')

    if (ApiCHat) {
      const { id } = ApiCHat

      if (Number(id) === data?.id) {
        dispatch(getIssueAllMentorPhuTrachApi(myUser.id))
        dispatch(GetGroupForTaskByMentorIdApi(myUser.id))
        // await dispatch(getDSChuyenDeHocVienDangKyApi());
        // await dispatch(getIssueHocVienTheoTungChuyenDeApi());
        // await dispatch(getDsGroupForTaskHocVienApi());

        //load data khi người dùng bật lên
        if (checkTypeChat(true, false) === true) {
          const newData = { ...data } as groupchatchuyendehocvienId
          if (current) {
            // console.log(current)/
            if (newData.hocVienId) dispatch(getGroupChatByChuyenDeIdAndHocVienIdApi2(data?.id, newData.hocVienId))
          } else {
            if (newData.hocVienId) dispatch(getGroupChatByChuyenDeIdAndHocVienIdApi(data?.id, newData.hocVienId))
          }
        } else if (checkTypeChat(true, false) === false) {
          const newData = { ...data } as GroupForTaskModel2
          if (current) {
            if (newData.id) dispatch(GetByDsChatGFTApi2(newData.id))
          } else {
            if (newData.id) dispatch(GetByDsChatGFTApi(newData.id))
          }
        }
      }
      // else if (data?.id === Number(id)) {
      //   await dispatch(getDSChuyenDeHocVienDangKyApi());
      //   await dispatch(getIssueHocVienTheoTungChuyenDeApi());
      //   await dispatch(getDsGroupForTaskHocVienApi());

      //   //load data khi người dùng bật lên
      //   if (checkTypeChat(true, false) === true) {
      //     if (current) {
      //       dispatch(getGroupChatByChuyenDeIdAndHocVienIdApi2(data?.id, myUser?.id))
      //     } else {
      //       dispatch(getGroupChatByChuyenDeIdAndHocVienIdApi(data?.id, myUser?.id))
      //     }
      //   } else if (checkTypeChat(true, false) === false) {
      //     if (current) {
      //       dispatch(GetByDsChatGFTApi2(Number(data?.id)));
      //     } else {
      //       dispatch(GetByDsChatGFTApi(Number(data?.id)));
      //     }
      //   }
      // }
    }

    // else{
    //   await dispatch(getDSChuyenDeHocVienDangKyApi());
    //   await dispatch(getIssueHocVienTheoTungChuyenDeApi());
    //   await dispatch(getDsGroupForTaskHocVienApi());

    //   //load data khi người dùng bật lên
    //   if (checkTypeChat(true, false) === true) {
    //     if (current) {
    //       dispatch(getGroupChatByChuyenDeIdAndHocVienIdApi2(data?.id, myUser?.id))
    //     } else {
    //       dispatch(getGroupChatByChuyenDeIdAndHocVienIdApi(data?.id, myUser?.id))
    //     }
    //   } else if (checkTypeChat(true, false) === false) {
    //     if (current) {
    //       dispatch(GetByDsChatGFTApi2(Number(data?.id)));
    //     } else {
    //       dispatch(GetByDsChatGFTApi(Number(data?.id)));
    //     }
    //   }
    // }
  }

  // const createIssue = async (data) => {
  //   // await dispatch(postIssueApi(data));
  // }
  // const UpdateIssuee = async (idIssue, data) => {
  //   await dispatch(putoptionalApi(idIssue, data));
  // }

  const showContentChat = (mess: ChatModel[]) => {
    return mess?.map((e, i) => {
      const alertContent = (text: string) => { return { __html: text } }
      if (e?.UserSendId === myUser?.id)
        return (
          <div key={i + ' user_chat'} className='user_chat'>
            <div className='user_avatar avatar'>
              {/* {checkImgUser ? (
                <img
                  src={getHinhAnhFB(myUser.avatar)}
                  onError={() => {
                    setcheckImgUser(false)
                  }}
                  alt=''
                />
              ) : (
                <Avatar style={{ backgroundColor: '#87d068' }}  >{lastName(myUser?.hoTen)}</Avatar>
              )} */}
            </div>
            <div className='user_content'>
              <p className='d-flex flex-column align-items-end items-end'>
                <span className='text-base'>{e?.Content}</span>
                {/* <span className='text-base' dangerouslySetInnerHTML={alertContent(e?.Content)}> </span> */}
                <span className='text-xs'>{convertTime(e?.TimeCreate)}</span>
              </p>
            </div>
          </div>
        )
      else
        return (
          <div key={i + ' guest_chat'} className='guest_chat'>
            <div className='guest_avatar avatar'>
              {checkImgGuest ? (
                <img
                  src={getHinhAnhFB(newdata.avatar)}
                  onError={() => {
                    setcheckImgGuest(false)
                  }}
                  alt=''
                />
              ) : (
                // <Avatar style={{ backgroundColor: '#87d068' }}  >{lastName(convertTenHocVien(e?.UserSendId))}</Avatar>
                <Popover key={i} content={convertTenHocVien(e?.UserSendId)}>
                  <Avatar
                    style={{ borderRadius: '100rem', width: '35px', height: '35px' }}
                    className="shape-avatar me-1"
                    shape="square"
                    size={40}
                    src={`https://ui-avatars.com/api/?name=${convertTenHocVien(e?.UserSendId)}`}
                  ></Avatar>
                </Popover>
              )}
            </div>
            <div className='guest_content'>
              {/* <span className=" text-xs">{loadTen(e.UserSendId)}</span> */}
              <p className='d-flex flex-column  align-items-start'>
                <span className='text-base'>{e?.Content}</span>
                {/* <p className='text-base'  dangerouslySetInnerHTML={alertContent(e?.Content)}>{e?.Content}</p> */}
                <span className='text-xs'>{convertTime(e?.TimeCreate)}</span>
              </p>
            </div>
          </div>
        )
    })
  }

  const handleClearPopup = () => {
    const index = findObjectIndexInArray(dspopup, data)
    if (index >= 0) {
      if (dspopup.length <= 1) {
        config.removeStore('LIST_POPUP')
      } else {
        dspopup.splice(index, 1)
        config.setStoreJson('LIST_POPUP', dspopup)
      }
    }
  }

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value)
  }

  const handleSubmit = () => {
    form.submit()
  }

  const handleClearInput = () => {
    form.setFieldsValue({
      content: '',
      timeCreate: '',
      isDelete: false,
      userChat: ''
    })
    // inputRef.current?.focus()
  }
  const handleClearInputIssue = () => {
    form.setFieldsValue({
      title: ''
    })
    // inputRef.current?.focus()
  }

  //Gưi tin nhắn đi
  const onFinish = async (values: MESSModel) => {
    if (
      issueChat(data.id, newdata.hocVienId)?.trangThai === 'CAN_HO_TRO' ||
      issueChat(data.id, newdata.hocVienId)?.trangThai === 'HO_TRO_LAI'
    )
      handelUpdateIssue('DANG_HO_TRO')
    if (inputValue.trim().length > 0) {
      values.content = replaceWithLinks(values.content)
      values.timeCreate = getTodate()
      values.isDelete = false
      values.userSendId = myUser.id

      if (checkTypeChat(true, false) && newdata.hocVienId) {
        await dispatch(
          sendmessChuyenDeApi(
            newdata.hocVienId,
            data?.id,
            issueChat(Number(data.id), newdata.hocVienId)?.id ?? 0,
            values
          )
        )
      } else {
        if (checkTypeChat(true, false) !== null) {
          await dispatch(sendmessGFTApi(data?.id, values))
        }
      }
      handleClearInput()
      setInputValue('')

      //Gọi sự kiện gữi tin nhắn
      if (checkConn()) sendMessage()
        loadApi()
    }
  }

  // const onFinishIssue = async (values: string) => {
  //   //*Tạo issue
  //   if (inputValue.trim().length > 0) {
  //     const path = config.getStore('path') ?? '/'
  //     // const issue = new IssueModel("", myUser?.id, values?.title ?? inputValue, typeOfIssue.CAN_HO_TRO, data?.id, path);
  //     try {
  //       // await createIssue(issue)
  //       // await updateIssuee()
  //       //clear value
  //       setInputValue('')
  //       handleClearInputIssue()
  //       message.success('Tạo issue thành công !')
  //       loadApi()
  //     } catch (error) {
  //       message.info('Opp! Cần kiểm tra đường truyền', 5000)
  //     }
  //   }
  // }

  const handelUpdateIssue = async (trangThai: TrangThaiIssue) => {
    const issue = issueChat(data?.id, newdata.hocVienId)
    if (issue && 'trangThai' in issue) {
      const newIssue = { ...issue }
      newIssue.trangThai = trangThai

      // issue.trangThai=typeOfIssue.DA_HO_TRO
      try {
        // await UpdateIssuee(issue?.id, newIssue)
        // dispatch()
        dispatch(putoptionalApi(newIssue.id, newIssue))
        await updateIssuee()
        await loadApi()

        // message.success('Update State Succes !')
      } catch (error) {
        message.info('OOP! Kiểm tra lại đường truyền')
      }
    } else {
      message.info('OOP! Kiểm tra lại đường truyền')
    }
  }

  const mini = () => {
    setOpenChat(false)
    if (current) dispatch(ChangeActiveChat2())
    else dispatch(ChangeActiveChat1())
  }
  const close = async () => {
    // await closeConnection()
    // delConn(data)
    handleClearPopup()
    if (current) dispatch(ChangeActiveChat2())
    else dispatch(ChangeActiveChat1())
  }

  //!Modal
  const showModal = () => {
    setOpenModal(true)
  }
  const handleOk = () => {
    setConfirmLoading(true)
    setTimeout(async () => {
      // await handelUpdateIssue(typeOfIssue.DA_HO_TRO)
      setOpenModal(false)
      setConfirmLoading(false)
    }, 2000)
  }
  const handleCancel = () => {
    setOpenModal(false)
  }

  const lastName = (fullname: string) => {
    const parts = fullname?.split(' ') // Tách chuỗi thành mảng các phần tử, tách theo khoảng trắng

    if (parts?.length > 0) {
      return parts[parts?.length - 1]
    } else {
      return null
    }
  }

  const loadAvatar = (userId: string) => {
    if (checkTypeChat(true, false)) {
      if (dsMentor.length) {
        const index = dsMentor.findIndex((e) => e.id === userId)
        if (index !== -1) return dsMentor[index].avatar
      }
    }
    // else {
    //   if (dsGroupTask.length) {
    //     const index = dsGroupTask.findIndex(e => e.id === userId)
    //     if (index !== -1)
    //       return dsGroupTask[index].avatar
    //   }
    // }
  }

  // khi có tn tới sẽ scroll xún cuối trang
  useEffect(() => {
    if (messageRef && messageRef.current) {
      const { scrollHeight, clientHeight } = messageRef.current
      messageRef.current.scrollTo({
        left: 0,
        top: scrollHeight - clientHeight,
        behavior: 'smooth'
      })
    }
  }, [chats, chats2, chats?.length, chats2?.length])

  //Reload issue theo chuyên đề
  useEffect(() => {
    checkIssue();
  }, [data?.id, data, issuesMentorPhuTrach?.length])

  //load api + loop
  useEffect(() => {
    // loadApi()
    loadApi()
    // connection?.on("ReceiveMessage",()=>{
    //   console.log('call ai again')
    // })
    current ? config.setStoreJson('ApiChat2', data) : config.setStoreJson('ApiChat1', data)

    // const intervalId = setInterval(loadApi, 30000)

    // return () => {
    //   clearInterval(intervalId)
    // }

  }, [data, data?.id])

  useEffect(() => {
    if (conection instanceof HubConnection) {
      conection.on('createIssue', () => {
        dispatch(getIssueAllMentorPhuTrachApi(myUser.id));
      })
    }

    return () => {
      // console.log('Đóng tabchat ' + data.id)
    }
  }, [])
const [dsUser,setDsUser] = useState("")
  //Tạo realtime
  useEffect(() => {
    //Khi người dùng chuyển chat thì sẽ clear connect cu đi
    
    if (data) {
      const connectionId = findConnection(data)
      if (connectionId === null) {
        joinRoom()
      } else {
        // Ensure connectionId is not null before passing it to changeConn
        if (typeof connectionId === 'string') {
          changeConn(connectionId)
        } else {
          // Handle the case where connectionId is not a string (null should be handled above)
        }
      }
      chuyenDeService.dangKyHocUser(newdata.hocVienId).then(res => {
        let arrdata: DangKyHocType[] = res.data.content;
        let itemFind = arrdata.find(item=>item.chuyenDeId==data.id)
        setDsUser(JSON.stringify(itemFind?.danhSachMentor))
      })
    }

    return () => {
      // closeConnection()
    }
  }, [data, data?.id])
 
  // useEffect(() => {
  //   let dsMentor = []
  //   let dsHocVien = []
  //   if (checkTypeChat(true, false)) {
  //     if (DSChuyenDeHocVienDangKy && data.id) {
  //       const index = DSChuyenDeHocVienDangKy.findIndex(e => e.id === data.id)
  //       if (index !== -1) {
  //         dsMentor = [...DSChuyenDeHocVienDangKy[index].dsMentor];
  //       }
  //       if (dsMentor.length > 0)
  //         dispatch(GetDsMentorApi(dsMentor))
  //     }
  //   }
  //   else {
  //     if (dsGroupForTaskHocVien && data.id) {
  //       const index = dsGroupForTaskHocVien.findIndex(e => e.id === data.id)
  //       if (index !== -1) {

  //         dsHocVien = [...dsGroupForTaskHocVien[index].danhSachHocVien, dsGroupForTaskHocVien[index].mentorId];
  //       }
  //       if (dsMentor.length > 0)
  //         dispatch(GetDsGroupTaskApi(dsHocVien))
  //     }
  //   }
  // }, [data, data?.id, dsGroupForTaskHocVien, DSChuyenDeHocVienDangKy])

  // useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, []);

  useEffect(() => {
    if (connection !== null) setConnection(connection)
  }, [connection])
  

  return activeChat ? (
    issue ? (
      <div className='TabChat'>
         <NotificationSound playSound={playSound} />
        {contextHolder}
        <div className='TabChat_container'>
          {/*//! Tiêu đề */}
          <div className='TabChat_head'>
            <div className='title'>
              <p className='TabChat_name uppercase py-2'>
                {checkTypeChat(`Chuyên đề ${data?.id} ${newdata.hoTen?.length > 30 ? newdata.hoTen?.substring(0, 25) + '...' : newdata.hoTen}`, `Group task ${data?.id}`)}

              </p>
            </div>
            <div className='action'>
              {/* <MoreOutlined /> */}
              <MinusOutlined
                onClick={() => {
                  mini()
                }}
              />
              <CloseOutlined
                onClick={() => {
                  close()
                }}
              />
            </div>
          </div>
          <div className='relative'>
            {!checkIssueMentorSupport() ? (
              <div className='OverLayIssue'><span className='content_issue'>Hiện tại bạn chưa có issue cần sử lý hoặc đã xử lý xong rồi!!</span></div>
            ) : (
              ''
            )}
            <div className='TabChat_content'>
              <div ref={messageRef} className='content'>
                {showContentChat(current ? chats2 : chats)}
              </div>

            </div>
            {/* //!Nhập liệu */}
            <Form key={'form ' + data?.id} form={form} onFinish={onFinish} className='TabChat_typing'>
              <Form.Item style={{ width: '100%' }} name='content' className='w-full'>
                {/* <Input.TextArea
                rows={1}
                value={inputValue}
                disabled={!conection}
                onChange={handleInputChange}
                onPressEnter={handleSubmit}
                autoFocus
                className='typing'
                placeholder='Nhập nội dung chat'
              /> */}
                <InputEmoji
                  disableRecent={checkIssueMentorSupport()}
                  inputClass='typing'
                  onEnter={form.submit}
                  placeholder='Nhập nội dung chat'
                  value={inputValue}
                  onChange={setInputValue}
                />
              </Form.Item>

              <div className='action'>
                <Button
                  htmlType='submit'
                  disabled={inputValue.trim().length == 0}
                  className={`send ${inputValue.trim().length > 0 ? 'hover' : ''}`}
                >
                  <SendOutlined />
                </Button>

                <button
                  type='button'
                  className='done'
                  onClick={() => {
                    handelUpdateIssue('CHO_PHAN_HOI')
                  }}
                >
                  <CheckCircleOutlined />
                </button>
              </div>
            </Form>


          </div>
          {issue && (
            <div className="issue_container">
              <Tooltip title={issue}>
                <p>{issue.length>20?issue.substring(0,17)+"...":issue}  </p>
              </Tooltip>
            </div>
          )}

        </div>
      </div>
    ) : (
      <div className='TabChat'>
        {contextHolder}
        <NotificationSound playSound={playSound} />
        <div className='TabChat_container'>
          {/*//! Tiêu đề */}
          <div className='TabChat_head'>
            <div className='title'>
              <p className='TabChat_name uppercase py-2'>
                {checkTypeChat(`Chuyên đề ${data?.id} ${newdata.hoTen?.length > 30 ? newdata.hoTen?.substring(0, 25) + '...' : newdata.hoTen}`, `Group task ${data?.id}`)}

              </p>
            </div>
            <div className='action'>
              {/* <MoreOutlined /> */}
              <MinusOutlined
                onClick={() => {
                  mini()
                }}
              />
              <CloseOutlined
                onClick={() => {
                  close()
                }}
              />
            </div>
          </div>
          <div className='relative'>

            <div className='TabChat_content'>
              <div ref={messageRef} className='content'>
                {showContentChat(current ? chats2 : chats)}
              </div>

            </div>
            {/* //!Nhập liệu */}
            <Form key={'form ' + data?.id} form={form} onFinish={onFinish} className='TabChat_typing'>
              <Form.Item style={{ width: '100%' }} name='content' className='w-full'>

                <InputEmoji
                  disableRecent={checkIssueMentorSupport()}
                  inputClass='typing'
                  onEnter={form.submit}
                  placeholder='Nhập nội dung chat'
                  value={inputValue}
                  onChange={setInputValue}
                />
              </Form.Item>

              <div className='action'>
                <Button
                  htmlType='submit'
                  disabled={inputValue.trim().length == 0}
                  className={`send ${inputValue.trim().length > 0 ? 'hover' : ''}`}
                >
                  <SendOutlined />
                </Button>
              </div>
            </Form>


          </div>

        </div>
      </div>
    )

  ) : (
    <></>
  )
}

export default TabChat
