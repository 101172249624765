import { DataChartUser, DataThongKeRow, NopBaiThongKe, ThoiGianHocThongKe, ThongKeDoiTac } from './../../Types/thongKeFree';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DispatchType } from "../configStore";
import { ThongKeFree } from "../../Types/thongKeFree";
import { thongKeFreeService } from "../../services/thongKeFreeService";
import { nopbaiService } from '../../services/nopbaiService';


interface thongKeFreeState {
  demTheoNgay: number[],
  demTheoNgayUser: number[],
  demTheoNgayAll: number[],
  thongKe: ThongKeFree[],
  dataNgay:DataThongKeRow[],
  dataTuan:DataThongKeRow[],
  dataThang:DataThongKeRow[],
  thongKeNopBai:NopBaiThongKe[],
  thongKeThoiGianHoc:ThoiGianHocThongKe[],
  thongKeDoiTac:ThongKeDoiTac[],
  ThongKeDoiTacAll:ThongKeDoiTac[],
  loadingThongKe:boolean,
  dataThoiGianHoc:number[],
  loadingThoigianChart:boolean,
  dataChartUser:DataChartUser[],
  loadChartUser:boolean,
  bangDiemUser:any,
  loadBangDiem:boolean
}

const initialState: thongKeFreeState = {
  demTheoNgay: [],
  demTheoNgayUser: [],
  demTheoNgayAll: [],
  thongKe: [],
  dataNgay:[],
  dataTuan:[],
  dataThang:[],
  thongKeNopBai:[],
  thongKeThoiGianHoc:[],
  thongKeDoiTac:[],
  ThongKeDoiTacAll:[],
  loadingThongKe:false,
  dataThoiGianHoc:[],
  loadingThoigianChart:false,
  dataChartUser:[],
  loadChartUser:false,
  bangDiemUser:{},
  loadBangDiem:false
}

const thongKeFreeReducer = createSlice({
  name: 'vatPham',
  initialState,
  reducers: {
    setThongKe: (state, action: PayloadAction<ThongKeFree[]>) => {
      state.thongKe = action.payload;
    },
    setDemTheoNgay: (state, action: PayloadAction<number[]>) => {
      state.demTheoNgay = action.payload;
    },
    setDemTheoNgayUser: (state, action: PayloadAction<number[]>) => {
      state.demTheoNgayUser = action.payload;
    },
    setDemTheoNgayAll: (state, action: PayloadAction<number[]>) => {
      state.demTheoNgayAll = action.payload;
    },
    setDataNgay: (state, action: PayloadAction<DataThongKeRow[]>) => {
      state.dataNgay = action.payload;
    },
    setDataThang: (state, action: PayloadAction<DataThongKeRow[]>) => {
      state.dataThang = action.payload;
    },
    setDataTuan: (state, action: PayloadAction<DataThongKeRow[]>) => {
      state.dataTuan = action.payload;
    },
    setThongKeNopBai: (state, action: PayloadAction<NopBaiThongKe[]>) => {
      state.thongKeNopBai = action.payload;
    },
    setThongKeThoiGianHoc: (state, action: PayloadAction<ThoiGianHocThongKe[]>) => {
      state.thongKeThoiGianHoc = action.payload;
    },
    setThongKeDoiTac: (state, action: PayloadAction<ThongKeDoiTac[]>) => {
      state.thongKeDoiTac = action.payload;
    },
    setThongKeDoiTacAll: (state, action: PayloadAction<ThongKeDoiTac[]>) => {
      state.ThongKeDoiTacAll = action.payload;
    },
    setLoadingThongKe: (state, action: PayloadAction<boolean>) => {
      state.loadingThongKe = action.payload;
    },
    setDataThoiGianHoc: (state, action: PayloadAction<number[]>) => {
      state.dataThoiGianHoc = action.payload;
    },
    setStartLoadChart: (state) => {
      state.loadingThoigianChart = true;
    },
    setEndLoadChart: (state) => {
      state.loadingThoigianChart = false;
    },
    setDataChartUser: (state, action: PayloadAction<DataChartUser[]>) => {
      state.dataChartUser = action.payload;
    },
    setLoadingChartUser: (state, action: PayloadAction<boolean>) => {
      state.loadChartUser = action.payload;
    },
    setBangDiemUserAction: (state, action: PayloadAction<any>) => {
      state.bangDiemUser = action.payload;
    },
    setLoadBangDiem:(state, action: PayloadAction<boolean>) => {
      state.loadBangDiem = action.payload;
    },
  }
})

export const { 
  setThongKe, 
  setDemTheoNgay, 
  setDemTheoNgayUser, 
  setDemTheoNgayAll,
  setDataNgay,
  setDataThang,
  setDataTuan,
  setThongKeNopBai,
  setThongKeThoiGianHoc,
  setThongKeDoiTac,
  setThongKeDoiTacAll,
  setDataThoiGianHoc,
  setStartLoadChart,
  setEndLoadChart,
  setDataChartUser,
  setLoadingChartUser,
  setBangDiemUserAction,
  setLoadBangDiem,
  setLoadingThongKe
  } = thongKeFreeReducer.actions;
export default thongKeFreeReducer.reducer;

export const getThongKeFreeApi = (date:string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await thongKeFreeService.GetThongKeFree(date);
      dispatch(setThongKe(result.data.content));
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }
}
export const getThongKeFreeUserApi = (date:string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await thongKeFreeService.GetThongKeFreeUser(date);
      dispatch(setThongKe(result.data.content));
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }
}
export const getThongKeFreeAllApi = (date:string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await thongKeFreeService.GetThongKeFreeAll(date);
      dispatch(setThongKe(result.data.content));
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }
}
export const getDemTKTheoNgayApi = (date:string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await thongKeFreeService.DemTKTheoNgay(date);
      dispatch(setDemTheoNgay(result.data.content));
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }
}
export const getDemTKTheoNgayUserApi = (date:string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await thongKeFreeService.DemTKTheoNgayUser(date);
      dispatch(setDemTheoNgayUser(result.data.content));
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }
}
export const getDemTKTheoNgayAllApi = (date:string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await thongKeFreeService.DemTKTheoNgayAll(date);
      dispatch(setDemTheoNgayAll(result.data.content));
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }
}
export const getDataTKRowNgay = (date:string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await thongKeFreeService.DataTKRowNgay(date);
      dispatch(setDataNgay(result.data.content));
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }
}
export const getDataTKRowNgayUser = (date:string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await thongKeFreeService.DataTKRowNgayUser(date);
      dispatch(setDataNgay(result.data.content));
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }
}
export const getDataTKRowTuan = (date:string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await thongKeFreeService.DataTKRowTuan(date);
      dispatch(setDataTuan(result.data.content));
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }
}
export const getDataTKRowTuanUser = (date:string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await thongKeFreeService.DataTKRowTuanUser(date);
      dispatch(setDataTuan(result.data.content));
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }
}
export const getDataTKRowThang = (date:string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await thongKeFreeService.DataTKRowThang(date);
      dispatch(setDataThang(result.data.content));
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }
}
export const getDataTKRowThangUser = (date:string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await thongKeFreeService.DataTKRowThangUser(date);
      dispatch(setDataThang(result.data.content));
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }
}
export const getThongKeThoiGianHoc = (chuyenDeId:number,key:string,start:string,end:string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await nopbaiService.ThongKeThoiGianHoc(chuyenDeId,key,start,end);
      dispatch(setThongKeThoiGianHoc(result.data.content));
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }
}
export const getThongKeNopBai = (chuyenDeId:number,key:string,start:string,end:string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await nopbaiService.ThongKeNopBai(chuyenDeId,key,start,end);
      dispatch(setThongKeNopBai(result.data.content));
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }
}
export const getThongKeDoiTac = (chuyenDeId:number,key:string,start:string,end:string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await nopbaiService.ThongKeDoiTac(chuyenDeId,key,start,end);
      dispatch(setThongKeDoiTac(result.data.content));
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }
}
export const getThongKeDoiTacAll = (chuyenDeId:number,key:string,start:string,end:string) => {
  return async (dispatch: DispatchType) => {
    try {
      dispatch(setLoadingThongKe(true))
      const result = await nopbaiService.ThongKeDoiTacAll(chuyenDeId,key,start,end);
      dispatch(setThongKeDoiTacAll(result.data.content));     
      dispatch(setLoadingThongKe(false))
    } catch (error) {
      console.error("Error fetching items:", error);
    }finally {
      dispatch(setLoadingThongKe(false));
    }
  }
}
export const getThongKeThoiGianHocDoiTac = (key:string,start:string,end:string) => {
  return async (dispatch: DispatchType) => {
    try {
      dispatch(setStartLoadChart())
      const result = await nopbaiService.ThongKeThoiGianHocDoiTac(key,start,end);
      dispatch(setDataThoiGianHoc(result.data.content));
      dispatch(setEndLoadChart())
    } catch (error) {
      console.error("Error fetching items:", error);
    }finally {
      // Dispatch loading false after the API call (both success and error)
      dispatch(setEndLoadChart());
    }
  }
}
export const getDataChartUser = (userId:string,start:string,end:string) => {
  return async (dispatch: DispatchType) => {
    try {
      dispatch(setLoadingChartUser(true))
      const result = await nopbaiService.ThongKeThoiGianHocUser(userId,start,end);
      dispatch(setDataChartUser(result.data.content));
      dispatch(setLoadingChartUser(false))
    } catch (error) {
      console.error("Error fetching items:", error);
    }finally {
      // Dispatch loading false after the API call (both success and error)
      dispatch(setLoadingChartUser(false));
    }
  }
}
export const getBangDiemUser = (userId:string,chuyenDeId:number) => {
  return async (dispatch: DispatchType) => {
    try {
      dispatch(setLoadBangDiem(true))
      const result = await nopbaiService.GetBangDiemUser(userId,chuyenDeId);
      dispatch(setBangDiemUserAction(result.data.content));
      dispatch(setLoadBangDiem(false))

    } catch (error) {
      console.error("Error fetching items:", error);
    }finally {
      dispatch(setLoadBangDiem(false));
    }
  }
}

