import React, { useEffect, useState } from 'react';
import { Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { uploadImageService } from '../../../services/uploadImagService';
import { API_URL_DOMAIN } from '../../../util/urlDomain';
import { CKEditor } from 'ckeditor4-react';

const QuillEditor = ({ data, setData, dataId }) => {
    const [loading, setLoading] = useState(false);
    const [keyEditor, setKey] = useState(Date.now());

    const onEditorChange = (evt) => {
        setData(evt.editor.getData());
    };

    const handleUpload = async ({ file }) => {
        if (file.size > 2000000) {
            message.error('Only images under 2MB are allowed');
            return;
        }
        setLoading(true);
        try {
            const res = await uploadImageService.uploadImage(file);
            const imageUrl = API_URL_DOMAIN + res;
            const imageTag = `<img src="${imageUrl}" className="w-full"/>`;
            setData(data + imageTag);
            setKey(Date.now());

            message.success('Image uploaded successfully');
        } catch (error) {
            message.error('Upload failed');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setKey(Date.now());
    }, [dataId]);

    return (
        <div>
            <Upload
                customRequest={handleUpload}
                showUploadList={false}
            >
                <Button icon={<UploadOutlined />} loading={loading}>
                    Upload Image
                </Button>
            </Upload>
            <CKEditor
                key={keyEditor}
                initData={data}
                config={{
                    versionCheck: false,
                    height: 200,
                    allowedContent: true,
                    toolbarGroups: [
                        { name: 'document', groups: ['mode', 'document', 'doctools'] },
                        { name: 'clipboard', groups: ['clipboard', 'undo'] },
                        { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
                        { name: 'forms' },
                        '/',
                        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
                        { name: 'links' },
                        { name: 'insert' },
                        '/',
                        { name: 'styles' },
                        { name: 'colors' },
                        { name: 'tools' },
                        { name: 'others' },
                        { name: 'about' }
                    ],
                    extraPlugins: 'codesnippet' // Add the codesnippet plugin to the list of extraPlugins
                }}
                onChange={onEditorChange}
            />
        </div>
    );
};

export default QuillEditor;
