import React, { Fragment, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card, DatePicker, DatePickerProps, Form, Modal, Popconfirm, Tag, Tooltip,Table } from "antd";
import { NoiDungMonHoc } from "../../Types/monHocType";
import { BaiHocModel } from "../../Types/baiHocType";
import { listLoaiTask } from "../../util/SelectOption";
import { useSelector } from "react-redux";
import { DispatchType, RootState } from "../../redux/configStore";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { NopBaiModel } from "../../Types/nopBaiType";
import { GetDSHVNopBaiApi, XoaNopBaiApi } from "../../redux/NopBaiReducer/nopBaiReducer";
import { useDispatch } from "react-redux";
import { ColumnsType } from "antd/es/table";


type Props = {
    task: NoiDungMonHoc,
    showDrawer: (task: NoiDungMonHoc) => void,
    xoaTaskKhoiMon: (taskId: number) => void,
    updateHanNop: any,
    STT: number,
    updateDemo: (task: any) => void,
    moveToTop: (iOld: number, iNew: number) => void
}

export const SortableItem = ({
    task, showDrawer, xoaTaskKhoiMon, updateHanNop, updateDemo, STT, moveToTop
}: Props) => {

    let { monHocId, chuyenDeId } = useParams()
    const { dsNopBaiByTaskId } = useSelector((state: RootState) => state.nopBaiReducer)


    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: task.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: 999,
        cursor: "grab"
    }
    const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {

        task.hanNop = date;

        updateHanNop(task);
    };
    //index đổi chỗ task

    const inputRef = useRef<HTMLInputElement>(null);
    const [indexTask, setIndexTask] = useState<any>(task.id)
    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            moveToTop(STT - 1, indexTask - 1);
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        }
    };
    //
    const [demo, setDemo] = useState(false)
    const { arrBaiHoc } = useSelector((state: RootState) => state.baiHocReducer)
    const Duyetarr = (arr1: any[], arr2: BaiHocModel[] | null) => {
        return arr1.every(id => {
            // Tìm phần tử trong mảng arr2 có id giống với id hiện tại
            const foundItem = arr2?.find(item => item.id === id);
            // Nếu không tìm thấy hoặc xemDemo=false, trả về false
            return foundItem && foundItem.xemDemo === true;
        })
    }
    interface Chuong {
        chuongHoc: number;
        tenChuong: string;
        danhSachBaiHoc: number[];
    }
    useEffect(() => {
        let arr: number[] = []
        switch (task.maLoaiNhiemVu) {
            case 'VIDEO': {
                let ds: Chuong[] = JSON.parse(task?.noiDungNhiemVu ? task?.noiDungNhiemVu : "")
                ds.forEach((chuong: Chuong) => {
                    arr = arr?.concat(chuong?.danhSachBaiHoc);
                });
                break
            }
            case 'QUIZ': {
                arr = JSON.parse(task?.noiDungNhiemVu ? task?.noiDungNhiemVu : "")
                break
            }
            case 'TAILIEU': {
                arr = JSON.parse(task?.noiDungNhiemVu ? task?.noiDungNhiemVu : "")
                break
            }
            case 'RUNCODE': {
                arr = JSON.parse(task?.noiDungNhiemVu ? task?.noiDungNhiemVu : "")
                break
            }
            case 'RECORD': {
                arr = JSON.parse(task?.noiDungNhiemVu ? task?.noiDungNhiemVu : "")
                break
            }
            case 'BAITAP': {
                let num = task?.noiDungNhiemVu
                arr.push(Number(num))
                break
            }
        }
        setDemo(Duyetarr(arr, arrBaiHoc))
    }, [task])

    let userLogin = localStorage.getItem("USER_LOGIN");
    let nhomQuyen = userLogin ? JSON.parse(userLogin).maNhomQuyen : null;
// ================XÓA BÀI LÀM CỦA HỌC VIÊN ĐỂ LÀM LẠI=================
    const dispatch: DispatchType = useDispatch()

    const columns: ColumnsType<NopBaiModel> = [
        {
          title: 'Họ tên',
          dataIndex: 'nguoiDungId',
          key: 'hoTen',
        },
        {
          title: 'Điểm',
          dataIndex: 'diem',
          key: 'diem',
        },
        {
          title: 'Require id',
          dataIndex: 'requireId',
          key: 'requireId',
        },
        {
          title: 'Nhận xét',
          dataIndex: 'nhanXet',
          key: 'nhanXet',
          render:(text)=> text.length>50?text.substring(0,50)+"...": text
        },
        {
          title: 'Bài làm',
          dataIndex: 'baiLam',
          key: 'baiLam',
          render:(text)=> <p title={text}>{text.length>50?text.substring(0,50)+"...": text}</p>
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            
                <Popconfirm
                        title={<p className='text-white'>Bạn có chắc muốn xóa ?</p>}
                        onConfirm={() =>  xoaBaiLam(record)}
                    >
                        <button className="btn btn-danger"><i className="fa-regular fa-trash-can"></i></button>
                    </Popconfirm>
          ),
        },
      ];
    
    const [dshv,setDSHV] = useState<boolean>(false)
    const showDS=(taskId:number) => { 
        dispatch(GetDSHVNopBaiApi(Number(chuyenDeId),Number(monHocId),taskId))
        setDSHV(true);
    }
    const closeDS=() => { 
        setDSHV(false)
    }
    const xoaBaiLam=(item:NopBaiModel) => { 
        dispatch(XoaNopBaiApi(item.id))
     }
    return (
        <div className=" m-3 row ">
            <div className="col-1 border bg-light py-3 rounded-start">
                {STT}
            </div>
            <div className="p-3 border col-8 bg-light  drag-hover" ref={setNodeRef} style={style} {...attributes} {...listeners}>

                <div >

                    {`${task.id} - ${task.tenNhiemVu}`} <Tag color="red"  >{listLoaiTask.find(n => n.value == task.maLoaiNhiemVu)?.label}</Tag>

                </div>
            </div >

            <div className="col-3 border bg-light py-3 rounded-end d-flex">

                {
                    // ["HOMEWORK", "DUAN", "CAPS", "BAITAP"].find(n => n == task.maLoaiNhiemVu) &&
                    // <p>
                    //     <Tooltip title="Gia hạn deadline !">

                    //         <DatePicker format={"DD/MM/YYYY"} defaultValue={dayjs(task.hanNop)} onChange={onChangeDate} />
                    //     </Tooltip>
                    // </p>
                }


                <button className='btn btn-sm btn-success me-2 ' onClick={() => {

                    showDrawer({ ...task })
                }}><i className="fas fa-edit"></i></button>
                {(nhomQuyen == "ADMIN" || nhomQuyen == "LECTURE") && <>
                    <Popconfirm
                        title={<p className='text-white'>Bạn có chắc muốn xóa ?</p>}
                        onConfirm={() => xoaTaskKhoiMon(task.id)}
                    >
                        <button className='btn btn-sm btn-danger me-2 '><i className="fas fa-times"></i></button>
                    </Popconfirm>
                    <button className={`btn btn-sm me-2  ${demo ? 'btn-primary' : "btn-warning"}`} onClick={() => {
                        updateDemo(task)
                    }}>{demo ? <i className="fas fa-unlock"></i> : <i className="fas fa-lock"></i>}</button>
                    <button className={`btn btn-sm me-2  btn-info`} onClick={() => {
                        showDS(task.id)
                    }}><i className="fa-solid fa-list-check"></i></button>
                
                    
                        <Fragment>
                            <input  ref={inputRef}  className="form-control" type="number" id=""  onKeyDown={handleKeyDown} onChange={(e) => {
                            setIndexTask(e.target.value) 
                         }} />
                        </Fragment>
                </>
                }
            </div>
            <Modal width={'80%'} title={null} open={dshv} footer={null} onCancel={closeDS} style={{top:'20px'}}>
                <h5>Danh sách nộp bài</h5>
            <p>Tên nhiệm vụ: {task.tenNhiemVu}</p>
            <Table columns={columns} dataSource={dsNopBaiByTaskId} />
            </Modal>
        </div >
    );
}
