import { MessageOutlined, UserOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React, { useEffect, useMemo, useState } from "react";
// import { PostChat, PostChatIssue } from "../../../model/Chat";
import { ChangeActiveChat1, ChangeActiveChat2, changeCurrentActive, setOpenDrawerAciton, updateDataChat1, updateDataChat2 } from "../../../redux/StackChatReducer/StackChatReducer.tsx";
import LoadAvatar from "../../DanhSachChat/components/LoadAvatar";
import { config } from "../../../util/config";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getHinhAnh2 } from "../../../util/GetHinhanh";

const PopupChat = ({ showDrawer }) => {
  const [arrow, setArrow] = useState("Show");
  const dspopup = config.getStoreJson("LIST_POPUP");
  const [popup, setPopup] = useState(dspopup ?? [])
  let myUser = config.getStoreJson("USER_INFOR_TECH_X");
  const dispatch = useAppDispatch();

  const { ActiveChat1, ActiveChat2, dataChat1, dataChat2, ActiveCurrent } = useAppSelector(
    (state) => state.StackChatReducer
  );
  // console.log(
  //   "😀 ~ file: PopupChat.jsx:11 ~ PopupChat ~ ActiveChat1:",
  //   ActiveChat1
  // );
  const {openDrawer}= useAppSelector(s=>s.StackChatReducer)
  const setOpenDrawer=(b)=>{
    dispatch(setOpenDrawerAciton(b))
  }
  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);
 
  const style ={ opacity: 1, transform: "translateY(0)", height: "100%" }

  const areObjectsEqual = (obj1, obj2) => {
    if (obj1 === obj2) {
      return true; // Cùng một tham chiếu đến đối tượng
    }

    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
      return false; // Một trong hai không phải là đối tượng
    }

    if (obj1 === null || obj2 === null) {
      return false; // Một trong hai là null
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false; // Số lượng thuộc tính khác nhau
    }

    for (let key of keys1) {
      if (!areObjectsEqual(obj1[key], obj2[key])) {
        return false; // Kiểm tra đệ quy cho các thuộc tính lồng nhau
      }
    }

    return true; // Nếu tất cả các kiểm tra trước đó không trả về false, thì hai đối tượng giống nhau.
  };

  const PushDataTabChat = async (e) => {
    // if(myUser){
    //   if(e?.dsMentor)
    //   await config.setStoreJson("ApiChat2",{chuyenDeId:e?.id,hocVienId:myUser?.id})
    //   else if(e?.danhSachHocVien)
    //   await config.setStoreJson("ApiChat1",{gftId:e?.id})
    // }
    if (areObjectsEqual(e, dataChat1)) {
      dispatch(ChangeActiveChat1());
    } else if (areObjectsEqual(e, dataChat2)) {
      dispatch(ChangeActiveChat2());
    } else {
      if (ActiveCurrent) {
        dispatch(updateDataChat2(e));
        !ActiveChat2 && dispatch(ChangeActiveChat2());
        dispatch(changeCurrentActive());
      } else {
        dispatch(updateDataChat1(e));
        !ActiveChat1 && dispatch(ChangeActiveChat1());
        dispatch(changeCurrentActive());
      }
    }
    // if (areObjectsEqual(e, dataChat1)) {
    //   !ActiveChat1 && dispatch(ChangeActiveChat1());
    // } else if (areObjectsEqual(e, dataChat2)) {
    //   !ActiveChat2 && dispatch(ChangeActiveChat2());
    // } else {
    //   if (ActiveCurrent) {
    //     dispatch(updateDataChat2(e));
    //     dispatch(ChangeActiveChat2());
    //     dispatch(changeCurrentActive());
    //   } else {
    //     dispatch(updateDataChat1(e));
    //     dispatch(ChangeActiveChat1());
    //     dispatch(changeCurrentActive());
    //   }
    // }


  };
  

  const rederPopup = () => {
    // console.log(popup);
    return popup?.map((e, i) => {
      return (
        <Tooltip
          key={i + " popup"}
          placement="left"
          title={<>
            {e.dsMentor
              ? <span>{"Group Chuyên Đề " + e?.id} <br /> {e.tenChuyenDe}</span>
              : <span>{`Group ${e?.maLoai === 'CAPS' ? 'Capstone' : "Dự án"} - ${e?.tenChuyenDe}`} <br />Task: {e?.tenBaiHoc} </span>}
          </>}
          arrow={mergedArrow}
          color="black"
        >
          <div
            className="acc_mess"
            onClick={() => {
              PushDataTabChat(e)
            }}

          >
            <img style={{width:'100%'}} src={getHinhAnh2(e)} alt={e.id} />
            {/* {<LoadAvatar url={e.hinhAnh} conent={e.id} key={e.id} />} */}
          </div>
        </Tooltip>
      );
    });
  };


  useEffect(() => {
    setPopup(dspopup)

  }, [dspopup?.length])
  return (
    <div className="popup_chat_container">
      {popup?.length > 0 && <div className="group">

        <div className="popup_mes" style={style}>
          {rederPopup()}

        </div>
      </div>} <Tooltip className="tooltipAvatar" placement="left" title={"Danh Sách Chat"} arrow={mergedArrow}>
        <div className="popup_dsChat" onClick={
          ()=>{
            // showDrawer()
            setOpenDrawer(true)
          }
        }>

          <UserOutlined />

        </div>
      </Tooltip>
    </div>
  );
};

export default PopupChat;
