import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  postOrPutRequireForStudentApi,
  setRequireForStudentsByJson,
  setRequires,
} from "../../../redux/GroupForTaskReducer/GroupForTaskReducer";
import { RequireForStudentModel } from "../../../Types/RequireForStudent";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import styled from "styled-components";
import { addTask, deleteTask, onChange } from "./static/utils";
import XDrop from "./component/drop";
import { SettingOutlined } from "@ant-design/icons";
import XDrag from "./component/drag";
import XColumn from "./component/column";
import { IData, ITask } from "../../../Types/groupChatType";
import { useParams } from "react-router-dom";
import { getRequireForStudentByTaskIdApi } from "../../../redux/GroupForTaskReducer/GroupForTaskReducer";
import { Skeleton } from "antd";
import { NguoiDungGroupForTask } from "../../../Types/groupForTask";

const TaskAssignmentTableCSS = styled.div`
  .column-content {
    display: flex;
    justify-content: space-evenly;
    /* width: 500px; */
    border-radius: 5%;
    padding: 10px;
    /* box-shadow: inset 0px 0px 14px 5px rgba(239, 239, 42, 0.068); */
    transition: all 0.3s;
  }
`;

/**
 *
 * Các trường hợp requireForStudents có thể có
 *
 * Nếu chưa có : miragen từ require sang
 * Nếu có   : + ko đủ số lượng  > tạo thêm theo số require yêu cầu
 *            + đủ ròi  ...
 *
 * => xắp xếp ngẫu nhiên cho các học viên
 *
 *
 *
 */

const TaskAssignmentTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    baiHoc,
    requireForStudents,
    checkRequireForStudents,
    arrDSNguoiDung,
  } = useAppSelector((state) => state.groupForTaskReducer);

  const [data, setData] = useState<IData>({
    tasks: {},
    columns: {},
    columnOrder: [],
  });
  // console.log(data)

  const handleSubmit = async (ds: RequireForStudentModel[]) => {
    await dispatch(postOrPutRequireForStudentApi(ds));
    await dispatch(getRequireForStudentByTaskIdApi(Number(id)));
  };

  const converToRequireForStuden = (data: IData): RequireForStudentModel[] => {
    const tasksArray: RequireForStudentModel[] = Object.values(data.tasks).map(
      (task) => task.content
    );
    // console.log(tasksArray)
    return [...tasksArray];
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination, draggableId, type } = result;
    if (!destination) return;
    if (onChange(source, destination)) return;
    if (type === "TASK") {
      // console.log(draggableId, source, destination);
      let newData = deleteTask(data, source);
      newData = addTask(newData, destination, draggableId);
      setData(newData);
      handleSubmit(converToRequireForStuden(newData));
    }
    if (type === "COLUMN") {
      let columnOrder = [...data.columnOrder];
      columnOrder.splice(source.index, 1);
      columnOrder.splice(destination.index, 0, draggableId);
      data.columnOrder = columnOrder;
      setData({ ...data });
    }
  };

  const buildDataDND = (
    dsHocVien: NguoiDungGroupForTask[],
    requireForStudents: RequireForStudentModel[]
  ) => {
    const requiresObj: Record<string, ITask> = requireForStudents.reduce(
      (obj: Record<string, ITask>, item) => {
        obj[item.requireId.toString()] = {
          id: item.requireId.toString(),
          content: item,
        };
        return obj;
      },
      {}
    );
    const columnsObj: Record<
      string,
      { id: string; title: string; taskIds: string[] }
    > = dsHocVien.reduce(
      (
        obj: Record<string, { id: string; title: string; taskIds: string[] }>,
        item
      ) => {
        obj[item.id] = {
          id: item.id,
          title: item.hoTen,
          taskIds: requireForStudents
            .filter((e) => e.studentId === item.id)
            .map((rq) => rq.requireId),
          // .filter((e) => e.studentId === item)
          // .map((rq) => rq.requireId.toString()),
        };
        return obj;
      },
      {}
    );

    const columnOrder: string[] = [];

    for (const key in columnsObj) {
      columnOrder.push(key);
    }

    const data: IData = {
      tasks: requiresObj,
      columns: columnsObj,
      columnOrder,
    };
    return { ...data };
  };

  useEffect(() => {
    if (baiHoc.noiDung && !checkRequireForStudents)
      dispatch(
        setRequireForStudentsByJson(baiHoc.noiDung)
        // setRequireForStudentsByJson(data4)
      );
     if (baiHoc.noiDung) {
      dispatch(setRequires(baiHoc.noiDung));
      // dispatch(setRequires(data4));
    }
  }, [baiHoc]);

  useEffect(() => {
    const data = { ...buildDataDND(arrDSNguoiDung, requireForStudents) };
    setData(data);
  }, [requireForStudents, arrDSNguoiDung]);

  return (
    <TaskAssignmentTableCSS>
      <h4 className="heading">
        <SettingOutlined /> Bảng Phân Công Các Công Việc{" "}
      </h4>
      {data.columnOrder.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <XDrop
            className="column-content"
            droppableId="all-columns"
            type="COLUMN"
            direction="horizontal"
          >
            {data.columnOrder.map((columnId, index) => {
              const column = data.columns[columnId];
              const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);
              return (
                <XDrag
                  className={`col-${12 / data.columnOrder.length}`}
                  key={columnId}
                  draggableId={columnId}
                  index={index}
                  dragAll={false}
                >
                  <XColumn column={column} tasks={tasks} />
                </XDrag>
              );
            })}
          </XDrop>
        </DragDropContext>
      ) : (
        <Skeleton className="column-content" active />
      )}
    </TaskAssignmentTableCSS>
  );
};
export default TaskAssignmentTable;
