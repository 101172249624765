
import { http } from "../util/config";

const GET_PATH: string = "/api/file";


const layDuongDanVideoService = (fileName: string) => {
  return http.get(`${GET_PATH}/ftp-video/${fileName}`);
};

const layDuongDanRecordService = (fileName: string) => {
  return http.get(`${GET_PATH}/ftp-video-record/${fileName}`);
};
const layDuongDanSolutionService = (fileName: string) => {
  return http.get(`${GET_PATH}/ftp-video-solution/${fileName}`);
};

const xoaVideoFtpService = (folder: string, fileName: string) => {
  return http.delete(`${GET_PATH}/delete-ftp-video/${folder}/${fileName}`, { headers: { "apiKey": "MKQ7oCoZcX33i2zq" } });
};

export const fileService = {
  layDuongDanRecordService,
  layDuongDanVideoService,
  layDuongDanSolutionService,
  xoaVideoFtpService
};
