import React, { Fragment, useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import BottomTab from "../components/BottomTab";
import HeaderHome from "../components/HeaderHome";
import ResponsiveItem from "../hoc/ResponsiveItem";
import { useAppSelector } from "../redux/hooks";
import { getStore, getStoreJson, removeStore, setStore } from "../util/config";
import { nguoiDungService } from "../services/nguoiDungService";
import {
  BellOutlined,
  CommentOutlined,
  ContainerOutlined,
  CustomerServiceOutlined,
  HomeOutlined,
  ReadOutlined,
  ApartmentOutlined,
  ProfileOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  CheckSquareOutlined,
  BookOutlined,
  BarChartOutlined,
} from "@ant-design/icons";

import Loading from "../hoc/loading/Loading";
import "../assets/style/popconfirm.css";
import {
  MenuProps,
  message,
} from "antd";
import { Menu } from "antd";
import Mentor from "../pages/Mentor/Mentor";
import CustomDrawer from "../components/Mentor/Task/customDrawer";
import { useDispatch } from "react-redux";
import { setOpenDrawerAciton } from "../redux/StackChatReducer/StackChatReducer";
import DanhSachChat from "../components/DanhSachChat/DanhSachChat";
import PopupStackChat from "../components/Popupchat/PopupStackChat";
import ModalDoiMatKhau from "../components/ModalDoiMatKhau/ModalDoiMatKhau";
import { connection, history} from "..";
import NotificationSound from "../components/NotificaSound/NotificationSound";
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}


type Props = {};

const divFunc: React.FC = (props) => {
  return <div></div>;
};

const HomeTemplate = (props: Props) => {
  
  const [playSound, setPlaySound] = useState(false);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openDrawer } = useAppSelector((s) => s.StackChatReducer)
  const setOpenDrawer = (b: boolean) => {
    dispatch(setOpenDrawerAciton(b))
  }
  const setCloseDrawer = () => {
    dispatch(setOpenDrawerAciton(false))
  }
  const { userLogin } = useAppSelector((state) => state.userReducer);

  let nhomQuyen = userLogin ? userLogin.maNhomQuyen : null;

  let items: MenuItem[] = [
    getItem(<NavLink className="text-decoration-none" to="/home"> Home</NavLink>, '1', <HomeOutlined />),

    getItem('QL module', 'sub1', <ReadOutlined />, [
      getItem(<NavLink className="text-decoration-none" to="/ql-chuyende"> Module </NavLink>, '2', <ApartmentOutlined />),
      getItem(<NavLink className="text-decoration-none" to="/monhoc"> Môn học </NavLink>, '3', <ReadOutlined />),
      getItem(<NavLink className="text-decoration-none" to="/baihoc"> Nhiệm vụ </NavLink>, '4', <ProfileOutlined />),
    ]),
    getItem('Hệ thống', 'sub4', <i className="fas fa-cogs"></i>, [
      getItem(<NavLink className="text-decoration-none" to="/quan-li-goi"> QL Gói </NavLink>, '16', <i className="fas fa-clipboard-list"></i>),

      getItem(<NavLink className='text-decoration-none' to='/code-demo'> Code demo</NavLink>, '15', <i className="fas fa-qrcode"></i>),
      getItem(<NavLink className="text-decoration-none" to="/quiz-test"> Bài Test</NavLink>, '10', <ContainerOutlined />),
      getItem(<NavLink className="text-decoration-none" to="/thong-ke-free"> Thống kê free</NavLink>, '17', <BarChartOutlined />),
    ]),

    getItem('Người dùng', 'sub2', <UserOutlined />, [
      getItem(<NavLink className="text-decoration-none" to="/quan-li-user"> QL người dùng </NavLink>, '8', <UsergroupAddOutlined />),
      getItem(<NavLink title="Mentor chấm công" className="text-decoration-none" to="/ql-chamcong">Mentor chấm công</NavLink>, '14', <UsergroupAddOutlined />),

    ]),


    getItem(<NavLink className="text-decoration-none" to="/review-task"> Review Task</NavLink>, '9', <CheckSquareOutlined />),
    getItem(
      <NavLink className='text-decoration-none' to='/mentor'> Chấm bài</NavLink>, '11', <HomeOutlined />
    ),

    getItem(
      <NavLink className='text-decoration-none' to='#' onClick={() => {
        setOpen(!open);
      }} > Đổi mật khẩu</NavLink>, '12', <i className="fas fa-unlock-alt"></i>
    ),
    getItem(
      <NavLink className='text-decoration-none' to='thong-ke-hoc-tap'> Thống kê học tập</NavLink>, '18', <BarChartOutlined />
    ),
    getItem(
      <NavLink className='text-decoration-none' to='/login' onClick={() => {
        removeStore('USER_LOGIN')
        removeStore('path')
      }}> Logout</NavLink>, '13', <i className="fas fa-sign-out-alt"></i>
    ),
  ];



  if (nhomQuyen == "LECTURE") {
    items = [getItem(<NavLink className="text-decoration-none" to="/home"> Home</NavLink>, '1', <HomeOutlined />),
    getItem('QL Lộ trình', 'sub1', <ReadOutlined />, [
      getItem(<NavLink className="text-decoration-none" to="/ql-chuyende"> Module </NavLink>, '2', <ApartmentOutlined />),
      getItem(<NavLink className="text-decoration-none" to="/baihoc"> Nhiệm vụ </NavLink>, '3', <ProfileOutlined />),

    ]),
    getItem(
      <NavLink className='text-decoration-none' to='/mentor'> Chấm bài</NavLink>, '4', <HomeOutlined />
    ),
    
    // getItem(<NavLink className="text-decoration-none" to="/review-task"> Review Task</NavLink>, '9', <CheckSquareOutlined />),

    getItem(
      <NavLink className='text-decoration-none' to='#' onClick={() => {
        setOpen(!open);
      }} > Đổi mật khẩu</NavLink>, '5', <i className="fas fa-unlock-alt"></i>
    ),

    getItem(
      <NavLink className='text-decoration-none' to='/login' onClick={() => {
        removeStore('USER_LOGIN')
        removeStore('path')
      }}> Logout</NavLink>, '13', <i className="fas fa-sign-out-alt"></i>
    ),
    ]

  }

  //   useEffect(() => {
  //     // Lưu đường dẫn URL vào localStorage
  //     localStorage.setItem("lastPath", location.pathname);
  //   }, [location]);

  // useEffect(() => {
  //   const path = window.location.pathname;

  //   if (path !== "/home" && path !== "/login" && userLogin?.maNhomQuyen==="ADMIN") {
  //     setStore("path", path);
  //     navigate("/");
  //   }
  // }, []);


  // useEffect(() => {
  //   if (!getStore("USER_LOGIN")) {
  //     navigate("/login");
  //   }
  // }, []);

  useEffect(() => {
    if (!getStore("USER_LOGIN")) {
      navigate("/login");
    } else {
      try {
        nguoiDungService.checkPassNguoiDung().then((res) => {
          if (res?.data?.content === "0") {
            removeStore("USER_LOGIN");
            removeStore('path')
            navigate("/login");
          }
        });
      } catch (error) {
        navigate("/login");
      }
    }
  }, [window.location.pathname]);

  useEffect(() => {

    const path = window.location.pathname;
    if (path !== "/home" && path !== "/login") {
      setStore("path", path);
      navigate("/home");
    }
  }, []);
  useEffect(() => {
    
    try{
      connection.on('ReceiveMessage', (userM) => {
        if(userM.includes(userLogin?.id)){
          setPlaySound(true)
        setTimeout(() => setPlaySound(false), 1000)
        }
      })
      connection.on('ReceiveMessage2', (userM) => {
        if(userM.includes(userLogin?.id)){
          setPlaySound(true)
        setTimeout(() => setPlaySound(false), 1000)
        }
      })
    }catch (error) {
      // success("Bạn vừa kết nối thành công chat !");
      message.error('Fail Connection')
    }
}, [connection]);

  // useEffect(() => {
  //   if (!getStore("USER_LOGIN")) {
  //     navigate("/login");
  //   }
  // }, []);

  //* Điều hướng trang theo role
  // useEffect(() => {
  //   if (!getStore("USER_LOGIN")) {
  //     navigate("/login");
  //   }
  //    else {
  //     const user = getStoreJson("USER_LOGIN") as NguoiDungType; // Giả sử vai trò của người dùng được lưu trong biến ROLE
  //     // if (user.maNhomQuyen === "ADMIN") {
  //     //   navigate("/home");
  //     // } else
  //     if (user.maNhomQuyen === "MENTOR") {
  //       navigate("/mentor");
  //     }
  //     else
  //     navigate("/")
  //   }
  // }, []);

  //Mỗi khi chuyển url, Nếu chưa đăng nhập thì điều hướng về login
  // useEffect( () => {
  //   // const path = window.location.pathname;
  //   // if (path !== "/home" && path !== "/login" && path !== "/mentor") {
  //   //   if(getStore('path'))
  //   //   setStore("prevPath",getStore('path'))
  //   //   setStore("path", path);
  //   // }

  //   if (!getStore("USER_LOGIN")) {
  //     navigate("/login");
  //   } else {
  //     try {
  //       nguoiDungService.checkPassNguoiDung().then((res) => {
  //         if (res?.data?.content === "0") {
  //           removeStore("USER_LOGIN");
  //           navigate("/login");
  //         }
  //       });
  //     } catch (error) {
  //       console.log('checkPassNguoiDung',error);
  //       navigate("/login");
  //     }
  //   }
  // }, [window.location.pathname]);

  return (
    <Fragment>
      {userLogin?.maNhomQuyen === "MENTOR" ? (
        <Mentor />
      ) : (
        <div className="d-flex">
          <div style={{ width: "10%" }}>
            <Menu
              style={{ height: "100vh" }}
              mode="inline"
              theme="dark"
              items={items}
            />
          </div>
          <div style={{ width: "90%" }}>

            <div style={{ minHeight: 600 }}>
              <Outlet />
              <PopupStackChat />
              <CustomDrawer
                open={openDrawer}
                setOpen={setOpenDrawer}
                key={'danh sach hs'}
                title={<h3 style={{ color: '#ffffff' }}> Danh Sách Học Viên</h3>}
                children={<DanhSachChat open={true} closeDrawer={setCloseDrawer} /> ?? <></>}
              />
            </div>
          </div>

          <ResponsiveItem component={divFunc} mobileComponent={BottomTab} />
          <Loading />
        </div>
      )}
      <ModalDoiMatKhau open={open} setOpen={setOpen} />
      
      <NotificationSound playSound={playSound} />
    </Fragment>
  );
};

export default HomeTemplate;
