import { Button, DatePicker, DatePickerProps, Drawer, Input, Popconfirm, Radio, RadioChangeEvent, Select, Table, Tag, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import React, { useEffect, useState, useCallback } from "react";
import { NguoiDungType } from "../../Types/nguoiDungType";
import { useAppDispatch } from "../../redux/hooks";
import { getNguoiDungChinhSuaAction } from "../../redux/UserReducer/userReducer";
import useQueryConfig from "../../hooks/useQueryConfig";
import moment, { Moment } from "moment";
import { FacebookOutlined, PlusOutlined } from "@ant-design/icons";
import FilterGoi from "./FilterGoi";
import { getAllArrGoiApi, getUserByPackage, giaHanGoi } from "../../redux/quanLiGoiReducer/quanLiGoiReducer";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/configStore";
import { PackageType, PackageTypeRes } from "../../Types/quanLyGoi";
import { packageService } from "../../services/quanyGoiService";
import FormTaoGoi from "./FormTaoGoi";
import { nguoiDungService } from "../../services/nguoiDungService";
import { chuyenDeService } from "../../services/chuyenDeService";
import dayjs, { Dayjs } from "dayjs";
import { toAliasString } from "../../util/toAliasString";

const { Search } = Input;
const { Option } = Select;

type Props = {};

const QuanLiGoi = (props: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  let { arrGoi } = useSelector((state: RootState) => state.quanLiGoiReducer);
  let { arrChuyenDe } = useSelector((state: RootState) => state.chuyenDeReducer);

  const availableColors = [
    'green', 'cyan', 'gold', 'geekblue', 'purple',
    'magenta', 'volcano', 'orange', 'lime', 'blue'
    
  ];

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * availableColors.length);
    return availableColors[randomIndex];
  }
  const getRandomColorStatic = (so: number) => {
    return availableColors[so]
  }

  const calculateSimilarity = (arr1: number[], arr2: number[]): number => {
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    const intersection = new Set([...set1].filter(x => set2.has(x)));
    return intersection.size;
  };

  const [searchModule, setSearchModule] = useState("");
  const [searchModuleArrUser, setSearchModuleArrUser] = useState("");



  const [openGD, setOpenGD] = useState(false);
  const [infoUser, setInfoUser] = useState<any>(null);
  const [arrHV, setArrHV] = useState<any[]>([]);
  const [infoGoi, setInfoGoi] = useState<PackageTypeRes | null>(null);

  const showDrawerGhiDanh = (item: PackageTypeRes) => {
    setOpenGD(true);
    setInfoGoi(item);
  };

  const onCloseGhiDanh = () => {
    setOpenGD(false);
  };

  const [giaHan,setGiaHan] = useState(false)
  const showDrawerGiaHan=(obj:PackageTypeRes) => { 
    setArrHV([])
    setInfoGoi(obj)
    setGiaHan(true)
   }
   const onCloseGiaHan = () => {
    setGiaHan(false);
  };
  //giahan ngày
  const onChange = (date: Dayjs | null, dateString: string, itemUser: any) => {
    const obj={
      "danhSachKhoaHoc": infoGoi?.danhSachKhoaHoc,
      "nguoiDungId": itemUser?.id,
      "ngayGiaHan": date?.toISOString()
    }
    dispatch(giaHanGoi(obj))
  };
  const columns: ColumnsType<PackageTypeRes> = [
    { title: "ID", dataIndex: "id", key: "id", render: text => <span>{text}</span>, sorter: (a, b) => a.id - b.id },
    { title: "Tên gói", dataIndex: "tenGoi", key: "tenGoi", render: text => <span>{text}</span> },
    {
      title: "Gói", dataIndex: "info", key: "info",
      render: (info, rec) => {
        return <div className="d-flex gap-2">
          {info.map((item: PackageType, idx: number) => {
            return <Tag key={idx} className="my-1" color={getRandomColorStatic(item.thang)} >{item.hocPhi / 1000000} triệu/{item.thang} tháng
              <i className="ps-1 fas fa-pen text-success" onClick={() => onHandleEdit(item)}></i>
              <Popconfirm
                title={<p className="text-light">Xóa gói này?</p>}
                description="Chắc chắn muốn xoá gói này ?"
                onConfirm={() => handleDeleteUser(item.id)}
                okText="Có"
                cancelText="Không"
              >
                <i className="ms-2 fas fa-trash text-danger"></i>
              </Popconfirm>
            </Tag>

          })}
        </div>
      }
    },
    {
      title: "Nội dung",
      render: text => {
        const ds = JSON.parse(text.danhSachKhoaHoc);
        return (
          <div className="gap-2">
            {ds.map((item: number, idx: number) => {
              const cdName = arrChuyenDe?.find(a => a.id === item)?.tenChuyenDe || '';
              return (
                <Tag key={idx} className="my-1" color={getRandomColor()}>
                  {cdName.length > 50 ? `${cdName.substring(0, 47)}...` : cdName}
                </Tag>
              );
            })}
          </div>
        );
      },
      sorter: (a, b) => {
        const dsA = JSON.parse(a.danhSachKhoaHoc) as number[];
        const dsB = JSON.parse(b.danhSachKhoaHoc) as number[];
        return calculateSimilarity(dsA, dsB);
      },
    },
    {
      title: "Gia hạn", dataIndex: "id", key: "id", width: 200,
      render: (id, record) => (
        <div>
          <button
            className='btn btn-info'
            style={{ fontSize: "16px", fontWeight: "bold" }}
            onClick={() => showDrawerGiaHan(record)}
          >
            + Gia hạn
          </button>
        </div>
      ),
    },
    {
      title: "Hành động", dataIndex: "id", key: "id", width: 200,
      render: (id, record) => (
        <div>
          <Button
            className='btn btn-success'
            style={{ fontSize: "16px", fontWeight: "bold" }}
            onClick={() => showDrawerGhiDanh(record)}
          >
            +Ghi danh
          </Button>
        </div>
      ),
    },
  ];
  const dispatch = useAppDispatch();
  const queryConfig = useQueryConfig();
  const [open, setOpen] = useState(false);
  const [itemGoi, setItemGoi] = useState<PackageType | undefined>();

  const onClose = () => {
    setOpen(false);
  };

  const onHandleEdit = (record: PackageType) => {
    setOpen(!open);
    setItemGoi(record);
  };

  const handleDeleteUser = (id: number) => {
    packageService.deletePackageService(id)
      .then(() => {
        dispatch(getAllArrGoiApi());
        messageApi.open({ type: "success", content: "Xoá Thành Công" });
      })
      .catch(() => {
        messageApi.open({ type: "error", content: "Có lỗi xảy ra vui lòng thử lại" });
      });
  };

  const [arrRes, setArrRes] = useState<PackageTypeRes[]>([]);
  const [arrResRender, setArrResRender] = useState<PackageTypeRes[]>([]);

  useEffect(() => {
    if (arrGoi) {
      const res: PackageTypeRes[] = [];
      arrGoi.forEach(item => {
        const existingItem = res.find(r => r.danhSachKhoaHoc === item.danhSachKhoaHoc);
        if (existingItem) {
          existingItem.info.push(item);
        } else {
          res.push({ ...item, info: [item] });
        }
      });
      setArrRes(res);
      setArrResRender(res);
    }
  }, [arrGoi]);

  useEffect(() => {
    dispatch(getAllArrGoiApi());
  }, [dispatch]);
  useEffect(() => {
    if (arrRes) {
      let newarrRes = arrRes?.filter(item => toAliasString(item.tenGoi).indexOf(toAliasString(searchModule)) !== -1);
      setArrResRender(newarrRes)
    }
  }, [searchModule]);

  //select gói ghi danh
  const [selectGoi, setSelectGoi] = useState<number>(0);
  const onChangeGoi = (value: number) => {
    setSelectGoi(value)
  };
  return (
    <>

      <FormTaoGoi
        isModalOpen={open}
        handleCancel={onClose}
        model={itemGoi}
      />
      <div className="p-4">
        <FilterGoi queryConfig={queryConfig} />
        <Search
          placeholder="Tên gói"
          allowClear
          enterButton="Tìm"
          style={{ width: 300 }}
          className='ms-3 my-3'
          onSearch={value => setSearchModule(value)}
        />
        <Table className="mt-2" columns={columns} dataSource={arrResRender ? arrResRender : []} />
      </div>
      <Drawer
        title="Ghi danh"
        width="40%"
        onClose={onCloseGhiDanh}
        open={openGD}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <div className='row'>
          <div className="col-6 mb-3">
            <Select
              placeholder="Chọn gói"
              onChange={onChangeGoi}
            >
              {Array.from({ length: 12 }, (_, i) => i + 1).map((number) => (
                <Option key={number} value={number}>
                  {number} tháng
                </Option>
              ))}
            </Select>
          </div>
          <div className='col-12'>
            <Search
              placeholder="Nhập email học viên"
              allowClear
              enterButton="Tìm"
              onSearch={value => {
                nguoiDungService.searchNguoiDung(value).then(res => {
                  setInfoUser(res.data.content);
                }).catch(() => {
                  message.error("Không tìm thấy thông tin !");
                });
              }}
            />
          </div>
          <div className='col-12'>
            {infoUser && (
              infoUser.map((itemUser: any, index: number) => {
                let thongTinMoRong = itemUser ? JSON.parse(itemUser.thongTinMoRong) : null;
                return <>
                  <h4 className='mt-3'><b>Họ tên:</b> {itemUser.hoTen}</h4>
                  <h4 className='mt-3'><b>Email:</b> {itemUser.email}</h4>
                  <h4 className='mt-3'><b>Số ĐT:</b> {itemUser.soDt}</h4>
                  {thongTinMoRong && thongTinMoRong.FacebookUrl && (
                    <a target='_blank' href={thongTinMoRong.FacebookUrl} className='btn btn-sm btn-primary my-3'>
                      <i className="fa-brands fa-facebook"></i> Facebook
                    </a>
                  )}

                  {selectGoi === 0 ? (
                    <Button
                      className='btn btn-success'
                      onClick={() => message.warning("Vui lòng chọn gói")}
                    >
                      Ghi danh
                    </Button>
                  ) : (
                    <Popconfirm
                      title="Bạn chắc có muốn tiếp tục ?"
                      onConfirm={() => {
                        if (infoGoi) {
                          const model = {
                            dsChuyenDe: infoGoi.danhSachKhoaHoc,
                            nguoiDungId: itemUser.id,
                            thoiHan: selectGoi
                          };
                          chuyenDeService.ghiDanhPackage(model).then(() => {
                            message.success("Ghi danh thành công");
                          }).catch(err => {
                            message.error(err.response.data.message);
                          });
                        }
                      }}
                    >
                      <button className='btn btn-success'>Ghi danh</button>
                    </Popconfirm>
                  )}

                </>
              }
              ))
            }
          </div>
        </div>
      </Drawer>
      <Drawer
        title="Gia hạn"
        width="40%"
        onClose={onCloseGiaHan}
        open={giaHan}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <div className='row'>
          <div className='col-12'>
          <div className='col-12'>
            <Search
              placeholder="Nhập email hoặc học viên"
              allowClear
              enterButton="Tìm"
              onSearch={value => {
               if(infoGoi){
                packageService.getUserGoiService(infoGoi.danhSachKhoaHoc,value).then(res => {
                  setArrHV(res.data.content);
                }).catch(() => {
                  message.error("Không tìm thấy thông tin !");
                });
               }
              }}
            />
          </div>
            {arrHV && (
              arrHV.map((itemUser: any, index: number) => {
                let thongTinMoRong = itemUser ? JSON.parse(itemUser.thongTinMoRong) : null;
                return <>
                  <h4 className='mt-3'><b>Họ tên:</b> {itemUser.hoTen}</h4>
                  <h4 className='mt-3'><b>Email:</b> {itemUser.email}</h4>
                  <h4 className='mt-3'><b>Số ĐT:</b> {itemUser.soDt}</h4>
                  <span className="text-warning">*Chọn ngày để gia hạn cho tất cả khóa học trong combo </span><br />
                  {thongTinMoRong && thongTinMoRong.FacebookUrl && (
                    <a target='_blank' href={thongTinMoRong.FacebookUrl} className='btn btn-sm btn-primary my-3'>
                      <i className="fa-brands fa-facebook"></i> Facebook
                    </a>
                  )}
                    <DatePicker  onChange={(date, dateString) => onChange(date, dateString, itemUser)} />
                </>
              }
              ))
            }
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default QuanLiGoi;
