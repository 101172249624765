import { http } from "../util/config";
import { MentorChamBaiModel, NopBaiModel } from "../Types/nopBaiType"

const GET_PATH = "/api/nopbai";

const GetDSByGtaskId = (gtaskId: number, mentorId: string) => {
    return http.get(`${GET_PATH}/get-ds-gtaskid-mentorid?gtaskid=${gtaskId}&mentorId=${mentorId}`)
}
const mentorChamBai = (mentorId: string, mentorChamBai: MentorChamBaiModel) => {
    return http.put(`${GET_PATH}/mentorchambai?mentorId=${mentorId}`, mentorChamBai)
}
const GetNopBaisByNguoiDungService = (mentorId: string) => {
    return http.get(`${GET_PATH}/mentorchambai?mentorId=${mentorId}`)
}
const GetNopBaisByNguoiDungServiceV2 = (mentorId: string) => {
    return http.get(`${GET_PATH}/mentorchambaiV2?mentorId=${mentorId}`)
}
const GetMentorChambaiViewmodel = (mentorId: string) => {
    return http.get(`${GET_PATH}/mentorchambaiviewmodel?mentorId=${mentorId}`)
}

const kichHoatBaiTap = (model: any) => {
    return http.post(`${GET_PATH}/kich-hoat-baitap`, model);
}
const GetDSHVNopBaiService = (chuyenDeId:number,monHocId:number,taskId:number)=>{
    return http.get(`${GET_PATH}/${chuyenDeId}/${monHocId}/${taskId}`)
}
const XoaNopBaiService = (id:number)=>{
    return http.delete(`${GET_PATH}/xoaNopBai?taskId=${id}`)
}
//thống kê
const ThongKeNopBai = (chuyenDeId:number,key:string,start:string,end:string)=>{
    return http.get(`${GET_PATH}/thongKeNopBai?chuyenDeId=${chuyenDeId}&key=${key}&start=${start}&end=${end}`)
}
const ExportThongKeNopBai = (chuyenDeId:number,key:string,start:string,end:string)=>{
    return http.get(`${GET_PATH}/exportThongKeNopBai?chuyenDeId=${chuyenDeId}&key=${key}&start=${start}&end=${end}`)
}
const ThongKeThoiGianHoc = (chuyenDeId:number,key:string,start:string,end:string)=>{
    return http.get(`${GET_PATH}/thongKeThoiGianHoc?chuyenDeId=${chuyenDeId}&key=${key}&start=${start}&end=${end}`)
}
const ExportThongKeThoiGianHoc = (chuyenDeId:number,key:string,start:string,end:string)=>{
    return http.get(`${GET_PATH}/exportThongKeThoiGianHoc?chuyenDeId=${chuyenDeId}&key=${key}&start=${start}&end=${end}`)
}
//thong ke doi tac va All
const ThongKeDoiTac = (chuyenDeId:number,key:string,start:string,end:string)=>{
    return http.get(`${GET_PATH}/thongKeDoiTac?chuyenDeId=${chuyenDeId}&key=${key}&start=${start}&end=${end}`)
}
const ExportThongKeDoiTac = (chuyenDeId:number,key:string,start:string,end:string)=>{
    return http.get(`${GET_PATH}/exportThongKeDoiTac?chuyenDeId=${chuyenDeId}&key=${key}&start=${start}&end=${end}`)
}
const ThongKeDoiTacAll = (chuyenDeId:number,key:string,start:string,end:string)=>{
    return http.get(`${GET_PATH}/thongKeDoiTacAll?chuyenDeId=${chuyenDeId}&key=${key}&start=${start}&end=${end}`)
}
const ExportThongKeDoiTacAll = (chuyenDeId:number,key:string,start:string,end:string)=>{
    return http.get(`${GET_PATH}/exportThongKeDoiTacAll?chuyenDeId=${chuyenDeId}&key=${key}&start=${start}&end=${end}`)
}
const ThongKeThoiGianHocDoiTac = (key:string,start:string,end:string)=>{
    return http.get(`/api/thong-ke/lay-thoi-gian-hoc-doi-tac?key=${key}&start=${start}&end=${end}`)
}
const ThongKeThoiGianHocUser = (userId:string,start:string,end:string)=>{
    return http.get(`/api/thong-ke/lay-thoi-gian-hoc-user?userId=${userId}&start=${start}&end=${end}`)
}
const GetBangDiemUser = (userId:string,chuyenDeId:number)=>{
    return http.get(`/api/nguoidung/thongTinUserChuyenDeId?userId=${userId}&chuyenDeId=${chuyenDeId}
`)
}
const DinhChiHocService = (obj:any)=>{
    return http.get(`/api/dangkyhoc/dinhChiHangLoat`,obj)
}
export const nopbaiService = {
    GetDSByGtaskId, mentorChamBai, GetMentorChambaiViewmodel, GetNopBaisByNguoiDungService,
    kichHoatBaiTap,
    GetDSHVNopBaiService,
    XoaNopBaiService,
    GetNopBaisByNguoiDungServiceV2,
    ThongKeNopBai,
    ThongKeThoiGianHoc,
    ExportThongKeThoiGianHoc,
    ExportThongKeNopBai,
    //
    ThongKeDoiTac,
    ThongKeDoiTacAll,
    ExportThongKeDoiTac,
    ExportThongKeDoiTacAll,
    ThongKeThoiGianHocDoiTac,
    ThongKeThoiGianHocUser,
    GetBangDiemUser,
    DinhChiHocService
}