import styled from "styled-components";

export const TaskCss = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  gap: 10px;
  min-height: 100vh;
  width: 100%;
  color: white;
  background-color: #141824;
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-weight: bold;
    gap: 10px;
  }
  .head {
    width: 100%;
    color: #ffffff;
    font-weight: bold;

    padding: 0px 40px;
    gap: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    span{
      cursor: pointer;
      transition: all .3s;
      :hover{
        color: #e6e6e6;
        text-decoration: underline;
      }
    }
  }
  .body {
    width: 100%;
    padding: 0px 20px;
    gap: 10px;
    .top {
      gap: 10px;
      padding: 10px 10px 0px;

      .thongtinduan {
        padding: 15px;
        border: 1px solid #31374a;
        border-radius: 15px 0px 0px 0px;
        .title {
          border-bottom: 1px dashed #31374a;
          padding-bottom: 10px;
        }

        .content {
          /* padding: 0px; */
          .content__item {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            padding: 10px 0;
            gap: 10px;
            text-transform: capitalize;
            p:nth-child(2) {
              color: #eebe47;
            }
          }
        }
      }
      .phancongviec {
        padding: 10px;
        border: 1px solid #31374a;
        border-radius: 0 15px 0px 0px;

        .action {
          background-color: #4e13f3;
          padding: 5px 10px;
          border-radius: 5px;
          transition: all 0.3s;
          cursor: pointer;
          :hover {
            background-color: rgb(59 18 172);
            color: #fff2ff;
          }
        }
        .chiacongviec {
          /* border: 1px solid red; */

          .column-content {
            display: flex;
            justify-content: space-evenly;
            /* width: 500px; */
            border-radius: 5%;
            padding: 10px;
            /* box-shadow: inset 0px 0px 14px 5px rgba(239, 239, 42, 0.068); */
            transition: all 0.3s;
          }
        }
      }
    }
    .bottom {
        .table{
            .ant-table-content{
                table{
                    background-color: radial-gradient(215.31% 215.31% at 57.96% -48.44%, #B1B1B1 0%, rgba(177, 177, 177, 0) 100%);
                }
                thead.ant-table-thead{
                    th.ant-table-cell{
                      background: var(--color-main-title);
                    }
                }
            }
        }
    }
  }
`;
