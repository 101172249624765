import { MonHocModel } from "../Types/monHocType";
import { http } from "../util/config";

const GET_PATH: string = '/api/monhoc';

const layDanhSachMonHoc = () => {
    return http.get(GET_PATH);
}

const themMonHoc = (data: MonHocModel | any) => {
    return http.post(GET_PATH, data);
}
const suaMonHoc = (data: MonHocModel | any) => {
    return http.put(`${GET_PATH}/${data.id}`, data);
}
const xoaMonHoc = (id: number) => {
    return http.delete(`${GET_PATH}/${id}`);
}
const GetMonHocsByListIdService = (ds:number[])=>{
    return http.post(`${GET_PATH}/getmonhocbylist`,ds)
}

export const monHocService = {
    layDanhSachMonHoc,
    themMonHoc,
    suaMonHoc,
    xoaMonHoc,
    GetMonHocsByListIdService
}
