import React, { useMemo, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { config } from '../../../util/config'
import { DashBoardCSS } from '../../../assets/style/DashBoardCss'
import CustomTableData from '../../../components/Mentor/Dashboard/CustomTableData'
import { history } from '../../../index'
import { useAppSelector } from '../../../redux/hooks'
import ComponentAddApi from '../../../hoc/ComponentAddApi'
import { NopBaiViewMentor } from '../../../Types/nopBaiType'
import {
  BulbOutlined,
  CloseOutlined,
  CodeOutlined,
  FileOutlined,
  FormOutlined,
  LoadingOutlined,
  SearchOutlined
} from '@ant-design/icons'
import { converTime, customSorter, dataIndex } from '../Dashboard/DashBoard'
import type { ColumnType, ColumnsType } from 'antd/es/table'
import { Button, Input, InputNumber, InputRef, Modal, Space, Tabs, TabsProps, Tag, Tooltip } from 'antd'
import { FilterConfirmProps } from 'antd/es/table/interface'
import Highlighter from 'react-highlight-words'
import { render } from '@testing-library/react'
import styled from 'styled-components'
import Icon from '@ant-design/icons/lib/components/Icon'
import { CKEditor } from 'ckeditor4-react'
import NoiDungChamBai from './NoiDungChamBai'

export const fillTrangThai = (trangthai: string) => {
  let tt: string
  switch (trangthai) {
    case 'CHO_CHAM':
      tt = 'Chờ Chấm'
      break
    case 'NOP_LAI':
      tt = 'Nộp Lại'
      break
    case "DANG_LAM":
      tt = "Đang làm";
      break;
    case 'DA_CHAM':
      tt = 'Đã Chấm'
      break
    default:
      tt = ''
  }
  return tt
}
export const covertMaLoai = (maLoai: string) => {
  let ml: string
  switch (maLoai) {
    case 'BAITAP':
      ml = 'Bài Tập'
      break
    case 'DUAN':
      ml = 'Dự Án'
      break
    case 'CAPS':
      ml = 'Capstone'
      break
    case 'JOIN_NHOM':
      ml = 'Gia Nhập Nhóm'
      break
    case 'HOMEWORK':
      ml = 'Bài tập VN'
      break
    default:
      ml = ''
  }
  return ml
}
export const ChamBaiCSS = styled.div`

.debai {
  background: var(--color-sub-bg);
  width: 50%;
  padding: 15px;
  border-radius: 10px;

  img {
    width: 100%;
  }
  .nav-item {
    padding-right: 10px;
  }
  .nav-link.chambaiItem {
    background: var(--color-bg);
    color: var(--color-text-hover);
    border-radius: 5px;
    :hover {
      color: white;
      background: var(--color-main-title);
    }
  }
  .nav-link.chambaiItem.active {
    color: white;
    background: var(--color-main-title);
  }
}
  /* height: 400px; */
  padding: 0 3%;
  padding-bottom: 3%;

  font-weight: bold;
  .title {
    color: White;
    display: flex;

    padding: 10px;
    gap: 20px;
    p {
      text-transform: capitalize;
    }
  }
  .head {
    display: flex;
    font-size: 1.6rem;
    align-items: center;

    padding: 10px 0;
    gap: 10px;
    color: white;
    /* background-color: #ffffff; */
    border-radius: 10px;
    border-bottom: 1px solid var(--color-border);
    .hint {
      padding: 10px;
      border-right: 3px solid #31374a;
    }
    .outcome {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      gap: 5px;
      padding: 10px;
    }
    img {
      width: 200px;
      height: 150px;
    }
  }
  .body {
    .title {
      background: var(--color-main-title);
      padding: 10px;
      height: 70px;
    }
    .tabYeucau {
      background: var(--color-main-title);
      padding: 10px;
      height: 70px;
      gap: 10px;
      li {
        button {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: #a49c9c;
          /* background: var(--color-bg-green); */
          border-bottom: none;
          opacity: 0.8;
        }
        button.active {
          opacity: 1;
          color: white;
        }
      }
      li.dacham {
        button {
          width: 55px;
          height: 50px;
          border-radius: 50%;
          background: var(--color-bg-green);
          border-bottom: none;
          opacity: 0.8;
        }
        button.active {
          opacity: 1;
          color: white;
        }
      }
      li.chocham {
        button {
          width: 55px;
          height: 50px;
          border-radius: 50%;
          background: var(--color-bg-yellow);
          border-bottom: none;
          opacity: 0.8;
        }
        button.active {
          opacity: 1;
          color: white;
        }
      }
      /* li.noplai {
        button {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: var(--color-bg-green);
          border-bottom: none;
          opacity: 0.8;
        }
        button.active {
          opacity: 1;
          color: white;
        }
      } */
    }

    
    .contentTab {
      padding: 10px;
      width: 100%;
      color: white;
      .tab-pane {
        gap: 3%;
        
        .chamdiem {
          background: var(--color-sub-bg);
          padding: 15px 20px;
          border-radius: 15px;
          width: 40%;
          .chamdiem_title {
            color: white;
            font-size: 1.3rem;
          }
          .filenop_table {
            table {
              background: var(--color-bg);
            }
            table,
            th,
            td {
              border: 1px solid var(--color-border);
            }
          }
          .chamdiem_inpt {
            display: flex;
            align-items: center;
            gap: 15px;

            .ant-input-number {
              background: transparent;
              border-color: var(--color-border);

              input {
                color: white;
              }
            }
            .ant-input-number.ant-input-number-focused {
              border-color: var(--color-main-title);
            }
            .ant-input-number .ant-input-number-handler-wrap {
              background: transparent;
              display: flex;
              gap: 5px;
              flex-direction: column;
              padding: 2px 0;
            }
            .ant-input-number-handler.ant-input-number-handler-up,
            .ant-input-number-handler.ant-input-number-handler-down {
              background: var(--color-main-title);
              border: none;
              span {
                ::after {
                  content: 'Up';
                  color: white;
                }
                color: white;
                svg {
                  display: none;
                }
              }
              :hover {
                opacity: 0.7;
              }
            }
            .ant-input-number-handler.ant-input-number-handler-up {
              span {
                ::after {
                  content: '\f0d8';
                  font-family: 'Font Awesome 6 Free';
                  color: white;
                  font-size: 0.8rem;
                }
              }
            }
            .ant-input-number-handler.ant-input-number-handler-down {
              span {
                ::after {
                  content: '\f0d7';
                  font-family: 'Font Awesome 6 Free';
                  color: white;
                  font-size: 0.8rem;
                }
              }
            }
          }
          .chamdiem_action {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 0.8rem;

            button {
              width: 92px;
              height: 38px;
              color: white;

              border-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: none;
              outline: none;

              transition: all 0.5s;
              :hover {
                opacity: 0.8;
              }
            }
            .btn-cham {
              background: var(--color-main-title);
            }
            .btn-noplai {
              background: var(--color-bg-blue);
            }
            .btn-huy {
              background: var(--color-bg-red);
            }
          }
        }
      }
      .tab-pane.active {
        display: flex;
      }
    }

    .diem {
      color: white;
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
    }
    .nav-tabs {
      .nav-link.active {
      }
    }
  }
`

function BaiTap() {
  const [loadingTable, setLoadingTable] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const myUser = config.getStoreJson('USER_LOGIN')
  // const { baitapid, idmonhoc, chuyendeid, tennhiemvu, maLoai } = useParams()
  let [searchParams] = useSearchParams();

  // Sử dụng bóc tách phần tử để lấy giá trị của tham số 'id' từ URL
  const { baitapid, idmonhoc, chuyendeid, tennhiemvu, maLoai } = Object.fromEntries(searchParams);
  const idTask = Number(baitapid)
  const idMonHoc = Number(idmonhoc)
  const idChuyenDe = Number(chuyendeid)
  console.log(baitapid, idmonhoc, chuyendeid, tennhiemvu, maLoai )


  const { dsLuyenTapByListId } = useAppSelector((state) => state.LuyenTapReducer)
  const { dsMonhocTheoDSId } = useAppSelector((state) => state.monHocReducer)

  const { DSChuyenDeMentorPhuTrach } = useAppSelector((state) => state.chuyenDeReducer)

  const { dsHocVienByMentorId, dsNopBaiByMentorId, dsNopBaiByMentorIdViewMentor } = useAppSelector(
    (state) => state.nopBaiReducer
  )

  const { dsBaiHocByDsId, dsIdBaiHoc } = useAppSelector((state) => state.baiHocReducer)

  //   if(dsIdBaiHoc.length>0&&dsBaiHocByDsId.length>0){
  //     if(!dsIdBaiHoc.some(e=>e==idTask))
  //     console.log('idTask',idTask);
  //     history.push('/mentor')
  //   }
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [nhanXet, setNhanXet] = useState('')
  const [data, setData] = useState<NopBaiViewMentor | null>(null)
  const searchInput = useRef<InputRef>(null)

  const convertTenHocVien = (id: string) => {
    if (dsHocVienByMentorId.length) {
      const index = dsHocVienByMentorId.findIndex((e) => e.id === id)
      if (index !== -1) return dsHocVienByMentorId[index].hoTen
    }
    return ''
  }

  const handleCancelModal = () => {
    setOpenModal(false)
  }

  // const countTotal = (chuyenDeId: number, taskId: number, monHocId: number, maLoai: string, loaiModule: string) => {
  //   return dsNopBaiByMentorIdViewMentor.filter(
  //     (e) =>
  //       e.chuyenDeId === chuyenDeId &&
  //       e.taskId === taskId &&
  //       e.monHocId === monHocId &&
  //       e.maLoai === maLoai &&
  //       e.loaiModule === loaiModule
  //   ).length
  // }
  // const countChuaCham = (chuyenDeId: number, taskId: number, monHocId: number, maLoai: string, loaiModule: string) => {
  //   return dsNopBaiByMentorIdViewMentor.filter(
  //     (e) =>
  //       e.chuyenDeId === chuyenDeId &&
  //       e.taskId === taskId &&
  //       e.monHocId === monHocId &&
  //       e.maLoai === maLoai &&
  //       e.loaiModule === loaiModule &&
  //       (e.trangThai === 'CHO_CHAM' || e.trangThai === 'NOP_LAI')
  //   ).length
  // }
  const onEditorChange = (evt: any) => {
    // console.log(evt.editor.getData());

    setNhanXet(evt.editor.getData())
  }



  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: dataIndex
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex: dataIndex): ColumnType<NopBaiViewMentor> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
          {/* <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button> */}
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  })

  const findTask = (idMonhoc: number, idThuTuTask: number) => {
    const indexMonhoc = dsMonhocTheoDSId.findIndex((e) => e.id === idMonhoc)
    if (indexMonhoc !== -1) {
      const indexTask = dsMonhocTheoDSId[indexMonhoc].danhSachNoiDung.findIndex((e) => e.id === idThuTuTask)

      if (indexTask !== -1) {
        const IdTask = Number(dsMonhocTheoDSId[indexMonhoc].danhSachNoiDung[indexTask].noiDungNhiemVu)
        const index = dsBaiHocByDsId.findIndex((e) => e.id === IdTask)
        if (index !== -1) return dsBaiHocByDsId[index] ?? null
      }
    }
    return null
  }


  const newColumns: ColumnsType<NopBaiViewMentor> = [
    // Tên học viên
    // Tiến trình
    // Trạng thái
    // Điểm
    // Lịch sử
    // Hành động

    // {
    //   title: 'STT',
    //   key: 'STT',
    //   render: (_, __, index) => {
    //     return index + 1
    //   }
    // },
    {
      title: 'Tên học viên',
      dataIndex: 'tenNguoiDung',
      key: 'tenNguoiDung',
      // width: '150px',
      align: 'center',

      ...getColumnSearchProps('tenNguoiDung')
      // sorter: (a, b) => a.taskId - b.taskId
    },
    {
      title: 'Tiến độ',
      dataIndex: 'tienTrinh',
      key: 'tienDo',
      align: 'center',
      render: (value, record, index) => {


        let nopBaiUser = dsNopBaiByMentorIdViewMentor.filter((e) => e.taskId == idTask && e.monHocId == idMonHoc && e.chuyenDeId == idChuyenDe && e.maLoai === maLoai && e.nguoiDungId == record.nguoiDungId && (e.trangThai == "CHO_CHAM" || e.trangThai == "DA_CHAM"))

        let noiDungTask = findTask(idMonHoc, idTask);

        let checkNop = nopBaiUser.filter(n => n.trangThai == "CHO_CHAM")

        return maLoai != "QUIZ" && maLoai != "RUNCODE" && <> <Tag color='magenta'>
          <b>{nopBaiUser.length} / {noiDungTask?.noiDung.length} </b>
        </Tag>
          {checkNop.length > 0 && <Tag color='green'> Chờ chấm</Tag>}
        </>
      }
    },
    {
      title: 'Đã chấm',
      dataIndex: 'trangThai',
      key: 'trangThai',
      align: 'center',
      width: '150px',


      render: (value, record, index) => {
        let nopBaiUser = dsNopBaiByMentorIdViewMentor.filter((e) => e.taskId == idTask && e.monHocId == idMonHoc && e.chuyenDeId == idChuyenDe && e.maLoai === maLoai && e.nguoiDungId == record.nguoiDungId)

        let daCham: any = nopBaiUser.filter(n => n.trangThai == "DA_CHAM");

        return <label>
          <b>{daCham.length} </b>
        </label>
      }
    },
    {
      title: 'Điểm',
      dataIndex: 'diem',
      key: 'diem',
      align: 'center',
      width: '100px',
      render: (value, record, index) => (
        <>
          <Tooltip
            key={'tool tip' + value + index}
            title={
              record.trangThai !== 'JOIN_NHOM'
                ? `${covertMaLoai(record.maLoai)} Cần Chấm - Yêu Cầu : ${record.requireId}`
                : `Join nhóm task ${record.id}`
            }
          >
            {value ?? 'Chưa Chấm'}
          </Tooltip>
        </>
      ),
      sorter: (a, b) => a.diem - b.diem,
      defaultSortOrder: 'ascend'
    },
    // {
    //   title: 'Lịch sử',
    //   dataIndex: 'lichSu',
    //   key: 'lichSu',
    //   // ...getColumnSearchProps('tenMonHoc'),
    //   // sorter: (a, b) => a.monHocId - b.monHocId,
    //   // sortDirections: ['descend', 'ascend']
    //   render:()=><></>
    // },
    {
      title: 'Bắt đầu ',
      dataIndex: 'ngayTao',
      key: 'ngayTao',
      ...getColumnSearchProps('ngayTao'),
      sorter: customSorter,
      sortDirections: ['descend', 'ascend'],
      render: (value) => converTime(value) ?? value
    },
    {
      title: 'Hạn nộp ',
      dataIndex: 'hanNopBai',
      key: 'hanNopBai',
      ...getColumnSearchProps('hanNopBai'),
      sorter: customSorter,
      sortDirections: ['descend', 'ascend'],
      render: (value) => converTime(value) ?? value
    },
    {
      title: 'Hành động',
      dataIndex: 'lichSu',
      key: 'lichSu',
      width: '150px',
      // ...getColumnSearchProps('tenMonHoc'),
      // sorter: (a, b) => a.monHocId - b.monHocId,
      // sortDirections: ['descend', 'ascend']
      render: (_, record) => {

        return maLoai != "QUIZ" && maLoai != "RUNCODE" && <Space size='middle'>
          <Button
            onClick={() => {

              setData(record)
              setOpenModal(true)
            }}
            className='d-flex align-items-center'
            type='primary'
          >
            <FormOutlined /> Chấm
          </Button>
        </Space>
      }
    }
  ]
  const onChangeChamDiem = (value: number | null) => {
    console.log('changed', value)
  }
  const onChangeTab = (key: string) => {
    // console.log(key)
  }

  const items = new Array(3).fill(null).map((_, i) => {
    const id = String(i + 1)
    return {
      label: `Tab ${id}`,
      key: id,
      children: `Content of Tab Pane ${id}`,
      style: i === 0 ? { height: 200 } : undefined
    }
  })
  const dataFilter = (data: NopBaiViewMentor[]) => {

    if (maLoai != "QUIZ" && maLoai != "RUNCODE") {
      data = data.filter(n => n.requireId == 1)
    }

    const newData = data.filter((e, i, self) => {
      return (
        i ===
        self.findIndex(
          (item) =>
            item.nguoiDungId === e.nguoiDungId &&
            // item.maLoai === e.maLoai &&
            // item.monHocId === e.monHocId &&
            item.taskId === e.taskId &&
            item.trangThai === e.trangThai

          // &&item.hanNopBai === e.hanNopBai
        )
      )
    })

    return newData.map((item, index) => {
      return { ...item, key: index + 1 }
    })
  }
console.log(dsNopBaiByMentorIdViewMentor.filter((e) => e.taskId == idTask && e.monHocId == idMonHoc && e.chuyenDeId == idChuyenDe && e.maLoai === maLoai))

  //!call api
  const { callAPI } = ComponentAddApi();

  const memoizedChamDiem = <NoiDungChamBai
    callAPI={callAPI}
    handleCancelModal={handleCancelModal}
    data={data}
    dataNopBai={dsNopBaiByMentorIdViewMentor.filter(
      (e) => e.taskId == idTask && e.chuyenDeId == idChuyenDe && e.monHocId == idMonHoc
    )}
  />


  return (
    <DashBoardCSS>
      <div className='head'></div>
      <div className='mid'>
        <div className='title'>

          <h4>{tennhiemvu ?? 'Task Và Các Bài Tập Cần Chấm'}</h4>
        </div>
        <div className='baitap'>
          <div className='noiDung'>
            <CustomTableData
              // IsTask={true}
              loadingTable={loadingTable}
              columns={newColumns}
              setData={setData}
              dataSource={dataFilter(
                dsNopBaiByMentorIdViewMentor.filter((e) => e.taskId == idTask && e.monHocId == idMonHoc && e.chuyenDeId == idChuyenDe && e.maLoai === maLoai)
              )}
            />
          </div>
        </div>
      </div>
      <Modal
        style={{
          maxWidth: '1200px',
          top:10
        }}
        className='mentor__modal'
        width={'90%'}
        title=''
        open={openModal}
        okText=''
        cancelText=''
        // onOk={handleOkModal}
        onCancel={handleCancelModal}
        closeIcon = {<span style={{ color: '#fff' }}><CloseOutlined/></span>}
        footer=''
      >
        {openModal && (
          // <NoiDungChamBai
          // callAPI={callAPI}
          //   handleCancelModal={handleCancelModal}
          //   data={data}
          //   dataNopBai={dsNopBaiByMentorIdViewMentor.filter(
          //     (e) => e.taskId == idTask && e.chuyenDeId == idChuyenDe && e.monHocId == idMonHoc
          //   )}
          // />
          memoizedChamDiem
        )}
      </Modal>
    </DashBoardCSS>
  )
}

export default BaiTap
