import axios from "axios";
import { API_RUNCODE_DOMAIN, API_URL_DOMAIN } from "./urlDomain";
import { history } from "..";

// import {history} from '../index';
export const config = {
  setCookie: (name: string, value: string, days: number) => {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  },
  getCookie: (name: string) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },
  getStore: (name: string): any => {
    if (localStorage.getItem(name)) {
      return localStorage.getItem(name);
    }
    return null;
  },
  setStore: (name: string, value: any) => {
    localStorage.setItem(name, value);
  },
  setStoreJson: (name: string, value: any) => {
    let json = JSON.stringify(value);
    localStorage.setItem(name, json);
  },
  getStoreJson: (name: string) => {
    if (localStorage.getItem(name)) {
      let result: any = localStorage.getItem(name);
      return JSON.parse(result);
    }
    return null;
  },
  removeStore: (name: string) => {
    localStorage.removeItem(name);
  },
  ACCESS_TOKEN: "accessToken",
  USER_LOGIN: "userLogin",
};

export const {
  setCookie,
  getCookie,
  getStore,
  setStore,
  setStoreJson,
  removeStore,
  getStoreJson,
  ACCESS_TOKEN,
  USER_LOGIN,
} = config;

/* Cấu hình request cho tất cả api - response cho tất cả kết quả từ api trả về */

//Cấu hình domain gửi đi
export const http = axios.create({
  baseURL: API_URL_DOMAIN,
  // timeout: 30000,
});
//Cấu hình request header
http.interceptors.request.use(
  (config: any) => {
    // const token = getStoreJson("USER_LOGIN").token;
    config.headers = {
      ...config.headers,
        // ["Authorization"]: `Bearer ${token}`,
    };
    // config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
//Cấu hình kết quả trả về
http.interceptors.response.use(
  (response) => {
  
    return response;
  },
  (err) => {
    const originalRequest = err.config;
  
    if (err?.response?.status === 400 || err?.response?.status === 404) {
      // history.push('/');
      return Promise.reject(err);
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      alert("Token không hợp lệ ! Vui lòng đăng nhập lại !");
      // history.push('/login');
      return Promise.reject(err);
    }
  }
);


export const https = axios.create({
  baseURL: API_RUNCODE_DOMAIN,
  headers: {},
});
//Cấu hình request header
https.interceptors.request.use(
  (config: any) => {
    // const token = getStoreJson("USER_LOGIN").token;
    config.headers = {
      ...config.headers,
        // ["Authorization"]: `Bearer ${token}`,
    };
    // config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
//Cấu hình kết quả trả về
https.interceptors.response.use(
  (response) => {

    return response;
  },
  (err) => {
    // const originalRequest = error.config;
  
    if (err?.response?.status === 400 || err?.response?.status === 404) {
      // history.push('/');
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      alert("Token không hợp lệ ! Vui lòng đăng nhập lại !");
      history.push('/login');

    }

    return Promise.reject(err);

  }
);


