import React, { useEffect } from "react";
import ProjectInformation from "./ProjectInformation";
import ProjectInstructions from "./ProjectInstructions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getBaiHocApi,
  getGroupForTaskApi,
  getRequireForStudentByTaskIdApi,
  getThongTinMentorApi,
  layDsNguoiDungByStringApi,
} from "../../redux/GroupForTaskReducer/GroupForTaskReducer";
import styled from "styled-components";
import { history } from "../../index";
import { useParams } from "react-router-dom";
import { config } from "../../util/config";
import { getDsNopBaiByGroupApi } from "../../redux/NopBaiReducer/nopBaiReducer";
// import { deCode } from "../../util/jwtUtils";


const GroupForTaskCSS = styled.div`
  background-color: antiquewhite;
  font-family: 'Cormorant Garamond';
  padding-bottom: 50px;

  .heading {
    margin-bottom: 10px;
    color: rgb(177, 110, 75);
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;

// type role = "ADMIN" | "MENTOR";

const GroupForTask: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  // console.log(history);
  const { id } = useParams();

  config.setStore("GROUP_FOR_TASK",id)
  const token = "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjA1ZDk1NTU3LTEwMzEtNGRiMy04OWVjLTI4NmM5NDgyZGM3NyIsImVtYWlsIjoibGFtaHV5bmh0aGllbnRpbmhAZ21haWwuY29tIiwicm9sZSI6IlZJRVdfUk9MRSIsIm5iZiI6MTY4MzYwMzk2MiwiZXhwIjoxNjgzNjkwMzYyfQ.2bVELlAdCoCKGa8r0c5iYDZOBYn4iAqayfs8_ZiMie4";
  config.setStore('accessToken',token);

  // const {id:tkId,role,email} = deCode(token)
  // console.log(tkId,role,email)
  

  const { groupForTask, baiHoc,arrDanhSachHocVien,requires,requireForStudents,checkRequireForStudents } = useAppSelector(
    (state) => state.groupForTaskReducer
  );
  // console.log(requires,checkRequireForStudents,requireForStudents)
 
  useEffect(() => {
    //fetch data  của groupForTask bởi id truyền vào
    dispatch(getGroupForTaskApi(Number(id)));

    //fetch data  của requireForStudent bởi id truyền vào
    dispatch(getRequireForStudentByTaskIdApi(Number(id)));
  }, [id]);

  useEffect(() => {
    if (groupForTask.taskId) dispatch(getBaiHocApi(groupForTask.taskId));

    if (groupForTask.mentorId)
      dispatch(getThongTinMentorApi(groupForTask.mentorId));

    if(arrDanhSachHocVien.length)
    dispatch(layDsNguoiDungByStringApi(arrDanhSachHocVien))

    if(groupForTask.mentorId)
    dispatch(getDsNopBaiByGroupApi(Number(id),groupForTask.mentorId))
  }, [groupForTask]);

  // if (maNhomQuyen === "ADMIN" || maNhomQuyen === "MENTOR")
  return (
    <GroupForTaskCSS>
      <h3 className="heading">TASK: {baiHoc.tenBaiHoc}</h3>
      <ProjectInformation />
      <hr />
      <ProjectInstructions />
    </GroupForTaskCSS>
  );
};

export default GroupForTask;
