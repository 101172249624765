import { Input, Modal, Table, Select, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDsLSHTApi, updateDsLSHTApi } from '../../redux/LichSuHocTapReducer/LichSuHocTapReducer';
import { useParams } from 'react-router-dom';
import { DispatchType, RootState } from '../../redux/configStore';
import { LichSuHocTapView, LichSuHocTapViewModel, TaskLSHT } from '../../Types/lichSuHocTap';
import moment from 'moment';

const { Search } = Input;
const { Option } = Select;

type Props = {
  danhSachNoiDung: any[];
};

const LichSuHocTap: React.FC<Props> = ({ danhSachNoiDung }) => {
  const { dsLichSuHocTap } = useSelector((state: RootState) => state.LichSuHocTapReducer);
  const [searchText, setSearchText] = useState<string>('');
  const [openTask, setOpenTask] = useState(false);
  const [valueTask, setValueTask] = useState<number>();
  const [filteredData, setFilteredData] = useState<LichSuHocTapView[]>([]);
  const [currentTasks, setCurrentTasks] = useState<TaskLSHT[]>([]);
  const [taskEdit, setTaskEdit] = useState<LichSuHocTapView>();

  let { monHocId, chuyenDeId } = useParams<{ monHocId: string; chuyenDeId: string }>();
  const dispatch: DispatchType = useDispatch();

  const addTask = () => {
    setOpenTask(true);
  };

  const closeTask = () => {
    setValueTask(undefined)
    setOpenTask(false);
  };

  const columns: ColumnsType<LichSuHocTapView> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Họ Tên',
      dataIndex: ['nguoiDung', 'hoTen'],
      key: 'hoTen',
    },
    {
      title: 'Email',
      dataIndex: ['nguoiDung', 'email'],
      key: 'email',
    },
    {
      title: 'Số Điện Thoại',
      dataIndex: ['nguoiDung', 'soDt'],
      key: 'soDt',
    },
    Table.EXPAND_COLUMN,
  ];

  const columnsChild: ColumnsType<TaskLSHT> = [
    {
      title: 'Ngày',
      dataIndex: 'NgayThangId',
      key: 'NgayThangId',
      render: (text) => {
        let dateTime = moment.unix(text);
        return <p>{dateTime.format('DD-MM-YYYY HH:mm')}</p>;
      },
    },
    {
      title: 'TaskId',
      dataIndex: 'TaskId',
      key: 'TaskId',
    },
    {
      title: 'Tên task',
      dataIndex: 'TaskId',
      key: 'tenTask',
      render: (text, rec) => {
        let item: any = danhSachNoiDung.find((detail: any) => detail?.id === text);
        return <p>{item?.tenNhiemVu}</p>;
      },
    },
    {
      title: 'MaLoai',
      dataIndex: 'MaLoai',
      key: 'MaLoai',
    },
    {
      title: 'NoiDung',
      dataIndex: 'NoiDung',
      key: 'NoiDung',
    },
  ];

  useEffect(() => {
    dispatch(getDsLSHTApi(Number(chuyenDeId), Number(monHocId)));
  }, [dispatch, chuyenDeId, monHocId]);

  useEffect(() => {
    setFilteredData(dsLichSuHocTap);
  }, [dsLichSuHocTap]);

  const handleSearch = (value: string) => {
    setSearchText(value);
    const filtered = dsLichSuHocTap.filter((item) =>
      item.nguoiDung.hoTen.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleExpand = (record: LichSuHocTapView) => {
    const dataChild = JSON.parse(record.danhSachHoanThanh);
    setCurrentTasks(dataChild);
    setTaskEdit(record);
    addTask();
  };

  const availableTasks = danhSachNoiDung.filter(
    (task) => !currentTasks.some((currentTask) => currentTask.TaskId === task.id)
  );
  const onChangeSelectTask = (e: number) => {
    setValueTask(e)
  }
  const onSave = () => {
    if (valueTask) {
      let noidung: TaskLSHT[] = JSON.parse(taskEdit?.danhSachHoanThanh || '[]')
      let item: any = danhSachNoiDung.find((detail: any) => detail?.id == valueTask);
      const obj: TaskLSHT = {
        NgayThangId: moment().unix() + "",
        TaskId: valueTask,
        MaLoai: item?.maLoaiNhiemVu,
        NoiDung: ""
      }
      noidung.push(obj)
      const newObj:LichSuHocTapViewModel = {
        id: taskEdit?.id||0,
        nguoiDungId: taskEdit?.nguoiDung.id||"",
        chuyenDeId: taskEdit?.chuyenDeId||0,
        monHocId: taskEdit?.monHocId||0,
        danhSachHoanThanh: JSON.stringify(noidung),
        loaiModule: taskEdit?.loaiModule||""
      }
      if(newObj.id!=0){
        dispatch(updateDsLSHTApi(newObj)).then(()=>{
          
          message.success("Cập nhật thành công")
          closeTask()
        }).catch((er)=>{
          message.error("Lỗi vui lòng thử lại")
          closeTask()
        })
        
      }
    }
  }
  return (
    <div>
      <Search
        placeholder="Nhập tên"
        enterButton
        value={searchText}
        onChange={(e) => handleSearch(e.target.value)}
        onSearch={handleSearch}
      />
      <Table
        columns={columns}
        dataSource={filteredData}
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => {
            const dataChild = JSON.parse(record.danhSachHoanThanh);
            return (
              <>
                <Table columns={columnsChild} dataSource={dataChild} size="small" />
                <button className='btn btn-info' onClick={() => handleExpand(record)}>Thêm task hoàn thành</button>
              </>
            );
          },
        }}
      />
      <Modal
        title="Thêm task đã hoàn thành"
        open={openTask}
        onCancel={closeTask}
        width="50%"
        style={{ top: '20px' }}
        onOk={onSave}
      >
        <Select
          value={valueTask}
          style={{ width: '100%' }}
          placeholder="Chọn task"
          onChange={(value) => onChangeSelectTask(value)}
        >
          {availableTasks.map((task) => (
            <Option key={task.id} value={task.id}>
              {task.tenNhiemVu}
            </Option>
          ))}
        </Select>
      </Modal>
    </div>
  );
};

export default LichSuHocTap;
