let URL_CKFINDER = "http://datatext.cybersoft.edu.vn/";

export const changeUrlImage = (data: string) => {
  let newData = data.replaceAll(
    `<img alt="" src="/`,
    `<img alt="" src="${URL_CKFINDER}`
  );

  return newData;
};

export function swapElements<T>(array: T[], indexA: number, indexB: number): void {
  [array[indexA], array[indexB]] = [array[indexB], array[indexA]];
}

export const array_move = (arr: number[], old_index: number, new_index: number) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(-1);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

export const listObject_move = (arr: any[], old_index: number, new_index: number) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(-1);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};
