import { InboxOutlined } from '@ant-design/icons'
import { Input, UploadProps, message } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import { CKEditor } from 'ckeditor4-react'
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { API_URL_RECORD } from '../../util/urlDomain'

type Props = {
    noiDungTaiLieu: string,
    setNoiDungTaiLieu: Dispatch<SetStateAction<string>>

}

const XemRecord = ({
    noiDungTaiLieu,
    setNoiDungTaiLieu
}: Props) => {
    const [key, setKey] = useState(Date.now());

    useEffect(() => {
        setKey(Date.now());

    }, [])


    //set data ck editor bth
    const onEditorChange = (evt: any) => {

        setNoiDungTaiLieu(evt.editor.getData());

    }

    const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {

        setNoiDungTaiLieu(event.target.value);

    }
    const props: UploadProps = {
        name: 'file',
        multiple: true,
        action: API_URL_RECORD,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {

                setNoiDungTaiLieu(info.file.response[0])

            } else if (status === 'error') {
                // message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <div>
            {/* <Input placeholder='link youtube' onChange={onChangeInput} value={noiDungTaiLieu} /> */}

            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>

            </Dragger>
        </div>
    )
}

export default XemRecord