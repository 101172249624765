import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { heThongService } from "../../services/heThongService";

import { DispatchType } from "../configStore";
import { danhSachTagService } from "../../services/danhSachTagService";
import { BaiTestModel, NhomQuyenModel } from "../../Types/heThongType";

type heThongState = {
  arrBaiTest: BaiTestModel[] | null;
  arrNhomQuyen: NhomQuyenModel[] | null;
};
const initialState: heThongState = {
  arrBaiTest: [],
  arrNhomQuyen: [],
};

const heThongReducer = createSlice({
  name: "heThongReducer",
  initialState,
  reducers: {
    getBaiTestAction: (
      state: heThongState,
      action: PayloadAction<BaiTestModel[]>
    ) => {
      state.arrBaiTest = action.payload;
    },

    getNhomQuyenAction: (
      state: heThongState,
      action: PayloadAction<NhomQuyenModel[]>
    ) => {
      state.arrNhomQuyen = action.payload;
    },
  },
});

export const { getBaiTestAction, getNhomQuyenAction } = heThongReducer.actions;
export default heThongReducer.reducer;
//--------- action async ------------

export const getBaiTestApi = () => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await heThongService.layDanhSachBaiTest();

      const action: PayloadAction<BaiTestModel[]> = getBaiTestAction(
        result.data.content
      );
      dispatch(action);
    } catch (error) {
      console.log("Lỗi getBaiTestApi");
      console.log(error);
    }
  };
};

export const getNhomQuyenApi = () => {
  return async (dispatch: DispatchType) => {
    try {
        const result = await heThongService.layNhomQuyen();

    const action: PayloadAction<NhomQuyenModel[]> = getNhomQuyenAction(
      result.data.content
    );
    dispatch(action);
    } catch (error) {
        console.log('lỗi getNhomQuyenApi');
        console.log(error);
    }
  };
};
