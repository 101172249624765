import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DispatchType } from "../configStore";
import { vatPhamService } from "../../services/vatPhamService";
import { VatPham } from "../../Types/vatPhamType";


interface vatPhamState {
  items: VatPham[],
  lstQuaUser: VatPham[]
}

const initialState: vatPhamState = {
  items: [],
  lstQuaUser: []
}

const vatPhamReducer = createSlice({
  name: 'vatPham',
  initialState,
  reducers: {
    setVatPhamItems: (state, action: PayloadAction<VatPham[]>) => {
      state.items = action.payload;
    },
    setQuaUser: (state, action: PayloadAction<VatPham[]>) => {
      state.lstQuaUser = action.payload;
    }
  }
})

export const { setVatPhamItems, setQuaUser } = vatPhamReducer.actions;
export default vatPhamReducer.reducer;

export const getListItemsApi = () => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await vatPhamService.getListItems();
      // Giả sử result trả về một array của items
      dispatch(setVatPhamItems(result.data.content));
    } catch (error) {
      // Xử lý lỗi ở đây nếu cần
      console.error("Error fetching items:", error);
    }
  }
}

export const getQuaUserApi = (userId: string) => {


  return async (dispatch: DispatchType) => {
    try {

      const result = await vatPhamService.getListQuaUser(userId);

      
      const action: PayloadAction<VatPham[]> = setQuaUser(
        result.data.content
      );
      dispatch(action);

    } catch (error) {
      // Xử lý lỗi ở đây nếu cần
      console.error("Error fetching items:", error);
    }
  }
}
