import React, { useEffect, useState } from "react";

import SplitPane from "react-split-pane";
import Alerting from "./components/Alerting/Alerting";
import CodeEditor from "./components/Editor/CodeEditor";
import Header from "./components/Header/Header";
import Loading from "./components/Loading/Loading";
import Problem from "./components/Problem/Problem";
import Submit from "./components/Submit/Submit";
import Testcase from "./components/Testcase/Testcase";

import { Container, Tab, Tabs } from "react-bootstrap";
import { TiLockClosed } from "react-icons/ti";

// import "bootstrap/dist/css/bootstrap.css";
import { useParams } from "react-router-dom";
import "./App.css";
import { isJsonString } from "./utils/utils";
import { fake_Data } from "./fake_data";
import { message } from "antd";
const data = JSON.parse(localStorage.getItem('formValues'));

function DetailQuestionPage() {
  const [questions, setQuestion] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [performaceTime, setPerformaceTime] = useState(null);
  const [defaultCode, setDefaultCode] = useState("");
  const [code, setCode] = useState("");
  const [lang, setLang] = useState("");
  const [theme, setTheme] = useState("vs-dark");
  const [currentOutput, setCurrentOutput] = useState();
  const [output, setOutput] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  let currentQuestion = questions.slice(currentPage - 1, currentPage);
  let params = useParams();
  // console.log(`  🚀: DetailQuestionPage -> params`, params);


  const handleDefaultCode = async (l) => {
    if (l === "py") l = "py";
    else if (l === "js") l = "js";

    let c = "";
    if (currentQuestion[0]?.init_code) {
      const code = currentQuestion[0].init_code.find((x) => x._language === l);
      c = code?._function || "";
    }

    setDefaultCode(c);
    setCode(c);
  };


  const handlePagination = async (page) => {
    setCurrentPage(page);
    currentQuestion = questions.slice(page - 1, page);
    handleDefaultCode(lang);
  };

  const changeTheme = async () => {
    if (theme === "light") setTheme("vs-dark");
    else setTheme("light");
  };

  const handleLanguage = async (e) => {
    let l = e.target.value;
    setLang(l);
    handleDefaultCode(l);
  };

  const handleSubmit = async () => {
    let l = lang;
    if (l === "") {
      setAlert(true);
      return;
    }
    if (l === "python") l = "py";
    else if (l === "javascript") l = "js";
    
    
    setCurrentOutput(currentQuestion[0].question_id);
    
    let content = JSON.stringify({
      question: currentQuestion[0].question_id,
      language: l,
      code: code,
    });

    const request_start_at = performance.now();
    try {
      setLoading(true);
      const res = await fetch("https://runcode.cyberlearn.vn/submit", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: content,
      })
      if (res.status == 200) {
        const data = await res.json();

        const request_end_at = performance.now();
        const request_duration = request_end_at - request_start_at;
        // console.log(request_duration);
        setPerformaceTime(request_duration);
        setOutput(data);
      }
      else {
        message.error("Có lỗi xảy ra")
      }

      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  // console.log(questions)

  useEffect(() => {
    fetch(`https://runcode.cyberlearn.vn/getQuestionID/${params.question_id}`)
      .then((res) => res.json())
      .then(
        (data) => {
          let result = [data];
          result[0].init_code = result[0]?.init_code?.map((code) => {
            const { _function, base_code, _answer } = code;

            return {
              ...code,
              base_code: isJsonString(base_code)
                ? JSON.parse(base_code)
                : base_code,
              _function: isJsonString(_function)
                ? JSON.parse(_function)
                : _function,
              _answer: isJsonString(_answer) ? JSON.parse(_answer) : _answer,
            };
          });
          setQuestion(result);
        },
        (error) => {
          setQuestion([data]);
        }
      );
  }, []);

  return (
    <Container fluid>

      <SplitPane split="vertical" defaultSize="50%" minSize={500} maxSize={800}>
        <div className="flex-container left">

          <Tabs defaultActiveKey="1" className="shadow-down" fill justify>
            <Tab eventKey="1" title="Problem">
              {/* {performaceTime && (
                <Alert key={"danger"} variant={"danger"}>
                  {performaceTime}
                </Alert>
              )} */}

              <Problem className="flex-item" question={currentQuestion} />
            </Tab>
            <Tab eventKey="2" title="Submissions"></Tab>
            <Tab
              eventKey="3"
              title={
                <div className="d-flex justify-content-center align-items-center">
                  <TiLockClosed className="icon-lock" />
                  <span>Solution</span>
                </div>
              }
              disabled
            ></Tab>
            <Tab eventKey="4" title="Discuss"></Tab>
          </Tabs>
          {/* <Pagination
            question={questions}
            current={currentPage}
            paginate={handlePagination}
          /> */}
        </div>
        <div className="right">

          <Header
            question={currentQuestion}
            theme={theme}
            changeTheme={changeTheme}
            setCode={() => setCode(defaultCode)}
            handleLanguage={handleLanguage}
          />
          <Submit handleSubmit={handleSubmit} />
          <CodeEditor
            question={currentQuestion}
            theme={theme}
            lang={lang}
            code={code}
            defaultCode={defaultCode}
            setCode={setCode}
          />


          <Testcase
            question={currentQuestion}
            current={currentOutput}
            output={output}
          />


          {alert && <Alerting setAlert={setAlert} />}
          {loading && <Loading />}
        </div>
      </SplitPane>
    </Container>
  );
}

export default DetailQuestionPage;
