import React, { Fragment } from "react";
import Icon, {
  PicRightOutlined,
  FieldTimeOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useAppSelector } from "../../../redux/hooks";
import { GroupOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Skeleton } from "antd";

const InformationTableCSS = styled.div`
 
  .table{
    --bs-table-striped-bg:rgb(190 86 17 / 20%);

    border-top: 1px solid rgb(198, 147, 121);
  }
`;

const InformationTable: React.FC = () => {
  const { arrDanhSachHocVien ,groupForTask,requireForStudents} = useAppSelector(
    (state) => state.groupForTaskReducer
  );
  return (
    <InformationTableCSS>
      <h4 className="heading">
        <GroupOutlined />  Thông Tin Dự Án
      </h4>
      {arrDanhSachHocVien&&arrDanhSachHocVien.length? <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Task</th>
            <th scope="col">Deadline</th>
            <th scope="col">Member</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <PicRightOutlined />
            </td>
            <td>
              <FieldTimeOutlined />
            </td>
            <td>
              <TeamOutlined />
            </td>
          </tr>
          <tr>
            <td>Task</td>
            <td>Deadline</td>
            <td>Member</td>
          </tr>
          <tr>
            <td>{requireForStudents.length}</td>
            <td>3 Ngày</td>
            <td>{arrDanhSachHocVien.length}</td>
          </tr>
        </tbody>
      </table>:<Skeleton active/>}
     
    </InformationTableCSS>
  );
};
export default InformationTable;
