
import { Spin, message } from 'antd'
import Axios from 'axios'
import React, { useState } from 'react'
import { API_URL_DOMAIN } from '../../util/urlDomain';
import { LoadingOutlined } from '@ant-design/icons';


export const DownloadVideo = ({ item }: any) => {

    const [loading, setLoading] = useState(false)
    const cutFileName = (fileName: any) => {
        let dashCount = 0; // Khởi tạo bộ đếm dấu gạch ngang
        let index = 0; // Để theo dõi vị trí trong chuỗi

        // Duyệt qua từng ký tự trong chuỗi
        while (index < fileName.length) {
            if (fileName[index] === '-') { // Kiểm tra nếu ký tự hiện tại là dấu gạch ngang
                dashCount++; // Tăng bộ đếm dấu gạch ngang
                if (dashCount === 7) { // Kiểm tra nếu đây là dấu gạch ngang thứ bảy
                    return fileName.substring(index + 1); // Cắt chuỗi từ vị trí này trở đi
                }
            }
            index++; // Tăng chỉ số
        }

        return fileName; // Trả về tên file gốc nếu không có đủ dấu gạch ngang
    }
    return <div style={{ width: 33 }}>
        {item?.maLoai == "VIDEO" && <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} > <button className="btn btn-info btn-sm mr-1" onClick={
            () => {
                setLoading(true)
                Axios.get(`${API_URL_DOMAIN}/api/file/down-load/techx/${item?.noiDung}`, { responseType: 'blob', headers: { apiKey: "MKQ7oCoZcX33i2zq" } }).then(response => {
                    console.log(response)
                    // Tạo một URL từ blob
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    // Tạo một thẻ <a> tạm thời để download file
                    const link: any = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', cutFileName(item?.tenBaiHoc + ".mp4")); // Đặt tên file và đuôi mở rộng thích hợp
                    document.body.appendChild(link);
                    link.click();

                    // Dọn dẹp và gỡ bỏ các URL và thẻ <a>
                    link.parentNode.removeChild(link);
                    window.URL.revokeObjectURL(url);


                }).catch(err => message.error("File not found or apiKey not valid")).finally(() => setLoading(false))
            }}
        ><i className='fa fa-download'></i></button>
        </Spin>
        }

    </div>




}
