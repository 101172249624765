import { Checkbox, Input, Modal, Popconfirm, Radio, RadioChangeEvent, Tag } from 'antd'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { tracNghiemType } from '../../Types/baiTapType'
import parse from 'html-react-parser'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-javascript'
import 'ace-builds/src-noconflict/theme-monokai'
import { CKEditor } from 'ckeditor4-react'
type TypeData = {
  [key: string]: any
}
type Props = {
  lstTracNghiem: tracNghiemType[]
  setLstTracNghiem: Dispatch<SetStateAction<tracNghiemType[] | any>>
  hint: String
  setHint: Dispatch<SetStateAction<String>>
  data?:any
}

const BaiTapTracNghiem = ({ lstTracNghiem, setLstTracNghiem, hint, setHint,data }: Props) => {
  // create state
  const [open, setOpen] = useState(false)
  const [type, setType] = useState('')
  const [viTri, setViTri] = useState(-1)
  const [inPutEnCode, setInPutEnCode] = useState('')
  const [inPutCssEnCode, setInPutCssEnCode] = useState('')
  const [outPutHtml, setOutPutHtml] = useState('')

  const [key, setKey] = useState(Date.now())

  // function else
  // chọn loai cau hoi
  const onRadioChange = (event: RadioChangeEvent) => {
    setType(event.target.value)
    handleOk(event.target.value)
  }

  // them cau hoi
  const handleOk = (typeRadio: string) => {
    let newNoiDung: tracNghiemType = {
      tieuDe: '',
      inPut: '',
      outPut: '',
      maLoaiBaiTap: typeRadio,
      cauTraLoi: [],
      dapAn: []
    }

    setOpen(false)

    // group quiz
    // setViTri(lstTracNghiem.length);
    // setLstTracNghiem([...lstTracNghiem, newNoiDung]);

    if (lstTracNghiem.length > 0) {
      newNoiDung = { ...lstTracNghiem[0], maLoaiBaiTap: typeRadio, dapAn: [] }
    }

    setLstTracNghiem([newNoiDung])
  }
  // xoa cau hoi
  const handleRemove = (index: number) => {
    const newList = [...lstTracNghiem]
    newList.splice(index, 1)
    setLstTracNghiem(newList)
  }

  // xuat du lieu danh sach cau trac nghiem
  const labelLoaiTracNghiem = (maLoai: string) => {
    switch (maLoai) {
      case 'single':
        return '(Chọn một - SINGLE)'
      case 'fill_inblank':
        return '(Điền từ - fill_inblank)'
      case 'fill_inblank_css':
        return '(Điền từ css - fill_inblank_css)'
      case 'fill_input':
        return '(Gõ từ - fill_input)'
      case 'multiple_choice':
        return '(Chọn nhiều - multiple_choice)'
    }
  }
  const renderQuizList = () => {
    return <div className='col-12'>{renderFormQuiz(0)}</div>

    // group quiz

    // return lstTracNghiem.map((item, index) => {
    //   let loaiBT = labelLoaiTracNghiem(item.maLoaiBaiTap);

    //   return index !== viTri ? (
    //     <div className="col-2 m-2 card">
    //       <div className="card-body">
    //         <h6 className="card-title">
    //           Câu {index + 1}: {loaiBT}
    //         </h6>
    //         <h6 className="card-subtitle mb-2 text-muted">{item.tieuDe}</h6>

    //         <button
    //           className="btn btn-sm btn-primary mx-2"
    //           onClick={() => setViTri(index)}
    //         >
    //           <i className="fas fa-pencil-alt"></i>
    //         </button>
    //         <Popconfirm
    //           title="Bạn có chắc muốn xóa ?"
    //           onConfirm={() => handleRemove(index)}
    //         >
    //           <button className="btn btn-sm btn-danger">
    //             <i className="fas fa-trash"></i>
    //           </button>
    //         </Popconfirm>
    //       </div>
    //     </div>
    //   ) : (
    //     <div className="col-12">{renderFormQuiz(index)}</div>
    //   );
    // });
  }

  // cac ham xu ly form trac nghiem
  const onChangeQuestion = (baiTap: tracNghiemType, viTri: number) => {
    if (baiTap) {
      const lstBaiTapTemp = [...lstTracNghiem]

      lstBaiTapTemp.splice(viTri, 1, baiTap)
      setLstTracNghiem(lstBaiTapTemp)
    }
  }

  const onInputChange = (e: any, baiTap: tracNghiemType, index = -1) => {
    const { name, value } = e.target
    const entities = require('entities')

    if (name === 'tieuDe') {
      onChangeQuestion({ ...baiTap, tieuDe: value }, viTri)
      return
    }

    if (name === 'inPut') {
      let replaceInput = entities.encodeHTML(value)

      replaceInput = replaceInput.replaceAll('&hearts;', '♥')
      setInPutEnCode(replaceInput)
      onChangeQuestion({ ...baiTap, inPut: replaceInput }, viTri)
      return
    }
    if (name === 'inputCss') {
      let replaceInput = entities.encodeHTML(value)

      replaceInput = replaceInput.replaceAll('&hearts;', '♥')
      setInPutCssEnCode(replaceInput)
      onChangeQuestion({ ...baiTap, inputCss: value }, viTri)

      return
    }
    if (name === 'outPut') {
      setOutPutHtml(value)

      onChangeQuestion({ ...baiTap, outPut: value }, viTri)
      return
    }

    if (name === 'cauTraLoi') {
      let cauTraLoi = baiTap.cauTraLoi

      for (let i = 0; i < cauTraLoi.length; i++) {
        if (parseInt(cauTraLoi[i].ma) == index + 1) {
          cauTraLoi[i].noiDung = value
          break
        }
      }

      onChangeQuestion({ ...baiTap, cauTraLoi }, viTri)
    }

    if (name === 'dapAn') {
      let dapAn = baiTap.dapAn

      for (let i = 0; i < dapAn.length; i++) {
        if (i == index) {
          dapAn[i] = value
          break
        }
      }

      onChangeQuestion({ ...baiTap, dapAn }, viTri)
    }
  }

  const onEditorChange = (evt: any) => {
    setHint(evt.editor.getData())
  }

  const themCauTraLoi = (baiTap: tracNghiemType) => {
    if (baiTap.maLoaiBaiTap == 'fill_input') {
      let dapAn = baiTap.dapAn

      dapAn.push('')

      onChangeQuestion({ ...baiTap, dapAn }, viTri)
    } else {
      let cauTraLoi = baiTap.cauTraLoi

      let maCau = cauTraLoi.length == 0 ? 1 : cauTraLoi.length + 1

      cauTraLoi.push({ ma: maCau.toString(), noiDung: '' })

      onChangeQuestion({ ...baiTap, cauTraLoi }, viTri)
    }
  }

  const xoaCauTraLoi = (index: number, baiTap: tracNghiemType, maCau = '0') => {
    if (baiTap.maLoaiBaiTap == 'fill_input') {
      let dapAn = baiTap.dapAn

      dapAn.slice(index + 1)

      let dapAnNew = []
      for (let i = 0; i < dapAn.length; i++) {
        if (i != index) {
          dapAnNew.push(dapAn[i])
        }
      }

      onChangeQuestion({ ...baiTap, dapAn: dapAnNew }, viTri)
    } else {
      let cauTraLoi = baiTap.cauTraLoi

      cauTraLoi = cauTraLoi.filter((n) => n.ma != maCau)

      cauTraLoi = cauTraLoi.sort((a, b) => parseInt(a.ma) - parseInt(b.ma))

      for (let i = 0; i < cauTraLoi.length; i++) {
        cauTraLoi[i].ma = (i + 1).toString()
      }
      onChangeQuestion({ ...baiTap, cauTraLoi, dapAn: [] }, viTri)
    }
  }

  const onCheckboxChange = (e: CheckboxChangeEvent, baiTap: tracNghiemType, index: number) => {
    const { checked } = e.target

    let dapAn = baiTap.dapAn
    if (baiTap.maLoaiBaiTap == 'single') {
      dapAn = []
    }
    if (checked) {
      dapAn.push((index + 1).toString())
    } else {
      dapAn = dapAn.filter((n) => parseInt(n) != index + 1)
    }

    onChangeQuestion({ ...baiTap, dapAn }, viTri)
  }

  // xuat form nhap trac nghiem
  const renderCauTraLoi = (baiTap: tracNghiemType) => {
    switch (baiTap.maLoaiBaiTap) {
      case 'fill_input': {
        return (
          <div className='single-answer'>
            <h4>Đáp án:</h4>
            {baiTap.dapAn
              ? baiTap.dapAn.map((item, index) => {
                  return (
                    <div className='input-group mb-3'>
                      <input
                        className='form-control'
                        style={{ width: '80%' }}
                        name='dapAn'
                        value={item}
                        onChange={(e) => onInputChange(e, baiTap, index)}
                        placeholder={`Đáp án ${index + 1}`}
                      />
                      <Popconfirm title='Bạn có chắc muốn xóa ?' onConfirm={() => xoaCauTraLoi(index, baiTap)}>
                        <input type='button' className='ml-1 btn bg-danger btn-danger btn-sm' value='Xóa' />
                      </Popconfirm>
                    </div>
                  )
                })
              : null}
          </div>
        )
      }

      default: {
        return (
          <div className='single-answer'>
            <h4>Câu trả lời:</h4>
            {baiTap.cauTraLoi
              ? baiTap.cauTraLoi.map((item, index) => {
                  return (
                    <div className='input-group mb-3'>
                      <div className='input-group-text'>
                        {baiTap.maLoaiBaiTap != 'single' &&
                          baiTap.maLoaiBaiTap != 'multiple_choice' &&
                          baiTap.dapAn.find((n) => n == item.ma) && (
                            <Tag color='red'>ô số {baiTap.dapAn.findIndex((n) => n == item.ma) + 1} </Tag>
                          )}

                        {baiTap.maLoaiBaiTap == 'single' ? (
                          <input
                            onChange={(e: any) => onCheckboxChange(e, baiTap, index)}
                            className='form-check-input mt-0'
                            type='radio'
                            name='radio'
                            checked={baiTap.dapAn.find((n) => n == item.ma) ? true : false}
                          />
                        ) : (
                          <input
                            onChange={(e: any) => onCheckboxChange(e, baiTap, index)}
                            className='form-check-input mt-0'
                            type='checkbox'
                            checked={baiTap.dapAn.find((n) => n == item.ma) ? true : false}
                          />
                        )}
                      </div>
                      <input
                        className='form-control'
                        name='cauTraLoi'
                        value={item.noiDung}
                        onChange={(e) => onInputChange(e, baiTap, index)}
                        placeholder={`Câu trả lời ${index + 1}`}
                      />
                      <Popconfirm title='Bạn có chắc muốn xóa ?' onConfirm={() => xoaCauTraLoi(index, baiTap, item.ma)}>
                        <input type='button' className='ml-1 btn bg-danger btn-danger btn-sm' value='Xóa' />
                      </Popconfirm>
                    </div>
                  )
                })
              : null}
          </div>
        )
      }
    }
  }

  const renderFormQuiz = (index: number) => {
    const entities = require('entities')
    const baiTap = lstTracNghiem[index]

    return (
      baiTap && (
        <div className='single-choose'>
          <div className='single-question row'>
            <div className='col-md-12 pt-2'>
              <h5>
                Câu: 
                <span>{data?.tenBaiHoc}</span> {labelLoaiTracNghiem(baiTap.maLoaiBaiTap)}{' '}
                {/* <button
                  className="btn btn-sm btn-outline-dark"
                  onClick={() => setViTri(-1)}
                >
                  Đóng
                </button> */}
              </h5>
              <h5>Tiêu đề</h5>

              <input
                className='form-control'
                name='tieuDe'
                value={baiTap.tieuDe}
                onChange={(e) => onInputChange(e, baiTap)}
              />
            </div>

            <div className='col-md-7 pt-4'>
              <h5>Nội dung </h5>
              {baiTap.maLoaiBaiTap != 'single' && baiTap.maLoaiBaiTap != 'multiple_choice' && (
                <span className='text-danger'>
                  {/* Thêm ký tự "♥" vào chỗ điền từ (lưu ý: ô điền = số lượng đáp
                    án) */}
                  <button
                    className='btn btn-sm btn-danger my-2'
                    onClick={() => {
                      let updatedInput = parse(baiTap.inPut)
                      // Giả sử bạn muốn thêm ký tự "♥" vào cuối chuỗi:
                      updatedInput += '♥'
                      // Cập nhật state (giả định onInputChange cập nhật state cho baiTap.inPut)
                      onInputChange({ target: { name: 'inPut', value: updatedInput } }, baiTap)
                    }}
                  >
                    Thêm ô điền từ
                  </button>
                </span>
              )}
              {/* <textarea
                className="form-control"
                name="inPut"
                value={baiTap.inPut && entities.decodeHTML(baiTap.inPut)}
                onChange={(e) => onInputChange(e, baiTap)}
                rows={8}
              /> */}

              <AceEditor
                name='inPut'
                mode='javascript'
                theme='monokai'
                width='100%'
                height='300px'
                fontSize={13}
                onChange={(value) => onInputChange({ target: { name: 'inPut', value } }, baiTap)}
                value={baiTap.inPut && entities.decodeHTML(baiTap.inPut)}
              />
            </div>

            <div className='col-md-5 pt-4'>
              <h5>Mã lưu ASCII (Xem trước)</h5>
              <br />
              <textarea className='form-control bg-dark text-white' readOnly value={inPutEnCode} rows={8} />
            </div>

            {baiTap.maLoaiBaiTap == 'fill_inblank_css' && (
              <>
                <div className='col-md-7 pt-4'>
                  <h5>Nội dung CSS</h5>
                  <span className='text-danger'>Thêm ký tự "♥" vào chỗ điền từ (lưu ý: ô điền = số lượng đáp án)</span>

                  {/* <textarea
                    className="form-control"
                    name="inputCss"
                    value={
                      baiTap.inputCss && entities.decodeHTML(baiTap.inputCss)
                    }
                    onChange={(e) => onInputChange(e, baiTap)}
                    rows={6}
                  /> */}
                  <AceEditor
                    name='inputCss'
                    mode='javascript'
                    theme='monokai'
                    width='100%'
                    height='300px'
                    fontSize={13}
                    onChange={(value) => onInputChange({ target: { name: 'inputCss', value } }, baiTap)}
                    value={baiTap.inputCss && entities.decodeHTML(baiTap.inputCss)}
                  />
                </div>

                <div className='col-md-5 pt-4'>
                  <h5>Mã lưu ASCII (Xem trước)</h5>
                  <br />
                  <textarea className='form-control bg-dark text-white' readOnly value={inPutCssEnCode} rows={6} />
                </div>
              </>
            )}

            {baiTap.maLoaiBaiTap != 'single' && (
              <>
                <div className='col-md-7 pt-4'>
                  <h5>Output (Mã html)</h5>

                  {/* <textarea
                    className="form-control"
                    name="outPut"
                    value={baiTap.outPut}
                    onChange={(e) => onInputChange(e, baiTap)}
                    rows={8}
                  /> */}
                  <AceEditor
                    name='outPut'
                    mode='javascript'
                    theme='monokai'
                    width='100%'
                    height='300px'
                    fontSize={13}
                    onChange={(value) => onInputChange({ target: { name: 'outPut', value } }, baiTap)}
                    value={baiTap.outPut && entities.decodeHTML(baiTap.outPut)}
                  />
                </div>
                <div className='col-md-5 pt-4'>
                  <h5>Biên dịch HTML (Xem trước)</h5>

                  {parse(outPutHtml)}
                </div>
              </>
            )}
          </div>
          {renderCauTraLoi(baiTap)}
          <input
            type='button'
            className='m-3 btn bg-success btn-success btn-sm'
            value='Thêm đáp án'
            onClick={() => themCauTraLoi(baiTap)}
          />

          <div className=''>
            <h4>Hint:</h4>

            <CKEditor
              key={key}
              initData={hint}
              config={{
                versionCheck: false,
                toolbarGroups: [
                  { name: 'document', groups: ['mode', 'document', 'doctools'] },
                  { name: 'clipboard', groups: ['clipboard', 'undo'] },
                  { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
                  { name: 'forms' },
                  '/',
                  { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                  { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
                  { name: 'links' },
                  { name: 'insert' },
                  '/',
                  { name: 'styles' },
                  { name: 'colors' },
                  { name: 'tools' },
                  { name: 'others' },
                  { name: 'about' }
                ]
              }}
              name='hint'
              // onChange={onInputChange}
              onChange={onEditorChange}
            />
          </div>
        </div>
      )
    )
  }

  return (
    <div>
      {/* <button className="btn btn-primary" onClick={() => setOpen(true)}>
        Thêm câu hỏi
      </button> */}
      <div className='quiz-type'>
        <h6>CHỌN LOẠI BÀI TẬP</h6>
        <Radio.Group onChange={onRadioChange} value={type}>
          <Radio className='p-2' value='single'>
            Chọn một (SINGLE)
          </Radio>
          <Radio className='p-2' value='multiple_choice'>
            Chọn nhiều (multiple_choice)
          </Radio>

          <Radio className='p-2' value='fill_inblank'>
            Điền từ (fill_inblank)
          </Radio>
          {/* <Radio className="p-2" value="fill_inblank_css">
            Điền từ css (fill_inblank_css)
          </Radio> */}
          <Radio className='p-2' value='fill_input'>
            Gõ từ (fill_input)
          </Radio>
        </Radio.Group>
      </div>
      <div className='row'>{renderQuizList()}</div>
      {/* <Modal open={open} onOk={handleOk} onCancel={() => setOpen(false)}>
        <div className="quiz-type">
          <h6>CHỌN LOẠI BÀI TẬP</h6>
          <Radio.Group onChange={onRadioChange} value={type}>
            <Radio className="p-2" value="single">
              Chọn một (SINGLE)
            </Radio>
            <Radio className="p-2" value="multiple_choice">
              Chọn nhiều (multiple_choice)
            </Radio>

            <Radio className="p-2" value="fill_inblank">
              Điền từ (fill_inblank)
            </Radio>
            <Radio className="p-2" value="fill_inblank_css">
              Điền từ css (fill_inblank_css)
            </Radio>
            <Radio className="p-2" value="fill_input">
              Gõ từ (fill_input)
            </Radio>
          </Radio.Group>
        </div>
      </Modal> */}
    </div>
  )
}

export default BaiTapTracNghiem
