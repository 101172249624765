import React, { useEffect, useState } from 'react';
import { DispatchType, RootState } from '../../redux/configStore';
import { useDispatch, useSelector } from 'react-redux';
import ThongKeFreeReducer, { getDataTKRowNgay, getDataTKRowNgayUser, getDataTKRowThang, getDataTKRowThangUser, getDataTKRowTuan, getDataTKRowTuanUser, getDemTKTheoNgayAllApi, getDemTKTheoNgayApi, getDemTKTheoNgayUserApi, getThongKeFreeAllApi, getThongKeFreeApi, getThongKeFreeUserApi } from '../../redux/ThongKeFreeReducer/ThongKeFreeReducer';
import moment from 'moment';
import { DatePicker, DatePickerProps, Modal, Select, Table, TableProps, Tabs, TabsProps } from 'antd';
import dayjs from 'dayjs';
import { LineChart } from '@mui/x-charts/LineChart';
import { DataThongKeRow } from '../../Types/thongKeFree';

type Props = {};

const ThongKeFree = (props: Props) => {
    const dispatch: DispatchType = useDispatch();
    const [fill, setFill] = useState<number>(0);
    const [fill2, setFill2] = useState<number>(1);
    const [modal, setModal] = useState<boolean>(false);
    const { demTheoNgay, demTheoNgayUser, demTheoNgayAll, thongKe, dataNgay, dataThang, dataTuan } = useSelector((state: RootState) => state.ThongKeFreeReducer);
    const [date, setDate] = useState<string>(moment().format('YYYY-MM-DD'));
    const [thang, setThang] = useState<number>(new Date().getMonth() + 1);
    const [nam, setNam] = useState<number>(new Date().getFullYear());
    const [daysOfMonth, setDaysOfMonth] = useState<number[]>([]);
    console.log('daysOfMonth: ', daysOfMonth);
    const columns: TableProps<DataThongKeRow>['columns'] = [
        {
            title: 'Họ têm',
            dataIndex: 'hoTen',
            key: 'hoTen',
            render: (text) => text,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Tên chuyên đề',
            dataIndex: 'tenChuyenDe',
            key: 'tenChuyenDe',
        },
        {
            title: 'Ngày học',
            dataIndex: 'ngayTao',
            key: 'ngayTao',
            render:(text)=> moment(text).format('YYYY-MM-DD HH:mm')
        }
    ];
    const items: TabsProps['items'] = [
        { key: '1', label: 'Ngày', children: <Table columns={columns} dataSource={dataNgay} /> },
        { key: '2', label: 'Tuần', children: <Table columns={columns} dataSource={dataTuan} /> },
        { key: '3', label: 'Tháng', children: <Table columns={columns} dataSource={dataThang} /> },
    ];

    useEffect(() => {
        function getDaysOfMonth(month: number, year: number) {
            const daysInMonth = new Date(year, month, 0).getDate();
            return Array.from({ length: daysInMonth }, (_, index) => index + 1);
        }

        const days = getDaysOfMonth(thang, nam);
        setDaysOfMonth(days);
    }, [thang, nam]);

    useEffect(() => {
        if (fill === 0) {
            dispatch(getThongKeFreeAllApi(date));
        } else if (fill === 1) {
            dispatch(getThongKeFreeUserApi(date));
        } else if (fill === 2) {
            dispatch(getThongKeFreeApi(date));
        }
    }, [fill, date]);

    useEffect(() => {
        dispatch(getDemTKTheoNgayApi(date));
        dispatch(getDemTKTheoNgayUserApi(date));
        dispatch(getDemTKTheoNgayAllApi(date));
    }, [date]);

    const handleChange = (value: number) => {
        setFill(value);
        if (value === 0) {
            setFill2(1)
        } setFill2(value)
    };
    const handleChange2 = (value: number) => {
        setFill2(value);
    };
    useEffect(() => {
        if (fill2 == 1) {
            dispatch(getDataTKRowNgayUser(date))
            dispatch(getDataTKRowTuanUser(date))
            dispatch(getDataTKRowThangUser(date))
        } else if (fill2 == 2) {
            dispatch(getDataTKRowNgay(date))
            dispatch(getDataTKRowTuan(date))
            dispatch(getDataTKRowThang(date))
        }
    }, [fill2,date])
    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        setDate(dateString);
    };
    const showModal = () => {
        setModal(true);
    };
    const closeModal = () => {
        setModal(false);
    };

    return (
        <div className='p-5'>
            <h2>Thống kê số lượng học viên học thử</h2>
            <Select
                defaultValue={0}
                style={{ width: 200 }}
                onChange={handleChange}
                options={[
                    { label: 'Tất cả', value: 0 },
                    { label: 'Học viên cũ', value: 1 },
                    { label: 'Khách hàng mới', value: 2 },
                ]}
                className='pe-2'
            />
            <DatePicker className='' defaultValue={dayjs(new Date())} format={'YYYY-MM-DD'} onChange={onChange} />
            <div className="row card_theme justify-around p-2">
                <div className='col text-center' onClick={showModal} style={{cursor:'pointer'}}>
                    <div className="bg-info p-3">
                        <span>{thongKe[0]?.group}</span>
                        <div className='font-bold h4'>{thongKe[0]?.soLuong}</div>
                    </div>
                </div>
                <div className='col text-center' onClick={showModal}  style={{cursor:'pointer'}}>
                    <div className="bg-warning p-3">
                        <span>{thongKe[1]?.group}</span>
                        <div className='font-bold h4'>{thongKe[1]?.soLuong}</div>
                    </div>
                </div>
                <div className='col text-center' onClick={showModal}  style={{cursor:'pointer'}}>
                    <div className="bg-success bg-opacity-75 p-3">
                        <span>{thongKe[2]?.group}</span>
                        <div className='font-bold h4'>{thongKe[2]?.soLuong}</div>
                    </div>
                </div>
            </div>
            {demTheoNgay.length === daysOfMonth.length &&
                demTheoNgayUser.length === daysOfMonth.length &&
                demTheoNgayAll.length === daysOfMonth.length && (
                    <LineChart
                        xAxis={[{ data: daysOfMonth}]}
                        series={[
                            {
                                data: demTheoNgay,
                                showMark: ({ index }) => false,
                                label: "Khách hàng mới"
                            },
                            {
                                data: demTheoNgayUser,
                                showMark: ({ index }) => false,
                                label: "Học viên cũ"
                            },
                        ]}
                        width={1000}
                        height={400}
                    />
                )}
            {modal && <Modal width={'80%'} title={null} open={modal} footer={null} onCancel={closeModal} style={{top:'20px'}}>
                <Select
                    value={fill2}
                    style={{ width: 200 }}
                    onChange={handleChange2}
                    options={[
                        { label: 'Học viên cũ', value: 1 },
                        { label: 'Khách hàng mới', value: 2 },
                    ]}
                    className='pe-2'
                />

                <Tabs
                    defaultActiveKey="1"
                    items={items}
                />
            </Modal>}
        </div>
    );
};

export default ThongKeFree;
