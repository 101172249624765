import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchType, RootState } from '../../redux/configStore';
import { getBangDiemUser, getDataChartUser, getThongKeDoiTac, getThongKeDoiTacAll, getThongKeNopBai, getThongKeThoiGianHoc, getThongKeThoiGianHocDoiTac } from '../../redux/ThongKeFreeReducer/ThongKeFreeReducer';
import moment from 'moment';
import { Button, DatePicker, Input, InputRef, message, Modal, Popconfirm, Progress, Select, Space, Spin, Switch, Table, TableColumnType, Tabs, TabsProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { getChuyenDeApi } from '../../redux/ChuyenDeReducer/chuyenDeReducer';
import { nguoiDungService } from '../../services/nguoiDungService';
import { maskEmail } from '../../util/maskEmail';
import { ColumnsType } from 'antd/es/table';
import { NopBaiThongKe, ThoiGianHocThongKe, ThongKeDoiTac } from '../../Types/thongKeFree';
import { nopbaiService } from '../../services/nopbaiService';
import { API_URL_DOMAIN } from '../../util/urlDomain';
import { LineChart } from '@mui/x-charts/LineChart';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/list';
import Highlighter from 'react-highlight-words';
import TableScore from './TableScore';

const { RangePicker } = DatePicker;

type Props = {}

const ThongKeHocTap = (props: Props) => {
  const [valueDoiTac, setValueDoiTac] = useState("HUTECH");
  const [start, setStart] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [end, setEnd] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [chuyenDeId, setChuyenDeId] = useState();
  const [all, setAll] = useState<boolean>(true);
  const [arrNguoiDung, setArrNguoiDung] = useState([])
  const [openDetail, setOpenDetail] = useState<boolean>(false)
  const [user, setUser] = useState<ThongKeDoiTac>()
  const { thongKeNopBai, thongKeThoiGianHoc, thongKeDoiTac, ThongKeDoiTacAll, dataThoiGianHoc, loadingThoigianChart, dataChartUser, loadChartUser, loadingThongKe} = useSelector((state: RootState) => state.ThongKeFreeReducer);
  const { arrChuyenDe } = useSelector((state: RootState) => state.chuyenDeReducer);
  // show model detail
  const showDetail = (rec: ThongKeDoiTac) => {
    setUser(rec)
    setOpenDetail(true)
  }
  const closeDetail = () => {
    setOpenDetail(false)
  }
  const dispatch: DispatchType = useDispatch();

  useEffect(() => {
    if (chuyenDeId) {
      dispatch(getThongKeThoiGianHoc(chuyenDeId || 0, valueDoiTac, start, end));
      dispatch(getThongKeNopBai(chuyenDeId || 0, valueDoiTac, start, end));
      dispatch(getThongKeDoiTac(chuyenDeId || 0, valueDoiTac, start, end));
      dispatch(getThongKeDoiTacAll(chuyenDeId || 0, valueDoiTac, start, end));
    }
  }, [chuyenDeId, valueDoiTac, start, end, dispatch]);
  useEffect(() => {
    dispatch(getThongKeThoiGianHocDoiTac(valueDoiTac, start, end))
  }, [valueDoiTac, start, end, dispatch]);
  useEffect(() => {
    dispatch(getChuyenDeApi());
  }, []);
  useEffect(() => {
    nguoiDungService.getDSDoiTacService(valueDoiTac).then((res) => {
      setArrNguoiDung(res.data.content)
    })
  }, [valueDoiTac]);
  useEffect(() => {
    if (user) {
      dispatch(getDataChartUser(user.nguoiDungId, start, end))
      chuyenDeId && dispatch(getBangDiemUser(user.nguoiDungId,chuyenDeId))
    }
  }, [user]);

  const onDateChange = (dates: [Dayjs | null, Dayjs | null] | null, dateStrings: [string, string]) => {
    if (dates) {
      setStart(dateStrings[0]);
      setEnd(dateStrings[1]);
    }
  };
  const dinhChiHocTap = () => { 
    let newArr  = ThongKeDoiTacAll.filter(item=>item.phanTramHoanThanh==0)
    const obj = {
      userId:newArr.map(item=>item.nguoiDungId),
      chuyenDeId:chuyenDeId
    }
    if(chuyenDeId){
      nopbaiService.DinhChiHocService(obj).then(()=>{
        message.success("Đình chỉ thành công")
        dispatch(getThongKeThoiGianHoc(chuyenDeId || 0, valueDoiTac, start, end));
      dispatch(getThongKeNopBai(chuyenDeId || 0, valueDoiTac, start, end));
      dispatch(getThongKeDoiTac(chuyenDeId || 0, valueDoiTac, start, end));
      dispatch(getThongKeDoiTacAll(chuyenDeId || 0, valueDoiTac, start, end));
      }).catch((er)=>{
        message.error("Có lỗi sảy ra, vui lòng thử lại")
      })
    }
   }
  //search
  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string): TableColumnType<ThongKeDoiTac> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record.tenNguoiDung
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },

    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  //end search
  const columns: ColumnsType<ThongKeDoiTac> = [
    {
      title: 'STT',
      key: 'stt',
      render: (_: any, __: any, index: any) => index + 1
    },
    {
      title: 'Họ Tên',
      dataIndex: 'tenNguoiDung',
      key: 'tenNguoiDung',
      ...getColumnSearchProps('tenNguoiDung'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text: string) => maskEmail(text)
    },
    {
      title: 'Số Điện Thoại',
      dataIndex: 'soDt',
      key: 'soDt',
    },
    // {
    //   title: 'Số Lượng Học',
    //   dataIndex: 'soLuongHoc',
    //   key: 'soLuongHoc',
    //   sorter: (a: ThongKeDoiTac, b: ThongKeDoiTac) => a.soLuongHoc - b.soLuongHoc

    // },
    {
      title: 'Số Lượng Bài Nộp',
      dataIndex: 'soLuongNopBai',
      key: 'soLuongNopBai',
      sorter: (a: ThongKeDoiTac, b: ThongKeDoiTac) => a.soLuongNopBai - b.soLuongNopBai

    },
    {
      title: 'Tỷ lệ hoàn thành',
      key: 'tyLeHoanThanh',
      render: (record: ThongKeDoiTac) => <p>{record.soLuongHoanThanh}/{record.totalTask}</p>,
      sorter: (a: ThongKeDoiTac, b: ThongKeDoiTac) => a.soLuongHoanThanh - b.soLuongHoanThanh
    },
    {
      title: 'Phần trăm hoàn thành',
      dataIndex: 'phanTramHoanThanh',
      key: 'phanTramHoanThanh',
      render: (text: number) => <Progress
        percent={text}
        size={[200, 20]}
        strokeColor="#7859f2"
      />,
      sorter: (a: ThongKeDoiTac, b: ThongKeDoiTac) => a.phanTramHoanThanh - b.phanTramHoanThanh

    },
    {
      title: 'Điểm Trung Bình',
      dataIndex: 'diemTrungBinh',
      key: 'diemTrungBinh',
      sorter: (a: ThongKeDoiTac, b: ThongKeDoiTac) => a.diemTrungBinh - b.diemTrungBinh
    },
    {
      title: 'Xem',
      key: 'diemTrungBinh',
      render: (rec: ThongKeDoiTac) => <Button onClick={() => {
        showDetail(rec)
      }}>Xem</Button>
    }

  ];
  const columns1: ColumnsType<NopBaiThongKe> = [
    {
      title: 'STT',
      key: 'stt',
      render: (_: any, __: any, index: any) => index + 1
    },
    {
      title: 'Họ Tên',
      dataIndex: 'tenNguoiDung',
      key: 'tenNguoiDung',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text: string) => maskEmail(text)
    },
    {
      title: 'Số Điện Thoại',
      dataIndex: 'soDt',
      key: 'soDt',
    },
    {
      title: 'Số Lượng Bài Nộp',
      dataIndex: 'soLuong',
      key: 'soLuong',
      sorter: (a: NopBaiThongKe, b: NopBaiThongKe) => a.soLuong - b.soLuong

    },
    {
      title: 'Điểm Trung Bình',
      dataIndex: 'diemTrungBinh',
      key: 'diemTrungBinh',
      sorter: (a: NopBaiThongKe, b: NopBaiThongKe) => a.diemTrungBinh - b.diemTrungBinh
    }
  ];
  const columns2: ColumnsType<ThoiGianHocThongKe> = [
    {
      title: 'STT',
      key: 'stt',
      render: (_: any, __: any, index: any) => index + 1
    },
    {
      title: 'Họ Tên',
      dataIndex: 'tenNguoiDung',
      key: 'tenNguoiDung',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text: string) => maskEmail(text)
    },
    {
      title: 'Số Điện Thoại',
      dataIndex: 'soDt',
      key: 'soDt',
    },
    {
      title: 'Số Lượng Task Hoàn Thành',
      dataIndex: 'soLuong',
      key: 'soLuong',
      sorter: (a: ThoiGianHocThongKe, b: ThoiGianHocThongKe) => a.soLuong - b.soLuong

    }
  ];
  const onChangeSwitch = (checked: boolean) => {
    setAll(checked)
  };
  const items: TabsProps['items'] = [
    {
      key: '1', label: 'Thống kê', children: <>
        <div className="d-flex gap-5 align-items-center ms-3">
          <Switch defaultChecked onChange={onChangeSwitch} />
          <button disabled={chuyenDeId && ThongKeDoiTacAll.length != 0 ? false : true} className='btn btn-success' onClick={() => {

            var div_loading = document.getElementsByClassName("div_loading");
            div_loading[0].removeAttribute("hidden");

            nopbaiService.ExportThongKeDoiTacAll(chuyenDeId || 0, valueDoiTac, start, end).then(res => {
              if (res.data.content == "1")
                window.location.href = `${API_URL_DOMAIN}/files/thongKeAll${valueDoiTac=='HOCVIEN'?"":valueDoiTac}.xlsx`;


            }).catch(err => { console.log(err); message.error("Không xuất được !"); }).finally(() => {
              div_loading[0].setAttribute("hidden", "");
            })
          }}>Export All</button>
          <button disabled={chuyenDeId && thongKeDoiTac.length != 0 ? false : true} className='btn btn-info' onClick={() => {

            var div_loading = document.getElementsByClassName("div_loading");
            div_loading[0].removeAttribute("hidden");

            nopbaiService.ExportThongKeDoiTac(chuyenDeId || 0, valueDoiTac, start, end).then(res => {
              if (res.data.content == "1")
                window.location.href = `${API_URL_DOMAIN}/files/thongKe${valueDoiTac=='HOCVIEN'?"":valueDoiTac}.xlsx`;


            }).catch(err => { console.log(err); message.error("Không xuất được !"); }).finally(() => {
              div_loading[0].setAttribute("hidden", "");
            })
          }}>Export</button>
          <Popconfirm
                                title={<p className='text-white'>Bạn có chắc muốn xóa ?</p>}
                                onConfirm={dinhChiHocTap}
                              >
                                <button className='btn btn-danger' >Đình chỉ</button>
                              </Popconfirm>
          
        </div>
        <Spin spinning={loadingThongKe}>
        <Table columns={columns} dataSource={all ? ThongKeDoiTacAll : thongKeDoiTac} />
          </Spin></>
    },
    {
      key: '2', label: 'Nộp bài', children: <>
        <button disabled={chuyenDeId && thongKeNopBai.length != 0 ? false : true} className='ms-3 btn btn-success' onClick={() => {

          var div_loading = document.getElementsByClassName("div_loading");
          div_loading[0].removeAttribute("hidden");

          nopbaiService.ExportThongKeNopBai(chuyenDeId || 0, valueDoiTac, start, end).then(res => {
            if (res.data.content == "1")
              window.location.href = `${API_URL_DOMAIN}/files/nopBai.xlsx`;


          }).catch(err => { console.log(err); message.error("Không xuất được !"); }).finally(() => {
            div_loading[0].setAttribute("hidden", "");
          })
        }}>Xuất report</button>
        <Table columns={columns1} dataSource={thongKeNopBai} /></>
    },
    {
      key: '3', label: 'Tham gia học', children:
        <>
          <button disabled={chuyenDeId && thongKeThoiGianHoc.length != 0 ? false : true} className='ms-3 btn btn-success' onClick={() => {

            var div_loading = document.getElementsByClassName("div_loading");
            div_loading[0].removeAttribute("hidden");

            nopbaiService.ExportThongKeThoiGianHoc(chuyenDeId || 0, valueDoiTac, start, end).then(res => {
              if (res.data.content == "1")
                window.location.href = `${API_URL_DOMAIN}/files/thoiGianHoc.xlsx`;


            }).catch(err => { console.log(err); message.error("Không xuất được !"); }).finally(() => {
              div_loading[0].setAttribute("hidden", "");
            })
          }}>Xuất report</button>
          <Table columns={columns2} dataSource={thongKeThoiGianHoc} />
        </>
    }];
  const averageDiemTrungBinh = thongKeNopBai.length
    ? Math.round(thongKeNopBai.map(item => item.diemTrungBinh).reduce((sum, value) => sum + value, 0) / thongKeNopBai.length) / 10
    : 0;

  return (
    <div className='p-5'>
      <h3>Thống kê thời gian học và làm bài</h3>
      <div className="flex pb-3">
        <Select
          value={chuyenDeId}
          style={{ width: 200 }}
          showSearch
          onChange={(e) => setChuyenDeId(e)}
          options={arrChuyenDe?.map((item) => ({
            label: item.tenChuyenDe,
            value: item.id,
          }))}
          placeholder="Chọn chuyên đề"
          optionFilterProp="label" // Filters based on the label property
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          className='pe-2'
        />
        <Select
          value={valueDoiTac}
          style={{ width: 200 }}
          onChange={(e) => setValueDoiTac(e)}
          options={[
            { label: 'ĐH Hutech', value: "HUTECH" },
            { label: 'ĐH Sài Gòn', value: "DHSAIGON" },
            { label: 'ĐH Văn Lang', value: "DHVANLANG" },
            { label: 'ĐH Mở', value: "DHMO" },
            { label: 'ĐH Hoa Sen', value: "DHHOASEN" },
            { label: 'Học viên', value: "HOCVIEN" },
          ]}
          className='pe-2'
        />
        <RangePicker
          defaultValue={[dayjs(start, 'YYYY-MM-DD'), dayjs(end, 'YYYY-MM-DD')]}
          onChange={onDateChange}
          format={'YYYY-MM-DD'}
        />
      </div>
      <div className="statistics">
        <div className="stat-column" style={{ backgroundColor: '#5a76f3' }}>
          <h2>{thongKeThoiGianHoc.length}</h2>
          <p>Tổng số lượng học viên tham gia học</p>
        </div>
        <div className="stat-column" style={{ backgroundColor: '#37c8a1' }}>
          <h2>{thongKeNopBai.length}/{thongKeThoiGianHoc.length}</h2>
          <p>Tổng số lượng học viên có làm bài tập</p>
        </div>
        <div className="stat-column" style={{ backgroundColor: '#f28c5b' }}>
          <h2>{averageDiemTrungBinh}</h2>
          <p>Điểm trung bình</p>
        </div>
        <div className="stat-column" style={{ backgroundColor: '#7859f2' }}>
          <h2>{arrNguoiDung.length - thongKeThoiGianHoc.length}/{arrNguoiDung.length}</h2>
          <p>Học viên chưa tham gia học</p>
        </div>
      </div>
      <div className="chart">
        <Spin spinning={loadingThoigianChart}>
          <div style={{ width: '100%', height: 400 }}>
            <LineChart
              series={[
                {
                  data: dataThoiGianHoc,
                  showMark: ({ index }: { index: number }) => false,
                  label: "Tổng thời gian học của học viên",
                },
              ]} // Default width, but the chart will stretch to fill the div
              height={400}
              sx={{ width: '100%', height: '100%' }} // Apply full width and height via sx prop
            />
          </div>
        </Spin>
      </div>
      <div className="tabContent mt-5">
        <Tabs
          defaultActiveKey="1"
          items={items}
        />
      </div>
      {openDetail && <Modal width={'80%'} title={null} open={openDetail} footer={null} onCancel={closeDetail} style={{ top: '20px' }}>
        <div className="chart">
          <Spin spinning={loadChartUser}>
            <div style={{ width: '100%', height: 400 }}>
              {dataChartUser[0] && <LineChart
                series={[
                  {
                    data: dataChartUser[0]?.lstSoPhut,
                    showMark: ({ index }: { index: number }) => false,
                    label: "Tổng thời gian học của học viên",
                  },
                ]} // Default width, but the chart will stretch to fill the div
                height={400}
                sx={{ width: '100%', height: '100%' }} // Apply full width and height via sx prop
              />}
            </div>
          </Spin>
        </div>
        <div className='d-flex px-5 align-items-center'>
          <div style={{ width: '50%' }}>
            <p><b>Họ tên :</b> {user?.tenNguoiDung}</p>
            <p><b>Email :</b> {user?.email}</p>
            <p><b>Số điện thoại :</b> {user?.soDt}</p>
            <p><b>Khóa học :</b> {arrChuyenDe?.find(item => item.id == chuyenDeId)?.tenChuyenDe} </p>
            <p><b>Số lượng task đã hoàn thành :</b> {user?.soLuongHoanThanh}/{user?.totalTask}</p>
            <p><b>Điểm trung bình :</b> {user?.diemTrungBinh}/100</p>
          </div>
          <div style={{ width: '50%' }}>
            <p><b>Phần trăn hoàn thành khóa học :</b> <Progress
            percent={user?.phanTramHoanThanh}
            type="circle" 
            strokeColor="#7859f2"
          /></p>
          </div>
         
        </div>
        <TableScore/>
      </Modal>}
    </div>
  );
}

export default ThongKeHocTap;
