import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { chamCongType } from "../../Types/chamCongType"
import { chamCongService } from "../../services/chamCongMentorService";
import { DispatchType } from "../configStore";

type chamCongState={
    arrChamCong:chamCongType[]|null;
    arrChamCongThang:chamCongType[]|null;
    qlChamCong:chamCongType[]|null;
}
const initialState: chamCongState={
    arrChamCong:[],
    arrChamCongThang:[],
    qlChamCong:[]
}
const chamCongMTReducer = createSlice({
    name:'chamCongMTReducer',
    initialState,
    reducers:{
        getAllChamCongAction:(state:chamCongState,action:PayloadAction<chamCongType[]>)=>{
            state.arrChamCong = action.payload
        },
        getAllChamCongthangAction:(state:chamCongState,action:PayloadAction<chamCongType[]>)=>{
            state.arrChamCongThang = action.payload
        },
        getAllquanLi:(state:chamCongState,action:PayloadAction<chamCongType[]>)=>{
            state.qlChamCong = action.payload
        }
    }
})
export const {
    getAllChamCongAction,
    getAllChamCongthangAction,
    getAllquanLi
} = chamCongMTReducer.actions

export default chamCongMTReducer.reducer

export const getAllChamCongThangApi = (mentorId:string,thang:string)=>{
    return async(dispatch:DispatchType)=>{
        const res = await chamCongService.getChamCongByMentor(mentorId,thang);
        const action :PayloadAction<any[]> = getAllChamCongthangAction(res.data.content)
        dispatch(action)
    }
}
export const getAllQLCCApi = (thang:string)=>{
    return async(dispatch:DispatchType)=>{
        const res = await chamCongService.getChamCongTheoThang(thang);
        const action :PayloadAction<any[]> = getAllquanLi(res.data.content)
        dispatch(action)
    }
}