import React from "react";
import Thumbnail from "./LayoutForPInformation/Thumbnail";
import ProjectDescription from "./LayoutForPInformation/ProjectDescription";
import ChatRoom from "./LayoutForPInformation/ChatRoom";
import TaskAssignmentTable from "./LayoutForPInformation/TaskAssignmentTable";
import InformationTable from "./LayoutForPInformation/InformationTable";

const ProjectInformation: React.FC = () => {
  return (
    <div className="ProjectInformation">
      <div className="row py-4">
        <div className="col-3">
          <Thumbnail />
        </div>
        <div className="col-9">
          <ProjectDescription />
        </div>
      </div>
      <hr />
      <div className="row py-4">
        <div className="col-3">
          <ChatRoom />
        </div>
        <div className="col-9">
          <div className="row">
            <div className="col-7">
              <TaskAssignmentTable />
            </div>
            <div className="col-5">
              <InformationTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProjectInformation;
