import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Space } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { TestCase } from '../../Types/runCodeType';

type Props = {
  testcase: TestCase[],
  setTestCase: Dispatch<SetStateAction<TestCase[]>>,
}


const Testcase = ({ testcase, setTestCase }: Props) => {
  const addNewTestcase = () => {
    const newTestcase: TestCase = { _input: '', _output: '' };
    const updatedTC = [...testcase, newTestcase];
    setTestCase(updatedTC);
  };

  const handleInputChange = (index: number, value: string, field: 'input' | 'output') => {
    const updatedTC = [...testcase];
    updatedTC[index][`_${field}`] = value;
    setTestCase(updatedTC);
  };

  const removeTestcase = (index: number) => {
    const updatedTC = [...testcase];
    updatedTC.splice(index, 1);
    setTestCase(updatedTC);
  };
  return (
    <div className="container-fluid">
      <div>
        {testcase.map((tc, index) => (
          <div key={index}>
            <Divider orientation="left">
              <span className="d-flex align-items-center">
                <span className="me-2">Testcase {index + 1}</span>
                {testcase.length > 2 && (
                  <Button
                    danger
                    type="primary"
                    onClick={() => removeTestcase(index)}
                    className="d-flex justify-content-center align-items-center"
                    icon={<MinusCircleOutlined />}
                  />
                )}
              </span>
            </Divider>
            <div className="d-flex align-items-center mb-3 px-5 py-3">
              <Input
                placeholder="Input"
                size="large"
                value={tc._input}
                onChange={(e) => handleInputChange(index, e.target.value, "input")}
                className="me-5"
              />
              <Input
                placeholder="Output"
                size="large"
                value={tc._output}
                onChange={(e) => handleInputChange(index, e.target.value, "output")}
                className="me-5"
              />
            </div>
          </div>
        ))}
        <Button
          type="dashed"
          onClick={addNewTestcase}
          block
          icon={<PlusOutlined />}
          className="mt-4"
        >
          Add Testcase
        </Button>
      </div>
    </div>

  )
}

export default Testcase
