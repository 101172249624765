import { Modal, Tag } from 'antd'
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react'
import { Table } from 'antd';
import { DispatchType, RootState } from '../../../redux/configStore';
import { useDispatch } from 'react-redux';
import { getChuyenDeHocApi, getDiemDanhLopApi, getUserChuyenDeApi } from '../../../redux/ChuyenDeReducer/chuyenDeReducer';
import { useSelector } from 'react-redux';
import { dinhDangNgay } from '../../../util/dateFormat';
import { classService } from '../../../services/classService';

type Props = {
    chuyenDeId: number,
    metaData: any
}

const DiemDanh = ({
    chuyenDeId,
    metaData
}: Props) => {

    const [open, setOpen] = useState(false);
    const { arrUserChuyenDe, arrDiemDanhLop } = useSelector((state: RootState) => state.chuyenDeReducer);

    const dispatch: DispatchType = useDispatch();


    let dNow = new Date();

    const diemDanh = (dateString: string, nguoiDungId: string, coMat: boolean) => {

        let diemDanhLop = arrDiemDanhLop.find((n: any) => dinhDangNgay(n.ngayTao) == dateString);


        if (diemDanhLop) {

            let danhSachDiemDanh = JSON.parse(diemDanhLop.danhSachDiemDanh);
            danhSachDiemDanh = danhSachDiemDanh.filter((n: any) => n.nguoiDungId != nguoiDungId);

            danhSachDiemDanh.push({
                nguoiDungId,
                coMat,
                lyDo: ""
            })


            let newDiemDanhLop = { ...diemDanhLop, danhSachDiemDanh: JSON.stringify(danhSachDiemDanh) }


            classService.capNhatDiemDanhLop(newDiemDanhLop).then(res => {
                dispatch(getDiemDanhLopApi(Number(chuyenDeId)))
            })
        } else {
            let userLogin = localStorage.getItem("USER_LOGIN");
            let mentorId = userLogin ? JSON.parse(userLogin).id : null;

            let model = {
                id: 0,
                chuyenDeId,
                danhSachDiemDanh: JSON.stringify([{
                    nguoiDungId,
                    coMat,
                    lyDo: ""
                }]),
                ngayTao: new Date(),
                nguoiDiemDanh: mentorId
            }
            classService.themDiemDanhLop(model).then(res => {
                dispatch(getDiemDanhLopApi(Number(chuyenDeId)))
            })
        }
    }

    const columns: ColumnsType<any> = [
        {
            title: 'Họ tên',

            width: 150,
            fixed: true,
            render: (_, record,idx) => {

                return <label>
                    {idx+1} - {record.hoTen}
                </label>
            }
        },

    ];

    arrDiemDanhLop.map((item: any) => {
        let ngayTao = item.ngayTao;
        columns.push({
            title: <p>{dinhDangNgay(ngayTao)} <br /> Có mặt {JSON.parse(item.danhSachDiemDanh).length}</p>,

            width: 150,
            render: (_, record) => {

                let danhSachDiemDanh = JSON.parse(item.danhSachDiemDanh);
                let checkCoMat = danhSachDiemDanh.find((n: any) => n.nguoiDungId == record.nguoiDungId);

                return <>

                    {
                        checkCoMat && checkCoMat.coMat ?
                            <Tag color='green' onClick={() => {
                                diemDanh(dinhDangNgay(ngayTao), record.nguoiDungId, false)
                            }}><i className='fa fa-check'></i></Tag>
                            :
                            <>
                                <Tag color='red' onClick={() => {
                                    diemDanh(dinhDangNgay(ngayTao), record.nguoiDungId, true)
                                }}><i className='fa fa-times'></i></Tag>

                                {/* <input placeholder='Lý do...' className='border-0' /> */}
                            </>
                    }



                </>
            }
        })
    })

    let diemDanhHomNay = arrDiemDanhLop.find((n: any) => dinhDangNgay(n.ngayTao) == dinhDangNgay(dNow));
    if (!diemDanhHomNay) {
        columns.push({
            title: dinhDangNgay(dNow),

            width: 150,
            render: (_, record) => {

                return <>
                    <Tag color='red' onClick={() => {
                        diemDanh(dinhDangNgay(dNow), record.nguoiDungId, true)
                    }}><i className='fa fa-times'></i></Tag>


                </>
            }
        })
    }

    let metaDatachuyenDe = metaData ? JSON.parse(metaData) : null;

    return (
        <div>
            {
                metaDatachuyenDe && metaDatachuyenDe.loaiChuyenDe == "CLASS" && <button className='btn btn-sm btn-success mb-3' onClick={() => {
                    setOpen(true)
                    dispatch(getUserChuyenDeApi(Number(chuyenDeId)))
                    dispatch(getDiemDanhLopApi(Number(chuyenDeId)))

                }}> Điểm danh </button>
            }


            <Modal
                title="Điểm danh"

                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={"90%"}
                footer={null}
            >

                <Table
                    columns={columns}
                    dataSource={arrUserChuyenDe}
                    scroll={{ x: 1500 }}
                    pagination={false}


                />

            </Modal>
        </div>
    )
}

export default DiemDanh