import React, { useEffect, useState } from 'react'
import { ChamBaiCSS } from './BaiTap'
import { BulbOutlined, CodeOutlined, FileOutlined, FormOutlined } from '@ant-design/icons'
import { Divider, InputNumber } from 'antd'
import { CKEditor } from 'ckeditor4-react'
import { NopBaiViewMentor, trangThai } from '../../../Types/nopBaiType'
import parse from 'html-react-parser'
import { useAppSelector } from '../../../redux/hooks'
import { BaiHocViewMentorModel } from '../../../Types/baiHocType'
import { RequireModel } from '../../../Types/RequireForStudent'
import { GroupForTaskModel, GroupForTaskModel2 } from '../../../Types/groupForTask'
import { config } from '../../../util/config'
import { NguoiDungType } from '../../../Types/nguoiDungType'
import ChamDiem from './ChamDiem'
function NoiDungChamBai({
  callAPI,
  data,
  dataNopBai,
  handleCancelModal
}: {
  callAPI: () => Promise<void>
  data: NopBaiViewMentor | null
  dataNopBai: NopBaiViewMentor[] | null
  handleCancelModal: () => void
}) {
  const myUser = config.getStoreJson('USER_LOGIN') as NguoiDungType

  const [nhanXet, setNhanXet] = useState('')
  const [dataTask, setDataTask] = useState<BaiHocViewMentorModel | null>(null)
  const [activeYeuCau, setActiveYeuCau] = useState(0)
  //tìm task
  const { groupForTaskViewMentorModel, groupForTaskViewMentorModel2, requireForStudentsByDsId } = useAppSelector(
    (e) => e.groupForTaskReducer
  )

  const { dsBaiHocByDsId, dsIdBaiHoc } = useAppSelector((s) => s.baiHocReducer)
  const { dsMonhocTheoDSId } = useAppSelector((s) => s.monHocReducer)

  // console.log(data&&findTask(data?.monHocId,data?.taskId));

  const onChangeChamDiem = (value: number | null) => {
    console.log('changed', value)
  }
  const onEditorChange = (evt: any) => {
    // console.log(evt.editor.getData());

    setNhanXet(evt.editor.getData())
  }
  const filterBaiTap = () => {
    if (data && dataNopBai) {
      if (data.maLoai === 'BAITAP' || data.maLoai === 'HOMEWORK') {
        let newNopBai: NopBaiViewMentor[] = []

        newNopBai = [...dataNopBai?.filter((e) => e.nguoiDungId === data.nguoiDungId)]

        return newNopBai
      }
    }
    return null
  }

  const findTask = (idMonhoc: number, idThuTuTask: number) => {
    const indexMonhoc = dsMonhocTheoDSId.findIndex((e) => e.id === idMonhoc)
    if (indexMonhoc !== -1) {
      const indexTask = dsMonhocTheoDSId[indexMonhoc].danhSachNoiDung.findIndex((e) => e.id === idThuTuTask)

      if (indexTask !== -1) {
        const IdTask = Number(dsMonhocTheoDSId[indexMonhoc].danhSachNoiDung[indexTask].noiDungNhiemVu)
        const index = dsBaiHocByDsId.findIndex((e) => e.id === IdTask)
        if (index !== -1) return dsBaiHocByDsId[index] ?? null
      }
    }
    return null
  }

  const checkBaiTapNop = (rqId: number) => {

    if (dataTask && dataNopBai && data && (dataTask.maLoai === 'BAITAP' || data.maLoai === 'HOMEWORK')) {
      const index = dataNopBai?.findIndex((e) => e.requireId === rqId && e.nguoiDungId === data?.nguoiDungId)

      if (index !== -1) return dataNopBai[index]
    } else if (dataTask && dataNopBai && data && (dataTask.maLoai === 'CAPS' || data.maLoai === 'DUAN')) {


      //tìm kiếm gft tương ứng theo userid,chuyendeid,monhocid,taskid
      const index = groupForTaskViewMentorModel2.findIndex(
        (e) =>
          e.chuyenDeId === data.chuyenDeId &&
          e.monHocId === data.monHocId &&
          e.taskId === data.taskId &&
          e.danhSachHocVien.includes(data.nguoiDungId)
      )


      //có được ds học viên => ds nộp bài tương ứng
      const gftFind = groupForTaskViewMentorModel2[index]
      let dsNopBai = dataNopBai.filter(
        (e) =>
          e.chuyenDeId === data.chuyenDeId &&
          e.monHocId === data.monHocId &&
          e.taskId === data.taskId
      )

      if (index !== -1) {
        dsNopBai = dsNopBai.filter(
          (e) =>
            gftFind.danhSachHocVien.includes(e.nguoiDungId)
        )
      }


      //render theo requireId
      const index2 = dsNopBai.findIndex((e) => e.requireId === rqId && e.nguoiDungId === data?.nguoiDungId)

      return dsNopBai[index2]

    }
    return null
  }

  const parseTo = (data: string) => {
    let a
    try {
      a = parse(data)
    } catch (error) {
      a = <></>
    }
    return a
  }
  const checkTrangThaiBaiTap = (trangThai: trangThai) => {
    switch (trangThai) {
      case 'DA_CHAM':
        return 'dacham'
      case 'CHO_CHAM':
        return 'chocham'

      default:
        return ''
    }
  }

  useEffect(() => {
    if (data && data.id) {
      setDataTask(findTask(data.monHocId, data.taskId))
    }
  }, [data, data?.id])

  const rederMenuYeuCau = () => {

    return (
      dataTask &&
      dataTask.noiDung &&
      dataTask.noiDung.map((e, i) => {
        const nopBai = checkBaiTapNop(e.requireId)


        return (
          <li
            key={'nav tt ' + i}
            className={`nav-item ${nopBai && nopBai.trangThai && checkTrangThaiBaiTap(nopBai.trangThai)}`}
            role='presentation'
          >
            <button
              onClick={() => {
                setActiveYeuCau(i)
              }}
              key={'nav-link yeucau' + i}
              className={`nav-link ${activeYeuCau === i ? 'active' : ''}`}
              id={'tab' + i}
              data-bs-toggle='tab'
              data-bs-target={'#tabpane' + i}
              type='button'
              role='tab'
            >
              {i + 1}
            </button>
          </li>
        )
      })
    )
  }
  const rederNoiDUngYeuCau = () => {

    return (
      dataTask &&
      dataTask.noiDung &&
      dataTask.noiDung.map((e, i) => {
        const nopBai = checkBaiTapNop(e.requireId)
        return (
          <div
            key={'tab-pane ' + i}
            className={`tab-pane  fade row ${activeYeuCau === i ? 'show active' : ''}`}
            id={'tabpane' + i}
            role='tabpanel'
          >
            <div className='debai'>
              <div>
                <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link chambaiItem active'
                      id='pills-debai-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-debai'
                      type='button'
                      role='tab'
                    >
                      <div className='d-flex align-items-center gap-1'>
                        {' '}
                        <FileOutlined /> Đề bài
                      </div>
                    </button>
                  </li>
                  {/* <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link chambaiItem'
                      id='pills-dapan-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-dapan'
                      type='button'
                      role='tab'
                    >
                      {' '}
                      <div className='d-flex align-items-center gap-1'>
                        <FormOutlined /> Đáp án
                      </div>
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link chambaiItem'
                      id='pills-goiy-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-goiy'
                      type='button'
                      role='tab'
                    >
                      <div className='d-flex align-items-center gap-1'>
                        <BulbOutlined /> Gợi ý
                      </div>
                    </button>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <button
                      className='nav-link chambaiItem'
                      id='pills-huongdan-tab'
                      data-bs-toggle='pill'
                      data-bs-target='#pills-huongdan'
                      type='button'
                      role='tab'
                    >
                      <div className='d-flex align-items-center gap-1'>
                        <CodeOutlined /> Hướng dẫn
                      </div>
                    </button>
                  </li> */}
                </ul>
                <div className='tab-content' id='pills-tabContent'>
                  <div className='tab-pane fade show active' id='pills-debai' role='tabpanel'>
                    <div>{e.noiDung && parseTo(e.noiDung)}</div>
                  </div>
                  <div className='tab-pane fade' id='pills-dapan' role='tabpanel' aria-labelledby='pills-profile-tab'>
                    {e.ketQua && parseTo(e.ketQua)}
                  </div>
                  <div className='tab-pane fade' id='pills-goiy' role='tabpanel' aria-labelledby='pills-contact-tab'>
                    {e.hint && parseTo(e.hint)}
                  </div>{' '}
                  <div
                    className='tab-pane fade'
                    id='pills-huongdan'
                    role='tabpanel'
                    aria-labelledby='pills-contact-tab'
                  >
                    {e.solution && parseTo(e.solution)}
                  </div>
                </div>
              </div>
            </div>
            <div className='chamdiem'>
              {nopBai && nopBai.trangThai !== 'CHUA_NOP' ? (
                <ChamDiem callAPI={callAPI} handleCancelModal={handleCancelModal} data={nopBai} />
              ) : (
                <span className='chamdiem_title'>Bài tập chưa nộp hoặc chưa làm</span>
              )}
            </div>
          </div>
        )
      })
    )
  }
  useEffect(() => {
    return () => {
      console.log('nội dung chấm bài close');
    }
  }, [])

  console.log(data)

  return (
    <ChamBaiCSS>
      <div className='title'></div>
      <div className='head'>
        <svg width={33} height={33} viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <circle cx='16.5' cy='16.5' r='16.5' fill='#3E80EB' fillOpacity='0.2' />
          <path
            d='M19.75 22.25C20.4404 22.25 21 21.6904 21 21C21 20.3096 20.4404 19.75 19.75 19.75C19.0596 19.75 18.5 20.3096 18.5 21C18.5 21.6904 19.0596 22.25 19.75 22.25Z'
            fill='#3E80EB'
          />
          <path
            d='M24.6106 20.6744C24.2245 19.6906 23.5581 18.8417 22.6941 18.233C21.83 17.6243 20.8064 17.2825 19.75 17.25C18.6936 17.2825 17.67 17.6243 16.8059 18.233C15.9419 18.8417 15.2755 19.6906 14.8894 20.6744L14.75 21L14.8894 21.3256C15.2755 22.3094 15.9419 23.1583 16.8059 23.767C17.67 24.3757 18.6936 24.7175 19.75 24.75C20.8064 24.7175 21.83 24.3757 22.6941 23.767C23.5581 23.1583 24.2245 22.3094 24.6106 21.3256L24.75 21L24.6106 20.6744ZM19.75 23.5C19.2555 23.5 18.7722 23.3534 18.3611 23.0787C17.95 22.804 17.6295 22.4135 17.4403 21.9567C17.2511 21.4999 17.2016 20.9972 17.298 20.5123C17.3945 20.0273 17.6326 19.5819 17.9822 19.2322C18.3319 18.8826 18.7773 18.6445 19.2623 18.548C19.7472 18.4516 20.2499 18.5011 20.7067 18.6903C21.1635 18.8795 21.554 19.2 21.8287 19.6111C22.1034 20.0222 22.25 20.5055 22.25 21C22.2492 21.6628 21.9855 22.2982 21.5169 22.7669C21.0482 23.2355 20.4128 23.4992 19.75 23.5Z'
            fill='#3E80EB'
          />
          <path
            d='M13.5 23.5H10.375V10.375H12.25V12.25H19.75V10.375H21.625V16H22.875V10.375C22.875 10.0435 22.7433 9.72554 22.5089 9.49112C22.2745 9.2567 21.9565 9.125 21.625 9.125H19.75V8.5C19.75 8.16848 19.6183 7.85054 19.3839 7.61612C19.1495 7.3817 18.8315 7.25 18.5 7.25H13.5C13.1685 7.25 12.8505 7.3817 12.6161 7.61612C12.3817 7.85054 12.25 8.16848 12.25 8.5V9.125H10.375C10.0435 9.125 9.72554 9.2567 9.49112 9.49112C9.2567 9.72554 9.125 10.0435 9.125 10.375V23.5C9.125 23.8315 9.2567 24.1495 9.49112 24.3839C9.72554 24.6183 10.0435 24.75 10.375 24.75H13.5V23.5ZM13.5 8.5H18.5V11H13.5V8.5Z'
            fill='#3E80EB'
          />
        </svg>
        <span>Tên nhiệm vụ: {data?.tenNhiemVu}</span>


      </div>
      <div className='body'>

        <h2 className='text-white my-3'>Họ tên: {data?.tenNguoiDung}</h2>
        <div className=''>
          <h5 className='text-white my-3'>Các nhiệm vụ:</h5>
        </div>
        <div className='noiDung'>
          <div className=''>
            <ul className='nav nav-tabs tabYeucau' id='myTab' role='tablist'>
              {/* <li className='nav-item dacham' role='presentation'>
                <button
                  className='nav-link active'
                  id='tab-1'
                  data-bs-toggle='tab'
                  data-bs-target='#tab-panel1'
                  type='button'
                  role='tab'
               
                >
                  1
                </button>
              </li>
              <li className='nav-item dacham' role='presentation'>
                <button
                  className='nav-link'
                  id='tab-2'
                  data-bs-toggle='tab'
                  data-bs-target='#tab-panel2'
                  type='button'
                  role='tab'
               
                >
                  2
                </button>
              </li>
              <li className='nav-item dacham' role='presentation'>
                <button
                  className='nav-link'
                  id='tab-3'
                  data-bs-toggle='tab'
                  data-bs-target='#tab-panel3'
                  type='button'
                  role='tab'
                 
                >
                  3
                </button>
              </li> */}
              {rederMenuYeuCau()}
            </ul>
            <div className='tab-content contentTab  mt-3' id='myTabContent'>
              {rederNoiDUngYeuCau()}
              {/* <div
                className='tab-pane  fade row show active'
                id='tabpane0'
                role='tabpanel'
              >
                <div className='debai'>
                  <div>
                    <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link chambaiItem active'
                          id='pills-debai-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-debai'
                          type='button'
                          role='tab'
                        >
                          <div className='d-flex align-items-center gap-1'>
                            {' '}
                            <FileOutlined /> Đề bài1
                          </div>
                        </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link chambaiItem'
                          id='pills-dapan-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-dapan'
                          type='button'
                          role='tab'
                        >
                          {' '}
                          <div className='d-flex align-items-center gap-1'>
                            <FormOutlined /> Đáp án
                          </div>
                        </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link chambaiItem'
                          id='pills-goiy-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-goiy'
                          type='button'
                          role='tab'
                        >
                          <div className='d-flex align-items-center gap-1'>
                            <BulbOutlined /> Gợi ý
                          </div>
                        </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link chambaiItem'
                          id='pills-huongdan-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-huongdan'
                          type='button'
                          role='tab'
                        >
                          <div className='d-flex align-items-center gap-1'>
                            <CodeOutlined /> Hướng dẫn
                          </div>
                        </button>
                      </li>
                    </ul>
                    <div className='tab-content' id='pills-tabContent'>
                      <div className='tab-pane fade show active' id='pills-debai' role='tabpanel'>
                        <div>{data && data.tenYeuCau && parse(data?.tenYeuCau)}</div>
                      </div>
                      <div
                        className='tab-pane fade'
                        id='pills-dapan'
                        role='tabpanel'
                        aria-labelledby='pills-profile-tab'
                      >
                        ..2.
                      </div>
                      <div
                        className='tab-pane fade'
                        id='pills-goiy'
                        role='tabpanel'
                        aria-labelledby='pills-contact-tab'
                      >
                        .3..
                      </div>{' '}
                      <div
                        className='tab-pane fade'
                        id='pills-huongdan'
                        role='tabpanel'
                        aria-labelledby='pills-contact-tab'
                      >
                        .4..
                      </div>
                    </div>
                  </div>
                </div>
                <div className='chamdiem'>
                  <div className='chamdiem_title'>Danh sách file nộp</div>
                  <div className='filenop_table'>
                    <table className='table text-light'>
                      <thead>
                        <tr>
                          <th scope='col'>Loại file nộp</th>
                          <th scope='col'>File nộp</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Lưu đồ</td>
                          <td>
                            <a href=''>Url</a>
                          </td>
                        </tr>
                        <tr>
                          <td>Source code</td>
                          <td>
                            <a href=''>Url</a>
                          </td>
                        </tr>
                        <tr>
                          <th>Deploy</th>
                          <td>
                            <a href=''>Url</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='chamdiem_inpt'>
                    <span className='chamdiem_title'>Điểm</span>
                    <InputNumber min={0} max={100} onChange={onChangeChamDiem} />
                  </div>
                  <div className='nhanxet mt-3'>
                    <span className='chamdiem_title'>Nhận xét</span>
                    <div className='nhanxet_content'>
                      <div className='nhanxet'>
                        {
                          <CKEditor
                            key={'a'}
                            // initData={NopBaiState.nhanXet}
                            // data={nopBai.nhanXet}
                            name='nhanXet'
                            config={{
                              toolbarGroups: [
                                {
                                  name: 'document',
                                  groups: ['mode', 'document', 'doctools']
                                },
                                { name: 'clipboard', groups: ['clipboard', 'undo'] },
                                {
                                  name: 'editing',
                                  groups: ['find', 'selection', 'spellchecker']
                                },
                                { name: 'forms' },
                                '/',
                                { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                                {
                                  name: 'paragraph',
                                  groups: ['list', 'indent', 'blocks', 'align', 'bidi']
                                },
                                { name: 'links' },
                                { name: 'insert' },
                                '/',
                                { name: 'styles' },
                                { name: 'colors' },
                                { name: 'tools' },
                                { name: 'others' },
                                { name: 'about' }
                              ],
                              extraPlugins: 'codesnippet' // Thêm plugin codesnippet vào danh sách extraPlugins
                            }}
                            onChange={onEditorChange}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='tab-pane  fade row'
               id='tabpane1' 
               role='tabpanel' 
               >
              <div className='debai'>
                  <div>
                    <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link chambaiItem active'
                          id='pills-debai-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-debai'
                          type='button'
                          role='tab'
                        >
                          <div className='d-flex align-items-center gap-1'>
                            {' '}
                            <FileOutlined /> Đề bài 2
                          </div>
                        </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link chambaiItem'
                          id='pills-dapan-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-dapan'
                          type='button'
                          role='tab'
                        >
                          {' '}
                          <div className='d-flex align-items-center gap-1'>
                            <FormOutlined /> Đáp án
                          </div>
                        </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link chambaiItem'
                          id='pills-goiy-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-goiy'
                          type='button'
                          role='tab'
                        >
                          <div className='d-flex align-items-center gap-1'>
                            <BulbOutlined /> Gợi ý
                          </div>
                        </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link chambaiItem'
                          id='pills-huongdan-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-huongdan'
                          type='button'
                          role='tab'
                        >
                          <div className='d-flex align-items-center gap-1'>
                            <CodeOutlined /> Hướng dẫn
                          </div>
                        </button>
                      </li>
                    </ul>
                    <div className='tab-content' id='pills-tabContent'>
                      <div className='tab-pane fade show active' id='pills-debai' role='tabpanel'>
                        <div>{data && data.tenYeuCau && parse(data?.tenYeuCau)}</div>
                      </div>
                      <div
                        className='tab-pane fade'
                        id='pills-dapan'
                        role='tabpanel'
                        // aria-labelledby='pills-profile-tab'
                      >
                        ..2.
                      </div>
                      <div
                        className='tab-pane fade'
                        id='pills-goiy'
                        role='tabpanel'
                        // aria-labelledby='pills-contact-tab'
                      >
                        .3..
                      </div>{' '}
                      <div
                        className='tab-pane fade'
                        id='pills-huongdan'
                        role='tabpanel'
                        // aria-labelledby='pills-contact-tab'
                      >
                        .4..
                      </div>
                    </div>
                  </div>
                </div>
                <div className='chamdiem'>
                  <div className='chamdiem_title'>Danh sách file nộp</div>
                  <div className='filenop_table'>
                    <table className='table text-light'>
                      <thead>
                        <tr>
                          <th scope='col'>Loại file nộp</th>
                          <th scope='col'>File nộp</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Lưu đồ</td>
                          <td>
                            <a href=''>Url</a>
                          </td>
                        </tr>
                        <tr>
                          <td>Source code</td>
                          <td>
                            <a href=''>Url</a>
                          </td>
                        </tr>
                        <tr>
                          <th>Deploy</th>
                          <td>
                            <a href=''>Url</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='chamdiem_inpt'>
                    <span className='chamdiem_title'>Điểm</span>
                    <InputNumber min={0} max={100} onChange={onChangeChamDiem} />
                  </div>
                  <div className='nhanxet mt-3'>
                    <span className='chamdiem_title'>Nhận xét</span>
                    <div className='nhanxet_content'>
                      <div className='nhanxet'>
                        {
                          <CKEditor
                            key={'a'}
                            // initData={NopBaiState.nhanXet}
                            // data={nopBai.nhanXet}
                            name='nhanXet'
                            config={{
                              toolbarGroups: [
                                {
                                  name: 'document',
                                  groups: ['mode', 'document', 'doctools']
                                },
                                { name: 'clipboard', groups: ['clipboard', 'undo'] },
                                {
                                  name: 'editing',
                                  groups: ['find', 'selection', 'spellchecker']
                                },
                                { name: 'forms' },
                                '/',
                                { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                                {
                                  name: 'paragraph',
                                  groups: ['list', 'indent', 'blocks', 'align', 'bidi']
                                },
                                { name: 'links' },
                                { name: 'insert' },
                                '/',
                                { name: 'styles' },
                                { name: 'colors' },
                                { name: 'tools' },
                                { name: 'others' },
                                { name: 'about' }
                              ],
                              extraPlugins: 'codesnippet' // Thêm plugin codesnippet vào danh sách extraPlugins
                            }}
                            onChange={onEditorChange}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='tab-pane  fade row'
               id='tabpane2' 
               role='tabpanel' 
               >
              <div className='debai'>
                  <div>
                    <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link chambaiItem active'
                          id='pills-debai-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-debai'
                          type='button'
                          role='tab'
                        >
                          <div className='d-flex align-items-center gap-1'>
                            {' '}
                            <FileOutlined /> Đề bài 3
                          </div>
                        </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link chambaiItem'
                          id='pills-dapan-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-dapan'
                          type='button'
                          role='tab'
                        >
                          {' '}
                          <div className='d-flex align-items-center gap-1'>
                            <FormOutlined /> Đáp án
                          </div>
                        </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link chambaiItem'
                          id='pills-goiy-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-goiy'
                          type='button'
                          role='tab'
                        >
                          <div className='d-flex align-items-center gap-1'>
                            <BulbOutlined /> Gợi ý
                          </div>
                        </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link chambaiItem'
                          id='pills-huongdan-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-huongdan'
                          type='button'
                          role='tab'
                        >
                          <div className='d-flex align-items-center gap-1'>
                            <CodeOutlined /> Hướng dẫn
                          </div>
                        </button>
                      </li>
                    </ul>
                    <div className='tab-content' id='pills-tabContent'>
                      <div className='tab-pane fade show active' id='pills-debai' role='tabpanel'>
                        <div>{data && data.tenYeuCau && parse(data?.tenYeuCau)}</div>
                      </div>
                      <div
                        className='tab-pane fade'
                        id='pills-dapan'
                        role='tabpanel'
                        aria-labelledby='pills-profile-tab'
                      >
                        ..2.
                      </div>
                      <div
                        className='tab-pane fade'
                        id='pills-goiy'
                        role='tabpanel'
                        aria-labelledby='pills-contact-tab'
                      >
                        .3..
                      </div>{' '}
                      <div
                        className='tab-pane fade'
                        id='pills-huongdan'
                        role='tabpanel'
                        aria-labelledby='pills-contact-tab'
                      >
                        .4..
                      </div>
                    </div>
                  </div>
                </div>
                <div className='chamdiem'>
                  <div className='chamdiem_title'>Danh sách file nộp</div>
                  <div className='filenop_table'>
                    <table className='table text-light'>
                      <thead>
                        <tr>
                          <th scope='col'>Loại file nộp</th>
                          <th scope='col'>File nộp</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Lưu đồ</td>
                          <td>
                            <a href=''>Url</a>
                          </td>
                        </tr>
                        <tr>
                          <td>Source code</td>
                          <td>
                            <a href=''>Url</a>
                          </td>
                        </tr>
                        <tr>
                          <th>Deploy</th>
                          <td>
                            <a href=''>Url</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='chamdiem_inpt'>
                    <span className='chamdiem_title'>Điểm</span>
                    <InputNumber min={0} max={100} onChange={onChangeChamDiem} />
                  </div>
                  <div className='nhanxet mt-3'>
                    <span className='chamdiem_title'>Nhận xét</span>
                    <div className='nhanxet_content'>
                      <div className='nhanxet'>
                        {
                          <CKEditor
                            key={'a'}
                            // initData={NopBaiState.nhanXet}
                            // data={nopBai.nhanXet}
                            name='nhanXet'
                            config={{
                              toolbarGroups: [
                                {
                                  name: 'document',
                                  groups: ['mode', 'document', 'doctools']
                                },
                                { name: 'clipboard', groups: ['clipboard', 'undo'] },
                                {
                                  name: 'editing',
                                  groups: ['find', 'selection', 'spellchecker']
                                },
                                { name: 'forms' },
                                '/',
                                { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                                {
                                  name: 'paragraph',
                                  groups: ['list', 'indent', 'blocks', 'align', 'bidi']
                                },
                                { name: 'links' },
                                { name: 'insert' },
                                '/',
                                { name: 'styles' },
                                { name: 'colors' },
                                { name: 'tools' },
                                { name: 'others' },
                                { name: 'about' }
                              ],
                              extraPlugins: 'codesnippet' // Thêm plugin codesnippet vào danh sách extraPlugins
                            }}
                            onChange={onEditorChange}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          {/* <div className='yeuCau'></div>
              <div className='chamDiem'></div> */}
        </div>
      </div>
    </ChamBaiCSS>
  )
}

export default NoiDungChamBai
