import { InboxOutlined } from '@ant-design/icons'
import { Input, UploadProps, message } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import { CKEditor } from 'ckeditor4-react'
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { API_URL_SOLUTION } from '../../util/urlDomain'

type Props = {
    setData: any,
    baiTap: any,
    setKeyState: any,
    setBlockUp:any,
    blockUp:any

}

export const VideoHuongDan = ({
    setData,
    baiTap,
    setKeyState,
    setBlockUp,
    blockUp
}: Props) => {
    const [key, setKey] = useState(Date.now());

    useEffect(() => {
        setKey(Date.now());

    }, [])


    const props: UploadProps = {
        name: 'file',
        multiple: true,
        action: API_URL_SOLUTION,
        beforeUpload() {
            setKeyState("huongDan")
            setBlockUp(true)

        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {

                let lstVideo = []

                if (!baiTap?.huongDan || baiTap?.huongDan == "") {
                    lstVideo = [{ tieuDe: info.file.response[0], noiDung: info.file.response[0] }]
                } else {
                    lstVideo = JSON.parse(baiTap?.huongDan)
                    lstVideo.push({ tieuDe: info.file.response[0], noiDung: info.file.response[0] })
                }


                setData(JSON.stringify(lstVideo))
                setBlockUp(false)

            } else if (status === 'error') {
                // message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <div>
            {/* <Input placeholder='link youtube' onChange={onChangeInput} value={noiDungTaiLieu} /> */}
            <Dragger {...props} disabled={blockUp}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>

            </Dragger>
             { blockUp && <span className='text-danger'>Hãy đợi các tác vụ upload hoàn thành</span>}
        </div>
    )
}

