import React, { useEffect } from "react";
import { DispatchType, RootState } from "../../redux/configStore";
import { useDispatch, useSelector } from "react-redux";

import { getChuyenDeApi, getDSChuyenDeMentorApi, getListClass, getListTagApi } from "../../redux/ChuyenDeReducer/chuyenDeReducer";
import { getMonHocApi } from "../../redux/MonHocReducer/monHocReducer";
import { getBaiHocApi } from "../../redux/BaiHocReducer/BaiHocReducer";
import { useNavigate } from "react-router-dom";
import { config, getStore } from "../../util/config";
import { getDanhSachGiangVienApi, getDanhSachMentorApi, getNguoiDungPhanTrangApi } from "../../redux/UserReducer/userReducer";
import useQueryConfig from "../../hooks/useQueryConfig";
import { fetchCategories, fetchQuestions } from "../../redux/RunCodeReducer/runcodeReducer";
import { getPracticeApi } from "../../redux/PracticeReducer/practiceReducer";
import { getBaiTestApi, getNhomQuyenApi } from "../../redux/HeThongReducer/heThongReducer";
import { getListItemsApi } from "../../redux/VatPhamReducer/vatPhamReducer";
import { NguoiDungType } from "../../Types/nguoiDungType";

type Props = {};

const Home: React.FC = (props: Props): JSX.Element => {
  const navigate = useNavigate();

  const dispatch: DispatchType = useDispatch();
  const queryConfig = useQueryConfig();
  const role = config.getStoreJson('USER_LOGIN') as NguoiDungType
  useEffect(() => {
    if (role && (role.maNhomQuyen === 'ADMIN' || role.maNhomQuyen === 'LECTURE')) {
      dispatch(getChuyenDeApi());
    }
    else {
      dispatch(getDSChuyenDeMentorApi(role?.id))
    }
    dispatch(getListClass())
    dispatch(getMonHocApi());
    dispatch(getPracticeApi());
    dispatch(getBaiHocApi());
    dispatch(getNguoiDungPhanTrangApi(queryConfig));
    dispatch(fetchQuestions())
    dispatch(fetchCategories())
    dispatch(getListTagApi());
    dispatch(getDanhSachMentorApi());
    dispatch(getDanhSachGiangVienApi());

    dispatch(getBaiTestApi());
    dispatch(getListItemsApi());
    dispatch(getNhomQuyenApi());

  }, []);

  useEffect(() => {
    let path = getStore("path");
    if (path !== "login" && path !== "home") {

      navigate(path);
    }
  }, [window.location.pathname]);

  return (
    <div className="container">
      <h3>Product features</h3>
      <div className="row">
        {/* {arrProduct.map((item:ProducModel,index:number)=>{
            return <div className='col-md-4 mt-2' key={index}>
                <ProductCard product={item} />
            </div>
          })} */}
      </div>
    </div>
  );
};

export default Home;

// const ComponentA:React.FC  = (prop) => {
//   return <div>
//     abc
//   </div>
// }

// const ComponentInstance: JSX.Element = <ComponentA />
