import { Col, Drawer, Row, Select, Space, message } from "antd";
import React from "react";
import { Button, Form, Input } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { nguoiDungService } from "../../services/nguoiDungService";
import useQueryConfig from "../../hooks/useQueryConfig";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getNguoiDungPhanTrangApi } from "../../redux/UserReducer/userReducer";
type Props = {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
};
const FormTaoNguoiDung = ({ isModalOpen, handleCancel, handleOk }: Props) => {

  const { arrNhomQuyen } = useAppSelector((state) => state.heThongReducer);
  const lstNhomQuyen: any[] = [];

  arrNhomQuyen?.forEach(item => {
    lstNhomQuyen.push({
      label: item.tenNhom || "",
      value: item.id
    })
  })


  const [messageApi, contextHolder] = message.useMessage();
  const queryConfig = useQueryConfig();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const formik = useFormik({
    initialValues: {
      hoTen: "",
      soDT: "",
      matKhau: "",
      email: "",
      maNhomQuyen: "USER",
      facebook:"",
    },
    validationSchema: Yup.object({
      hoTen: Yup.string().required("Không bỏ trống tên"),
      soDT: Yup.string().required("Không bỏ trống số điện thoại"),
      matKhau: Yup.string().required("Không bỏ trống mật khẩu"),
      email: Yup.string()
        .email("Email chưa đúng định dạng")
        .required("Không bỏ trống email"),
    }),
    onSubmit: (values) => {
      nguoiDungService
        .dangKyNguoiDung({
          ...values,
          thongTinMoRong: JSON.stringify({
            NamSinh: "1993",
            SoCmnd: "123456789",
            HinhCmnd: [],
            CongViecHienTai: "[]",
            NoiCongTacHienTai: "",
            NguonGioiThieu: "",
            FacebookUrl: values.facebook,
            LuongMongMuon: 0,
            DongYHoTroTimViec: true,
            gioiThieu: "",
            truongHoc: [
              {
                tenTruong: "",
                thoiGian: [],
                moTa: ""
              }
            ],
            kinhNghiem: [
              {
                tenCongTy: "",
                thoiGian: [],
                chucVu: "",
                moTa: ""
              }
            ],
            thanhTich: [],
            capDanhHieu: 1,
            tagSkill: [],
            hoTenPH: "",
            emailPH: "",
            soDTPH: ""
          }),
        })
        .then((res) => {
          if (res.data.content == 0) {
            messageApi.error({
              type: "error",
              content: "Email hoặc Sđt trùng, vui lòng kiểm tra lại",
            });
          } else {
            formik.resetForm();
            form.resetFields();
            dispatch(getNguoiDungPhanTrangApi(queryConfig));
            messageApi.open({
              type: "success",
              content: "Tạo người dùng thành công",
            });
            handleCancel();
          }
        })
        .catch((err) => {
          messageApi.error({
            type: "error",
            content: "Có lỗi xảy ra, vui lòng thử lại",
          });
        });
    },
  });
  const { handleChange, handleBlur, errors, touched, handleSubmit } = formik;

  return (
    <>
      {contextHolder}
      <Drawer
        title="Chỉnh sửa thông tin người dùng"
        placement="right"
        onClose={() => {
          formik.resetForm({
            values: {
              hoTen: "a",
              soDT: "",
              matKhau: "",
              email: "",
              maNhomQuyen: "USER",
              facebook:""
            },
          });
          handleCancel();
        }}
        open={isModalOpen}
        size="large"
        width={720}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={handleCancel}>Đóng</Button>
            <Button
              onClick={() => {
                handleSubmit();
              }}
              type="primary"
            >
              Lưu lại
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="hoTen" label="Họ tên">
                <Input
                  name="hoTen"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Vui lòng nhập tên"
                />
                {touched.hoTen && errors.hoTen ? (
                  <div className="mt-2 text-danger">{errors.hoTen}</div>
                ) : null}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Khu vực">
                <Select

                  placeholder="Vui lòng chọn khu vực"

                  onChange={(value: boolean) => {
                    formik.setFieldValue("nuocNgoai", value);
                  }}

                  options={[
                    { value: true, label: "Nước ngoài" },
                    { value: false, label: "Việt Nam" }
                  ]}
                />

              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Nhóm quyền" >
                <Select
                  onChange={(value: string) => {
                    formik.setFieldValue("maNhomQuyen", value);
                  }}
                  options={lstNhomQuyen}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="email" label="Email">
                <Input
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ width: "100%" }}
                  placeholder="Vui lòng nhập email"
                />
                {touched.email && errors.email ? (
                  <div className="mt-2 text-danger">{errors.email}</div>
                ) : null}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="soDt" label="Số Đt">
                <Input
                  style={{ width: "100%" }}
                  placeholder="Vui lòng nhập số đt"
                  name="soDT"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.soDT && errors.soDT ? (
                  <div className="mt-2 text-danger">{errors.soDT}</div>
                ) : null}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Link facebook">
                <Input
                  name="facebook"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Vui lòng nhập link facebook"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Mật khẩu" name="matKhau">
                <Input.Password
                  name="matKhau"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Vui lòng nhập mật khẩu"
                />
                {touched.matKhau && errors.matKhau ? (
                  <div className="mt-2 text-danger">{errors.matKhau}</div>
                ) : null}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default FormTaoNguoiDung;
