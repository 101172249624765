import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card, Popconfirm, Tag } from "antd";
import { BaiHocModel } from "../../Types/baiHocType";
import { listLoaiTask } from "../../util/SelectOption";


type Props = {
    task: BaiHocModel,
    showModal: (task: BaiHocModel) => void,
    xoaBestPracticeKhoiChuyenDe: (taskId: number) => void
}

export const SortableItem = ({
    task, showModal, xoaBestPracticeKhoiChuyenDe
}: Props) => {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: task.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: 999
    }

    return (
        <div className=" m-3 row ">

            <div className="p-3 border col-11 bg-light rounded-start" ref={setNodeRef} style={style} {...attributes} {...listeners}>


                <div>
                    {`${task.id} - ${task.tenBaiHoc}`} <Tag color="red"  >{listLoaiTask.find(n => n.value == task.maLoai)?.label}</Tag>
                </div>



            </div >
            <div className="col-1 border bg-light px-0 py-3 rounded-end">
                <button className='btn btn-sm btn-success mx-2' onClick={() => {

                    showModal(task)
                }}><i className="fas fa-edit"></i></button>
                <Popconfirm
                    title="Bạn có chắc muốn xóa ?"
                    onConfirm={() => xoaBestPracticeKhoiChuyenDe(task.id)}
                >
                    <button className='btn btn-sm btn-danger'><i className="fas fa-times"></i></button>
                </Popconfirm>
            </div>
        </div >
    );
}
