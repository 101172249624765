import { BaseApiOnline} from './BaseServiceOnline';


const layLoTrinhOnLineService = () => {
    return BaseApiOnline.get('/api/lotrinh');
}

const layKhoaHocOnLineService = () => {
    return BaseApiOnline.get('/api/khoahoc');
}

const layChuongHocOnLineService = () => {
    return BaseApiOnline.get('/api/chuonghoc');
}

const layBaiHocOnLineService = () => {
    return BaseApiOnline.get('/api/baihoc');
}

const getVideoFtpOnlineService = (data:string)=>{
    return BaseApiOnline.get(`/api/file/ftp-video/${data}`)
}

export const loTrinhOnlineService = {
    
    layBaiHocOnLineService,
    layLoTrinhOnLineService,
    layKhoaHocOnLineService,
    layChuongHocOnLineService,
    getVideoFtpOnlineService
}