import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataGroupChat } from '../Types/groupChatType'
import { HubConnection } from '@microsoft/signalr'

export const deepCompare = (obj1: any, obj2: any): boolean => {

  const keys1 = obj1 ? Object.keys(obj1) : [];
  const keys2 = obj2 ? Object.keys(obj2) : [];

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    // Kiểm tra nếu cả hai giá trị là đối tượng và thực hiện so sánh sâu
    if (
      typeof val1 === 'object' &&
      val1 !== null &&
      typeof val2 === 'object' &&
      val2 !== null
    ) {
      if (!deepCompare(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
};

export interface Context {
  messages: { message: string; description: string }
  connection: HubConnection | null
  sendNotify: (message: string, description: string) => void
  addConn: (data: DataGroupChat, connection: HubConnection) => void
  delConn: (data: DataGroupChat) => void
  changeConn: (connectionId: string) => void
  findConnection: (data: DataGroupChat) => null | string // Update the return type if needed
}

const AuthContext = createContext<Context>({
  messages: { message: '', description: '' },
  connection: null,
  sendNotify: () => {},
  addConn: () => {},
  delConn: () => {},
  changeConn: () => {},
  findConnection: () => null
})

// const apiUrl = API_URL_DOMAIN
interface AuthProviderProps {
  children: ReactNode
}
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const dispatch = useDispatch()

  const [messages, setMessages] = useState({ message: '', description: '' })

  const sendNotify = (mess: string, description: string) => {
    // console.log(mess, description)
    const newMess = { message: mess, description }
    // console.log(deepCompare(newMess, messages), 'deepCompare')
    // console.log('😀 ~ file: useChatHub.js:73 ~ sendNotify ~ newMess,messages:', newMess, messages)
    setMessages(newMess)
  }

  const [connection, setConnection] = useState<HubConnection | null>(null)
  const [connections, setConnections] = useState<{ data: DataGroupChat; connection: HubConnection }[]>([])

  const addConn = (data: DataGroupChat, connection: HubConnection) => {
    // console.log('😀 ~ file: useChatHub.js:67 ~ addConn ~ data, connection:', data, connection)

    setConnections((conns) => [...conns, { data: { ...data }, connection }])
  }
  const delConn = (data: DataGroupChat) => {
    // console.log(connections)

    if (data) {
      setConnections((conns) => {
        const index = conns.findIndex((e) => deepCompare(e.data, data))
        conns.splice(index, 1)
        return [...conns]
      })
    }
  }
  const changeConn = (connectionId: string) => {
    // console.log(connections)

    if (connectionId) {
      const index = connections.findIndex((conn) => conn.connection.connectionId === connectionId)
      if (index !== -1) setConnection(connections[index].connection)
    }
  }
  const findConnection = (data: DataGroupChat) => {
    if (data) {
      const index = connections.findIndex((e) => deepCompare(e.data, data))

      if (index !== -1 && connections[index].connection && connections[index].connection.state === 'Connected') {
        return connections[index].connection.connectionId
      }
    }
    return null
  }

  const Values = {
    messages,
    connection,
    addConn,
    changeConn,
    findConnection,
    delConn,
    sendNotify
  }

  return <AuthContext.Provider value={Values}>{children}</AuthContext.Provider>
}

export default function UseChatConnection() {
  return useContext(AuthContext)
}
