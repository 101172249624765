import React from 'react'
import { DanhGiaModel } from '../../../Types/danhGia'
import Table, { ColumnsType } from 'antd/es/table'
import { TableCSS, TableCSSDanhGia } from '../../../assets/style/CustomAntdCss'
import { Rate, Tooltip } from 'antd'
import styled from 'styled-components'

type DanhGiaType = {
    data: DanhGiaModel[]
}
const CustomStyle = styled.div`
.item_rank {
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    text-align: center;
    .ant-rate {
      .ant-rate-star-first {
        span {
          color: rgb(255, 205, 23);
        }
      }
      .ant-rate-star.ant-rate-star-half.ant-rate-star-active {
        .ant-rate-star-second {
          span {
            color: rgb(250 219 21 / 13%);
          }
        }
      }
      .ant-rate-star.ant-rate-star-zero {
        .ant-rate-star-second {
          span {
            color: rgb(250 219 21 / 13%);
          }
        }
      }
    }

    /* span:last-child {
      color: rgb(255, 205, 23);
    } */

    /* .anticon.anticon-star {
      background: linear-gradient(180deg, #ff0c0c 0%, #fa00ff 100%);
    } */
  }
`

const DanhGia: React.FC<DanhGiaType> = ({ data }) => {
    const collumn: ColumnsType<DanhGiaModel> = [
        {
            title: 'Người đánh giá',
            key: 'nguoiDanhGia',
            render: (_, __, index) => {
                return <div className='text-start'>
                    <p className='font-bold text-white'>Ẩn danh</p>
                   <Tooltip title={_.noiDungDanhGia}>
                   <button className='btn btn-sm btn-success mb-3'>Nhận xét</button>
                   </Tooltip>
                </div>
            }
        },
        {
            title: 'Thông Tin',
            key: 'thongTin',
            render: (_, __, index) => {
                const danhgia = JSON.parse(_.danhGiaRank)
                return <CustomStyle>
                    <div key={index} className='month-rank'>
                        <div className='item_rank'>
                            <span> Chia Sẽ kiến thức </span>

                            <Rate
                                disabled
                                allowHalf
                                defaultValue={danhgia.chiaSeKienThuc}
                                style={{ gap: '5px' }}
                            />
                        </div>

                        <div className='item_rank'>
                            <span> Hỗ Trợ Học Viên </span>

                            <Rate
                                disabled
                                allowHalf
                                defaultValue={danhgia.hoTroHocVien}
                                style={{ gap: '5px' }}
                            />
                        </div>
                        <div className='item_rank'>
                            <span> Kiến Thức Cao </span>

                            <Rate
                                disabled
                                allowHalf
                                defaultValue={danhgia.kienThucCao}
                                style={{ gap: '5px' }}
                            />
                        </div>
                        <div className='item_rank'>
                            <span> Chấm Bài Đúng Hạn</span>

                            <Rate
                                disabled
                                allowHalf
                                defaultValue={danhgia.chamBaiDungHan}
                                style={{ gap: '5px' }}
                            />
                        </div>
                        <div className='item_rank'>
                            <span> Thân Thiện </span>

                            <Rate
                                disabled
                                allowHalf
                                defaultValue={danhgia.thanThien}
                                style={{ gap: '5px' }}
                            />
                        </div>
                        <div className='item_rank'>
                            <span> Năng Động</span>

                            <Rate
                                disabled
                                allowHalf
                                defaultValue={danhgia.nangDong}
                                style={{ gap: '5px' }}
                            />
                        </div>
                    </div>
                </CustomStyle>
            }
        }
    ]
    return (
        <TableCSSDanhGia>
            <Table style={{ width: '500px', background:'#000'}} columns={collumn} dataSource={data} />
        </TableCSSDanhGia>

    )
}

export default DanhGia;