import { Segmented } from "antd";

import React, { ReactNode } from "react";
import styled from "styled-components";

interface props {
  // options: ((string|number) | {laber:string,value:string|number,displayed:disabled})[];
  option :string[] | number[] | Array<{ label: ReactNode, value: string, icon?: ReactNode, disabled?: boolean, className?: string }>
  value: string | number;
  setValue:React.Dispatch<React.SetStateAction<string | number>>;
}
const SegmentedCSS = styled.div`
width: 100%;
  .ant-segmented {
    transition: all 0.2s;
    width: 100%;
    background-color: transparent;
    .ant-segmented-group {
      width: 100%;
      justify-content: space-around;
      .ant-segmented-item-selected {
        background: radial-gradient(
          154.25% 156.67% at 100% 135.53%,
          rgb(248, 219, 119) 20.4%,
          rgb(236, 169, 55) 31.76%,
          rgb(255, 201, 76) 51.58%
        );
      }
     
      .ant-segmented-thumb {
        background: radial-gradient(
          154.25% 156.67% at 100% 135.53%,
          rgb(248, 219, 119) 20.4%,
          rgb(236, 169, 55) 31.76%,
          rgb(255, 201, 76) 90.58%
        );
      }
      .ant-segmented-item-label {
        color: white;
        font-weight: bold;
      }
      .ant-segmented-item.ant-segmented-item-selected{
        .ant-segmented-item-label {
        color: black;
        font-weight: bold;
      }
      }
    
  }}
`;

const SegmentedTemp: React.FC<props> = (props) => {
  
  return (
    <SegmentedCSS>
      
      <Segmented
        options={props.option}
        value={props.value}
        onChange={props.setValue}
        color="red"
      />
     
    </SegmentedCSS>
  );
};

export default SegmentedTemp;
