import { LichSuHocTapViewModel } from "../Types/lichSuHocTap";
import { http } from "../util/config";


const layLichSuHocTapMonHocService = (chuyenDeId:number,monHocId:number) => {
    return http.get(`/api/lich-su-hoc-tap/monHoc?chuyenDeId=${chuyenDeId}&monHocId=${monHocId}`);
}
const updateLichSuHocTapMonHocService = (obj:LichSuHocTapViewModel) => {
    return http.put(`/api/lich-su-hoc-tap`,obj);
}


export const lichSuHocTapService = {
    layLichSuHocTapMonHocService,
    updateLichSuHocTapMonHocService
}