import { ChuyenDeModel } from "../Types/chuyenDeType";
import { http } from "../util/config";

const GET_PATH: string = '/api/chuyende';
const GET_PATH_DIEM_DANH: string = '/api/diemdanh';

const getListClass = () => {
    return http.get(GET_PATH + "/DsClass")
}

const getDiemDanhLop = (chuyenDeId: number) => {
    return http.get(GET_PATH_DIEM_DANH + "/get-by-lop/" + chuyenDeId);
}

const themDiemDanhLop = (model: any) => {
    return http.post(GET_PATH_DIEM_DANH + "/by-lop", model);
}

const capNhatDiemDanhLop = (model: any) => {
    return http.put(GET_PATH_DIEM_DANH + "/by-lop/" + model.id, model);
}

export const classService = {
    getListClass,
    getDiemDanhLop,
    themDiemDanhLop,
    capNhatDiemDanhLop
}