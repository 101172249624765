import { Avatar, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import React, { useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { ChatModel } from '../../Types/groupChatType'
import './style.css'
import { config } from '../../util/config'
import { NguoiDungType } from '../../Types/nguoiDungType'
import { NguoiDungGroupForTask } from '../../Types/groupForTask'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  getGroupChatByDsChatApi,
  getGroupChatByDsChatByChuyenDeApi
} from '../../redux/GroupChatReducer/groupChatReducer'
import { IssueModel } from '../../Types/issueType'
import { putoptionalApi } from '../../redux/IssueReducer/IssueReducer'

const ConntentMessageCSS = styled.div`
  height: 450px;
  width: 100%;
  padding: 0px 20px;
  background: #141824;
  .MessageContainerCSS {
    width: 100%;
    height: 400px;
    /* max-width: 700px; */

    overflow: auto;
    border-radius: 5px;
    margin: auto;

    .myMess,
    .yourMess {
      width: 100%;

      padding: 10px 0px;

      .content {
        width: 80%;
        display: flex;
        gap: 10px;
        .avatar {
        }
        .chat {
          padding: 10px;
          font-size: 13px;
          min-width: 150px;

          p {
            margin-bottom: 5px;
            font-weight: boid;
          }
        }
      }
    }
    .myMess {
      .content {
        margin-right: auto;
        .chat {
          border-radius: 10px 10px 10px 0px;

          background-color: rgb(52, 58, 75);
          border: 1px solid #31374a;

          p {
            font-size: 16px;
            color: #ffffff;
          }
          span {
            font-size: 12px;
            color: #a0a6bc;
          }
        }
      }
    }
    .yourMess {
      .content {
        margin-left: auto;
        justify-content: flex-end;

        text-align: right;
        .chat {
          border: 1px solid #31374a;
          background: rgb(56, 116, 255);
          border-radius: 10px 10px 0px 10px;

          color: rgb(255, 255, 255);
          p {
            font-size: 16px;
            /* color: #a0a6bc; */
          }
          span {
            font-size: 12px;
            /* color: #a0a6bc; */
          }
        }
      }
    }

    .rowCss {
      margin-top: 10px;
    }
    .bg_user {
    }
    .textAlignRight {
      text-align: right;
    }
    .contentMess {
      font-size: 1rem;
      font-weight: 500;
      padding: 15px 10px;
      color: black;
      background: rgb(219, 174, 151);
      background: -moz-linear-gradient(
        287deg,
        rgba(219, 174, 151, 1) 0%,
        rgba(219, 185, 168, 1) 50%,
        rgba(210, 183, 168, 1) 100%
      );
      background: -webkit-linear-gradient(
        287deg,
        rgba(219, 174, 151, 1) 0%,
        rgba(219, 185, 168, 1) 50%,
        rgba(210, 183, 168, 1) 100%
      );
      background: linear-gradient(
        287deg,
        rgba(219, 174, 151, 1) 0%,
        rgba(219, 185, 168, 1) 50%,
        rgba(210, 183, 168, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dbae97",endColorstr="#d2b7a8",GradientType=1);
      border-radius: 5px;
    }
    .userBG {
      background: linear-gradient(
        90deg,
        rgba(242, 231, 223, 1) 0%,
        rgba(238, 215, 198, 1) 50%,
        rgba(237, 203, 178, 1) 100%
      );
    }
    /* .clineBG {
      background-color: #edcbb2;
    } */
  }
  .suport {
    height: 50px;
    display: flex;
    justify-content: end;
  }
`

interface props {
  updateIssuee: () => Promise<void>
  callApi: () => void
}

const ConntentMessage: React.FC<props> = ({ updateIssuee, callApi }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { issueState, issuesMentorPhuTrach } = useAppSelector((state) => state.IssueReducer)

  const myUser = config.getStoreJson('USER_LOGIN') as NguoiDungType
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const paramChuyenDeId = Number(params.get('paramChuyenDeId'))
  const paramHocVienId = params.get('paramHocVienId')
  const paramGFTId = Number(params.get('paramGFTId'))
  const paramIssueId = Number(params.get('paramIssueId'))

  const { chats } = useAppSelector((state) => state.groupChatReducer)

  const { thongTinMentor } = useAppSelector((state) => state.groupForTaskReducer)

  const messageRef = useRef<any>()
  // Khi messages được cập nhập, thanh điều hướng sẽ đưa nội dung xuống cuối trang
  useEffect(() => {
    if (messageRef && messageRef.current) {
      const { scrollHeight, clientHeight } = messageRef.current
      messageRef.current.scrollTo({
        left: 0,
        top: scrollHeight - clientHeight,
        behavior: 'smooth'
      })
    }
  }, [chats])

  const showName = (id: string, arr: NguoiDungGroupForTask[]) => {
    let hoten = ''
    if (arr.map((e) => e.id).includes(id)) hoten = arr[arr.findIndex((e) => e.id === id)]?.hoTen
    else if (id === thongTinMentor.id) hoten = thongTinMentor.hoTen
    return hoten
  }

  const convertTime = (dateTimeString: string) => {
    const dateTime = new Date(dateTimeString)
    const hour = dateTime.getHours()
    const minute = dateTime.getMinutes()

    let formattedHour = hour % 12
    if (formattedHour === 0) {
      formattedHour = 12
    }

    const period = hour >= 12 ? 'PM' : 'AM'
    return `${formattedHour}:${minute < 10 ? '0' + minute : minute} ${period}`
  }

  const showContentMessage = (messages: ChatModel[]) => {
    return messages.map((e, i) => {
      return (
        <div className={`${e.UserSendId !== myUser.id ? 'myMess' : 'yourMess'}`} key={`chat ${i}`}>
          <div className='content'>
            {e.UserSendId !== myUser.id ? (
              <div className='avatar'>
                <Avatar src={'https://i.pravatar.cc/300'} size={36} />
                {/* <Avatar src={<img src={url} alt="avatar" />} /> */}
              </div>
            ) : (
              ''
            )}
            <div className='chat'>
              <p>{e.Content}</p>
              <span>{convertTime(e.TimeCreate)}</span>
            </div>
            {e.UserSendId !== myUser.id ? (
              ''
            ) : (
              <div className='avatar'>
                <Avatar src={'https://i.pravatar.cc/300'} size={36} />
                {/* <Avatar src={<img src={url} alt="avatar" />} /> */}
              </div>
            )}
          </div>
        </div>
      )
    })
  }
  const UpdateIssuee = async (idIssue: number, data: IssueModel) => {
    await dispatch(putoptionalApi(idIssue, data))
  }


  const handleDoneIssue = async () => {
    const index = issuesMentorPhuTrach.map((e) => e.id).findIndex((e) => e === paramIssueId)
    if (index !== -1) {
      const newIssue = { ...issuesMentorPhuTrach[index] }
      newIssue.trangThai = 'CHO_PHAN_HOI'
      await UpdateIssuee(newIssue.id, newIssue)
      await updateIssuee()
      callApi()
      navigate('/mentor')
    }
  }

  return (
    <ConntentMessageCSS>
      <div ref={messageRef} className='MessageContainerCSS'>
        <> {showContentMessage(chats)}</>
      </div>
      {paramChuyenDeId && paramHocVienId && paramIssueId ? (
        <div className='suport p-1'>
          <div onClick={handleDoneIssue} className='btn btn-outline-light d-flex align-items-center'>
            Done
          </div>
        </div>
      ) : (
        ''
      )}
    </ConntentMessageCSS>
  )
}

export default ConntentMessage
