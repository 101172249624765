import { BackwardOutlined, PlusOutlined } from "@ant-design/icons";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Button, Modal, Space, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DispatchType, RootState } from "../../redux/configStore";
import { BaiHocModel } from "../../Types/baiHocType";
import { SortableItem } from "./SortableItem";
import { NoiDungMonHoc } from "../../Types/monHocType";
import NguonThemTask from "../Task/NguonThemTask";
import BaiHocAbove from "../HocTap/BaiHocAbove";
import { RuncodeModel } from "../../Types/runCodeType";
import { listType } from "./TaoMonHoc";
import { array_move, listObject_move, swapElements } from "../../util/adminUti";
import { chuyenDeService } from "../../services/chuyenDeService";
import { getChuyenDeApi } from "../../redux/ChuyenDeReducer/chuyenDeReducer";
import { useDispatch } from "react-redux";
import { ChuyenDeModel } from "../../Types/chuyenDeType";

type Props = {
  chuyenDeId: number;
};

const TaoBestPractice = ({ chuyenDeId }: Props) => {
  const [taskDetail, setTaskDetail] = useState<BaiHocModel>({ id: 0 });
  console.log(
    "😀 ~ file: TaoBestPractice.tsx:32 ~ TaoBestPractice ~ taskDetail:",
    taskDetail
  );
  const [taskDetailNoiDung, setTaskDetailNoiDung] = useState<NoiDungMonHoc>({
    id: 0,
    maLoaiNhiemVu: "TAILIEU",
  });
  const [runcodeDetail, setRuncodeDetail] = useState<RuncodeModel>({ id: 0 });

  // modal thêm bài học

  const [isModalOpen, setIsModalOpen] = useState(false);

  // modal nguồn thêm

  const [isModalNguonOpen, setIsModalNguonOpen] = useState(false);

  const { arrChuyenDe } = useSelector(
    (state: RootState) => state.chuyenDeReducer
  );
  const { arrBaiHoc } = useSelector((state: RootState) => state.baiHocReducer);

  const navigate = useNavigate();

  const dispatch: DispatchType = useDispatch();

  let chuyende = arrChuyenDe?.find(
    (item) => Number(item.id) === Number(chuyenDeId)
  );

  let danhSach = chuyende && JSON.parse(chuyende.danhSachNoiDung);
  console.log("😀 ~ file: TaoBestPractice.tsx:64 ~ TaoBestPractice ~ danhSach:", danhSach)

  let danhSachBestPracticeId: number[] = danhSach?.danhSachBest || [];
  console.log("😀 ~ file: TaoBestPractice.tsx:66 ~ TaoBestPractice ~ danhSachBestPracticeId:", danhSachBestPracticeId)

  let danhSachBestPractice: BaiHocModel[] = danhSachBestPracticeId
    .map((id) => arrBaiHoc?.find((item) => item.id === id))
    .filter(Boolean) as BaiHocModel[];
  console.log("😀 ~ file: TaoBestPractice.tsx:70 ~ TaoBestPractice ~ danhSachBestPractice:", danhSachBestPractice)

  const showModal = (task: BaiHocModel | null) => {
    if (task) {
      setTaskDetail({ ...task });
    } else setTaskDetail({ id: 0 });

    setIsModalOpen(true);
  };

  const modalClose = () => {
    setIsModalOpen(false);
    setTaskDetail((pre) => ({ id: 0 }));
    console.log("close", taskDetail);
  };

  const showNguonModal = (task: NoiDungMonHoc | null) => {
    if (task) {
      setTaskDetail({ ...task });
    }
    setIsModalNguonOpen(true);
  };

  const modalNguonClose = () => {
    setIsModalNguonOpen(false);
  };

  const xoaBestPracticeKhoiChuyenDe = (bestPracticeId: number) => {
    let noiDung = { ...danhSach };

    let updatedDanhSachBestPractice = danhSachBestPracticeId.filter(
      (id) => id !== bestPracticeId
    );

    noiDung.danhSachBest = updatedDanhSachBestPractice;

    let newChuyenDe = { ...chuyende };

    newChuyenDe.danhSachNoiDung = JSON.stringify(noiDung);

    chuyenDeService
      .suaChuyenDe(newChuyenDe)
      .then((res) => {
        dispatch(getChuyenDeApi());

        message.success("Đã lưu");
      })
      .catch((err) => {
        message.error("Lỗi Báo IT", err);
      });
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    let iOld = active.data.current?.sortable.index;
    let iNew = over?.data.current?.sortable.index;

    if (iNew != undefined) {
      let noiDung = { ...danhSach };

      let danhSachBestNew = array_move(danhSachBestPracticeId, iOld, iNew);

      // update noi dung danh sach
      noiDung.danhSachBest = danhSachBestNew;

      let setNoiDung = { ...noiDung };

      let newChuyenDe = { ...chuyende };

      newChuyenDe.danhSachNoiDung = JSON.stringify(setNoiDung);

      chuyenDeService
        .suaChuyenDe(newChuyenDe)
        .then((res) => {
          dispatch(getChuyenDeApi());

          message.success("Đã lưu");
        })
        .catch((err) => {
          message.error("Lỗi Báo IT");
        });
    }
  };

  const setMaLoaiBaiTap = (maLoai: string) => {
    setTaskDetail({ ...taskDetail, maLoai });
  };

  const updateBestPracticeVaoChuyenDe = (
    baiHoc: BaiHocModel,
    maLoai: string,
    isRemove: boolean,
    chuongId: number
  ) => {
    if (taskDetail) {
      let noiDung = { ...danhSach };

      // Kiểm tra xem noiDung có thuộc tính danhSachBest hay không
      if (!noiDung.hasOwnProperty('danhSachBest')) {
        noiDung.danhSachBest = []; // Nếu không có, tạo mới danhSachBest
      }

      let newBestPracticeId = baiHoc.id;
      if(!noiDung.danhSachBest){
        noiDung.danhSachBest=[]
      }
      
      noiDung.danhSachBest.push(newBestPracticeId);

      let setNoiDung = { ...noiDung };

      let newChuyenDe = { ...chuyende };

      newChuyenDe.danhSachNoiDung = JSON.stringify(setNoiDung);

      chuyenDeService
        .suaChuyenDe(newChuyenDe)
        .then((res) => {
          if (res.data.statusCode === 200 || res.data.statusCode === 201) {
            dispatch(getChuyenDeApi());
            message.success("Đã thêm thành công");
            // modalClose()
          }
        })
        .catch((err) => {
          message.success("Lỗi báo IT");
        });
    }
  };


  const updateTaskVaoMonHoc = (baiHoc: BaiHocModel, maLoai: string, isRemove: boolean, chuongId: number) => {
    //Xu lys cac truong hop
  };
  let opSegmented: listType[] = [];
  switch (taskDetail.maLoai) {
    case "VIDEO": {
      opSegmented = [{ value: "VIDEO", label: "Video" }];

      break;
    }
    case "QUIZ": {
      opSegmented = [{ value: "QUIZ", label: "Trắc nghiệm" }];
      break;
    }
    case "TAILIEU": {
      opSegmented = [{ value: "TAILIEU", label: "Tài liệu" }];
      break;
    }
    case "RUNCODE": {
      opSegmented = [{ value: "RUNCODE", label: "Runcode" }];
      break;
    }
    default: {
      opSegmented = [
        {
          label: (
            <>
              <i className="fas fa-file-signature"></i> Bài tập nộp
            </>
          ),
          value: "BAITAP",
        },
        // {
        //   label: (
        //     <>
        //       <i className="fas fa-user-friends"></i> Thảo luận
        //     </>
        //   ),
        //   value: "DISCUSS",
        // },
        {
          label: (
            <>
              <i className="fas fa-object-group"></i> Capstone
            </>
          ),
          value: "CAPS",
        },
        {
          label: (
            <>
              <i className="fas fa-object-group"></i> Dự án cuối khóa
            </>
          ),
          value: "DUAN",
        },
      ];
    }
  }

  useEffect(() => {}, []);

  return (
    <div className=" p-3">
      <Typography.Title level={2} style={{ marginBottom: "20px" }}>
        Best practice: <span>Danh sách best practice</span>
      </Typography.Title>

      <Space>
        <Button
          className="d-flex align-items-center bg-success mx-2 p-3"
          type="primary"
          style={{ fontSize: "16px", fontWeight: "bold" }}
          onClick={() => navigate(`/chuyende`)}
        >
          <BackwardOutlined className="fs-5" />
          <span>Back</span>
        </Button>
        <Button
          className="d-flex align-items-center bg-primary my-3 p-3"
          type="primary"
          style={{ fontSize: "16px", fontWeight: "bold" }}
          onClick={() => {
            showModal({ id: 0, maLoai: "TAILIEU" });
          }}
        >
          <PlusOutlined />
          <span>Thêm mới</span>
        </Button>
        <Button
          className="d-flex align-items-center my-3 p-3"
          block
          style={{ fontSize: "16px", fontWeight: "bold" }}
          onClick={() => {
            showNguonModal({ id: 0, maLoaiNhiemVu: "VIDEO" });
          }}
        >
          <PlusOutlined />
          <span>Thêm từ nguồn</span>
        </Button>
      </Space>

      <DndContext onDragEnd={onDragEnd}>
        {danhSachBestPractice !== undefined ? (
          <SortableContext
            items={danhSachBestPractice.map((item) => item.id)}
            strategy={verticalListSortingStrategy}
          >
            {danhSachBestPractice.map((item: BaiHocModel) => (
              <SortableItem
                key={item.id}
                task={item}
                showModal={showModal}
                xoaBestPracticeKhoiChuyenDe={xoaBestPracticeKhoiChuyenDe}
              />
            ))}
          </SortableContext>
        ) : null}
      </DndContext>

     {isModalOpen&& <Modal
        title="Chi tiết task"
        open={isModalOpen}
        onCancel={modalClose}
        width="80%"
        centered
        footer={null}
      >
        {isModalOpen ? (
          <div className="col-12 practice">
            <BaiHocAbove
              opSegmented={opSegmented}
              onClose={modalClose}
              setMaLoaiBaiTap={setMaLoaiBaiTap}
              taskDetail={taskDetail}
              runcodeDetail={runcodeDetail}
              updateTaskVaoMonHoc={updateTaskVaoMonHoc}
              updateBestPracticeVaoChuyenDe={updateBestPracticeVaoChuyenDe}
            />
          </div>
        ) : (
          <></>
        )}
      </Modal>}

      <Modal
        title="Nguồn task"
        open={isModalNguonOpen}
        onCancel={modalNguonClose}
        width="80%"
        centered
      >
        <NguonThemTask
          taskDetail={taskDetailNoiDung}
          updateTaskVaoMonHoc={updateTaskVaoMonHoc}
          updateBestPracticeVaoChuyenDe={updateBestPracticeVaoChuyenDe}
        />
      </Modal>
    </div>
  );
};

export default TaoBestPractice;
