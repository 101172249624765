import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChuyenDeModel } from '../../Types/chuyenDeType'
import { DispatchType, RootState } from '../../redux/configStore'
import { Card, Collapse, Divider, Drawer, Input, Modal, Popconfirm, Result, Select, Table, TableColumnsType, TableProps, message } from 'antd'
import { chuyenDeService } from '../../services/chuyenDeService'
import { getChuyenDeApi, getDSChuyenDeMentorApi, getListTagApi } from '../../redux/ChuyenDeReducer/chuyenDeReducer'
import ChuyenDeForm from '../../components/ChuyenDe/ChuyenDeForm'
import { useNavigate } from 'react-router-dom'
import { NguoiDungType } from '../../Types/nguoiDungType'
import { config } from '../../util/config'
import { getMonHocApi } from '../../redux/MonHocReducer/monHocReducer'
import { getPracticeApi } from '../../redux/PracticeReducer/practiceReducer'
import { getBaiHocApi } from '../../redux/BaiHocReducer/BaiHocReducer'
import {
  getDanhSachGiangVienApi,
  getDanhSachMentorApi,
  getNguoiDungPhanTrangApi
} from '../../redux/UserReducer/userReducer'
import { fetchCategories, fetchQuestions } from '../../redux/RunCodeReducer/runcodeReducer'
import { getBaiTestApi, getNhomQuyenApi } from '../../redux/HeThongReducer/heThongReducer'
import { getListItemsApi } from '../../redux/VatPhamReducer/vatPhamReducer'
import useQueryConfig from '../../hooks/useQueryConfig'

type Props = {}

const { Panel } = Collapse

type danhSachNoiDung = {
  danhSachMonHoc: string
}
let chuyenDeEmpty: ChuyenDeModel = {
  id: 0,
  tenChuyenDe: '',
  danhSachNoiDung: '{"danhSachMonHoc":[]}',
  moTa: '',
  thoiHan: 0,
  tenChungChi: '',
  gioiThieu: '',
  danhSachTag: '[]',
  hinhAnh: '',
  danhSachMentor: '[]',
  danhSachGiangVien: '[]'
}

const { Search } = Input;


export default function QuanLyChuyenDe({ }: Props) {
  const myUser = config.getStoreJson('USER_LOGIN') as NguoiDungType
  const [open, setOpen] = useState(false)
  const [clas, setClass] = useState<ChuyenDeModel | null>(null)
  const [nameClass, setNameClass] = useState('')
  const [loaiChuyenDe, setLoaiChuyenDe] = useState('')

  const [chuyenDeDetail, setChuyenDeDetail] = useState<ChuyenDeModel>(chuyenDeEmpty)
  const navigate = useNavigate()

  let { arrChuyenDe } = useSelector((state: RootState) => state.chuyenDeReducer)

  // get redux
  const dispatch: DispatchType = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isThongBao, setIsThongBao] = useState(false)
  const [searchModule, setSearchModule] = useState("");


  const showModal = () => {

    setIsModalOpen(true)
  }
  const handleChange = (e: any) => {


    setNameClass(e.target.value)
  }

  const handleOk = async () => {
    let div_loading = document.getElementsByClassName("div_loading");
    div_loading[0].removeAttribute("hidden");

    chuyenDeService.cloneChuyenDe({ loai: loaiChuyenDe, chuyenDeId: clas?.id, tieuDe: nameClass }).then(res => {
      setIsThongBao(true);
      message.success("Tạo mới thành công !")
    }).finally(() => {
      div_loading[0].setAttribute("hidden", "");
    })
  }

  const handleCancel = () => {
    setNameClass('')
    setIsModalOpen(false)
  }

  // function else
  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }
  const queryConfig = useQueryConfig()

  const role = config.getStoreJson('USER_LOGIN') as NguoiDungType
  useEffect(() => {
    if (role && (role.maNhomQuyen === 'ADMIN' || role.maNhomQuyen === 'LECTURE')) {
      dispatch(getChuyenDeApi())
    } else {
      dispatch(getDSChuyenDeMentorApi(role?.id))
    }

    dispatch(getMonHocApi())
    dispatch(getPracticeApi())
    dispatch(getBaiHocApi())
    dispatch(getNguoiDungPhanTrangApi(queryConfig))
    dispatch(fetchQuestions())
    dispatch(fetchCategories())
    dispatch(getListTagApi())
    dispatch(getDanhSachMentorApi())
    dispatch(getDanhSachGiangVienApi())

    dispatch(getBaiTestApi())
    dispatch(getListItemsApi())
    dispatch(getNhomQuyenApi())
  }, [])

  let userLogin = localStorage.getItem('USER_LOGIN')
  let nguoiDung = userLogin ? JSON.parse(userLogin) : null

  if (nguoiDung && nguoiDung.maNhomQuyen == 'LECTURE') {
    if (arrChuyenDe) {
      arrChuyenDe = arrChuyenDe?.filter((item) => {
        if (item.danhSachGiangVien) {
          let danhSachGiangVien = JSON.parse(item.danhSachGiangVien)

          if (danhSachGiangVien.find((n: any) => n == nguoiDung.id)) {
            return item
          }
        }
      })
    }
  }


  if (arrChuyenDe) {
    arrChuyenDe = arrChuyenDe.filter(item => item.tenChuyenDe?.toLowerCase().indexOf(searchModule.toLowerCase()) != -1)
  }

  const columns: TableProps<any>['columns'] = [
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        let { danhSachMonHoc } = JSON.parse(record.danhSachNoiDung)

        return <>
          <p>
            <a onClick={() => { navigate('/monhoc/' + record.id) }}>
              {record.tenChuyenDe} <label className='text-danger'> ({danhSachMonHoc.length} môn học)</label>
            </a>
          </p>
        </>
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <>
            <button
              className='btn btn-sm btn-info'
              onClick={() => {
                navigate('/monhoc/' + record.id)
              }}
            >
              <i className='fas fa-forward'></i>
            </button>

            {role && role.maNhomQuyen === 'ADMIN' &&
              <>
                <button
                  className='btn btn-sm btn-primary mx-2'
                  onClick={() => {
                    setClass(record)
                    setNameClass('')
                    setIsThongBao(false)
                    showModal()
                  }}
                >
                  <i className="fa-solid fa-copy"></i> Clone
                </button>



                <button
                  className='btn btn-sm btn-success mx-2'
                  onClick={(event) => {
                    event.stopPropagation()

                    setChuyenDeDetail(record)
                    showDrawer()
                  }}
                >
                  <i className='fas fa-edit'></i>
                </button>
                <Popconfirm
                  title='Bạn có chắc muốn xóa ?'
                  onConfirm={() =>
                    chuyenDeService
                      .xoaChuyenDe(record.id)
                      .then((res) => {
                        dispatch(getChuyenDeApi())

                        message.success('Xóa thành công')
                      })
                      .catch((err) => {
                        message.error('Lỗi Báo IT')
                      })
                  }
                >
                  <button
                    className='btn btn-sm btn-danger'
                    onClick={(event) => {
                      event.stopPropagation()
                    }}
                  >
                    <i className='fas fa-trash'></i>
                  </button>
                </Popconfirm>
              </>
            }

          </>
        )

      }
    },
  ];


  return (
    <div className=' p-3'>
      {role && role.maNhomQuyen === 'ADMIN' ? (
        <button
          className='btn btn-primary my-3'
          onClick={() => {
            setChuyenDeDetail(chuyenDeEmpty)
            showDrawer()
          }}
        >
          Tạo module
        </button>

      ) : (
        <></>
      )}

      <Search
        placeholder="Tên module"
        allowClear
        enterButton="Tìm"
        style={{ width: 300 }}
        className='ms-3 my-3'
        onSearch={(value) => {
          setSearchModule(value)
        }}
      />

      <Divider />

      {arrChuyenDe && <Table columns={columns} dataSource={arrChuyenDe} />}


      <Drawer title='Quản lý chuyên đề' width='40%' onClose={onClose} open={open} bodyStyle={{ paddingBottom: 80 }}>
        <ChuyenDeForm chuyenDeDetail={chuyenDeDetail} onClose={onClose} />
      </Drawer>

      <Modal title='Khởi tạo bản chính' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
        footer={isThongBao ? null : <button className='btn btn-primary' onClick={handleOk} >Đồng ý</button>}
      >
        {isThongBao ?
          <Result
            status="success"
            title="Tạo mới thành công"
          />
          :
          <div className='row'>

            <div className='col-12'>
              <Input key={clas?.id} placeholder='Nhập tên chuyên đề mới...' onChange={handleChange} />
            </div>
          </div>

        }
      </Modal>
    </div>
  )
}
